import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, Image, Carousel } from '@belong/ui';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import ApplicationSelector from 'components/ApplicationSelector/ApplicationSelector';
import Button from 'components/Button/Button';
import Divider from 'components/Divider/Divider';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { Tag } from 'components/HomeTag/HomeTag';
import IconButton from 'components/IconButton/IconButton';
import ImageCard from 'components/ImageCard/ImageCard';
import Spinner from 'components/Spinner/Spinner';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEmpty, isNil } from 'lodash';
import { ApplicantType } from 'models/enums/index';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchHome } from 'store/redux/homes/actions';
import { showModal } from 'store/redux/modals/actions';
import {
  fetchAllApplicationsForUser,
  fetchApplicantInfo,
  fetchMoveInDate,
  updateApplicationIdAppState,
} from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationsForUser,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { selectIsUserLoggedIn } from 'store/redux/user/selectors';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { MODALS } from '../../../../containercomponents/Modals/modal.consts';
import { CreditScoreModal } from '../CreditScoreModal/CreditScoreModal';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './PrimaryApplicantLandingPage.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.pa_landing_page;

const enjoy_section = [
  {
    icon: 'residents/r_application_moon.png',
    body: RAS.enjoy_section.stress_free,
  },
  {
    icon: 'residents/r_application_wallet.png',
    body: RAS.enjoy_section.on_time,
  },
  {
    icon: 'residents/r_application_phone.png',
    body: RAS.enjoy_section.belong_app,
  },
];

function EnjoySection({ inCarousel = false }) {
  return enjoy_section.map((item, index) => (
    <div
      className={clsx(
        'flex flex-col items-center p-xs md:px-sm md:pt-xl md:pb-0',
        inCarousel ? 'flex 1 min-w-full' : 'basis-6/12 md:basis-full'
      )}
      key={index}
    >
      <div className="mb-xs w-4xl">
        <Image src={item.icon} alt="" />
      </div>
      <Text variant="p1" className="text-center max-w-sm leading-p2">
        {item.body}
      </Text>
    </div>
  ));
}

function KeyFactorsItems({ inCarousel = false }) {
  return RAS.key_factors_section.items.map((item, index) => {
    return (
      <div className={clsx('flex flex-col', inCarousel ? 'min-w-full flex-1' : `${styles.item} mt-xl`)} key={index}>
        <Text fontWeight="semibold">{item.title}</Text>
        <Text variant="p1" className={clsx('leading-p2 mt-xs', !inCarousel && 'pr-sm')}>
          {item.body}
        </Text>
        {/* modal button for credit */}
        {item.title === 'Credit' && (
          <div className={clsx('flex justify-end mt-xs', !inCarousel && 'pr-sm')}>
            <CreditScoreModal />
          </div>
        )}
      </div>
    );
  });
}

class PrimaryApplicantLandingPage extends Component {
  state = {
    selectedApplicationId: '',
    awaitingFetchComplete: true,
    homeDetails: null,
  };

  awaitingFetchComplete = true;

  async componentDidMount() {
    const {
      fetchAllApplicationsForUser: fetchAllApplicationsForUserAction,
      fetchHome: fetchHomeAction,
      userLoggedIn,
      applyForHouseId,
      match,
    } = this.props;

    try {
      if (userLoggedIn) {
        await fetchAllApplicationsForUserAction();
      }

      const homeDetails = await fetchHomeAction(applyForHouseId || match?.params?.houseId);
      this.setState({ homeDetails });
    } catch (err) {
      console.error(err);
    }

    this.setLoadingComplete();
  }

  setLoadingComplete() {
    this.setState({
      awaitingFetchComplete: false,
    });
  }

  disableUseExistingButton() {
    const { selectedApplicationId } = this.state;

    if (selectedApplicationId && selectedApplicationId !== '') {
      return false;
    }

    return true;
  }

  handleClickSignIn = () => {
    const { showModal: showModalAction } = this.props;

    showModalAction(MODALS.LOGIN, {
      redirectToHomeOnHide: false,
      currentScreen: 'login_screen',
      onSucessfulLogin: () => {
        window.location.reload();
      },
    });
  };

  handleSelectPreviousApplication = (state, application) => {
    if (state) {
      this.setState({
        selectedApplicationId: application.basicInfo.applicationId,
      });
    } else {
      this.setState({
        selectedApplicationId: '',
      });
    }
  };

  handleClickStartNew = () => {
    const {
      applyForHouseId,
      history: { push },
      updateApplicationIdAppState: updateApplicationIdAppStateAction,
    } = this.props;
    updateApplicationIdAppStateAction('');

    push(getResidentApplicationStepPath(STEPS_CONFIG.MOVE_IN_DATE, { houseId: applyForHouseId }));
  };

  handleClickUseExisting = async () => {
    const { selectedApplicationId } = this.state;
    const {
      applyForHouseId,
      history: { push },
      updateApplicationIdAppState: updateApplicationIdAppStateAction,
      fetchApplicantInfo: fetchApplicantInfoAction,
      fetchMoveInDate: fetchMoveInDateAction,
    } = this.props;

    this.setState({ awaitingFetchComplete: true });

    updateApplicationIdAppStateAction(selectedApplicationId);
    // Do we need a mechanism to obtain the applicationId before navigating to MoveInDate?
    // This would enable us to change MoveInDate to be coupled with an applicationId

    let applicantInfo;
    try {
      applicantInfo = await fetchApplicantInfoAction(selectedApplicationId);
    } catch (err) {
      console.error(err);
    }

    const { applicantType } = applicantInfo || {};

    // If user is a coapplicant then redirect to credit check screen when trying to
    // apply to the same house, or move-in date screen if applying to a different house.
    // This condition will be reached only when a coapplicant reloads the page while
    // going through the flow
    if (applicantType !== ApplicantType.MainApplicant) {
      try {
        const moveInDate = await fetchMoveInDateAction(selectedApplicationId);

        if (isEmpty(moveInDate) || isNil(moveInDate)) {
          push(
            getResidentApplicationStepPath(STEPS_CONFIG.MOVE_IN_DATE, {
              houseId: applyForHouseId,
            })
          );
          return;
        }

        push(
          getResidentApplicationStepPath(STEPS_CONFIG.CREDIT_CHECK, {
            houseId: applyForHouseId,
            applicationId: selectedApplicationId,
          })
        );
        return;
      } catch (err) {
        console.error(err);
      }
    }

    push(getResidentApplicationStepPath(STEPS_CONFIG.MOVE_IN_DATE, { houseId: applyForHouseId }));
  };

  isApplicationChecked(application) {
    const { selectedApplicationId } = this.state;

    if (application.basicInfo.applicationId === selectedApplicationId) {
      return true;
    }

    return false;
  }

  showExistingApplicationsSection() {
    const { existingApplications, userLoggedIn } = this.props;

    const show = !userLoggedIn || existingApplications.length === 0;
    return show;
  }

  render() {
    const { applyForHouseId, existingApplications, userLoggedIn } = this.props;
    const { awaitingFetchComplete, homeDetails } = this.state;

    const homeBasicInfo = homeDetails ? homeDetails.basicInfo : null;
    const homeInterest = homeDetails ? homeDetails.homeInterests : null;

    const previousStepPropsNotLoggedIn = applyForHouseId
      ? {
          previousStep: `${BASE_PATHS.HOME}/${applyForHouseId}`,
          previousStepCustomLabel: RAS.back_to_house_page,
        }
      : {};

    if (awaitingFetchComplete && userLoggedIn) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <FormLayout>
        <FormLayout.Section>
          {homeBasicInfo && (
            <div className="relative -mt-sm md:mt-0 -left-2sm md:left-0 w-screen md:w-auto mb-xl md:mb-2xl h-7xl">
              <div className={cx('md:rounded-b absolute p-sm z-10', 'gradient')} />
              <Text
                variant="h3"
                fontWeight="semibold"
                className="absolute bottom-sm left-sm text-white whitespace-pre-line z-20"
              >
                {RAS.title}
              </Text>

              <ImageCard
                fullHeight
                showTag
                className={cx('h-full')}
                imageClassName={cx('border-0 rounded-none md:border-solid md:border-gray md:rounded')}
                imageUrl={homeBasicInfo.bannerImageUrl}
                tag={
                  homeInterest?.interested > 1 ? (
                    <Tag.LG>
                      <GeneralIcon icon={GENERAL_ICONS.FIRE} />
                      <div className={cx('tag-text')}>Hot Home!</div>
                    </Tag.LG>
                  ) : null
                }
              />
            </div>
          )}
          <div>
            <div>
              <Text variant="h3" fontWeight="semibold">
                {RAS.enjoy_section.title}
              </Text>
              <div className="hidden sm:flex flex-row">
                <EnjoySection />
              </div>
              <div className="flex sm:hidden mt-xl">
                <Carousel>
                  <EnjoySection inCarousel />
                </Carousel>
              </div>
            </div>
          </div>
          <div className="mt-2xl md:mt-3xl">
            <Text variant="h3" fontWeight="semibold">
              {RAS.key_factors_section.title}
            </Text>
            <div className="hidden sm:flex flex-row flex-wrap mr-sm md:mb-3xl">
              <KeyFactorsItems />
            </div>
            <div className="flex sm:hidden mt-xl">
              <Carousel>
                <KeyFactorsItems inCarousel />
              </Carousel>
            </div>
          </div>
        </FormLayout.Section>
        <div className={cx('bottom-section-wrapper', { hidden: this.showExistingApplicationsSection() })}>
          <FormLayout.Section
            sectionTitle={
              existingApplications.length === 1
                ? RAS.previous_applications_section.title_one
                : RAS.previous_applications_section.title_multiple
            }
          >
            <div className={cx('previous-applications-section-wrapper')}>
              {existingApplications.map((application, index) => (
                <ApplicationSelector
                  key={index}
                  application={application}
                  onSelect={(state) => this.handleSelectPreviousApplication(state, application)}
                  checked={this.isApplicationChecked(application)}
                />
              ))}
            </div>
          </FormLayout.Section>
        </div>
        <div className={cx('bottom-bar', { hidden: this.showExistingApplicationsSection() })}>
          <div className={cx('start-new-wrapper')}>
            <Button buttonType="text" onClick={this.handleClickStartNew}>
              {RAS.start_new}
            </Button>
          </div>
          <div className={cx('use-existing-wrapper')}>
            <Button
              size="fit"
              disabled={this.disableUseExistingButton()}
              // label=
              onClick={this.handleClickUseExisting}
            >
              <div className={cx('use-existing-button-label')}>{RAS.use_existing}</div>
            </Button>
          </div>
        </div>
        <div className={cx('bottom-bar-not-logged-in', { hidden: !this.showExistingApplicationsSection() })}>
          <div className={cx('bottom-bar-wrapper')}>
            <div className={cx('previous-step')}>
              {!isEmpty(previousStepPropsNotLoggedIn) && (
                <IconButton
                  to={previousStepPropsNotLoggedIn.previousStep}
                  icon={GENERAL_ICONS.BACK_ARROW}
                  label={previousStepPropsNotLoggedIn.previousStepCustomLabel}
                />
              )}
            </div>
            <div className={cx('right-controls')}>
              {!userLoggedIn && (
                <Button className={cx('resume-application')} onClick={this.handleClickSignIn} buttonType="text">
                  RESUME APPLICATION
                </Button>
              )}
              <Button
                className={cx('start-new')}
                type="submit"
                size="small"
                onClick={this.handleClickStartNew}
                label={RAS.start_new_not_logged_in}
              />
            </div>
          </div>
          <div className={cx('bottom-bar-wrapper-mobile')}>
            {!userLoggedIn && (
              <div className={cx('sign-in-section')}>
                <div className={cx('or-divider')}>
                  <Divider label="Or" labelClassName={cx('or-text')} />
                </div>
                <div className={cx('sign-in-text')}>
                  <Button onClick={this.handleClickSignIn} buttonType="text">
                    RESUME APPLICATION
                  </Button>
                </div>
              </div>
            )}
            <FormLayout.BottomBar
              ctaProps={{
                onClick: this.handleClickStartNew,
                label: RAS.start_new_not_logged_in,
              }}
              {...previousStepPropsNotLoggedIn}
            />
          </div>
        </div>
      </FormLayout>
    );
  }
}

PrimaryApplicantLandingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  updateApplicationIdAppState: PropTypes.func.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  existingApplications: PropTypes.arrayOf(PropTypes.object),
  fetchAllApplicationsForUser: PropTypes.func.isRequired,
  fetchApplicantInfo: PropTypes.func.isRequired,
  fetchHome: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  applyForHouseId: PropTypes.string,
  fetchMoveInDate: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

PrimaryApplicantLandingPage.defaultProps = {
  applyForHouseId: '',
  existingApplications: [],
};

export default connect(
  (state) => ({
    userLoggedIn: selectIsUserLoggedIn(state),
    applyForHouseId: selectResidentApplyForHouseId(state),
    existingApplications: selectResidentApplicationsForUser(state),
  }),
  {
    fetchAllApplicationsForUser,
    updateApplicationIdAppState,
    fetchHome,
    fetchApplicantInfo,
    fetchMoveInDate,
    showModal,
  }
)(PrimaryApplicantLandingPage);
