import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Text } from '@belong/ui';
import { UserModel } from 'api/models';
import classNames from 'classnames/bind';
import ColoredText, { COLORS } from 'components/ColoredText/ColoredText';
import Divider from 'components/Divider/Divider';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import { TourHostTile } from 'components/tour-host-tile/tour-host-tile';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import Space from 'corecomponents/Space/Space';
import { Box, Flex, Timeline } from 'design-system';
import { useModal } from 'hooks/useModal';
import startCase from 'lodash/startCase';
import {
  ApplicantStatus,
  ApplicationStatus,
  DisplayStatus,
  LeaseStatus,
  ListingApplicationStatus,
  UnitVisitType,
  UserSignatureStatus,
} from 'models/enums';
import RenterAccountBookmark from 'models/renterAccounts/RenterAccountBookmark';
import { ApplicationsInProcess } from 'pages/ResidentApplication/ApplicationsInProcess/ApplicationsInProcess';
import { PlaceInLine } from 'pages/ResidentApplication/PlaceInLine/PlaceInLine';
import { BASE_PATHS } from 'routes/paths';
import {
  cancelSelfTour,
  cancelPrivateTour,
  fetchResidentBookmarks,
  updateCancelResidentBookmarkById,
} from 'store/redux/renter-accounts/actions';
import { validateUserInterestSurveyEntry } from 'store/redux/user/actions';
import { selectUser } from 'store/redux/user/selectors';
import { arrayInsertIf } from 'utils/insertIf';
import BDate, { DATE_TYPES } from '../../components/BDate/BDate';
import styles from './TourAndAppsCard.module.css';
import { TourCard, TourCardActions, TourCardTitleWithDetails } from './TourCard';
import { WithdrawModal } from './WithdrawModal';

const cx = classNames.bind(styles);

const getTimelineSteps = (currentResidentId, applicants, isCosigner, applicationGroupHasTouredHome, leaseStatus) => {
  const otherResidents = applicants.filter((applicant) => applicant.residentId !== currentResidentId);
  return [
    {
      value: 'Submit your application',
      checked: true,
    },
    ...otherResidents.map((applicant) => {
      const { firstName, status } = applicant;
      const checked = status === ApplicantStatus.Submitted || status === ApplicantStatus.VerificationsCompleted;
      return {
        value: `Receive ${firstName}'s application`,
        checked,
      };
    }),
    ...arrayInsertIf(!isCosigner, { value: 'Tour the home', checked: applicationGroupHasTouredHome }),
    {
      value: 'Get approved',
      checked: leaseStatus === LeaseStatus.Offered || leaseStatus === LeaseStatus.ResidentsSigned,
    },
    {
      value: 'Become a Belong Resident',
      checked: false,
    },
  ];
};

const getStatus = (bookmark) => {
  if (bookmark.displayStatus === DisplayStatus.ScheduledTour) {
    if (bookmark.tourType === UnitVisitType.SelfTour) {
      return 'Self Tour';
    }
    if (bookmark.tourType === UnitVisitType.PrivateTour) {
      return 'Private Tour';
    }

    if (bookmark.isOccupied) {
      return 'Occupied Group Tour';
    }

    return 'Group Tour';
  }

  if (bookmark.displayStatus === DisplayStatus.CanceledTour) {
    return 'Canceled Tour';
  }
};

const getTourTagColor = (bookmark) => {
  const { tourType, displayStatus, hasPaymentHold } = bookmark;

  if (displayStatus === DisplayStatus.ScheduledTour && !hasPaymentHold && tourType === UnitVisitType.SelfTour) {
    return COLORS.RED;
  }

  switch (displayStatus) {
    case DisplayStatus.ScheduledTour:
      return COLORS.GREEN;
    case DisplayStatus.Applied:
      return COLORS.GREEN;
    case DisplayStatus.CanceledTour:
      return COLORS.DARKGRAY;
    case DisplayStatus.Rejected:
      return COLORS.RED;
    default:
      return COLORS.NAVY;
  }
};

const getListingApplicationStatusColorConfig = (status) => {
  switch (status) {
    case ListingApplicationStatus.Withdrawn:
      return COLORS.GRAY;
    case ListingApplicationStatus.Approved:
      return COLORS.GREEN;
    case ListingApplicationStatus.CoSignerRequired:
    case ListingApplicationStatus.Rejected:
      return COLORS.RED;
    case ListingApplicationStatus.None:
    case ListingApplicationStatus.Qualified:
    default:
      return COLORS.NAVY;
  }
};

const getListingApplicationStatusMapping = (status) => {
  switch (status) {
    // TODO: Figure out what to do with this after we fix the verifications
    // case ListingApplicationStatus.CoSignerRequired:
    // return 'Cosigner Required';
    case ListingApplicationStatus.Rejected:
    case ListingApplicationStatus.NotOffered:
      return 'Not Approved';
    case ListingApplicationStatus.None:
    case ListingApplicationStatus.Qualified:
    case ListingApplicationStatus.NotQualified:
    case ListingApplicationStatus.CoSignerRequired:
      return 'Applied';
    case ListingApplicationStatus.ApplicationStarted:
      return 'Application Started';
    default:
      return startCase(status);
  }
};

const getLeaseStatusColorConfig = (status) => {
  switch (status) {
    case LeaseStatus.Offered:
      return COLORS.GREEN;
    default:
      return COLORS.NAVY;
  }
};

const getLeaseStatusMapping = (status) => {
  switch (status) {
    case LeaseStatus.Offered:
      return 'Lease Offered';
    case LeaseStatus.ResidentsSigned:
      return 'Interview Pending';
    default:
      return startCase(status);
  }
};

const getLabels = (bookmark, isVerified) => {
  const { tourType, displayStatus, hasPaymentHold, leaseInfo, listingApplication } = bookmark;

  const labels = [];
  if (leaseInfo) {
    const leaseStatus = leaseInfo.basicInfo.status;
    labels.push(<Label text={getLeaseStatusMapping(leaseStatus)} color={getLeaseStatusColorConfig(leaseStatus)} />);
  } else if (listingApplication) {
    labels.push(
      <Label
        text={getListingApplicationStatusMapping(displayStatus)}
        color={getListingApplicationStatusColorConfig(listingApplication.status)}
      />
    );
  }

  if (tourType === UnitVisitType.SelfTour && displayStatus === DisplayStatus.ScheduledTour) {
    if (!isVerified) {
      labels.push(<Label text="ID Unverified" color={LABEL_COLOR_TYPES.RED} />);
    }

    if (!hasPaymentHold) {
      <Label text="Credit Card Missing" color={LABEL_COLOR_TYPES.RED} />;
    }
  }

  return labels.length > 0 ? <div>{labels}</div> : null;
};

type Props = {
  bookmark: RenterAccountBookmark;
  isVerified: boolean;
  user: UserModel;
  cancelSelfTourAction: (tourUniqueId: string) => void;
  cancelPrivateTourAction: (tourUniqueId: string) => void;
  updateCancelResidentBookmarkByIdAction: (id: string) => void;
  fetchResidentBookmarksAction: () => void;
  validateUserInterestSurveyEntryAction: (interestUpdateToken: string) => void;
};

const TourAndApps = ({
  bookmark,
  isVerified,
  user,
  cancelSelfTourAction,
  cancelPrivateTourAction,
  fetchResidentBookmarksAction,
  updateCancelResidentBookmarkByIdAction,
  validateUserInterestSurveyEntryAction,
}: Props) => {
  const [isWithdrawModalOpen, openWithdrawModal, hideWithdrawModal] = useModal(false);
  const [isValid, setIsValid] = useState(false);

  const {
    tourId,
    attendeeStatus,
    attendeeId,
    tourType,
    rentAmount,
    unit,
    displayStatus,
    applicationStatus,
    hasPaymentHold,
    listingApplication,
    listing,
    leaseInfo,
    applicants,
    applicationGroupHasTouredHome,
    isOccupied,
    bookedAttendeesAmount,
    host,
  } = bookmark;

  const { basicInfo } = unit;
  const leaseStatus = leaseInfo?.basicInfo?.status;

  const { coSigners = [], coApplicants = [], mainApplicant } = applicants || {};

  const allApplicants = mainApplicant ? [mainApplicant, ...coSigners, ...coApplicants] : [];
  const currentApplicant = allApplicants.find((applicant) => applicant.residentId === user.userId);
  const isCosigner = !!coSigners?.find((coSigner) => coSigner.residentId === user.userId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await validateUserInterestSurveyEntryAction(attendeeId);
        setIsValid(true);
      } catch (e) {
        setIsValid(false);
      }
    };

    if (tourType) {
      fetchData();
    }
  }, [attendeeId, tourType, validateUserInterestSurveyEntryAction]);

  function getMainCTA() {
    if (leaseStatus === LeaseStatus.ResidentsSigned) return;

    if (leaseStatus === LeaseStatus.Offered) {
      const currentResident = leaseInfo.residents?.find(
        (resident) => resident.basicInfo.userInfo.userId === user.userId
      );

      if (currentResident?.leaseInfo?.signatureStatus === UserSignatureStatus.Pending) {
        return {
          label: 'SIGN LEASE',
          href: `${BASE_PATHS.LEASE_SIGNING}/${basicInfo.unitId}?cosigner=${isCosigner ? 1 : 0}`,
        };
      }
    }

    if (!applicationGroupHasTouredHome && !isCosigner && displayStatus !== DisplayStatus.ScheduledTour) {
      return {
        label: 'TOUR HOME',
        href: `${BASE_PATHS.HOME}/${basicInfo.unitId}?view=tours`,
      };
    }

    // Don't show CTA if the user has applied and has attended the tour
    if (
      displayStatus === 'Applied' &&
      currentApplicant &&
      (currentApplicant.status === ApplicantStatus.Submitted ||
        currentApplicant.status === ApplicantStatus.VerificationsCompleted) &&
      (attendeeStatus === 'Booked' || attendeeStatus === 'Attended')
    ) {
      return;
    }

    // Don't show CTA if the user has applied
    if (
      displayStatus === 'Applied' &&
      currentApplicant &&
      (currentApplicant.status === ApplicantStatus.Submitted ||
        currentApplicant.status === ApplicantStatus.VerificationsCompleted)
    ) {
      return;
    }

    if (
      currentApplicant &&
      currentApplicant.status !== ApplicantStatus.Submitted &&
      currentApplicant.status !== ApplicantStatus.VerificationsCompleted &&
      (attendeeStatus === 'Booked' || attendeeStatus === 'Attended')
    ) {
      return {
        label: 'APPLY',
        href: `resident/${basicInfo.unitId}/applications/get-started`,
      };
    }

    if (attendeeStatus === 'Invited') {
      // TODO: Use variable
      if (isOccupied && bookedAttendeesAmount >= 10 && tourType !== UnitVisitType.PrivateTour)
        return {
          label: 'Reschedule Tour',
          href: `${BASE_PATHS.HOME}/${basicInfo.unitId}?view=tours`,
        };

      return {
        label: 'Confirm Spot',
        href: `${BASE_PATHS.HOME}/${basicInfo.unitId}?view=tours`,
      };
    }
  }

  const getActionsArray = () => {
    const actionsArray = [];

    if (tourType) {
      if (
        isValid &&
        displayStatus === DisplayStatus.CompletedTour &&
        leaseStatus !== LeaseStatus.Offered &&
        leaseStatus !== LeaseStatus.ResidentsSigned
      ) {
        actionsArray.push({
          label: 'UPDATE TOUR INTEREST',
          href: `${BASE_PATHS.HOME}/${basicInfo.unitId}?view=survey&token=${attendeeId}&attendance=${attendeeStatus}`,
        });
      }

      if (tourType !== UnitVisitType.PrivateTour && displayStatus === DisplayStatus.ScheduledTour && !hasPaymentHold) {
        actionsArray.push({
          label: 'RESCHEDULE',
          href: `${BASE_PATHS.HOME}/${basicInfo.unitId}?view=tours&step=reschedule`,
        });
      }

      if (!leaseInfo && listingApplication) {
        actionsArray.push({
          label: 'WITHDRAW APPLICATION',
          onClick: openWithdrawModal,
        });
      }

      if (displayStatus === DisplayStatus.ScheduledTour) {
        actionsArray.push({
          label: 'CANCEL TOUR',
          onClick: async () => {
            try {
              if (tourType === UnitVisitType.OpenHome) {
                await updateCancelResidentBookmarkByIdAction(tourId);
              } else if (tourType === UnitVisitType.PrivateTour) {
                await cancelPrivateTourAction(tourId);
              } else {
                await cancelSelfTourAction(tourId);
              }
              await fetchResidentBookmarksAction();
            } catch (error) {
              console.error(error);
            }
          },
        });
      }
    }

    return actionsArray;
  };

  const actions = getActionsArray();
  const mainCta = getMainCTA();

  const showDates = displayStatus === DisplayStatus.ScheduledTour || displayStatus === DisplayStatus.CanceledTour;

  const showBottom = allApplicants.some((applicant) => {
    const { status } = applicant || {};
    return status === ApplicantStatus.Submitted || status === ApplicantStatus.VerificationsCompleted;
  });

  const isPrequalified =
    applicationStatus === ApplicationStatus.VerificationsCompleted &&
    listingApplication?.status === ListingApplicationStatus.Qualified;

  const placeInLine = listingApplication?.waitlistRanking;

  return (
    <>
      <TourCard
        homeUniqueId={basicInfo.unitId}
        imageUrl={unit.basicInfo.bannerImageUrl}
        topContent={
          <>
            <div className="flex gap-xs mb-xs">{getLabels(bookmark, isVerified)}</div>
            <div className={cx('visitorStatus')}>
              <ColoredText
                text={
                  <>
                    <Text variant="p1" as="span" fontWeight="semibold">
                      {getStatus(bookmark)}
                    </Text>
                    {showDates && (
                      <>
                        &nbsp;|&nbsp;
                        <BDate
                          time={bookmark.visitStartOn}
                          formatType={DATE_TYPES.DOTTED_LONG_WITH_SPACE}
                          timeZone={unit?.basicInfo?.timeZone}
                          withTimeZone
                        />
                        &nbsp;-&nbsp;
                        <BDate
                          time={bookmark.visitEndOn}
                          formatType={DATE_TYPES.TIME}
                          timeZone={unit?.basicInfo?.timeZone}
                          withTimeZone
                        />
                      </>
                    )}
                  </>
                }
                color={getTourTagColor(bookmark)}
              />
            </div>
            <TourCardTitleWithDetails
              title={bookmark.address.getAddressAndUnitNumber(unit.basicInfo.unitNumber)}
              basicInfo={basicInfo}
              rentAmount={rentAmount}
            />
            {host && <TourHostTile {...host} />}
          </>
        }
        bottomContent={
          <>
            {(mainCta || actions.length > 0) && (
              <TourCardActions
                actions={[
                  ...actions,
                  ...(mainCta
                    ? [
                        {
                          ...mainCta,
                          isCTA: true,
                        },
                      ]
                    : []),
                ]}
              />
            )}
            {showBottom ? (
              <>
                <Space.LG />
                <Divider />
                <Space.LG />

                <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
                  <Box flex={1} px={['2sm', '2sm', 0]} py={['2xl', '2xl', 0]} className={cx('timeline-container')}>
                    <Box mb="sm">
                      <Text fontWeight="semibold">What&apos;s Next?</Text>
                    </Box>
                    <Timeline
                      items={getTimelineSteps(
                        user.userId,
                        allApplicants,
                        isCosigner,
                        applicationGroupHasTouredHome,
                        leaseInfo?.basicInfo?.status
                      )}
                    />
                  </Box>
                  {leaseInfo ? (
                    <Flex flex={1} pl="sm" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                      <ResidentListContainer withStatus residents={leaseInfo} />
                    </Flex>
                  ) : (
                    <Flex flex={1} py="lg" justifyContent="center">
                      {isPrequalified ? (
                        <PlaceInLine value={placeInLine} qualifiedApplicants={listing?.waitlistRankingTotal} />
                      ) : (
                        <ApplicationsInProcess value={listing?.processingApplications} />
                      )}
                    </Flex>
                  )}
                </Flex>
              </>
            ) : null}
          </>
        }
      />
      <WithdrawModal
        onHide={hideWithdrawModal}
        show={isWithdrawModalOpen}
        applicationId={bookmark.applicationId}
        unitId={basicInfo.unitId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = {
  cancelSelfTourAction: cancelSelfTour,
  cancelPrivateTourAction: cancelPrivateTour,
  fetchResidentBookmarksAction: fetchResidentBookmarks,
  updateCancelResidentBookmarkByIdAction: updateCancelResidentBookmarkById,
  validateUserInterestSurveyEntryAction: validateUserInterestSurveyEntry,
};

export default connect(mapStateToProps, mapDispatchToProps)(TourAndApps);
