import React, { Component } from 'react';
import { Text, Tooltip } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import Collapsible from 'components/Collapsible/Collapsible';
import Money from 'components/Money/Money';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import String from 'components/String/String';
import styles from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay.module.css';
import { SplitInfoBox } from 'containercomponents/MaintenanceStatus/split-infobox/split-infobox';
import GetInfoModal from 'containercomponents/Modals/GetInfoModal/GetInfoModal';
import Space from 'corecomponents/Space/Space';
import find from 'lodash/find';
import { getCostLabel } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/costs.utils';
import { DelightfulBadge, QualityStaticContent } from 'pages/PostInspectionFlow/steps/Improvements/Quality/Quality';
import PropTypes from 'prop-types';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';

const cx = classNames.bind(styles);

export const BackedByBelong = ({ onClick, onHide, showModal, title }) => {
  return (
    <>
      <GetInfoModal
        description={
          <div className={cx('backed-by-belong')}>
            <div className={cx('align-center')}>
              <DelightfulBadge />
              <Space.XL />
            </div>
            <div className={cx('title')}>
              <String string={MAINTENACE_STATUS_MODAL_STRINGS.estimatedCost.delightfulGuarantee.title} />
            </div>
            <div className={cx('paragraph')}>
              <String string={MAINTENACE_STATUS_MODAL_STRINGS.estimatedCost.delightfulGuarantee.paragraph_1} />
              <Space.XXL />
            </div>
            {MAINTENACE_STATUS_MODAL_STRINGS.estimatedCost.delightfulGuarantee.paragraph_2 && (
              <div className={cx('paragraph')}>
                <String string={MAINTENACE_STATUS_MODAL_STRINGS.estimatedCost.delightfulGuarantee.paragraph_2} />
                <Space.LG />
              </div>
            )}
            <QualityStaticContent />
          </div>
        }
        closeButton
        show={showModal}
        shadow
        closable={false}
        onHide={onHide}
      />
      <div className={cx('flex-and-fix-sizes')}>
        <div>Backed by our&nbsp;</div>
        <Button smallOnMobile className={cx('zindex')} buttonType={BUTTON_TYPES.TEXT} label={title} onClick={onClick} />
      </div>
    </>
  );
};

BackedByBelong.propTypes = {
  onClick: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

BackedByBelong.defaultProps = {
  title: 'Quality Guarantee',
};

export default class MaintenanceCostDisplay extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    actualCost: PropTypes.bool,
    isRenter: PropTypes.bool,
    fullTask: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    customClassName: PropTypes.string,
    defaultClassName: PropTypes.string,
  };

  static defaultProps = {
    actualCost: false,
    isRenter: false,
    disabled: false,
    customClassName: '',
    defaultClassName: '',
  };

  render() {
    const { task, fullTask, actualCost, isRenter, disabled, customClassName, defaultClassName } = this.props;
    const { isLegacyInvoicing, financedPayment, result, payments, isHandledBySmsAssist } = fullTask;

    const costItems = task?.items.filter((item) => !!item.description);
    const additionalFees = task?.items.filter((item) => !item.description);
    const subTotal = costItems?.reduce((prev, curr) => prev + curr.cost, 0);

    let displayResponsibleCost;
    let displayResponsiblePercentage;
    let otherResponsibleCost;
    let otherResponsiblePercentage;

    const renterResponsibility = find(payments, (payment) => payment.paidBy === 'Resident');
    const homeOwnerResponsibility = find(payments, (payment) => payment.paidBy === 'Homeowner');
    if (isRenter) {
      if (renterResponsibility) {
        displayResponsiblePercentage = renterResponsibility.percentage;
        displayResponsibleCost =
          renterResponsibility.actualAdjustmentApplied || renterResponsibility.estimatedAdjustmentApplied;
      }
      if (homeOwnerResponsibility) {
        otherResponsiblePercentage = homeOwnerResponsibility.percentage;
        otherResponsibleCost =
          homeOwnerResponsibility.actualAdjustmentApplied || homeOwnerResponsibility.estimatedAdjustmentApplied;
      }
    }

    if (!isRenter) {
      if (homeOwnerResponsibility) {
        displayResponsiblePercentage = homeOwnerResponsibility.percentage;
        displayResponsibleCost =
          homeOwnerResponsibility.actualAdjustmentApplied || homeOwnerResponsibility.estimatedAdjustmentApplied;
      }
      if (renterResponsibility) {
        otherResponsiblePercentage = renterResponsibility.percentage;
        otherResponsibleCost =
          renterResponsibility.actualAdjustmentApplied || renterResponsibility.estimatedAdjustmentApplied;
      }
    }

    const balances = isRenter ? renterResponsibility?.balances : homeOwnerResponsibility?.balances;
    const financePlanId = balances?.find((balance) => !!balance.currentFinanceId)?.currentFinanceId;
    const hasElegibleOpenBalance = !!balances?.find((balance) => balance.isOpen && balance.isFinanceable);
    const showUpfront = !isRenter && !actualCost && !isLegacyInvoicing && financedPayment?.dueNow > 0;

    return (
      <Collapsible
        createMarginAndPaddingClassName={cx('margin-padding', customClassName)}
        defaultClassName={defaultClassName}
        collapsed={disabled}
        disabled={disabled}
        className={disabled && 'opacity-50'}
        title={actualCost ? 'Receipt' : 'Estimated Pricing'}
        inner={
          <>
            {Boolean(task?.items.length) && (
              <div className={cx('content')}>
                {!!task.scopeOfWork && (
                  <div className="flex flex-col gap-xs mt-sm">
                    <Text fontWeight="semibold">Scope Of Work</Text>
                    <Text className="whitespace-pre-wrap">{task.scopeOfWork}</Text>
                  </div>
                )}
                <div className={cx('maintenanceCostDisplay')}>
                  {!isHandledBySmsAssist && (
                    <>
                      <NameValueTable
                        boldValue
                        items={[
                          {
                            name: 'Item',
                            value: 'Amount',
                          },
                        ]}
                      />
                      <div className={cx('underline')} />
                      <NameValueTable
                        noBold
                        items={costItems.map(({ cost, type, quantity, unitRate, description }) => ({
                          name:
                            type === 'Labor'
                              ? `${description} (${quantity} ${quantity === 1 ? 'hour' : 'hours'} * $${unitRate}/hr)`
                              : description,
                          value: <Money cash={cost} />,
                        }))}
                      />
                    </>
                  )}
                  {additionalFees.length > 0 && !isHandledBySmsAssist ? (
                    <>
                      <div className={cx('underline')} />
                      <NameValueTable
                        boldValue
                        items={[
                          {
                            name: 'Subtotal',
                            value: <Money cash={subTotal} />,
                          },
                        ]}
                      />
                      <NameValueTable
                        noBold
                        items={additionalFees.map((cost) => ({
                          name: getCostLabel(cost.type),
                          value: <Money cash={cost.cost} />,
                        }))}
                      />
                    </>
                  ) : null}
                  <div className={cx('underline')} />
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: actualCost ? (
                          <div className="flex gap-2xs">
                            <Text fontWeight="semibold">Total</Text>
                            <Tooltip
                              arrowProps={{ className: 'fill-dark-navy' }}
                              contentProps={{ side: 'top', alignOffset: -48, align: 'start', sideOffset: 8 }}
                              content={
                                <div className="p-2sm rounded bg-dark-navy max-w-xl sm:max-w-4xl text-white">
                                  <Text className="whitespace-pre-wrap" fontWeight="semibold">
                                    With all Belong Pro Services, we strive to charge at market rate for high quality,
                                    reliable work.
                                  </Text>
                                  <Space />
                                  <Text fontWeight="semibold">There are 3 contributors to the final cost.</Text>
                                  <ul className="list-decimal pl-md">
                                    <li>
                                      <Text>Maintenance Labor</Text>
                                    </li>
                                    <li>
                                      <Text>Sourcing and purchasing parts and materials</Text>
                                    </li>
                                    <li>
                                      <Text>Belong operations, scheduling, and coordination fee</Text>
                                    </li>
                                  </ul>
                                </div>
                              }
                            />
                          </div>
                        ) : (
                          'Est. Total'
                        ),
                        value: <Money cash={task.totalCost} />,
                      },
                    ]}
                  />
                  {otherResponsiblePercentage && (
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: `${
                            isRenter ? 'Homeowner' : 'Resident'
                          } Responsibility (${otherResponsiblePercentage}%)`,
                          value: <Money cash={otherResponsibleCost} />,
                        },
                      ]}
                    />
                  )}
                  {!!displayResponsiblePercentage && displayResponsiblePercentage < 100 && (
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: `Your Portion (${displayResponsiblePercentage}%)`,
                          value: <Money cash={displayResponsibleCost} />,
                        },
                      ]}
                    />
                  )}
                  {!displayResponsiblePercentage && (
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: 'Your Portion (0%)',
                          value: 'Free',
                        },
                      ]}
                    />
                  )}
                  {showUpfront && (
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: 'Due Upfront',
                          value: <Money cash={financedPayment?.dueNow} />,
                        },
                      ]}
                    />
                  )}
                </div>
                {!isLegacyInvoicing && !isRenter && (
                  <div className="mt-lg">
                    <SplitInfoBox
                      financePlanId={financePlanId}
                      hasOpenBalance={hasElegibleOpenBalance}
                      isMaintenanceCompleted={result === 'Completed'}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        }
      />
    );
  }
}
