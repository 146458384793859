import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Image, Text } from '@belong/ui';
import { sendTextMessage } from 'store/redux/user/actions';
import { selectFullUser } from 'store/redux/user/selectors';

export type MobileBannerProps = {
  sendTextMessage?: (message: { eventType: string; cta: string }) => void;
  title?: string;
  isInbox?: boolean;
};

export function MobileBanner({
  sendTextMessage: sendTextMessageAction,
  title = 'Want to use a different payment method?',
  isInbox = false,
}: MobileBannerProps) {
  const { user } = useSelector(selectFullUser);
  const [sent, setSent] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  const handleSendLink = async () => {
    const link = 'https://dl.bln.hm/6BG2';

    const textMessage = {
      eventType: 'AppDownloadRequest',
      cta: `Hi ${user.firstName}${user.lastName ? ' ' : ''}${
        user.lastName
      }, Team Belong here. You can use this link to download our app! ${link}`,
    };

    try {
      if (isMobile) {
        window.open(link, '_blank').focus();
      } else {
        await sendTextMessageAction(textMessage);
        setSent(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      className="flex flex-col-reverse md:flex-row w-full border border-solid border-green rounded-[8px] md:max-h-[180px] mb-xl"
      style={{ backgroundColor: 'rgba(62, 228, 169, 0.1)' }}
    >
      <div className="flex flex-col p-sm pl-sm self-center">
        {!isMobile && (
          <Text className="text-[20px] text-center md:text-left" fontWeight="semibold">
            {title}
          </Text>
        )}
        <Text className="text-center md:text-left my-sm">
          Download our app to pay early, switch payment methods, and manage your <strong>monthly cashflow</strong>.
        </Text>
        {!sent ? (
          <Text
            variant="body"
            fontWeight="semibold"
            className="text-center md:text-left cursor-pointer"
            onClick={() => handleSendLink()}
          >
            {isMobile ? 'DOWNLOAD NOW' : 'SEND LINK TO MY PHONE'}
          </Text>
        ) : (
          <>
            {isMobile ? (
              <Text
                variant="body"
                fontWeight="semibold"
                className="text-center md:text-left cursor-pointer"
                onClick={() => handleSendLink()}
              >
                DOWNLOAD NOW
              </Text>
            ) : (
              <Text variant="body" fontWeight="semibold" className="text-center md:text-left">
                MESSAGE SENT!
              </Text>
            )}
          </>
        )}
      </div>
      <div className="border-0 border-b border-solid border-b-green lg:border-b-0" />
      <div className="flex md:self-end mx-auto md:ml-auto w-3/5 xl:w-2/5">
        <Image
          src={isInbox ? '/homeowner-earnings/ho_inbox_pixelated.png' : '/homeowner-earnings/ho_earnings.png'}
          alt="Homeowner Earnings Mobile"
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  sendTextMessage,
};

export default connect(null, mapDispatchToProps)(MobileBanner);
