export default class MaintenanceRecurrence {
  constructor(data = {}) {
    this.uniqueId = data.uniqueId;
    this.repeatFrequencyDays = data.repeatFrequencyDays;
    this.startOn = data.startOn;
    this.nextOn = data.nextOn;
    this.endOn = data.endOn;
    this.isCancelled = data.isCancelled;
  }
}
