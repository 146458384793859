import { useSelector, connect } from 'react-redux';
import { GroupBalanceInfoModel, FinanceNewModel, FinanceInfoModel } from 'api/models';
import { GroupedBalancesPerCategoryInfoModel, HomeDetailInfoModel, BillingInfoModel } from 'api/models.v2';
import cx from 'classnames';
import { useFastMaintenanceSettings } from 'hooks/useFastMaintenanceSettings';
import { selectSimulatePayments } from 'store/redux/accounts/selectors';
import { selectAccountPropertyByUnitId, selectAccountUnitByUnitId } from 'store/redux/homeowner-accounts/selectors';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';
import { BalanceItem } from './balance-item/balance-item';

type BalanceProps = GroupedBalancesPerCategoryInfoModel & {
  address?: string;
  index: number;
  homeId: HomeDetailInfoModel['homeId'];
  showAddress: boolean;
  isResident?: boolean;
  hideSplitIt?: boolean;
  noBorder?: boolean;
  handleFinancingModal: (id: string | number, balance: GroupBalanceInfoModel, home: string) => void;
  billingInfo?: BillingInfoModel;
  simulateSplitPayments?: (data: FinanceNewModel) => FinanceInfoModel;
  handleShowFinanceSuccessModal?: () => void;
  handleShowBalanceDetailsModal?: (balance: GroupBalanceInfoModel, home: string) => void;
};

function Balance({
  address: streetAddress,
  groupedBalancesInfo: baseItems,
  homeId,
  showAddress,
  billingInfo,
  noBorder = false,
  hideSplitIt = false,
  isResident = false,
  handleFinancingModal,
  handleShowBalanceDetailsModal,
}: BalanceProps) {
  const items = baseItems.filter((item) => Boolean(item));

  const { isEnabled: isFastMaintenanceEnabled } = useFastMaintenanceSettings();
  const property = useSelector(selectAccountPropertyByUnitId(homeId));
  const unit = useSelector(selectAccountUnitByUnitId(homeId));

  const balanceItems = items.sort((a, b) => {
    if (a.sourceType !== 'Finance' && b.sourceType === 'Finance') {
      return -1;
    }

    if (a.sourceType === 'Finance' && b.sourceType !== 'Finance') {
      return 1;
    }

    return 0;
  });

  function navigateToFinanceDetails(id, balance, home) {
    if (handleFinancingModal) handleFinancingModal(id, balance, home);
  }

  if (!property && !isResident) {
    return null;
  }

  const date = new Date(new Date().toISOString());
  date.setDate(date.getDate() + 1);

  const address = !isResident
    ? formatString(addressAndUnit(property?.address, unit?.basicInfo.unitNumber))
    : streetAddress;

  return (
    <article>
      <main
        className={cx('transition-all ease-linear duration-hover', {
          'opacity-100 visible h-full': !showAddress,
        })}
      >
        <div className={noBorder ? '' : 'pl-xs border-0 border-l-3 border-solid border-gray'}>
          {balanceItems.map((item, itemIindex) => (
            <BalanceItem
              key={`balance-item-${homeId}-${itemIindex}`}
              {...item}
              navigateToFinanceDetails={navigateToFinanceDetails}
              handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
              address={address}
              isResident={isResident}
              hideSplitIt={hideSplitIt}
              noBorder={noBorder}
              billStatusInfo={item.billStatusInfo}
              billingInfo={billingInfo}
              balances={item.sourceType === 'Balance' ? [item] : item.balances}
              isFastMaintenanceEnabled={isFastMaintenanceEnabled}
            />
          ))}
        </div>
      </main>
    </article>
  );
}

function mapStateProps(state) {
  return {
    simulation: selectSimulatePayments(state),
  };
}

export default connect(mapStateProps)(Balance);
