import { Component } from 'react';
// FinalForm
// Redux
import { connect } from 'react-redux';
import { PATHS, COOKIES_CONFIG } from '@belong/common';
import { IconClose } from '@belong/icons';
import { ButtonBase, Spinner } from '@belong/ui';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import Collapsible from 'components/Collapsible/Collapsible';
import Grid from 'components/Grid/Grid';
import Label from 'components/Label/Label';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import config from 'config/config';
import MaintenanceCostDisplay from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay';
import styles from 'containercomponents/MaintenanceStatus/MaintenanceStatus.module.css';
import { MaintenanceStatusFooter } from 'containercomponents/MaintenanceStatus/MaintenanceStatusFooter/maintenance-status-footer';
import {
  GroupEmployeeAssignments,
  GroupImprovementCards,
  GroupHomeownerReceiptCosts,
  GroupResidentReceiptCosts,
} from 'containercomponents/MaintenanceStatus/ModalGroupMaintenance/ModalGroupMaintenance';
import ModalMaintAccountResponsibility from 'containercomponents/MaintenanceStatus/ModalMaintAccountResponsibility/ModalMaintAccountResponsibility';
import { MaintenanceBalance } from 'containercomponents/MaintenanceStatus/ModalMaintenanceBalance/ModalMaintenanceBalance';
import { ModalMaintenanceSummary } from 'containercomponents/MaintenanceStatus/ModalMaintenanceSummary/ModalMaintenanceSummary';
import ModalMaintenanceTitleModalSubtitleandLabels from 'containercomponents/MaintenanceStatus/ModalMaintenanceTitleModalSubtitleandLabels/ModalMaintenanceTitleModalSubtitleandLabels';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';
import MaintenanceCancelModal from 'containercomponents/Modals/MaintenanceCancelModal/MaintenanceCancelModal';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
// Styling
import find from 'lodash/find';
import UserBasicInfo from 'models/common/UserBasicInfo';
import { RequestType } from 'models/enums';
import MaintenanceTaskModel from 'models/maintenance/MaintenanceTaskModel';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';
import { fetchAccountUnitListings, fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';
import {
  fetchTaskByTaskId,
  fetchRenterTaskByTaskId,
  maintenanceCurrentClear,
  fetchImprovementReportByTaskId,
} from 'store/redux/maintenance/actions';
import { selectCurrentTask, selectImprovementsReport } from 'store/redux/maintenance/selectors';
import { selectUser } from 'store/redux/user/selectors';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';
import { formatMaintenanceStringsForTasks, getStringFromTime } from 'utils/formatMaintenance.js';
import { pluralizeWithoutValue } from 'utils/pluralize';
import { checkIfCurrentStatusIsSame, getStatusOfTask } from 'utils/validation';

const { JWT_TOKEN } = COOKIES_CONFIG;
const cx = classNames.bind(styles);

export const { shouldDoIt, renterSplitText } = MAINTENACE_STATUS_MODAL_STRINGS;

const isRenterValidTypes = {
  Repair: RequestType.Repair,
  Upgrade: RequestType.Upgrade,
  Addition: RequestType.Addition,
  KeysAndAccess: RequestType.KeysAndAccess,
  HaulingAndStorage: RequestType.HaulingAndStorage,
};

const isHomeownerValidTypes = {
  Repair: RequestType.Repair,
  Upgrade: RequestType.Upgrade,
  Addition: RequestType.Addition,
  HaulingAndStorage: RequestType.HaulingAndStorage,
  Preventative: RequestType.Preventative,
};

const editableRequestTypes = (requestType, status, isRenter, recurring, requestedBy) => {
  if (status === 'New') {
    if (isRenter && requestedBy === 'Resident') {
      if (isRenterValidTypes[requestType]) {
        return true;
      }
    }

    if (!isRenter && requestedBy === 'Homeowner') {
      if (isHomeownerValidTypes[requestType]) {
        return true;
      }

      if (requestType === RequestType.Preventative && !!recurring) {
        return true;
      }
    }
  }

  return false;
};

const getFormattedAddress = (address) =>
  `${address.streetAddress}${address.unitNumber ? ` Unit ${address.unitNumber}` : ''},
  <span>${address.city}, ${address.state} ${address.zipcode}</span>`;

const showEditButton = (isRenter, task) => {
  if (!task) {
    return false;
  }

  return editableRequestTypes(
    task.requestType,
    task.status,
    isRenter,
    task.recurrence?.repeatFrequencyDays,
    task.requestedBy
  );
};

class MaintenanceStatus extends Component {
  static propTypes = {
    task: PropTypes.instanceOf(MaintenanceTaskModel).isRequired,
    close: PropTypes.func.isRequired,
    fetchTaskByTaskId: PropTypes.func.isRequired,
    fetchRenterTaskByTaskId: PropTypes.func.isRequired,
    fetchImprovementReportByTaskId: PropTypes.func.isRequired,
    fetchAccountUnitListings: PropTypes.func.isRequired,
    fetchAccountProperties: PropTypes.func.isRequired,
    isRenter: PropTypes.bool,
    showSpinner: PropTypes.bool.isRequired,
    showSpinnerFunction: PropTypes.func.isRequired,
    hideSpinnerFunction: PropTypes.func.isRequired,
    taskId: PropTypes.any.isRequired,
    maintenanceCurrentClear: PropTypes.func.isRequired,
    improvementsReport: PropTypes.object.isRequired,
    inspection: PropTypes.object,
    user: PropTypes.instanceOf(UserBasicInfo).isRequired,
    onClosePress: PropTypes.func.isRequired,
    history: PropTypes.object,
  };

  static defaultProps = {
    isRenter: false,
    inspection: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      cancelModal: false,
      isLoading: false,
      listingsData: null,
    };
  }

  componentDidMount() {
    this.fetchTask();
  }

  componentWillUnmount() {
    this.props.maintenanceCurrentClear();
  }

  getBottom(task) {
    const { isRenter } = this.props;
    const { cancelModal } = this.state;

    const truthy1 = getStatusOfTask(task.status);
    const truthy2 = checkIfCurrentStatusIsSame(task.requestedBy, isRenter);

    const url = PATHS.SUPPORT;

    // if pending consent return null.

    if (cancelModal) {
      return null;
    }

    if (this.requestAccountResponsibility()) {
      return null;
    }

    if (truthy1 && truthy2) {
      const menuItems = [];
      if (task.recurrence?.uniqueId) {
        menuItems.push({
          name: (
            <Button
              className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
              label="CANCEL THIS VISIT"
              buttonType={BUTTON_TYPES.TEXT_DANGER}
              onClick={this.handleShowCancelModal}
            />
          ),
          key: 'cancel-request',
        });

        if (!task.recurrence?.isCancelled) {
          menuItems.push({
            name: (
              <Button
                className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
                buttonType={BUTTON_TYPES.TEXT_DANGER}
                label="STOP RECURRING VISITS"
                onClick={this.handleShowCancelRecurringModal}
              />
            ),
            key: 'cancel-recurring',
          });
        }
      }

      if (showEditButton(isRenter, task)) {
        menuItems.push({
          name: (
            <Button
              className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
              buttonType={BUTTON_TYPES.TEXT_DANGER}
              label="EDIT"
              onClick={this.handleShowAddTaskModal}
            />
          ),
          key: 'edit',
        });
      }

      menuItems.push({
        name: (
          <Button
            className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
            label="CANCEL REQUEST"
            buttonType={BUTTON_TYPES.TEXT_DANGER}
            onClick={this.handleShowCancelModal}
          />
        ),
        key: 'cancel-visit',
      });

      return <MaintenanceStatusFooter menuActions={menuItems} path={url} isRenter={isRenter} />;
    }

    return <MaintenanceStatusFooter path={url} isRenter={isRenter} />;
  }

  handleCloseModal = () => {
    const { close } = this.props;
    this.fetchTask();
    this.setState({
      cancelModal: false,
      isRecurring: false,
    });
    close();
  };

  handleShowAddTaskModal = () => {
    const { isRenter, task, history } = this.props;

    if (isRenter && task.requestType) {
      const cookies = parseCookies();
      const jwtToken = cookies[JWT_TOKEN.name];

      window.open(`${config.smsAssistUrl}${jwtToken}`, '_blank');
    } else if (!isRenter && task.requestType) {
      history.push(`/homeowner/pro-services/${task.requestType}/${task.uniqueId}`);
    }
  };

  handleShowCancelModal = () => {
    this.setState({
      cancelModal: true,
    });
  };

  handleShowCancelRecurringModal = () => {
    this.setState({
      isRecurring: true,
      cancelModal: true,
    });
  };

  getIsGroup = (task) => {
    return task?.requestType === RequestType.Group;
  };

  get isGroup() {
    return this.getIsGroup(this.props.task);
  }

  fetchTask = async () => {
    const {
      close,
      isRenter,
      taskId,
      fetchTaskByTaskId: fetchHomeOwnerTaskByTaskIdAction,
      fetchRenterTaskByTaskId: fetchRenterTaskByTaskIdAction,
      fetchImprovementReportByTaskId: fetchImprovementReportByTaskIdAction,
      fetchAccountUnitListings: fetchAccountUnitListingsAction,
      fetchAccountProperties: fetchAccountPropertiesAction,
    } = this.props;

    let task;
    if (!task?.uniqueId) {
      if (!isRenter) {
        try {
          task = await fetchHomeOwnerTaskByTaskIdAction(taskId);
        } catch (e) {
          close();
        }
      } else if (isRenter) {
        try {
          task = await fetchRenterTaskByTaskIdAction(taskId);
        } catch (e) {
          close();
        }
      }
    }

    if (this.getIsGroup(task)) {
      this.setState({ isLoading: true });

      try {
        let listingsData;
        let propertiesData;
        if (!isRenter) {
          // homeowner
          [listingsData, propertiesData] = await Promise.all([
            fetchAccountUnitListingsAction(),
            fetchAccountPropertiesAction(),
            fetchImprovementReportByTaskIdAction(taskId),
          ]);
        }
        this.setState({ listingsData, propertiesData, groupTask: task });
      } catch (err) {
        close();
      }
      this.setState({ isLoading: false });
    }
  };

  useForEveryTask() {
    const { showSpinnerFunction, isRenter, inspection, hideSpinnerFunction, task, improvementsReport } = this.props;
    const { cancelModal, listingsData, propertiesData, groupTask, isRecurring } = this.state;
    const getAllLabels = [];

    if (!task.uniqueId) {
      return null;
    }

    // We temporarily got rid of recommendations. TODO: ADd them back.

    if (task.urgency === 'Urgent') {
      getAllLabels.push({
        label: 'Urgent',
        color: LABEL_COLOR_TYPES.RED,
      });
    }

    if (task.recurrence?.repeatFrequencyDays) {
      getAllLabels.push({
        label: getStringFromTime(task.recurrence.repeatFrequencyDays),
      });
    }

    if (task.result === 'Completed') {
      getAllLabels.push({
        label: formatMaintenanceStringsForTasks(task.status, task),
        color: LABEL_COLOR_TYPES.GREEN,
      });
    } else if (task.result === 'Cancelled') {
      getAllLabels.push({
        label: formatMaintenanceStringsForTasks(task.status, task),
        color: LABEL_COLOR_TYPES.GRAY_BORDER,
      });
    } else {
      getAllLabels.push({
        label: formatMaintenanceStringsForTasks(task.status, task),
        color: LABEL_COLOR_TYPES.WHITE,
      });
    }

    const hasAppointments = !!task.appointments.length;
    const hasPublishedInvoices = groupTask?.requestTypeDetails?.hasPublishedInvoices;
    return (
      <>
        <MaintenanceCancelModal
          isRenter={isRenter}
          show={cancelModal}
          isCancelRecurrence={isRecurring}
          hideSpinnerFunction={hideSpinnerFunction}
          showSpinnerFunction={showSpinnerFunction}
          close={this.handleCloseModal}
          taskId={task.uniqueId}
        />

        {!cancelModal && (
          <>
            <div className="mb-2xl">
              <ModalMaintenanceTitleModalSubtitleandLabels
                title={task.summary}
                streetAddress={getFormattedAddress(task.address)}
                jobId={task.jobId}
                labels={getAllLabels}
              />
            </div>
            {this.isGroup && (
              <>
                <GroupEmployeeAssignments
                  task={task}
                  report={improvementsReport}
                  listingsData={listingsData}
                  propertiesData={propertiesData}
                  inspectionData={inspection}
                />
                <Space />
                <GroupImprovementCards
                  report={improvementsReport}
                  groupTask={groupTask}
                  hasAppointments={hasAppointments}
                  isRenter={isRenter}
                  listingsData={listingsData}
                />
                <Space value={SPACE_TYPES.LG} />
                {hasAppointments && (
                  <>
                    <MaintenanceScheduledComponent appointments={task.appointments} />
                    <Space value={SPACE_TYPES.XL} />
                  </>
                )}
                {isRenter ? (
                  <GroupResidentReceiptCosts groupTask={groupTask} />
                ) : (
                  <GroupHomeownerReceiptCosts groupTask={groupTask} report={improvementsReport} />
                )}

                {hasPublishedInvoices && <MaintenanceBalance task={task} isRenter={isRenter} />}
              </>
            )}

            {!this.isGroup && (
              <>
                <ModalMaintenanceSummary task={task} hideDetails={this.isGroup} />
                {hasAppointments && (
                  <>
                    <Space value={SPACE_TYPES.XL} />
                    <MaintenanceScheduledComponent appointments={task.appointments} />
                  </>
                )}
                {this.renderMaintenanceCost()}
                {this.renderMaintenanceApprovalAndResponsibilityComponent()}
                {this.renderAccountResponsibility()}
              </>
            )}
          </>
        )}
      </>
    );
  }

  requestAccountResponsibility() {
    const { task, isRenter, user } = this.props;

    // responsibility currently requires both the resident / homeowner  user to display the mock.
    // Also there can be no actual cost object. Once there is a real cost there is no account responsibility.
    if (task.actualCost || !task.estimatedCost) {
      return false;
    }

    if (!task.payments?.length) {
      return false;
    }

    const renterResponsibility = find(task.payments, (payment) => payment.paidBy === 'Resident');
    const homeOwnerResponsibility = find(task.payments, (payment) => payment.paidBy === 'Homeowner');

    if (renterResponsibility?.consentStatus === 'Required' && isRenter) {
      if (renterResponsibility.paidByUserId === user.id) {
        return true;
      }
      return false;
    } else if (homeOwnerResponsibility?.consentStatus === 'Required' && !isRenter) {
      return true;
    }

    return false;
  }

  renderMaintenanceCost() {
    const { task, isRenter } = this.props;

    if (task.actualCost || task.estimatedCost) {
      return (
        <>
          <Space value={SPACE_TYPES.XL} />
          <MaintenanceCostDisplay
            task={task.actualCost || task.estimatedCost}
            actualCost={!!task.actualCost}
            // This is bad code. Writing because of time.
            fullTask={task}
            isRenter={isRenter}
          />
        </>
      );
    }

    return null;
  }

  renderAccountResponsibility() {
    const { task, isRenter, showSpinnerFunction } = this.props;

    if (this.requestAccountResponsibility()) {
      return (
        <>
          <Space value={SPACE_TYPES.XL} />
          <ModalMaintAccountResponsibility
            shouldDoIt={shouldDoIt}
            renterSplitText={renterSplitText}
            task={task}
            close={this.handleCloseModal}
            isRenter={isRenter}
            showSpinnerFunction={showSpinnerFunction}
          />
        </>
      );
    }
  }

  renderMaintenanceApprovalAndResponsibilityComponent() {
    const { task } = this.props;

    const findOne = find(
      task.payments,
      (consent) => consent.consentStatus === 'Approved' || consent.consentStatus === 'Rejected'
    );

    if (!findOne) {
      return false;
    }

    return (
      <>
        <Space value={SPACE_TYPES.XL} />
        <MaintenanceApprovalAndResponsibilityComponent task={task} payments={task.payments} />
      </>
    );
  }

  render() {
    const { showSpinner, task, onClosePress } = this.props;
    const { isLoading } = this.state;

    const isSpinnerVisible = !task?.uniqueId || isLoading || showSpinner;

    return (
      <div className="w-full flex flex-col items-center">
        <div className="flex py-xs px-xs justify-end sm:py-xs sm:px-sm w-full">
          <ButtonBase className="p-xs sm:p-sm" onClick={onClosePress}>
            <IconClose height={16} />
          </ButtonBase>
        </div>
        {isSpinnerVisible ? (
          <div className={clsx('m-auto w-screen', styles.spinner)}>
            <Spinner />
          </div>
        ) : (
          <div className={cx('wrapper')}>
            <Grid>
              {task?.uniqueId && (
                <div className={cx('maintenanceStatusModal', { hideUndefinedText: !task?.uniqueId })}>
                  {this.useForEveryTask()}
                  {this.getBottom(task)}
                </div>
              )}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const getLabelFromOutcome = (outcome) =>
  outcome === 'Resolved' ? <Label color={LABEL_COLOR_TYPES.GREEN} text="Resolved" /> : null;

const getEnding = (status, outcome) => {
  if (status?.toLowerCase() !== 'completed') {
    return `${status.toLowerCase()}`;
  }

  return outcome === 'NotResolved' ? 'completed.notresolved' : 'completed';
};

export const MaintenanceScheduledComponent = ({ appointments }) => (
  <Collapsible
    title={MAINTENACE_STATUS_MODAL_STRINGS.appointmentScheduledModal.title}
    collapsed={false}
    inner={
      <>
        {appointments.map((appointment) => {
          const user = appointment.pro.name ? { ...appointment.pro } : { ...appointment.partner };

          return (
            <UserActionMaintenance
              key={user.userId}
              belongPro={!!appointment.pro?.name}
              partner={!!appointment.partner?.name}
              name={user.name}
              time={appointment.scheduledStartOn}
              allDay={appointment.isAllDay}
              userId={user.userId}
              profileImage={user.profileImageUrl || user.logoImageUrl}
              location={appointment.location}
              taskType={
                <div className={cx('task-type-wrapper', { 'handle-tags': appointment.outcome === 'Resolved' })}>
                  <div>
                    {
                      MAINTENACE_STATUS_MODAL_STRINGS[
                        `maintenance.scheduled.status.${getEnding(appointment.status, appointment.outcome)}`
                      ]
                    }
                  </div>
                  {appointment.status === 'Completed' && (
                    <div className={cx('label-wrapper')}>{getLabelFromOutcome(appointment.outcome)}</div>
                  )}
                </div>
              }
            />
          );
        })}
      </>
    }
  />
);

MaintenanceScheduledComponent.defaultProps = {
  appointments: [],
};
MaintenanceScheduledComponent.propTypes = {
  appointments: PropTypes.array,
};

const getUserFromPayment = (task, payment) => {
  if (payment.paidBy === 'Homeowner') {
    const newTask = {
      ...task.homeowner,
      reportedBy: 'Homeowner',
    };
    return newTask;
  }

  if (payment.paidBy === 'Resident') {
    const newTask = {
      ...task.resident,
      ...payment.paidByUser,
      reportedBy: 'Resident',
    };
    return newTask;
  }

  return {};
};

const getTextFromConsentStatus = (approval) => {
  if (approval === 'Approved') {
    return 'Approved by';
  }

  if (approval === 'Rejected') {
    return 'Not approved by';
  }
};

export const MaintenanceApprovalAndResponsibilityComponent = ({ task, payments }) => {
  const filteredMaintenance = payments.filter((payment) => {
    if (payment.consentStatus === 'Approved' || payment.consentStatus === 'Rejected') {
      return true;
    }

    return false;
  });

  return (
    <Collapsible
      title={pluralizeWithoutValue(
        filteredMaintenance.length,
        MAINTENACE_STATUS_MODAL_STRINGS['approvalAndResponsibility.title']
      )}
      collapsed={false}
      inner={
        <>
          {filteredMaintenance.map((payment) => {
            const user = getUserFromPayment(task, payment);

            return (
              <UserActionMaintenance
                key={user.userId}
                reportedBy={user.reportedBy ? user.reportedBy : 'Belong'}
                name={user.name}
                time={payment.consentOn}
                profileImage={user.profileImageUrl}
                userId={user.userId}
                taskType={getTextFromConsentStatus(payment.consentStatus)}
              />
            );
          })}
        </>
      }
    />
  );
};

MaintenanceApprovalAndResponsibilityComponent.propTypes = {
  task: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  task: selectCurrentTask(state),
  improvementsReport: selectImprovementsReport(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  fetchTaskByTaskId,
  fetchRenterTaskByTaskId,
  fetchAccountUnitListings,
  maintenanceCurrentClear,
  fetchImprovementReportByTaskId,
  fetchAccountProperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceStatus);
