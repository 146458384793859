import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { maskSSN, unmaskSSN } from 'components/Input/masks';
import Spinner from 'components/Spinner/Spinner';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import FormLayout from 'layouts/FormLayout/FormLayout';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { fetchApplicantInfo, updateApplicationFlowComplete } from 'store/redux/resident-application/actions';
import {
  selectMustCallCompleteEndpoint,
  selectResidentApplicationApplicantInfo,
  selectResidentApplicationBackgroundCheck,
  selectResidentApplicationEmploymentInformation,
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { updateLegalIdentityInformation } from 'store/redux/user/actions';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { composeValidators, required, requiredChecked, ssnValidation, ssnValidationFirstTime } from 'utils/validation';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './BackgroundCheck.module.css';

const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.background_check;
const { FormLayoutHeader } = FormLayout;

class BackgroundCheck extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    backgroundCheck: PropTypes.object,
    applicationId: PropTypes.string.isRequired,
    houseId: PropTypes.string.isRequired,
    fetchApplicantInfo: PropTypes.func.isRequired,
    updateLegalIdentityInformation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    backgroundCheck: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      awaitingFetchComplete: true,
      formData: {},
    };
  }

  async componentDidMount() {
    const { backgroundCheck, fetchApplicantInfo: fetchApplicantInfoAction } = this.props;

    try {
      if (!backgroundCheck || isEmpty(backgroundCheck)) {
        await fetchApplicantInfoAction();
      }
    } catch (err) {
      console.error(err);
    }

    this.setupInitialValues();
  }

  setupInitialValues() {
    const { backgroundCheck } = this.props;
    const newState = {
      formData: {
        ssn: {},
      },
    };

    if (backgroundCheck.ssnLastDigits && backgroundCheck.ssnLastDigits !== '') {
      newState.formData.ssn.inputValue = `xxx-xx-${backgroundCheck.ssnLastDigits}`;
      newState.formData.creditCheckAuth = true;
      newState.formData.backgroundCheckAuth = true;
    } else if (backgroundCheck.hasNoSsn) {
      newState.formData.ssn.checkboxValue = backgroundCheck.hasNoSsn;
      newState.formData.creditCheckAuth = true;
      newState.formData.backgroundCheckAuth = true;
    }

    this.setState({
      awaitingFetchComplete: false,
      ...newState,
    });
  }

  handleSubmit = async (values) => {
    const {
      history: { push },
      applicationId,
      houseId,
      updateLegalIdentityInformation: updateLegalIdentityInformationAction,
    } = this.props;

    const userSsnInformation = {
      ssn: values.ssn.inputValue,
      hasNoSsn: values.ssn.checkboxValue,
    };

    this.setState({
      awaitingFetchComplete: true,
    });

    if (!/^xxx\-xx\-?[0-9]{4}$/i.test(values.ssn.inputValue)) {
      try {
        await updateLegalIdentityInformationAction(userSsnInformation);
      } catch (err) {
        console.error(err);
      }
    }

    push(getResidentApplicationStepPath(STEPS_CONFIG.APPLICATION_FEE, { houseId, applicationId }));
  };

  render() {
    const { backgroundCheck } = this.props;
    const { awaitingFetchComplete, formData } = this.state;

    if (awaitingFetchComplete) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <FormLayout>
        <FormLayoutHeader subtitle={RAS.subtitle} title={RAS.title} />
        <Form
          initialValues={formData}
          onSubmit={this.handleSubmit}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: RAS.next_button_text,
                // disabled: formProps.invalid || this.validateSsn(formProps),
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={({ handleSubmit, form }) => (
            <div className={cx('form-wrapper')}>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Field
                      name="ssn"
                      component={InputCheckboxAdapter}
                      form={form}
                      inputProps={{
                        mask: maskSSN,
                        unmask: unmaskSSN,
                        placeholder: RAS.ssn_input,
                        validate: !backgroundCheck.ssnLastDigits
                          ? composeValidators(required, ssnValidationFirstTime)
                          : composeValidators(required, ssnValidation),
                      }}
                      checkboxProps={{
                        label: RAS.no_ssn_checkbox,
                      }}
                    />
                  </Col>
                </Row>
                <FormLayout.Section>
                  <Condition when="ssn.checkboxValue" is>
                    <div>
                      <Row>
                        <Col md={12}>
                          <GreenInfoBox>
                            <GreenInfoBox.Title>{RAS.no_ssn_section_title}</GreenInfoBox.Title>
                            <GreenInfoBox.RegularText>{RAS.no_ssn_section_text}</GreenInfoBox.RegularText>
                          </GreenInfoBox>
                        </Col>
                      </Row>
                    </div>
                  </Condition>
                  <Row>
                    <Col md={12}>
                      <Field
                        name="backgroundCheckAuth"
                        component={CheckboxFinalFormAdapter}
                        useOnChangeFormToHandle
                        alignWithField={false}
                        label={RAS.background_pull_auth}
                        validate={requiredChecked}
                        // onClickCustom={(inputValue) => this.handleChange('backgroundCheckAuth', inputValue)}
                      />
                    </Col>
                  </Row>
                </FormLayout.Section>
              </form>
            </div>
          )}
        />
      </FormLayout>
    );
  }
}

export default connect(
  (state) => ({
    backgroundCheck: selectResidentApplicationBackgroundCheck(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    applicantInfo: selectResidentApplicationApplicantInfo(state),
    mustCallCompleteEndpoint: selectMustCallCompleteEndpoint(state),
    employmentInformation: selectResidentApplicationEmploymentInformation(state),
  }),
  {
    fetchApplicantInfo,
    updateLegalIdentityInformation,
    updateApplicationFlowComplete,
  }
)(BackgroundCheck);
