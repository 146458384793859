import Address from 'models/common/Address';

export default class AppointmentsLocation {
  constructor(data = {}) {
    this.isHome = data.isHome;
    this.homeId = data.homeId;
    this.homeUniqueId = data.homeUniqueId;
    this.address = new Address(data.address);
  }
}
