import React from 'react';
import { AddressAutocompleteFinalFormAdapter } from 'components/AddressAutocomplete/AddressAutocomplete';
import Field from 'components/Field/Field';
import { Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';

export const SingleLineLayout = ({
  UnitName,
  UnitNumberInput,
  UnitPlaceHolder,
  name,
  form,
  onChange,
  onSelect,
  handleUnitChange,
  placeholder,
  validate,
  fullWidth,
}) => {
  const customOnSelect = (value) => {
    onSelect(value);
    form.change(`${name}.completeAddress`, `${value.streetAddress}, ${value.city} ${value.state}`);
    form.change(`${name}.fullAddress`, `${value.streetAddress}, ${value.city} ${value.state}`);
  };

  return (
    <div>
      <Col xs={12} sm={fullWidth ? 12 : 9}>
        <Field
          form={form}
          name={`${name}.completeAddress`}
          component={AddressAutocompleteFinalFormAdapter}
          placeholder={placeholder}
          addressComponentObj
          onChangeCustom={onChange}
          onSelect={customOnSelect}
          customHandleSelect
          validate={validate}
        />
      </Col>
      <Col xs={12} sm={fullWidth ? 12 : 3}>
        <Field
          name={UnitName}
          onChangeCustom={handleUnitChange}
          component={UnitNumberInput}
          placeholder={UnitPlaceHolder}
          ctaLabel="ADD UNIT"
        />
      </Col>
    </div>
  );
};

SingleLineLayout.propTypes = {
  UnitName: PropTypes.string,
  UnitNumberInput: PropTypes.node,
  UnitPlaceHolder: PropTypes.string,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleUnitChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  validate: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

SingleLineLayout.defaultProps = {
  placeholder: 'Address',
  UnitName: null,
  UnitNumberInput: null,
  UnitPlaceHolder: null,
};
