import { getApiActionTypesValues } from 'store/redux/redux_utils';
import { IMAGE_CONSTS } from 'store/redux/images/constants';
import { uploadDocumentToS3 } from 'store/redux/common/uploadDocumentToS3';
import { getMediaTypeAndNameFromFile } from 'utils/file';
import { filter, find, isNil } from 'lodash';

const getS3urls = (fileType, data) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues(IMAGE_CONSTS.UPLOAD_FILES),
    promise: ({ client }) => client.post(`/files/upload-url/${fileType}`, data),
    auth: true,
  });

const uploadFileToS3 = (fileConfig) => async (dispatch) => {
  const { headers, uploadUrl, file } = fileConfig;
  await uploadDocumentToS3(dispatch, file, headers, uploadUrl);
};

export const uploadFiles = (fileType, data = {}) => async (dispatch) => {
  const { availableMedia = [], stagingMedia = [], deletedMedia = [] } = data;
  if (isNil(availableMedia) || isNil(availableMedia)) return [];
  const oldMedia = filter(availableMedia, (media) => !find(deletedMedia, media));
  const getAttachmentsFromNewMedia = stagingMedia.map((attachment) => getMediaTypeAndNameFromFile(attachment));

  const s3URLs = await dispatch(getS3urls(fileType, getAttachmentsFromNewMedia));

  const mergeDataWithS3Urls = s3URLs.map((s3Url, index) => ({
    ...s3Url,
    file: stagingMedia[index],
  }));

  const uploadPromises = [];
  mergeDataWithS3Urls.forEach((file) => {
    uploadPromises.push(dispatch(uploadFileToS3(file)));
  });
  await Promise.all(uploadPromises);

  const s3IdAttachments = s3URLs.map((s3Url, index) => ({
    uniqueId: s3Url.uniqueId,
    name: getAttachmentsFromNewMedia[index].fileName,
    contentType: getAttachmentsFromNewMedia[index].contentType,
  }));

  return [...oldMedia, ...s3IdAttachments];
};
