import { fetchHomeownerInspections } from 'store/redux/inspections';
import { fetchAccountProperties, fetchAccountUnitListings } from '../homeowner-accounts/actions';
import { fetchProDetailsById } from '../pros/actions';
import { fetchReports } from '../user/actions';

export const fetchActiveImprovements = (stepId) => async (dispatch) => {
  const [flows, reports] = await dispatch({
    promise: ({ client }) => Promise.all([client.get(`/homeowners/flows`), fetchReports().promise({ client })]),
    auth: true,
    redirectToHomeOnHide: true,
  });

  const flowWithStep = flows.find((flow) => !!flow.steps.find((step) => step.uniqueId === stepId));

  return [flowWithStep, reports];
};

export const fetchBaseResources = (stepId) => async (dispatch) => {
  const [inspections, homeListings, properties, flows, reports] = await dispatch({
    promise: async ({ client }) =>
      Promise.all([
        dispatch(fetchHomeownerInspections()),
        fetchAccountUnitListings().promise({ client }),
        fetchAccountProperties().promise({ client }),
        client.get(`/homeowners/flows`),
        fetchReports().promise({ client }),
      ]),
    auth: true,
    redirectToHomeOnHide: true,
  });

  const improvementFlow = flows.find((flow) => !!flow.steps.find((step) => step.uniqueId === stepId));

  // Find inspected by
  let inspection;
  const reportId = improvementFlow?.steps?.find(
    (x) => x.dataType === 'Report' && x.stepName === 'ImprovementsQuality'
  )?.dataUniqueId;

  console.log({ reportId, reports });

  if (reportId) {
    // Find group
    const currentReport = reports?.find((x) => x.report.uniqueId === reportId && x.report.type === 'Improvements');
    if (currentReport) {
      inspection = inspections?.find((x) => x.groupUniqueId === currentReport.details.group.uniqueId);
    }
  }

  let pro;
  if (inspection?.inspectedBy) {
    pro = await dispatch(fetchProDetailsById(inspection.inspectedBy.userId));
  }
  const homeListingsWithProDetails = homeListings.map((listing) => ({
    ...listing,
    homeListingDetailsModel: {
      ...listing.homeListingDetailsModel,
      inspection,
      inspectedBy: pro,
    },
  }));
  return [homeListingsWithProDetails, properties];
};

export const fetchImprovementsFlowStep = (flowId, stepKey, stepId) => ({
  promise: ({ client }) => client.get(`/homeowners/improvements/${flowId}/${stepKey}/${stepId}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const updateImprovementsFlowStep = (flowId, stepKey, stepId, stepData = {}) => ({
  promise: ({ client }) =>
    client.put(`/homeowners/improvements/${flowId}/${stepKey}/${stepId}`, {
      ...stepData,
    }),
  auth: true,
  redirectToHomeOnHide: true,
});

export const fetchPricingFlowStep = (flowId, stepKey, stepId) => ({
  promise: ({ client }) => client.get(`/homeowners/pricing/${flowId}/${stepKey}/${stepId}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const updatePricingFlowStep = (flowId, stepKey, stepId, stepData = {}) => ({
  promise: ({ client }) =>
    client.put(`/homeowners/pricing/${flowId}/${stepKey}/${stepId}`, {
      ...stepData,
    }),
  auth: true,
  redirectToHomeOnHide: true,
});
