import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PATHS } from '@belong/common';
import { Image, Link, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import { FixedMenu } from 'components/FixedMenu/FixedMenu';
import Menu from 'components/Menu/Menu';
import { MENU_TYPES } from 'components/Menu/menuTypes';
import {
  MY_HOME_MENU_ITEM,
  RESIDENT_ACCOUNT_MENU_ITEMS,
  TOUR_AND_APPS_MENU_ITEM,
  ALL_USER_MENU,
  FAVORITE_MENU_ITEM,
  getHomePath,
  TO_HOMEOWNERS_ACCOUNT_MENU_ITEM,
  RESIDENT_DOCUMENTS_MENU_ITEM,
  RESIDENT_INBOX_MENU_ITEM,
} from 'consts/account-menu-items';
import { selectAccountAllLeases, selectFavoriteHomes, selectBookmarks } from 'store/redux/renter-accounts/selectors';
import styles from './accounts-layout.module.css';

const getFavoriteMenuBasedOnAmount = (favoriteHomes) => {
  if (favoriteHomes.length === 0 || favoriteHomes.length > 99) {
    return FAVORITE_MENU_ITEM;
  }

  return { ...FAVORITE_MENU_ITEM, header: `Favorites (${favoriteHomes.length})` };
};

const cx = classNames.bind(styles);

export function ResidentsLayout({ comeFromTheMobileApp, isHomeOwner, children }) {
  const favoriteHomes = useSelector(selectFavoriteHomes);
  const accountAllLeases = useSelector(selectAccountAllLeases);
  const bookmarks = useSelector(selectBookmarks);

  const params = useParams<any>();
  const { section, subSection, propertyId } = params;

  const getInitialDestopMenuItems = () => {
    if (!accountAllLeases?.length) return [];

    let leasesMenuItems = [];
    if (accountAllLeases.length > 1) {
      leasesMenuItems = accountAllLeases.map((lease) => ({
        header: lease.address.streetAddress,
        key: lease.unitInfo.unitId,
        to: `${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/${lease.unitInfo.unitId}/${lease.leaseInfo.basicInfo.leaseId}`,
      }));
    } else {
      const [firstAccountLease] = accountAllLeases;
      leasesMenuItems = [
        {
          header: firstAccountLease.address.streetAddress,
          key: firstAccountLease.unitInfo.unitId,
          to: `${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/${firstAccountLease.unitInfo.unitId}/${firstAccountLease.leaseInfo.basicInfo.leaseId}`,
        },
      ];
    }

    return [
      {
        header: 'My Home',
        key: 'home',
        to: getHomePath(accountAllLeases),
        menuType: MENU_TYPES.BORDER,
        selected: subSection,
        menuItems: [
          {
            header: '',
            menuHideOverFive: true,
            menuType: MENU_TYPES.BORDER_NESTED,
            alwaysOpen: true,
            selected: propertyId,
            menuItems: leasesMenuItems,
          },
          ...RESIDENT_ACCOUNT_MENU_ITEMS,
        ],
      },
    ];
  };

  const mobileMenu = [MY_HOME_MENU_ITEM, ...RESIDENT_ACCOUNT_MENU_ITEMS];
  const desktopMenuItems = getInitialDestopMenuItems();
  const commonMenuItems: any[] = [getFavoriteMenuBasedOnAmount(favoriteHomes)];

  if (bookmarks?.length) {
    commonMenuItems.push(TOUR_AND_APPS_MENU_ITEM);
  }

  commonMenuItems.push(RESIDENT_DOCUMENTS_MENU_ITEM);
  commonMenuItems.push(...ALL_USER_MENU);
  commonMenuItems.push(RESIDENT_INBOX_MENU_ITEM);

  return (
    <Fragment>
      {!comeFromTheMobileApp && (
        <FixedMenu
          menuItems={[...mobileMenu, ...commonMenuItems, ...(isHomeOwner ? [TO_HOMEOWNERS_ACCOUNT_MENU_ITEM] : [])]}
          params={params}
        />
      )}
      <div className="container mt-sm lg:mt-lg">
        <div className={cx('sidebar-wrapper')}>
          <div className={cx('sidebar')}>
            <Menu
              controlled
              menuType={MENU_TYPES.BACKGROUND}
              selected={section}
              menuItems={[...desktopMenuItems, ...commonMenuItems]}
            />
            {isHomeOwner && (
              <div className="hidden lg:flex flex-1 items-end pl-sm">
                <Link href={TO_HOMEOWNERS_ACCOUNT_MENU_ITEM.to}>
                  <div className="flex items-center">
                    <div className={styles.switchAccountContainer}>
                      <Image src="/accounts/switch-account-icon.svg" alt="" />
                    </div>
                    <Text fontWeight="semibold" className="ml-xs normal-case">
                      {TO_HOMEOWNERS_ACCOUNT_MENU_ITEM.header}
                    </Text>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    </Fragment>
  );
}
