import { isNil, sortBy, toNumber, includes } from 'lodash';
import Address from 'models/common/Address';
import Media from 'models/common/Media';
import ProfileInfo from 'models/common/ProfileInfo';
import PropertyBasicInfo from 'models/common/PropertyBasicInfo';
import UnitBasicInfo from 'models/common/UnitBasicInfo';
import UploadedMedia from 'models/common/UploadedMedia';
import { FeatureHighlights, FeatureCategories, HousePageTabs } from 'models/enums/index';
import { propertyStaticMapImageUrl } from '../../utils/staticMapUrlGenerator';

const HOME_LABELS = {
  UNAVAILABLE_LABEL: 'Unavailable',
  RENTED_UNIT_LABEL: 'Loved',
};

export default class Home {
  constructor(data = {}) {
    this.basicInfo = new UnitBasicInfo(data.basicInfo);
    this.propertyInfo = new PropertyBasicInfo(data.propertyInfo);
    this.address = new Address(data.address);
    this.rooms = data.rooms;
    this.spaces = data.spaces;
    this.features = data.features;
    this.employees = data.employees;
    this.galleryMedia = sortBy(
      data.galleryMedia.map((media) => new Media(media)),
      'sortOrder'
    );
    this.tourMedia = data.tourMedia.map((media) => new Media(media));
    this.owner = new ProfileInfo(data.owner);
    this.inspectedBy = data.inspectedBy ? new ProfileInfo(data.inspectedBy) : null;
    this.listingInfo = data.listingInfo;
    this.propertyScores = data.propertyScores;
    this.minLiabilityCoverage = data.minLiabilityCoverage;
    this.maxLiabilityCoverage = data.maxLiabilityCoverage;
    this.description = data.description;
    this.homeInterests = data.homeInterests;
    this.details = {};
    this.utilities = data.utilities;
    this.qualities = data.qualities;
    this.staticMapImageUrl = propertyStaticMapImageUrl({
      streetAddress: this.address?.streetAddress,
      city: this.address?.city,
      state: this.address?.state,
      zoom: 16,
    });
    this.disableApply = this.listingInfo.isDemoUnit || !this.listingInfo.isAvailableForRent;
    this.ctaLabel = 'Apply';
    this.guidedTour = data.guidedTour ? new UploadedMedia(data.guidedTour) : null;
    this.isPhotoReady = data.isPhotoReady;
    this.is3DTourReady = data.is3DTourReady;
    this.isGuidedTourReady = data.isGuidedTourReady;
    this.leaseOffered = data.leaseOffered;
    this.virtual3DTourId = data.virtual3DTourId;
    this.virtual3DTourProvider = data.virtual3DTourProvider;

    if (!this.listingInfo.isAvailableForRent) {
      this.ctaLabel = HOME_LABELS.RENTED_UNIT_LABEL;
    }

    if (this.listingInfo.isDemoUnit) {
      this.ctaLabel = HOME_LABELS.UNAVAILABLE_LABEL;
    }
    this.showMediaAvailable = {
      [HousePageTabs.ExteriorPhotos]: this.basicInfo?.mediaAvailable.includes(HousePageTabs.ExteriorPhotos),
      [HousePageTabs.RoomsPhotos]: this.basicInfo?.mediaAvailable.includes(HousePageTabs.RoomsPhotos),
      [HousePageTabs.Virtual3DTour]: !!(
        this.basicInfo?.mediaAvailable.includes(HousePageTabs.Virtual3DTour) &&
        this.virtual3DTourProvider &&
        this.virtual3DTourId
      ),
      [HousePageTabs.GuidedTour]:
        !!this.basicInfo?.mediaAvailable.includes(HousePageTabs.GuidedTour) && this.guidedTour?.url,
      [HousePageTabs.StreetView]: this.basicInfo?.mediaAvailable.includes(HousePageTabs.StreetView),
      [HousePageTabs.Map]: this.basicInfo?.mediaAvailable.includes(HousePageTabs.Map),
    };

    this.showGuidedTour = this.showMediaAvailable[HousePageTabs.GuidedTour];

    /* Process Media */
    this.mediaGroup = {};

    const interiorSections = [];
    const exteriorSections = [];
    const featureSections = [];

    const combinedExterior = ['Exterior-combined'];

    const sortHelper = (sections) => {
      sections = sortBy(sections, 'sortOrder');

      return sections.reduce((arr, item) => {
        if (item.type) {
          if (item.type !== 'Exterior') arr.push(`${item.type}-${item.id}`);
        } else {
          arr.push(`${item.name}-${item.category}`);
        }
        return arr;
      }, []);
    };

    this.spaces.forEach((space) => {
      const sectionKey = space.type === 'Exterior' ? combinedExterior[0] : `${space.type}-${space.id}`;

      if (this.mediaGroup[sectionKey]) {
        this.mediaGroup[sectionKey] = this.mediaGroup[sectionKey].concat(
          this.tourMedia.filter((media) => media.ownerId === space.id)
        );
      } else {
        this.mediaGroup[sectionKey] = sortBy(
          this.tourMedia.filter((media) => media.ownerId === space.id && media.ownerType === 'Space'),
          'sortOrder'
        );
        return space.isCommunalSpace ? exteriorSections.push(space) : interiorSections.push(space);
      }
    });

    this.mediaSectionNames = combinedExterior
      .concat(sortHelper(interiorSections))
      .concat(sortHelper(exteriorSections))
      .filter((section) => this.mediaGroup[section].length > 0);

    this.featuresMediaSectionNames = featureSections;

    /* Process details */
    this.details.hasAirCondition = this.features.find((feature) => feature.name === 'A/C');
    this.details.parkingSpots = 0;
    this.details.parkingType = 'No Parking';
    this.details.isCentralAirCondition =
      this.details.hasAirCondition && this.details.hasAirCondition.attributes?.IsCentralAirCondition;
    this.details.heaterType =
      this.features.find((feature) => feature.name === 'Heat')?.attributes.HeatType ||
      this.features.find((feature) => feature.name === 'Heat')?.attributes.HeatDistribution;
    this.details.interiorHighlights = {};
    this.details.exteriorHighlights = {};

    const { interiorHighlights, exteriorHighlights } = this.details;

    this.features.forEach((feature) => {
      const { category } = feature;

      if (feature.isCommunalFeature) {
        exteriorHighlights[category] = exteriorHighlights[category] || [];
        exteriorHighlights[category].push(feature);
      } else {
        interiorHighlights[category] = interiorHighlights[category] || [];
        interiorHighlights[category].push(feature);
      }
    });

    // string formatters
    const stringify = (list = []) =>
      list.map((feature) => {
        const returnObj = {};
        returnObj[feature.name] = {
          displayName: feature.name,
          feature,
        };
        return returnObj;
      });

    const parkingFormatter = (list = []) => {
      const returnObj = {};

      list.forEach((feature) => {
        const featureName = feature.name;
        const { attributes = {} } = feature;
        returnObj[featureName] = {
          features: [feature],
          count: 1,
        };

        const {
          ChargingStation,
          GarageParking,
          DrivewayParking,
          LotParking,
          CarportParking,
          OnStreetPermit,
          AlleyOffStreetParking,
        } = FeatureHighlights;

        const spots = toNumber(attributes.numberOfSpots) || toNumber(attributes.NumberOfSpots) || 0;

        switch (featureName) {
          case AlleyOffStreetParking:
            this.details.parkingType = 'Street Parking';
            returnObj[featureName].displayName = AlleyOffStreetParking;
            break;
          case OnStreetPermit:
            this.details.parkingType = 'Street Permit';
            returnObj[featureName].displayName = OnStreetPermit;
            break;
          case ChargingStation:
            returnObj[featureName].displayName = attributes.IsOutletOnly
              ? 'Electric Vehicle Outlet'
              : 'Charging Station';
            break;
          case GarageParking:
            this.details.parkingSpots += spots;
            returnObj[featureName].displayName = spots ? `${spots} Car Garage` : 'Car Garage';
            break;
          case DrivewayParking:
            this.details.parkingSpots += spots;
            returnObj[featureName].displayName = spots ? `${spots}  ${DrivewayParking}` : DrivewayParking;
            break;
          case LotParking:
            this.details.parkingSpots += spots;
            returnObj[featureName].displayName = spots ? `${spots}  ${LotParking}` : LotParking;
            break;
          case CarportParking:
            this.details.parkingSpots += spots;
            returnObj[featureName].displayName = spots ? `${spots}  ${CarportParking}` : CarportParking;
            break;
          default:
            returnObj[featureName].displayName = featureName;
            break;
        }
      });

      return returnObj;
    };

    const climateControlFormatter = (list = []) =>
      list.map((feature) => {
        const featureName = feature.name;
        const { attributes = {} } = feature;
        const returnObj = {
          [featureName]: {
            feature,
            displayName: featureName,
          },
        };

        switch (featureName) {
          case FeatureHighlights.Heat:
            if (attributes.HeatType && attributes.HeatDistribution) {
              returnObj[featureName].displayName = `Heating: ${attributes.HeatType} (${attributes.HeatDistribution})`;
              return returnObj;
            }

            if (attributes.HeatType) {
              returnObj[featureName].displayName = `Heating: ${attributes.HeatType}`;
              return returnObj;
            }
            if (attributes.HeatDistribution) {
              returnObj[featureName].displayName = `Heating: (${attributes.HeatDistribution})`;
              return returnObj;
            }
            return returnObj;
          case FeatureHighlights['A/C']:
            returnObj[featureName].displayName = attributes.IsCentralAirCondition ? 'Cooling: Central' : featureName;
            return returnObj;
          case FeatureHighlights.Thermostat:
            returnObj[featureName].displayName = attributes.Type === 'Smart' ? 'Smart Thermostat' : featureName;
            return returnObj;
          default:
            return returnObj;
        }
      });

    const cookingFormatter = (list = []) =>
      list.map((feature) => {
        const featureName = feature.name;
        const { attributes = {} } = feature;
        const returnObj = {
          [featureName]: {
            feature,
            displayName: featureName,
          },
        };

        switch (featureName) {
          case FeatureHighlights.Hood:
            if (attributes && attributes.Type === 'Vented') {
              returnObj[featureName].displayName = 'Vented Hood';
              return returnObj;
            }
            if (attributes && attributes.Type) {
              returnObj[featureName].displayName = `${featureName} (${attributes.Type})`;
              return returnObj;
            }
            return returnObj;
          default:
            return returnObj;
        }
      });

    const laundryFormatter = (list = []) =>
      list.map((feature) => {
        const featureName = feature.name;
        const returnObj = {
          [featureName]: {
            feature,
            displayName: feature.name,
          },
        };

        if (featureName === 'Laundry Hookup') {
          return returnObj;
        }

        if (!feature.attributes) {
          returnObj[featureName].displayName = 'Hookups Only';
          return returnObj;
        }

        const {
          isCommunalFeature,
          attributes: { CommunalLaundryType, communalLaundryType, canBeConcealed, CanBeConcealed },
        } = feature;

        if (this.details.laundryType !== 'In Unit') {
          this.details.laundryType = isCommunalFeature ? 'Communal' : 'In Unit';
        }

        const comLaundryType = communalLaundryType || CommunalLaundryType;
        const concealed = canBeConcealed || CanBeConcealed;

        returnObj[featureName].displayName = isCommunalFeature
          ? `Communal (${comLaundryType})`
          : `${concealed ? 'Enclosed ' : ''}Washer/Dryer`;
        return returnObj;
      });

    const countReducer = (list = [], noCount = false, uniqueCase = []) => {
      const memo = {};
      list.forEach((item) => {
        const [name] = Object.keys(item);
        if (isNil(memo[name])) memo[name] = {};
        memo[name].count =
          noCount && !includes(uniqueCase, name) ? memo[name]?.count || 1 : (memo[name]?.count || 0) + 1;

        if (!Array.isArray(memo[name]?.features)) memo[name].features = [];

        memo[name].features.push(item[name].feature);
        memo[name].displayName = item[name].displayName;
      });
      Object.keys(memo).forEach((key) => {
        if (memo[key].count > 1) memo[key].displayName = `${memo[key].count} ${memo[key].displayName}s`;
        else memo[key].displayName = memo[key].displayName;
      });
      return memo;
    };

    const applyFormatter = (highlights) => {
      Object.keys(highlights).forEach((category) => {
        switch (category) {
          case FeatureCategories.Yard:
            highlights[category] = countReducer(stringify(highlights[category]), true, [
              'Fire Pit',
              'Courtyard',
              'Patio',
              'Deck',
              'Garden',
              'Bike Rack',
            ]);
            break;
          case FeatureCategories.Accessibility:
            highlights[category] = countReducer(stringify(highlights[category]));
            break;
          case FeatureCategories.Utilities:
            highlights[category] = countReducer(stringify(highlights[category]), true, ['Water Heater']);
            break;
          case FeatureCategories.Surroundings:
            highlights[category] = countReducer(stringify(highlights[category]), true);
            break;
          case FeatureCategories.Storage:
            highlights[category] = countReducer(stringify(highlights[category]), true, ['Medicine Cabinet']);
            break;
          case FeatureCategories['Safety & Security']:
            highlights[category] = countReducer(stringify(highlights[category]), true);
            break;
          case FeatureCategories.Pool:
            highlights[category] = countReducer(stringify(highlights[category]));
            break;
          case FeatureCategories.Other:
            highlights[category] = countReducer(stringify(highlights[category]));
            break;
          case FeatureCategories.Fitness:
            highlights[category] = countReducer(stringify(highlights[category]), true);
            break;
          case FeatureCategories['Audio & Video']:
            highlights[category] = countReducer(stringify(highlights[category]), true);
            break;
          case FeatureCategories.Aesthetics:
            highlights[category] = countReducer(stringify(highlights[category]), true);
            break;
          case FeatureCategories.Parking:
            highlights[category] = parkingFormatter(highlights[category]);
            break;
          case FeatureCategories['Climate Control']:
            highlights[category] = countReducer(climateControlFormatter(highlights[category]), true);
            break;
          case FeatureCategories.Laundry:
            highlights[category] = countReducer(laundryFormatter(highlights[category]), true);
            break;
          case FeatureCategories.Cooking:
            highlights[category] = countReducer(cookingFormatter(highlights[category]), true);
            break;
          case FeatureCategories.Lighting:
            highlights[category] = countReducer(stringify(highlights[category]), true, ['Sun Tunnel']);
            break;
          default:
            highlights[category] = stringify(highlights[category]);
        }
      });
    };

    applyFormatter(interiorHighlights);
    applyFormatter(exteriorHighlights);

    this.features.forEach((feature) => {
      let featureCategoryName = `${feature.category}|`;
      let parsedFeatures;
      let parsedFeatureObj;

      if (feature.name === 'Fireplace' && !feature.attributes?.IsFunctional) {
        feature.name = 'Fireplace (Decorative Only)';
      }

      if (feature.isCommunalFeature) {
        parsedFeatureObj = exteriorHighlights[feature.category][feature.name];
        parsedFeatures = parsedFeatureObj?.features || [];
        featureCategoryName += `${parsedFeatureObj?.displayName || feature.name}|communal`;
      } else {
        parsedFeatureObj = interiorHighlights[feature.category][feature.name];
        parsedFeatures = parsedFeatureObj?.features || [];
        featureCategoryName += parsedFeatureObj?.displayName || feature.name;
      }

      const sectionKeyExists = featureSections.includes(featureCategoryName);
      const sortedMedia = sortBy(
        this.tourMedia.filter(
          (media) => !!parsedFeatures.filter((f) => f.id === media.ownerId)?.length && media.ownerType === 'Feature'
        ),
        'sortOrder'
      );
      this.mediaGroup[featureCategoryName] = sortedMedia;

      if (!sectionKeyExists) {
        featureSections.push(featureCategoryName);
      }
    });
  }

  getHousePageUrl() {
    const {
      address,
      basicInfo: { unitId },
    } = this;
    const cityStateString = `${address.city.split(' ').join('-').toLowerCase()}-${address.state.toLowerCase()}`;
    return `/homes/${cityStateString}/${unitId}`;
  }
}
