// React imports
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import { Toast } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskMMDDYYYY, unmaskMMDDYYYY, maskSSN, unmaskSSN } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
// Component imports
import Spinner from 'components/Spinner/Spinner';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import FormLayout from 'layouts/FormLayout/FormLayout';
// Lodash imports
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import {
  fetchPersonalInformation,
  updateLegalIdentityInformation as updateResidentLegalIdentityInformation,
} from 'store/redux/resident-onboarding/actions';
import { selectResidentPersonalInfo } from 'store/redux/resident-onboarding/selectors';
import { updateLegalIdentityInformation } from 'store/redux/user/actions';
import { selectUser } from 'store/redux/user/selectors';
import { getString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { RESIDENT_ONBOARDING_STRINGS } from 'strings/resident-onboarding.strings';
import { converDateTimeServerResponseToDateTimeObject, parseDateTimeInputString } from 'utils/dateTimeUtils';
import { ssnValidation, dateOfBirthValidation, required, composeValidators } from 'utils/validation';
import { IS_LEGAL_NAME } from '../../constants';
import { getResidentOnboardingStepPath, STEPS_DATA } from '../common';
import styles from './PersonalInformation.module.css';

const cx = classNames.bind(styles);
const ROS = RESIDENT_ONBOARDING_STRINGS.personal_info;
const RAS = RESIDENT_APPLICATION_STRINGS.background_check;
const { FormLayoutHeader } = FormLayout;

class PersonalInformation extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    fetchPersonalInformation: PropTypes.func.isRequired,
    updateLegalIdentityInformation: PropTypes.func.isRequired,
    updateResidentLegalIdentityInformation: PropTypes.func.isRequired,
    residentPersonalInfo: PropTypes.object,
  };

  static defaultProps = {
    residentPersonalInfo: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      awaitingFetchComplete: true,
      formData: {},
      outsideUsCurrentAddress: false,
      isErrorToastVisible: false,
      errorText: '',
    };
  }

  async componentDidMount() {
    const { residentPersonalInfo, fetchPersonalInformation: fetchPersonalInformationAction } = this.props;

    if (isEmpty(residentPersonalInfo)) {
      try {
        await fetchPersonalInformationAction();
      } catch (err) {
        console.error(err);
      }
    }

    this.setupInitialValues();
  }

  setupInitialValues() {
    const {
      residentPersonalInfo,
      residentPersonalInfo: { userInfo = {} },
    } = this.props;
    const updatedState = {
      outsideUsCurrentAddress: false,
      formData: {
        ...residentPersonalInfo.userInfo,
        ssn: {},
      },
    };

    const hasLegalInfo = userInfo.legalFirstName && userInfo.legalFirstName !== '';
    let isSameUserAndLegalName = false;

    if (residentPersonalInfo.currentAddress && !isEmpty(residentPersonalInfo.currentAddress)) {
      // To-Do: Remove after the bug is fixed on the backend
      updatedState.formData = {
        ...updatedState.formData,
        currentAddress: {
          ...residentPersonalInfo.currentAddress,
        },
      };
    }

    if (userInfo.ssnLastDigits && userInfo.ssnLastDigits !== '') {
      updatedState.formData.ssn.inputValue = `xxx-xx-${userInfo.ssnLastDigits}`;
    } else if (userInfo.hasNoSsn) {
      updatedState.formData.ssn.checkboxValue = true;
    }

    if (!isNil(userInfo.legalFirstName) && userInfo.legalFirstName !== '') {
      isSameUserAndLegalName = this.compareUserAndLegalName();
    }

    if (hasLegalInfo && isSameUserAndLegalName) {
      updatedState.formData.isLegalName = IS_LEGAL_NAME.YES;
    } else if (hasLegalInfo && !isSameUserAndLegalName) {
      updatedState.formData.isLegalName = IS_LEGAL_NAME.NO;
    }

    if (!isNil(userInfo.dateOfBirth) && userInfo.dateOfBirth !== '') {
      const dateOfBirthDateObject = converDateTimeServerResponseToDateTimeObject(userInfo.dateOfBirth);
      updatedState.formData.dateOfBirth = formatDateTime({
        dateTime: dateOfBirthDateObject,
        format: DATE_FORMATS.STANDARD,
      });
    }

    this.setState({
      awaitingFetchComplete: false,
      ...updatedState,
    });
  }

  compareUserAndLegalName() {
    const {
      user,
      residentPersonalInfo: { userInfo },
    } = this.props;
    let isSame = true;

    if (user.firstName !== userInfo.legalFirstName) {
      isSame = false;
    } else if (user.lastName !== userInfo.legalLastName) {
      isSame = false;
    } else if (userInfo.legalMiddleName && userInfo.legalMiddleName !== '') {
      isSame = false;
    }

    return isSame;
  }

  handleSubmit = async (values) => {
    const {
      history: { push },
      residentPersonalInfo: { userInfo: previousUserInfo = {} },
      updateResidentLegalIdentityInformation: updateResidentLegalIdentityInformationAction,
      updateLegalIdentityInformation: updateLegalIdentityInformationAction,
      user,
    } = this.props;
    const { outsideUsCurrentAddress } = this.state;

    const dateOfBirthUserString = values.dateOfBirth;
    const dateOfBirthServerString = parseDateTimeInputString(dateOfBirthUserString);

    values.dateOfBirth = dateOfBirthServerString;

    const userInfo = {
      dateOfBirth: dateOfBirthServerString,
    };
    const userSsnInformation = {
      ssn: values.ssn?.inputValue || '',
    };

    this.setState({
      awaitingFetchComplete: true,
      isErrorToastVisible: false,
    });

    if (values.isLegalName === IS_LEGAL_NAME.NO) {
      userInfo.legalFirstName = values.legalFirstName;
      userInfo.legalMiddleName = values.legalMiddleName;
      userInfo.legalLastName = values.legalLastName;
    } else if (values.isLegalName === IS_LEGAL_NAME.YES) {
      userInfo.legalFirstName = user.firstName;
      userInfo.legalLastName = user.lastName;
    }

    if (values.ssn?.checkboxValue) {
      userSsnInformation.hasNoSsn = values.ssn.checkboxValue;
      userSsnInformation.ssn = '';
    }

    if (!previousUserInfo.ssnLastDigits || !/^xxx\-xx\-?[0-9]{4}$/i.test(values.ssn?.inputValue)) {
      try {
        await updateLegalIdentityInformationAction(userSsnInformation);
      } catch (err) {
        console.error(err);
      }
    }

    if (previousUserInfo.hasNoSsn || values.noSsn) {
      try {
        await updateLegalIdentityInformationAction(userSsnInformation);
      } catch (err) {
        console.error(err);
      }
    }

    const serverObject = {
      userInfo: { ...userInfo },
      currentAddress: { ...values.currentAddress, outsideUS: outsideUsCurrentAddress },
    };

    try {
      await updateResidentLegalIdentityInformationAction(serverObject);

      push(getResidentOnboardingStepPath(STEPS_DATA.IDENTITY_CHECK));
    } catch (error) {
      console.error(error);

      if (error?.[0]?.errorCode === 'INVALID_ADDRESS') {
        this.setState({
          isErrorToastVisible: true,
          errorText: error?.[0]?.message,
          awaitingFetchComplete: false,
        });
      }
    }
  };

  handleOutsideUsAddressCallback = (outsideUs) => {
    this.setState({ outsideUsCurrentAddress: outsideUs });
  };

  handleToastClose = () => {
    this.setState({ isErrorToastVisible: false });
  };

  render() {
    const { user } = this.props;
    const { awaitingFetchComplete, formData, outsideUsCurrentAddress } = this.state;

    return (
      <>
        <FormLayout className="relative">
          <FormLayoutHeader title={getString(ROS.title, { firstName: user.firstName })} subtitle={ROS.subTitle} />
          <Form
            initialValues={formData}
            onSubmit={this.handleSubmit}
            render={({ handleSubmit, invalid, form }) => (
              <div className={cx('form-wrapper')}>
                <form onSubmit={handleSubmit}>
                  <FormLayout.Section
                    firstSection
                    sectionTitle={getString(ROS.is_name_legal_question, {
                      firstName: user.firstName,
                      lastName: user.lastName,
                    })}
                  >
                    <Row>
                      <Col md={12}>
                        <div className={cx('is-legal-name-selector-wrapper')}>
                          <Field
                            name="isLegalName"
                            component={SelectorFinalFormAdapter}
                            validate={required}
                            buttons={[
                              {
                                label: ROS.is_name_legal_yes,
                                key: IS_LEGAL_NAME.YES,
                              },
                              {
                                label: ROS.is_name_legal_no,
                                key: IS_LEGAL_NAME.NO,
                              },
                            ]}
                            type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Condition when="isLegalName" is={IS_LEGAL_NAME.NO}>
                      <Row>
                        <Col md={4}>
                          <Field
                            name="legalFirstName"
                            component={InputFinalFormAdapter}
                            validate={required}
                            placeholder={ROS.legal_first_name}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            name="legalMiddleName"
                            component={InputFinalFormAdapter}
                            placeholder={ROS.legal_middle_name}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            name="legalLastName"
                            component={InputFinalFormAdapter}
                            validate={required}
                            placeholder={ROS.legal_last_name}
                          />
                        </Col>
                      </Row>
                    </Condition>
                    <Row>
                      <Col md={6}>
                        <Field
                          name="dateOfBirth"
                          component={InputFinalFormAdapter}
                          placeholder={ROS.date_of_birth}
                          validate={dateOfBirthValidation}
                          mask={maskMMDDYYYY}
                          unmask={unmaskMMDDYYYY}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          name="ssn"
                          component={InputCheckboxAdapter}
                          form={form}
                          inputProps={{
                            mask: maskSSN,
                            unmask: unmaskSSN,
                            placeholder: ROS.full_ssn_itin,
                            validate: composeValidators(required, ssnValidation),
                          }}
                          checkboxProps={{
                            label: 'No SSN',
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Condition when="ssn.checkboxValue" is>
                          <div className={cx('large-separator')} />
                          <div>
                            <Row>
                              <Col md={12}>
                                <GreenInfoBox>
                                  <div className={cx('no-ssn-section-title')}>{RAS.no_ssn_section_title}</div>
                                  <div className={cx('no-ssn-section-text')}>{RAS.no_ssn_section_text}</div>
                                </GreenInfoBox>
                              </Col>
                            </Row>
                          </div>
                          <div className={cx('large-separator')} />
                        </Condition>
                      </Col>
                    </Row>
                    <AddressField
                      form={form}
                      name="currentAddress"
                      allowOutsideUs
                      outsideUs={outsideUsCurrentAddress}
                      outsideUsAddressCallback={this.handleOutsideUsAddressCallback}
                    />
                    <div className={cx('form-submit')}>
                      <Button
                        className={cx('cta-button')}
                        type="submit"
                        label={ROS.next_button_text}
                        disabled={invalid}
                      />
                    </div>
                  </FormLayout.Section>
                </form>
              </div>
            )}
          />
        </FormLayout>
        <Toast isVisible={this.state.isErrorToastVisible} onClose={this.handleToastClose} variant="danger">
          {this.state.errorText}
        </Toast>
        {awaitingFetchComplete && (
          <Row className="w-full absolute top-0">
            <Col md={12}>
              <div className={cx('spinner')}>
                <Spinner />
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    user: selectUser(state),
    residentPersonalInfo: selectResidentPersonalInfo(state),
  }),
  {
    fetchPersonalInformation,
    updateLegalIdentityInformation,
    updateResidentLegalIdentityInformation,
  }
)(PersonalInformation);
