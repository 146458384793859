import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { BREAKPOINTS_WIDTHS, useModal, useWindowSize, PATHS } from '@belong/common';
import { Divider, Form, Modal, Spinner, Text, TextField, Toast } from '@belong/ui';
import type { MaintenanceRecurrenceDetailModel } from 'api/models';
import LegacyButton from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import DottedMenu, { STYLED_MENUS } from 'components/DottedMenu/DottedMenu';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { isAfter } from 'date-fns';
import { API_ERRORS, URL_QUERY_PARAMS } from 'maintenance/constants';
import { STRINGS } from 'maintenance/strings';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import { cancelRecurringProService, cancelRecurringProServiceVisit } from 'store/redux/pros/actions';
import styles from './maintenance-recurrence-footer.module.css';
import LinkBase from 'corecomponents/LinkBase/LinkBase';

type MaintenanceRecurrentRouteParams = {
  recurrenceType: string;
  subtaskId: string;
};

type CancelSingleVisitFormSchema = {
  cancellationReason: string;
};

type Props = {
  isResident?: boolean;
  isSubtask?: boolean;
  task: MaintenanceRecurrenceDetailModel;
};

export const MaintenanceRecurrenceFooter = ({ isResident = false, isSubtask = false, task }: Props) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const { push } = useHistory();
  const { recurrenceType, subtaskId } = useParams<MaintenanceRecurrentRouteParams>();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [isCancelModalOpen, openCancelModal, closeCancelModal] = useModal();

  const isMobile = width < BREAKPOINTS_WIDTHS.SM;

  const isCleaning = recurrenceType.toLowerCase() === 'cleaning';
  const isEnabledForCancellation = !['landscaping', 'poolmaintenance'].includes(recurrenceType.toLowerCase());

  const isEditionEnabled = (isResident && isCleaning) || !isResident;

  const isCancellationForAllVisitsEnabled = (isResident && isCleaning) || (!isResident && isEnabledForCancellation);

  const isCancellationForSingleVisitEnabled = isSubtask && isCancellationForAllVisitsEnabled;

  const cancelSingleVisitInitialValues: CancelSingleVisitFormSchema = {
    cancellationReason: '',
  };

  const handleEditClick = () => {
    const baseURL = isResident ? PATHS.RESIDENT_PRO_SERVICE_CREATE : PATHS.HOMEOWNER_PRO_SERVICE_CREATE;
    const proServiceCreationPage = baseURL.replace(':proService', recurrenceType);

    push({
      pathname: proServiceCreationPage,
      search: `?unitId=${task.home.unitId}`,
    });
  };

  const handleViewHistory = () => {
    const baseURL = isResident
      ? PATHS.RESIDENTS_ACCOUNT_RECURRING_MAINTENANCE
      : PATHS.HOMEOWNER_ACCOUNT_RECURRING_MAINTENANCE;

    push(`${baseURL}/${task.home.unitId}/${recurrenceType}`);
  };

  const validateCancelThisVisitOnly = ({ cancellationReason }: CancelSingleVisitFormSchema) => {
    const errors: any = {}; /* eslint-disable-line @typescript-eslint/no-explicit-any */

    if (!cancellationReason) {
      return (errors.cancellationReason = 'Required');
    }

    return errors;
  };

  const handleCancelThisVisitOnlySubmit = async ({ cancellationReason }: CancelSingleVisitFormSchema) => {
    try {
      setIsCancelling(true);
      setIsToastVisible(false);

      const currentSubtask = task.subTasks.find((subtask) => subtask.uniqueId === subtaskId);
      const nextProServiceAppointment = task.subTasks.find(
        (subtask) =>
          subtask.uniqueId !== subtaskId &&
          ['OccurringAround', 'Scheduled'].includes(subtask.subTaskStatus) &&
          isAfter(new Date(subtask.calculatedDateNextOn), new Date(currentSubtask.calculatedDateNextOn))
      );

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append(URL_QUERY_PARAMS.TOAST_VISIBLE, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.SUCCESS, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.CANCEL, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.TASK, recurrenceType);

      if (nextProServiceAppointment) {
        urlSearchParams.append(URL_QUERY_PARAMS.NEXT_ON, nextProServiceAppointment.calculatedDateNextOn);
      }

      await dispatch(
        cancelRecurringProServiceVisit({
          maintenanceUniqueId: subtaskId,
          resultReason: cancellationReason,
        })
      );

      const baseURL = isResident
        ? PATHS.RESIDENTS_ACCOUNT_RECURRING_MAINTENANCE
        : PATHS.HOMEOWNER_ACCOUNT_RECURRING_MAINTENANCE;

      push({
        pathname: `${baseURL}/${task.home.unitId}/${recurrenceType}`,
        search: urlSearchParams.toString(),
      });
    } catch (error) {
      console.error(error);

      if (error?.[0]?.errorCode === API_ERRORS.APPOINTMENT_TOO_SOON) {
        setIsToastVisible(true);
      }
    } finally {
      setIsCancelling(false);
    }
  };

  const handleToastClose = () => setIsToastVisible(false);

  const handleCancelModalClose = () => {
    setIsToastVisible(false);
    closeCancelModal();
  };

  const handleCancelAllUpcomingVisitsClick = async () => {
    try {
      setIsCancelling(true);

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append(URL_QUERY_PARAMS.TOAST_VISIBLE, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.SUCCESS, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.TURN_OFF, 'true');

      await dispatch(
        cancelRecurringProService({
          recurringTask: recurrenceType,
          unitId: task.home.unitId,
        })
      );

      push({
        pathname: isResident ? PATHS.RESIDENTS_ACCOUNT_MAINTENANCE : PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE,
        search: urlSearchParams.toString(),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsCancelling(false);
    }
  };

  const showMoreMenu = [
    isEditionEnabled,
    isSubtask,
    isCancellationForSingleVisitEnabled,
    isCancellationForAllVisitsEnabled,
  ].filter(Boolean);

  return (
    <>
      {isCancelling && <Spinner />}
      <div className="flex flex-col justify-end w-full items-end mb-7xl">
        <Divider className="mb-xl" />
        <div className="flex gap-lg">
          <LinkBase href={PATHS.SUPPORT}>
            <Text fontWeight="semibold" className="hover:text-green uppercase">
              CONTACT MEMBER SUCCESS LEAD
            </Text>
          </LinkBase>

          {showMoreMenu.length ? (
            <DottedMenu
              style={STYLED_MENUS.NO_BORDER_BETWEEN}
              className="w-[350px] px-0 py-2sm cursor-default"
              menuItems={[
                ...(isEditionEnabled && [
                  {
                    name: (
                      <LegacyButton
                        className="w-full flex justify-start px-sm"
                        label="EDIT"
                        buttonType={BUTTON_TYPES.TEXT_DANGER}
                        onClick={handleEditClick}
                      />
                    ),
                    key: 'edit-master-ticket',
                  },
                ]),
                ...(isSubtask && [
                  {
                    name: (
                      <LegacyButton
                        className="w-full flex justify-start px-sm"
                        label="VIEW HISTORY"
                        buttonType={BUTTON_TYPES.TEXT_DANGER}
                        onClick={handleViewHistory}
                      />
                    ),
                    key: 'view-history',
                  },
                ]),
                ...(isCancellationForSingleVisitEnabled && [
                  {
                    name: (
                      <LegacyButton
                        className="w-full flex justify-start px-sm"
                        label="SKIP THIS VISIT"
                        buttonType={BUTTON_TYPES.TEXT_DANGER}
                        onClick={openCancelModal}
                      />
                    ),
                    key: 'cancel-visit',
                  },
                ]),
                ...(isCancellationForAllVisitsEnabled && [
                  {
                    name: (
                      <LegacyButton
                        className="w-full flex justify-start px-sm"
                        label="CANCEL ALL UPCOMING VISITS"
                        buttonType={BUTTON_TYPES.TEXT_DANGER}
                        onClick={handleCancelAllUpcomingVisitsClick}
                      />
                    ),
                    key: 'cancel-upcoming-visits',
                  },
                ]),
              ]}
              menuButtonLabel={
                <div>
                  <div>MORE</div>
                </div>
              }
            />
          ) : null}
        </div>
      </div>
      <Modal
        isOpen={isCancelModalOpen}
        name="cancel_recurring_visit"
        onDismiss={handleCancelModalClose}
        size={isMobile ? 'fullScreen' : 'auto'}
        overlayClassName="z-fullscreen"
        contentClassName="sm:w-[730px] md:w-[730px]"
      >
        <div className="flex flex-col px-2sm pt-3xl sm:pt-2xl sm:px-lg">
          {isCancelling && <Spinner />}
          <div className="max-w-[264px] sm:max-w-none mb-2xl self-center text-center">
            <Text variant="h2" fontWeight="semibold">
              Why do you want to skip?
            </Text>
          </div>
          <Form
            initialValues={cancelSingleVisitInitialValues}
            onSubmit={handleCancelThisVisitOnlySubmit}
            validate={validateCancelThisVisitOnly}
          >
            {() => (
              <div className="flex flex-col">
                <div className="mb-2xl">
                  <TextField label="Reason" name="cancellationReason" />
                </div>
                <div className="sm:hidden mb-sm">
                  <Form.SubmitButton>Submit</Form.SubmitButton>
                </div>
                <div className={styles.footer}>
                  <QuestionsOrConcernsModalFooter>
                    <div className="hidden sm:block">
                      <Form.SubmitButton>Submit</Form.SubmitButton>
                    </div>
                  </QuestionsOrConcernsModalFooter>
                </div>
              </div>
            )}
          </Form>
        </div>
      </Modal>
      <Toast isVisible={isToastVisible} onClose={handleToastClose} variant="danger">
        <div className="sm:max-w-[480px]">{STRINGS['appointment-too-soon']}</div>
      </Toast>
    </>
  );
};
