import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@belong/ui';
import type { MaintenanceRecurrenceModel } from 'api/models';
import AddTaskModal from 'containercomponents/Modals/AddTaskModal/AddTaskModal';
import RenterAddTaskModal from 'containercomponents/Modals/RenterAddTaskModal/RenterAddTaskModal';
import RenterRequestUpgradeModal from 'containercomponents/Modals/RenterRequestUpgradeModal/RenterRequestUpgradeModal';
import { TaskList } from 'maintenance/components/task-list/task-list';
import type { ProServiceView } from 'maintenance/types';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';
import MaintenanceTaskModel from 'models/maintenance/MaintenanceTaskModel';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import {
  fetchHomeOwnersMaintenance,
  fetchRecurringTasks,
  fetchResidentMaintenance,
} from 'store/redux/maintenance/actions';
import { selectUserClaims } from 'store/redux/user/selectors';

type Props = {
  address: string;
  property?: HomeownerAccountProperty;
  showMaintenanceModal: (task: MaintenanceTaskModel | MaintenanceRecurrenceModel) => () => void;
  recurringTasks: MaintenanceRecurrenceModel[];
  tasks: MaintenanceTaskModel[];
  unit?: HomeownerAccountUnit | RenterAccountLease['unitInfo'];
  view?: ProServiceView;
};

export function UnitTasksSection({
  address,
  property,
  showMaintenanceModal,
  tasks,
  recurringTasks,
  unit,
  view = 'homeOwner',
}: Props) {
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isResidentAddTaskModalVisible, setIsResidentAddTaskModalVisible] = useState(false);
  const [isResidentRequestUpgradeModalVisible, setIsResidentRequestUpgradeModalVisible] = useState(false);
  const { isHomeOwner, isResident } = useSelector(selectUserClaims);
  const dispatch = useDispatch();

  function handleAddTaskModalClose() {
    dispatch(fetchHomeOwnersMaintenance());
    dispatch(fetchRecurringTasks());

    setIsAddTaskModalVisible(false);
  }

  function handleResidentAddTaskModalClose() {
    dispatch(fetchResidentMaintenance());
    dispatch(fetchRecurringTasks());

    setIsResidentAddTaskModalVisible(false);
  }

  function handleResidentRequestUpgradeModalClose() {
    dispatch(fetchResidentMaintenance());
    dispatch(fetchRecurringTasks());

    setIsResidentRequestUpgradeModalVisible(false);
  }

  return (
    <>
      <section>
        <header className="flex justify-between mb-xs">
          <Text fontWeight="semibold" variant="h3">
            {address}
          </Text>
        </header>
        <div className="mb-2xl">
          <TaskList showMaintenanceModal={showMaintenanceModal} tasks={tasks} type="non-recurring" view={view} />
        </div>
        {recurringTasks?.length > 0 && (
          <TaskList showMaintenanceModal={showMaintenanceModal} tasks={recurringTasks} type="recurring" view={view} />
        )}
      </section>
      {isHomeOwner && (
        <AddTaskModal
          closeModal={handleAddTaskModalClose}
          property={property}
          show={isAddTaskModalVisible}
          unit={(unit as HomeownerAccountUnit).basicInfo}
        />
      )}
      {isResident && (
        <>
          <RenterAddTaskModal
            closeModal={handleResidentAddTaskModalClose}
            show={isResidentAddTaskModalVisible}
            unit={unit as RenterAccountLease['unitInfo']}
          />
          <RenterRequestUpgradeModal
            closeModal={handleResidentRequestUpgradeModalClose}
            show={isResidentRequestUpgradeModalVisible}
            unit={unit as RenterAccountLease['unitInfo']}
          />
        </>
      )}
    </>
  );
}
