import axios from 'axios';
import { isArray } from 'lodash';
import { MODALS } from '../../../containercomponents/Modals/modal.consts';
import { hideModal, showModal } from '../modals/actions';

/* May be move this to reducer */
const activeRequests = {};

const handleReducerError = (_e) => {
  console.error(_e);
};

export default function clientMiddleware(helpers) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, auth, handleError, cancel, ...rest } = action;

      if (!promise) {
        try {
          return next(action);
        } catch (error) {
          handleReducerError(error);
        }
      }

      if (auth) {
        const { client } = helpers;

        if (!client.getJwtToken()) {
          const existingUserData = getState().user.user;
          return new Promise((resolve) => {
            dispatch(
              showModal(MODALS.LOGIN, {
                currentScreen: action.loginModalScreen ? action.loginModalScreen : null,
                redirectToHomeOnHide: !!action.redirectToHomeOnHide,
                screenProps: {
                  ...existingUserData,
                  emailAddress: existingUserData?.email,
                },
                onSucessfulLogin: async () => {
                  const response = await dispatch(action);
                  resolve(response);
                },
              })
            );
          });
        }
      }

      const [REQUEST, SUCCESS, FAILURE] = types || [];

      if (REQUEST) {
        try {
          next({ ...rest, type: REQUEST });
        } catch (error) {
          handleReducerError(error);
        }
      }

      let actionPromise;

      if (cancel) {
        if (activeRequests[REQUEST]) {
          activeRequests[REQUEST].cancel();
        }
        const source = axios.CancelToken.source();
        activeRequests[REQUEST] = source;
        actionPromise = promise(helpers, source);
      } else {
        actionPromise = promise(helpers);
      }

      return actionPromise
        .then((result) => {
          if (SUCCESS) {
            delete activeRequests[REQUEST];
            try {
              next({ ...rest, result, type: SUCCESS });
            } catch (error) {
              handleReducerError(error);
            }
          }
          return result;
        })
        .catch((err) => {
          // Had to move this here from apiClient interceptor because err.response.data can be an object or an array,
          const isError400s = err?.response?.status >= 400 && err?.response?.status < 500;
          const error = err?.response ? err.response.data : err;

          if (FAILURE) {
            try {
              if (!axios.isCancel(err)) {
                next({ ...rest, error, type: FAILURE });
              }
            } catch (reducerError) {
              handleReducerError(reducerError);
            }
          }

          let errorMessage = '';
          if (typeof error === 'string') {
            errorMessage = error;
          } else if (isArray(error)) {
            const errorData = error[0];
            errorMessage = errorData?.message || errorData?.Message;
          } else {
            errorMessage = error?.message || error?.Message;
          }

          // https://github.com/axios/axios#handling-errors
          const context = {};
          if (err?.response) {
            context.validation_error = String(isError400s);
            context.error_object = error;
          } else if (err?.request) {
            context.server_error = 'true';
            context.request = JSON.stringify(err.request);
          } else {
            context.request_error = 'true';
          }

          console.warn(errorMessage);

          // Commenting this out to avoid noise in DataDog.
          // datadogRum.addError(new Error(errorMessage ? `Api Error: ${errorMessage}` : 'Api Error'), context);

          if (handleError) {
            dispatch(
              showModal(MODALS.GENERIC_ERROR, {
                onCtaClick: async () => {
                  dispatch(hideModal());
                  // await dispatch(action);
                },
              })
            );
          }
          return Promise.reject(error);
        });
    };
}
