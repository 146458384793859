import React from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { FormLayoutContext } from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import styles from './RedInfoBox.module.css';

const cx = classNames.bind(styles);

export default class RedInfoBox extends React.Component {
  static propTypes = {
    cssClassNames: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    useGreenBoxLineHeight: PropTypes.bool,
  };

  static defaultProps = {
    cssClassNames: [],
    children: null,
    useGreenBoxLineHeight: false,
  };

  static Header = ({ handleClose }) => (
    <div className={cx('header')}>
      <ButtonBase onClick={handleClose}>
        <GeneralIcon icon={GENERAL_ICONS.CLOSE} />
      </ButtonBase>
    </div>
  );

  static RegularText = ({ children }) => <div className={cx('regular-text')}>{children}</div>;

  static LargeText = ({ children }) => <div className={cx('large-text')}>{children}</div>;

  static Title = ({ children }) => <div className={cx('green-info-box-title')}>{children}</div>;

  static List = ({ list }) => (
    <ul className={cx('list-items')}>
      {list.map((listItem, index) => (
        <li className={cx('list-item')} key={index}>
          {listItem}
        </li>
      ))}
    </ul>
  );

  render() {
    const { cssClassNames, children, useGreenBoxLineHeight, ...rest } = this.props;

    return (
      <FormLayoutContext.Consumer>
        {(isInFormLayout) => (
          <div
            {...rest}
            className={cx(
              ['info-section', ...cssClassNames],
              { 'add-form-layout-margin': isInFormLayout },
              { useGreenBoxLineHeight }
            )}
          >
            {children}
          </div>
        )}
      </FormLayoutContext.Consumer>
    );
  }
}
