import React from 'react';
import { DATE_FORMATS, formatDateTime } from '@belong/common';
import Input from 'components/Input/Input';
import { maskMMDDYY, unmaskMMDDYY } from 'components/Input/masks';
import { includes } from 'lodash';
import PropTypes from 'prop-types';

export const DateInputFinalFormAdapter = ({
  mask,
  unmask,
  ignoreError,
  input,
  meta,
  onChangeCustom,
  newFieldComponent,
  ...rest
}) => {
  const controlledProps = {};

  const formattedDate =
    typeof input.value === 'object' || (input.value?.length > 3 && !includes(input.value, '/'))
      ? formatDateTime({ dateTime: input.value, format: DATE_FORMATS.STANDARD_SHORT })
      : input.value;

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }

  return (
    <Input
      mask={mask}
      {...input}
      value={formattedDate}
      {...rest}
      onChange={(event) => {
        let { value } = event.target;
        if (unmask) {
          value = unmask(mask(value));
        }

        input.onChange(value);

        if (onChangeCustom) {
          onChangeCustom(value);
        }
      }}
      {...controlledProps}
    />
  );
};

DateInputFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  ignoreError: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  newFieldComponent: PropTypes.bool,
  mask: PropTypes.func,
  unmask: PropTypes.func,
  // requires a / to work
  dateFormat: PropTypes.oneOf([DATE_FORMATS.STANDARD_SHORT, DATE_FORMATS.STANDARD, DATE_FORMATS.SHORT]),
};

DateInputFinalFormAdapter.defaultProps = {
  ignoreError: false,
  newFieldComponent: false,
  onChangeCustom: () => {},
  mask: maskMMDDYY,
  unmask: unmaskMMDDYY,
  dateFormat: DATE_FORMATS.STANDARD_SHORT,
};
