import { useState } from 'react';
import { Text } from '@belong/ui';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { USER_SSN_STRINGS } from 'accounts/constants/strings/user-ssn.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskSSN, unmaskSSN } from 'components/Input/masks';
import Tooltip from 'components/Tooltip/Tooltip';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { cloneDeep } from 'lodash';
import { required, ssnValidation, ssnValidationFirstTime } from 'utils/validation';

export function UserSsn() {
  const { currentStepFormData, goPreviousStep, handleSaveAndNext } = useSetupFlowStep();
  const [showSSNError, setShowSSNError] = useState(false);

  function getInitialValues() {
    if (!currentStepFormData) return {};

    const formData: any = cloneDeep(currentStepFormData);
    if (formData?.tin?.ssn?.length === 4) {
      formData.tin.ssn = `xxx-xx-${formData.tin.ssn}`;
    }

    return formData;
  }

  async function handleSubmit(values) {
    const submitValues = cloneDeep(values);
    setShowSSNError(false);

    const isExistingTIN = submitValues.tin?.ssn?.includes('x');
    if (isExistingTIN) {
      delete submitValues.tin?.ssn;
    }

    try {
      handleSaveAndNext(submitValues);
    } catch (error) {
      console.error(error);
      setShowSSNError(true);
    }
  }

  function validateSSN(value) {
    const { tin } = currentStepFormData || {};

    if (tin?.ssn?.length === 4) {
      return required(value) || ssnValidation(value);
    } else {
      return required(value) || ssnValidationFirstTime(value);
    }
  }

  return (
    <StepFormLayout
      title={USER_SSN_STRINGS.title}
      subTitle={USER_SSN_STRINGS.subtitle}
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      handleBackStep={goPreviousStep}
      showUnitAddress={false}
      // DELETE EVERYTHING IF CONTAINS XXXX
      getForm={({ form, values }) => {
        return (
          <>
            <Row>
              <Col md={6}>
                {showSSNError ? (
                  <Tooltip
                    open
                    contentStyle={{ width: 300, backgroundColor: '#ff5661', padding: '12px', marginTop: '20px' }}
                    on={[]}
                    arrowStyle={{ backgroundColor: 'transparent', color: '#ff5661' }}
                    trigger={
                      <div>
                        <Field
                          placeholder={USER_SSN_STRINGS.full_ssn_itin}
                          component={InputFinalFormAdapter}
                          name="tin.ssn"
                          validate={validateSSN}
                          mask={maskSSN}
                          unmask={unmaskSSN}
                          error
                        />
                      </div>
                    }
                  >
                    <Text>Ops! This SSN doesn’t see valid. Please try again.</Text>
                  </Tooltip>
                ) : (
                  <Field
                    placeholder={USER_SSN_STRINGS.full_ssn_itin}
                    component={InputFinalFormAdapter}
                    name="tin.ssn"
                    validate={validateSSN}
                    mask={maskSSN}
                    unmask={unmaskSSN}
                  />
                )}
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
}
