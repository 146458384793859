import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_PATHS } from '@belong/common';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  fetchIsSelfServeEligible,
  fetchOnboardingInfo,
  fetchOnboardingProperties,
  updateOnboardingInfo,
} from 'store/redux/homeowner-onboarding/actions';
import { selectOnboardingInfo } from 'store/redux/homeowner-onboarding/selectors';
import { updateUserPhone } from 'store/redux/user/actions';
import { selectIsUserLoggedIn, selectUser } from 'store/redux/user/selectors';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { composeValidators, phoneValidation, required } from 'utils/validation';
import { getHomeownerOnboardingStepPath, STEPS_CONFIG } from '../steps';

const HOB = HOMEOWNER_ONBOARDING_STRINGS.schedule_chat;

const { FormLayoutHeader } = FormLayout;

const clear = ([name], state, { changeValue }) => {
  changeValue(state, name, () => null);
};

const ScheduleChat = ({ hideSpinner, history, showSpinner, signUpFlow }) => {
  const [homeownerInfo, setHomeownerInfo] = useState({});
  const [properties, setProperties] = useState({});
  const [isSelfServeEligible, setIsSelfServeEligible] = useState(false);
  const [title, setTitle] = useState('');
  const user = useSelector(selectUser);
  const onboardingInfo = useSelector(selectOnboardingInfo);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const dispatch = useDispatch();

  if (!signUpFlow) {
    history.push(BASE_PATHS.ACCOUNTS);
  }

  const getInitialValues = () => {
    const currentUser = isUserLoggedIn ? user : {};

    return {
      ...currentUser,
      onboardingInfo,
    };
  };

  const handleSubmit = async (values) => {
    showSpinner();

    const updatePhoneNumber = { phone: values.phone };

    try {
      await Promise.all([
        dispatch(updateUserPhone(updatePhoneNumber)),
        dispatch(updateOnboardingInfo(values.onboardingInfo)),
      ]);
    } catch (err) {
      console.error(err);
    }

    hideSpinner();

    history.push(getHomeownerOnboardingStepPath(STEPS_CONFIG.SUCCESS_PAGE));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        showSpinner();

        const [homeownerInfoData, { properties: propertiesData }] = await Promise.all([
          dispatch(fetchOnboardingInfo()),
          dispatch(fetchOnboardingProperties()),
        ]);

        const isSelfServeEligibleResponse = await dispatch(fetchIsSelfServeEligible({ flowId: signUpFlow?.id }));

        setHomeownerInfo(homeownerInfoData);
        setProperties(propertiesData);
        setIsSelfServeEligible(isSelfServeEligibleResponse);

        hideSpinner();
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(homeownerInfo) && !isEmpty(properties)) {
      setTitle(isSelfServeEligible ? HOB.titleSelfServeElegible : HOB.titleNotSelfServeElegible);
    }
  }, [homeownerInfo, properties, isSelfServeEligible]);

  if (!title) {
    return null;
  }

  return (
    <FormLayout>
      <FormLayoutHeader title={title} subtitle={HOB.subtitle} />
      <Form
        mutators={{
          clear,
          ...arrayMutators,
        }}
        getFormBottomBar={(formProps, nextButtonProps) => (
          <FormLayout.BottomBar
            ctaProps={{
              label: HOB.button_label,
              type: 'submit',
            }}
            nextButtonWrapperProps={nextButtonProps}
          />
        )}
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        getForm={() => (
          <>
            <FormLayout.Section>
              <Field
                name="phone"
                component={InputFinalFormAdapter}
                placeholder="Phone number"
                validate={composeValidators(required, phoneValidation)}
                mask={maskPhoneNumber}
                unmask={unmaskPhoneNumber}
              />
            </FormLayout.Section>
            <FormLayout.Section sectionTitle={HOB.additional_comments_title}>
              <Field
                name="onboardingInfo.additionalComments"
                component={InputFinalFormAdapter}
                textarea
                placeholder={HOB.additional_information_placeholder}
              />
            </FormLayout.Section>
          </>
        )}
      />
    </FormLayout>
  );
};

ScheduleChat.propTypes = {
  hideSpinner: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showSpinner: PropTypes.func.isRequired,
  signUpFlow: PropTypes.func.isRequired,
};

export default ScheduleChat;
