import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './NameValueTable.module.css';

const cx = classNames.bind(styles);

class NameValueTable extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
      })
    ).isRequired,
    noBold: PropTypes.bool,
    highlightName: PropTypes.bool,
    boldValue: PropTypes.bool,
    discountValue: PropTypes.bool,
    doubleRowMargin: PropTypes.bool,
    responsiveText: PropTypes.bool,
  };

  static defaultProps = {
    highlightName: true,
    noBold: false,
    doubleRowMargin: false,
    boldValue: false,
    discountValue: false,
    responsiveText: true,
  };

  render() {
    const { doubleRowMargin, items, boldValue, noBold, highlightName, discountValue, responsiveText } = this.props;

    return (
      <div className={cx('name-value-table')}>
        {items.map((item, index) => (
          <div
            className={cx(
              'row',
              { 'responsive-text': responsiveText },
              { doubleRowMargin },
              // Only supports doubleRowMargin, but should in the near future support all margins
              { noMarginBottom: doubleRowMargin && index === items.length - 1 }
            )}
            key={index}
          >
            <div className={cx('name', { noBold }, { 'no-highlight': !highlightName })}>{item.name}</div>
            <div className={cx('right', { bold: boldValue }, { discount: discountValue })}>{item.value}</div>
          </div>
        ))}
      </div>
    );
  }
}

NameValueTable.propTypes = {};

export default NameValueTable;
