import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { COOKIES_CONFIG, PATHS } from '@belong/common';
import { HomeownersSetupFlowPage } from 'accounts/pages/homeowners/setup-flow/setup-flow';
import classNames from 'classnames/bind';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { ALIGN_TYPES } from 'components/IconButton/IconButton';
import HomeownerAccountPropertyContainer from 'containers/HomeownerAccountProperty/HomeownerAccountPropertyContainter';
import { UnitStatus } from 'models/enums';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { parseCookies } from 'nookies';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectUser, selectUserFlows } from 'store/redux/user/selectors';
import styles from './Properties.module.css';
import Property from './Property/Property';

const cx = classNames.bind(styles);

function Properties(props) {
  const { accountProperties, match, history, flows } = props;
  const { propertyId } = match.params;
  const cookies = parseCookies();

  const incompletePlacementsOnlyQualificationFlow = flows?.filter(
    (flow) => flow.flowType === 'HomeownerPlacementsQualification' && flow.status !== 'Completed'
  );

  const placementsOnlyPropertyUnits = accountProperties?.reduce((acc, property) => {
    const unitsWithPlacementsOnlyPlan =
      property.units.filter(
        (unit) =>
          !!unit.homePlans.find((plan) => plan.planType === 'PlacementsOnly') &&
          incompletePlacementsOnlyQualificationFlow.find((flow) => flow.steps[0].dataUniqueId === unit.basicInfo.unitId)
      ) || [];
    if (unitsWithPlacementsOnlyPlan.length > 0) {
      acc.push(
        new HomeownerAccountProperty({
          ...property,
          units: unitsWithPlacementsOnlyPlan,
        })
      );
    }
    return acc;
  }, []);

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  function handleAddPropertyClick() {
    history.push(PATHS.HOMEOWNER_ONBOARDING_PROPERTY_ADDRESS);
  }

  if (propertyId) {
    const property = accountProperties?.find((accountProperty) => accountProperty.basicInfo.propertyId === propertyId);

    if (!property) {
      return <Redirect to={BASE_PATHS.ACCOUNTS} />;
    }

    const isSetupFlowComplete = property.units.every((unit) => unit.isSetupFlowCompleted);
    const isPostInspectionFlowComplete = property.units.every(
      (unit) => !unit.hasImprovementsFlowOpen && !unit.hasPricingFlowOpen
    );
    const isLandlordFlowComplete = property.units.every((unit) => !unit.hasLandlordFlowOpen);
    const isPreMoveOutFlowCompleted = property.units.every((unit) => !unit.hasHomeownerPreMoveOutFlowOpen);

    if (isSetupFlowComplete && isPostInspectionFlowComplete && isLandlordFlowComplete && isPreMoveOutFlowCompleted) {
      return <Route path={`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/:propertyId`} render={() => <Property {...props} />} />;
    } else {
      return (
        <Route path={`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/:propertyId`} render={() => <HomeownersSetupFlowPage />} />
      );
    }
  }

  const addHomeButton = comeFromTheMobileApp
    ? {}
    : {
        iconLabelText: 'ADD HOME',
        align: ALIGN_TYPES.RIGHT,
        icon: GENERAL_ICONS.PLUS,
        onClick: handleAddPropertyClick,
      };

  const renderAccountProperty = (property, propertyProps) => {
    const propertyWithIncompleteHomeownerQualificationFlow =
      placementsOnlyPropertyUnits.length > 0
        ? placementsOnlyPropertyUnits.find(
            (placementsProperty) => placementsProperty.basicInfo.propertyId === property.basicInfo.propertyId
          )
        : undefined;

    return (
      <>
        {propertyWithIncompleteHomeownerQualificationFlow && (
          <div key={`${property.basicInfo.propertyId}-placements`}>
            <HomeownerAccountPropertyContainer
              accountProperty={propertyWithIncompleteHomeownerQualificationFlow}
              isLeasingServiceProperty
              flows={incompletePlacementsOnlyQualificationFlow}
            />
          </div>
        )}
        <div className={cx('property')} key={property.basicInfo.propertyId}>
          <HomeownerAccountPropertyContainer
            accountProperty={property}
            showPROHomeTag={!!propertyWithIncompleteHomeownerQualificationFlow}
            {...propertyProps}
          />
        </div>
      </>
    );
  };

  return (
    <AccountPage
      headerProps={{
        headerText: 'My Homes',
        rowFlex: true,
        ...addHomeButton,
      }}
    >
      <div>
        {accountProperties.map((property) => {
          if (!property.getPublishedUnits().length) {
            return null;
          }

          return renderAccountProperty(property, { isPublished: true });
        })}
        {accountProperties.map((property) => {
          if (!property.getUnitsByStatus(UnitStatus.InspectionCompleted).length) {
            return null;
          }
          return renderAccountProperty(property, { status: UnitStatus.InspectionCompleted });
        })}
        {accountProperties.map((property) => {
          if (!property.getUnitsByStatus(UnitStatus.InspectionScheduled).length) {
            return null;
          }
          return renderAccountProperty(property, { status: UnitStatus.InspectionScheduled });
        })}
        {accountProperties.map((property) => {
          if (!property.getUnitsByStatus(UnitStatus.InspectionPending).length) {
            return null;
          }
          return renderAccountProperty(property, { status: UnitStatus.InspectionPending });
        })}
        {accountProperties.map((property) => {
          if (!property.getUnitsByStatus(UnitStatus.ApplicationPending).length) {
            return null;
          }
          return renderAccountProperty(property, { status: UnitStatus.ApplicationPending });
        })}
      </div>
    </AccountPage>
  );
}

Properties.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      propertyId: PropTypes.string,
      unitId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
  accountProperties: PropTypes.arrayOf(PropTypes.instanceOf(HomeownerAccountProperty)),
  user: PropTypes.object,
  userClaims: PropTypes.object,
  isFlowIncompleteLoading: PropTypes.bool,
  flows: PropTypes.array,
};

Properties.defaultProps = {
  accountProperties: [],
  flows: [],
};

const mapStateToProps = (state) => ({
  accountProperties: selectAccountProperties(state),
  user: selectUser(state),
  flows: selectUserFlows(state),
});

export default connect(mapStateToProps)(Properties);
