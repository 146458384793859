import React from 'react';
import classNames from 'classnames/bind';
import Form from 'components/Form/Form';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import styles from './AgreementFlowFormLayout.module.css';

const { FormLayoutHeader } = FormLayout;
const cx = classNames.bind(styles);

const AgreementFlowFormLayout = ({
  title,
  subTitle,
  getForm,
  onSubmit,
  initialValues,
  isLastStep,
  form,
  mutators,
  customValidation,
  ctaProps,
}) => {
  return (
    <div className={cx('progress-list-wrapper')}>
      <FormLayout>
        <FormLayoutHeader title={title} subtitle={subTitle} />
        <FormLayout>
          {form || (
            <Form
              mutators={{
                ...arrayMutators,
                ...mutators,
              }}
              initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
              initialValues={initialValues}
              validate={customValidation}
              onSubmit={onSubmit}
              getForm={getForm}
              getFormBottomBar={(_, nextButtonProps) => (
                <FormLayout.BottomBar
                  ctaProps={{
                    label: isLastStep ? 'Done' : 'Next',
                    ...ctaProps,
                  }}
                  nextButtonWrapperProps={nextButtonProps}
                />
              )}
            />
          )}
        </FormLayout>
        <Space value={SPACE_TYPES.XXXL} />
      </FormLayout>
    </div>
  );
};

AgreementFlowFormLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  getForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isLastStep: PropTypes.bool,
  mutators: PropTypes.object,
  form: PropTypes.node,
  customValidation: PropTypes.func,
  ctaProps: PropTypes.object,
};

AgreementFlowFormLayout.defaultProps = {
  initialValues: {},
  mutators: {},
  isLastStep: false,
  subTitle: '',
  form: null,
  customValidation: null,
  ctaProps: {},
};

export default AgreementFlowFormLayout;
