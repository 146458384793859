import { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { StepStatus } from 'models/enums';
import { useSetupFlowStep } from './use-setup-flow-step-context';

export function useHomeOccupancy() {
  const { currentStepFormData, currentStep } = useSetupFlowStep();
  const [initialValues, setInitialValues] = useState({
    unitOnboardingInfo: {
      existingResidents: [null],
    },
  });

  useEffect(() => {
    if (currentStepFormData?.unitOnboardingInfo) {
      const { unitOnboardingInfo } = currentStepFormData;

      if (unitOnboardingInfo) {
        if (unitOnboardingInfo.currentLeaseEndOn) {
          unitOnboardingInfo.currentLeaseEndOn = {
            inputValue: unitOnboardingInfo.currentLeaseEndOn,
          };
        } else if (!isNil(unitOnboardingInfo.isLeaseMonthToMonth)) {
          unitOnboardingInfo.currentLeaseEndOn = {
            checkboxValue: unitOnboardingInfo.isLeaseMonthToMonth,
          };
        }

        if (!currentStepFormData.unitOnboardingInfo?.existingResidents?.length) {
          currentStepFormData.unitOnboardingInfo.existingResidents = [null];
        } else {
          const newResidents = currentStepFormData.unitOnboardingInfo.existingResidents.map((resident) => {
            const newObj = { ...resident };

            newObj.email = resident?.email ? { inputValue: resident.email } : null;
            newObj.phone = resident?.phone ? { inputValue: resident.phone } : null;

            if (isNil(newObj.email) && !isNil(newObj.phone)) {
              newObj.email = { checkboxValue: true };
            } else if (!isNil(newObj.email) && isNil(newObj.phone)) {
              newObj.phone = { checkboxValue: true };
            }

            return newObj;
          });

          currentStepFormData.unitOnboardingInfo.existingResidents = newResidents;
        }

        if (currentStepFormData.onboardingLeaseFiles?.length) {
          currentStepFormData.onboardingLeaseFiles = {
            availableMedia: [...currentStepFormData.onboardingLeaseFiles],
            deletedMedia: [],
            stagingMedia: [],
            mediaToDisplay: [...currentStepFormData.onboardingLeaseFiles],
            later: false,
          };
        } else if (!currentStepFormData.onboardingLeaseFiles?.length && currentStep?.status === StepStatus.Incomplete) {
          currentStepFormData.onboardingLeaseFiles = {
            availableMedia: [],
            deletedMedia: [],
            stagingMedia: [],
            mediaToDisplay: [],
            later: true,
          };
        } else {
          currentStepFormData.onboardingLeaseFiles = {
            availableMedia: [],
            deletedMedia: [],
            stagingMedia: [],
            mediaToDisplay: [],
          };
        }

        setInitialValues(currentStepFormData);
      } else {
        currentStepFormData.unitOnboardingInfo = {};
        currentStepFormData.unitOnboardingInfo.existingResidents = [null];

        setInitialValues(currentStepFormData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepFormData]);

  return { initialValues, setInitialValues };
}
