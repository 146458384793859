import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { ShareableBanner } from 'components/Referral/ShareableBanner';
import String from 'components/String/String';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { useModal } from 'hooks/useModal';
import { UserSignatureStatus } from 'models/enums';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { formatString } from 'strings';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import styles from './SuccessPage.module.css';

const cx = classNames.bind(styles);
const convertListToDisplayText = (list) => {
  return list.reduce((acc, item, index) => {
    if (index === 0) return acc;
    if (item) {
      if (index === list.length - 1 && list.length > 2) return `${acc}, and ${item}`;
      if (index === list.length - 1 && list.length === 2) return `${acc} and ${item}`;
      return `${acc}, ${item}`;
    }
    return acc;
  }, list[0]);
};

const SuccessPage = ({
  agreement,
  employeeAssignment,
  isPrimaryUser,
  user,
  history,
  fetchAccountPropertiesAction,
  accountProperties,
}) => {
  const [loading, startLoader, stopLoader] = useModal();
  const listOfNotSignedUsers = agreement?.agreementUsers?.filter(
    (au) => au?.agreementUser?.isSigner && au?.agreementUser?.signatureStatus !== UserSignatureStatus.Signed
  );

  useEffect(() => {
    const fetchData = async () => {
      startLoader();
      try {
        await fetchAccountPropertiesAction();
      } catch (e) {
        console.error(e);
      }
      stopLoader();
    };
    fetchData();
  }, []);

  const getCoSignerText = () => {
    if (listOfNotSignedUsers?.length) {
      const namesInDisplayFormat = convertListToDisplayText(listOfNotSignedUsers.map((au) => au.user.firstName));
      return formatString(AGREEMENT_FLOW_STRINGS['success.co-signer.signatures-left'], {
        namesYetToSign: namesInDisplayFormat,
        homeString: `${AGREEMENT_FLOW_STRINGS['success.home.string']}${
          +agreement?.homeAgreements?.length === 1 ? '' : 's'
        }`,
      });
    }

    return formatString(AGREEMENT_FLOW_STRINGS['success.co-signer.all-signed'], {
      homeString: `${AGREEMENT_FLOW_STRINGS['success.home.string']}${
        +agreement?.homeAgreements?.length === 1 ? '' : 's'
      }`,
    });
  };

  const getPrimaryUserText = () => {
    const namesInDisplayFormat = convertListToDisplayText(listOfNotSignedUsers.map((au) => au.user.firstName));

    if (listOfNotSignedUsers?.length === 1) {
      return formatString(AGREEMENT_FLOW_STRINGS['success.primary.signatures-left.one'], {
        nameYetToSign: namesInDisplayFormat,
      });
    } else {
      return formatString(AGREEMENT_FLOW_STRINGS['success.primary.signatures-left.multiple'], {
        namesYetToSign: namesInDisplayFormat,
      });
    }
  };

  const getText = () => {
    if (isPrimaryUser) return getPrimaryUserText();

    return getCoSignerText();
  };

  const getInfoBox = () => {
    const currentAgreementUser = agreement?.agreementUsers?.find((au) => au.user.email === user.email);

    if (!currentAgreementUser?.agreementUser?.isSigner) {
      return (
        <div className={cx('green-info-wrapper')}>
          <GreenInfoBox>
            <div className={cx('semibold')}>Note</div>
            <String string={AGREEMENT_FLOW_STRINGS['success.non-signer.info']} />
          </GreenInfoBox>
        </div>
      );
    }

    return null;
  };

  const handleDoneClick = () => {
    history.push(BASE_PATHS.ACCOUNTS);
  };

  const employee = {
    ...employeeAssignment?.employee,
    ...employeeAssignment?.user,
    ...employeeAssignment?.employeeAssignment,
    jobTitle: EMPLOYEE_TITLES[employeeAssignment?.employeeAssignment?.assignmentType],
  };

  if (loading || (isPrimaryUser && !accountProperties.length)) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Confetti />
      <div className={cx('success-page')}>
        <div className={cx('content')}>
          <TextBubbleWithUserProfile employee={employee} text={getText()} />
          {getInfoBox()}
          <div className={cx('button-section')}>
            <Button
              onClick={handleDoneClick}
              label="Done"
              className={cx('button')}
              size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
            />
          </div>
          {isPrimaryUser && (
            <div className="mt-2xl mb-5xl">
              <ShareableBanner
                title={AGREEMENT_FLOW_STRINGS.shareableInfo.bannerTitle}
                body={AGREEMENT_FLOW_STRINGS.shareableInfo.bannerMessage}
                shareableMessage={AGREEMENT_FLOW_STRINGS.shareableInfo.shareableMessage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SuccessPage.propTypes = {
  agreement: PropTypes.object.isRequired,
  employeeAssignment: PropTypes.object.isRequired,
  isPrimaryUser: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  accountProperties: PropTypes.array.isRequired,
  fetchAccountPropertiesAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accountProperties: selectAccountProperties(state),
});

const mapDispatchToProps = {
  fetchAccountPropertiesAction: fetchAccountProperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
