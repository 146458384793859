import find from 'lodash/find';
import { AgreementFlowStepNames, STATUS, StepStatus } from 'models/enums';
import { BASE_PATHS } from '../../routes/paths';

// import { getString } from 'strings/index';

export const STEPS_CONFIGURATION = [
  {
    name: AgreementFlowStepNames.Plan,
    displayName: 'Plan',
    key: 'plans',
  },
  {
    name: AgreementFlowStepNames.UserPersonal,
    displayName: 'Personal',
    key: 'personal',
  },

  {
    name: AgreementFlowStepNames.Ownership,
    displayName: 'Ownership',
    key: 'ownership',
  },
  {
    name: AgreementFlowStepNames.Payment,
    displayName: 'Payments',
    key: 'payment',
  },
  {
    name: AgreementFlowStepNames.Sign,
    displayName: 'Sign',
    key: 'sign',
  },
];

export const getStepByName = (name) => find(STEPS_CONFIGURATION, { name });

export const getDisplayStatus = (status) => {
  if (status === StepStatus.Created) {
    return STATUS.NOT_TOUCHED;
  }

  if (status === StepStatus.Started || status === StepStatus.Incomplete) {
    return STATUS.INCOMPLETE;
  }

  if (status === StepStatus.Completed) {
    return STATUS.COMPLETED;
  }

  return status;
};

export const getStepPath = (mappedStep, flowId) => {
  if (!flowId) {
    return BASE_PATHS.HOMEOWNER_SETUP_FLOW;
  }

  return `${BASE_PATHS.AGREEMENT_FLOW}/${mappedStep.key}/${flowId}/${mappedStep.id}`;
};
