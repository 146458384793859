import { useState } from 'react';
import { Image, Text, Slider, ButtonBase } from '@belong/ui';
import PropTypes from 'prop-types';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import css from './TheMarket.module.css';

const priceImage = {
  1: '/pricing-flow/pricing_under.png',
  2: '/pricing-flow/pricing_optimal.png',
  3: '/pricing-flow/pricing_over.png',
};

const TheMarket = ({ stepConfigs, stepConfig, onSaveAndNext, stepResource, onPrevious }) => {
  const [price, setPrice] = useState(2);

  const { user, employee } = stepResource?.employeeAssignments?.[0];
  const headOfPricingProfile = {
    ...user,
    bannerImageUrl: employee?.bannerImageUrl,
    jobTitle: EMPLOYEE_TITLES.ResidentGrowth,
  };

  return (
    <PostInspectionContentLayout
      showCityBackground
      noOverlay
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      employee={headOfPricingProfile}
      title={POST_INSPECTION_FLOW_STRINGS['the_market.title']}
    >
      <Text variant="h3" fontWeight="semibold" className="mb-xs">
        {POST_INSPECTION_FLOW_STRINGS['the_market.grap.title']}
      </Text>
      <Text className="mb-2xl">{POST_INSPECTION_FLOW_STRINGS['the_market.grap.subtitle']}</Text>
      <div className="mb-md" style={{ height: 340 }}>
        <Image src={priceImage[price]} alt="" style={{ overflow: 'unset' }} />
      </div>
      <div className="w-full flex flex-col items-center mb-sm">
        <Text variant="p1" fontWeight="semibold">
          Optimal Price
        </Text>
        <div className="w-full px-0 lg:px-3xl py-lg flex flex-row gap-lg items-center justify-center">
          <Text fontWeight="semibold">Low</Text>
          <div className="w-full">
            <Slider
              min={1}
              max={3}
              step={1}
              value={price}
              onChange={(val) => setPrice(val)}
              variant="gradient-red-to-green-to-red"
              orientation="horizontal"
            />
          </div>
          <Text fontWeight="semibold">High</Text>
        </div>
      </div>
      <div className="hidden md:flex justify-center mb-3xl">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {POST_INSPECTION_FLOW_STRINGS['the_market.button_text']}
        </PostInspectionContentLayout.CtaButton>
      </div>
      <div className="w-fit mx-auto">
        <Text variant="h3" fontWeight="semibold" className="mb-sm">
          {POST_INSPECTION_FLOW_STRINGS['the_market.section.other_factors']}
        </Text>

        <div className="grid grid-cols-1 gap-y-sm lg:grid-cols-4">
          <div className="flex flex-col items-center">
            <div className={css.iconContainer}>
              <Image src="/pricing-flow/pricing_seasonality.png" alt="" />
            </div>
            <Text fontWeight="semibold" className="mt-xs">
              Seasonality
            </Text>
          </div>
          <div className="flex flex-col items-center">
            <div className={css.iconContainer}>
              <Image src="/pricing-flow/pricing_location.png" alt="" />
            </div>
            <Text fontWeight="semibold" className="mt-xs">
              Location
            </Text>
          </div>
          <div className="flex flex-col items-center">
            <div className={css.iconContainer}>
              <Image src="/pricing-flow/pricing_pets.png" alt="" />
            </div>
            <Text fontWeight="semibold" className="mt-xs">
              Allowing Pets
            </Text>
          </div>
          <div className="flex flex-col items-center">
            <div className={css.iconContainer}>
              <Image src="/pricing-flow/pricing_home.png" alt="" />
            </div>
            <Text fontWeight="semibold" className="mt-xs">
              Home Condition
            </Text>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
        <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
          <Text fontWeight="semibold" className="text-white">
            Previous
          </Text>
        </ButtonBase>

        <ButtonBase className="h-2xl flex-1 bg-green disabled:bg-gray" onClick={() => onSaveAndNext()}>
          <Text fontWeight="semibold" className="text-white">
            Next
          </Text>
        </ButtonBase>
      </div>
    </PostInspectionContentLayout>
  );
};

TheMarket.propTypes = {
  onSaveAndNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
  stepResource: {
    employeeAssignments: PropTypes.array.isRequired,
  },
};

export default TheMarket;
