import PropTypes from 'prop-types';
import { get } from 'lodash';

import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';
import { EmailFinalFormAdapter } from 'components/Input/EmailFinalFormAdapter/EmailFinalFormAdapter';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import Field from 'components/Field/Field';
import { required, composeValidators, phoneValidation, email } from 'utils/validation';
import { InputFinalFormAdapter } from 'components/Input/Input';
import String from 'components/String/String';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { PROPERTY_HOA_STRINGS } from 'accounts/constants/strings/property-hoa.strings';
import { SECTION_NAMES } from 'accounts/constants/property-hoa';

export function HOAFormFields({ name, form, isLocked }) {
  const hoaValue = get(form.getState().values, name) || {};

  function getWhoIsHoaContactSectionTitle() {
    if (!isLocked) {
      return <String string={PROPERTY_HOA_STRINGS['who_is_hoa_contact.title']} />;
    }

    return (
      <div>
        <div>
          <String string={PROPERTY_HOA_STRINGS['who_is_hoa_contact.title']} />
        </div>
        <div>
          <GeneralIcon icon={GENERAL_ICONS.LOCK} />
        </div>
      </div>
    );
  }

  return (
    <FormLayout.Section sectionTitle={getWhoIsHoaContactSectionTitle()}>
      <Row>
        <Col md={6}>
          <Field
            name={`${name}.${SECTION_NAMES.firstName}`}
            validate={hoaValue.later ? undefined : required}
            component={InputFinalFormAdapter}
            placeholder="First Name"
            disabled={isLocked}
            applyDisabledStyle={isLocked}
          />
        </Col>
        <Col md={6}>
          <Field
            name={`${name}.${SECTION_NAMES.lastName}`}
            validate={hoaValue.later ? undefined : required}
            component={InputFinalFormAdapter}
            placeholder="Last Name"
            disabled={isLocked}
            applyDisabledStyle={isLocked}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            component={EmailFinalFormAdapter}
            validate={hoaValue.later ? email : composeValidators(required, email)}
            name={`${name}.${SECTION_NAMES.email}`}
            disabled={isLocked}
            applyDisabledStyle={isLocked}
          />
        </Col>
        <Col md={6}>
          <Field
            component={InputFinalFormAdapter}
            validate={hoaValue.later ? phoneValidation : composeValidators(required, phoneValidation)}
            mask={maskPhoneNumber}
            unmask={unmaskPhoneNumber}
            placeholder="Phone"
            name={`${name}.${SECTION_NAMES.phone}`}
            disabled={isLocked}
            applyDisabledStyle={isLocked}
          />
        </Col>
      </Row>
    </FormLayout.Section>
  );
}

HOAFormFields.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  isLocked: PropTypes.bool.isRequired,
};
