import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import PropTypes from 'prop-types';
import styles from './MaintenanceTaskStatus.module.css';

const cx = classNames.bind(styles);

export default class MaintenanceTaskCostandStatus extends Component {
  static propTypes = {
    // estimated cost
    moreInfo: PropTypes.string.isRequired,
    // List of statuses from team. Need to refactor to get the enums
    maintenanceTaskStatusLabel: PropTypes.string.isRequired,
    mobileDisplay: PropTypes.string,
  };

  static defaultProps = {
    mobileDisplay: MAINTENANCE_TASK_MOBILE_PROPS.NO_MOBILE,
  };

  render() {
    const { moreInfo, maintenanceTaskStatusLabel, mobileDisplay } = this.props;
    let maintenanceText;

    if (maintenanceTaskStatusLabel === 'Complete' || maintenanceTaskStatusLabel === 'Completed') {
      maintenanceText = <div className={cx('bold', 'green')}>{maintenanceTaskStatusLabel}</div>;
    } else if (maintenanceTaskStatusLabel === 'Cancelled') {
      maintenanceText = <div className={cx('bold', 'gray')}>{maintenanceTaskStatusLabel}</div>;
    } else if (maintenanceTaskStatusLabel === 'InProgress') {
      maintenanceText = <div className={cx('bold')}>In Progress</div>;
    } else {
      maintenanceText = <div className={cx('bold')}>{maintenanceTaskStatusLabel}</div>;
    }

    return (
      <div className={cx('maintenanceTaskCostandStatus', mobileDisplay)}>
        {maintenanceText}
        {moreInfo && <div className={cx('maintenanceTaskDate')}>{moreInfo}</div>}
      </div>
    );
  }
}
