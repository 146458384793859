import { UnitSummaryModel, PropertySummary } from 'api/models';
import { flatten } from 'lodash';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';

export type UnitWithProperty = UnitSummaryModel & {
  property: PropertySummary;
};

export function getUnitsFromProperties(homeownerProperties: HomeownerAccountProperty[]): UnitWithProperty[] {
  return flatten(
    homeownerProperties?.map((property) =>
      property.units.map((unit) => {
        return {
          ...unit,
          property,
        };
      })
    )
  );
}
