import React, { Component } from 'react';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import MaintenanceTasks from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceTasks';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { find } from 'lodash';
import { RequestType } from 'models/enums';
import PropTypes from 'prop-types';
import { formatMaintenanceStringsForTasks, getImage, getStringFromTime } from 'utils/formatMaintenance';
import { LABEL_COLOR_TYPES } from '../Label/labelTypes';
import styles from './MaintenanceTaskComponent.module.css';

const cx = classNames.bind(styles);

export default class MaintenanceTaskComponent extends Component {
  static propTypes = {
    showMaintenanceModal: PropTypes.func.isRequired,
    // make array shape
    tasks: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    tasks: [],
  };

  renderStatusText = (task) => {
    if (task.status === 'Closed') {
      return <BDate time={task.closedOn} formatType={DATE_TYPES.MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE} />;
    }

    if (task.status === 'InProgress' && task.nextAppointmentUniqueId) {
      const nextAppointment = find(task.appointments, (t) => t.uniqueId === task.nextAppointmentUniqueId);

      if (nextAppointment.isAllDay) {
        return (
          <span className={cx('all-day-wrapper')}>
            <BDate time={nextAppointment.scheduledStartOn} formatType={DATE_TYPES.MAINTENANCE_DEFAULT_DATE} /> (All Day)
          </span>
        );
      }

      return (
        <BDate
          time={nextAppointment.scheduledStartOn}
          formatType={DATE_TYPES.MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE}
        />
      );
    }
  };

  render() {
    const { tasks, showMaintenanceModal } = this.props;
    return (
      <>
        {tasks.map((task) => {
          const getTextToDisplay = formatMaintenanceStringsForTasks(task.status, task);
          let image = getImage(task.requestType);

          let displayEstimatedResponsibleCost;
          let displayResponsibleCost;

          if (task.involvedRole === 'Resident') {
            const renterResponsibility = find(task.payments, (payment) => payment.paidBy === task.involvedRole);

            if (renterResponsibility?.percentage > 0) {
              displayEstimatedResponsibleCost = renterResponsibility?.estimatedAdjustmentApplied || 'Pricing TBD';
              displayResponsibleCost = renterResponsibility.actualAdjustmentApplied;
            } else if (task.actualCost?.totalCost) {
              displayResponsibleCost = 'Free';
            } else {
              displayEstimatedResponsibleCost = '';
              displayResponsibleCost = '';
            }
          }

          if (task.involvedRole === 'Homeowner') {
            const homeOwnerResponsibility = find(task.payments, (payment) => payment.paidBy === task.involvedRole);
            if (task.requestType === RequestType.Group) {
              displayEstimatedResponsibleCost = homeOwnerResponsibility?.estimatedNetTotal || 'Pricing TBD';
              if (task.requestTypeDetails?.hasPublishedInvoices) {
                displayResponsibleCost = homeOwnerResponsibility?.actualNetTotal;
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (homeOwnerResponsibility?.percentage > 0) {
                displayEstimatedResponsibleCost = homeOwnerResponsibility?.estimatedAdjustmentApplied || 'Pricing TBD';
                displayResponsibleCost = homeOwnerResponsibility.actualAdjustmentApplied;
              } else if (task.actualCost?.totalCost) {
                displayResponsibleCost = 'Free';
              } else {
                displayEstimatedResponsibleCost = '';
                displayResponsibleCost = '';
              }
            }
          }

          // const dateText = getStringFromTime(task.basicInfo.repeatFrequencyDays);
          const labels = [];

          if (task.urgency === 'Urgent') {
            labels.push({
              text: 'Urgent',
              color: LABEL_COLOR_TYPES.RED,
            });
          }

          if (task.recurrence?.repeatFrequencyDays) {
            image = getImage('Recurring');
            labels.push({
              text: getStringFromTime(task.recurrence.repeatFrequencyDays),
            });
          }

          return (
            <ButtonBase onClick={showMaintenanceModal(task)} className="w-full mb-xs last-of-type:mb-0" key={task.id}>
              <MaintenanceTasks
                maintenanceLabels={labels}
                maintenanceDate={task.inspection?.completedOn || task.createdOn}
                maintenanceTask={task.summary}
                maintenanceTaskStatusLabel={getTextToDisplay}
                maintenanceTaskStatusText={this.renderStatusText(task)}
                maintenanceTaskImage={image}
                estimatedCost={displayEstimatedResponsibleCost}
                actualCost={displayResponsibleCost}
                // used to hide Maintenance Task Cost if there's no estimate cost / actual cost
                maintenanceTaskStatus={task?.status}
              />
            </ButtonBase>
          );
        })}
      </>
    );
  }
}
