import { Fragment } from 'react';
import { CollapseFlowSection } from 'accounts/components/collapse-flow-section/collapse-flow-section';
import { HomeAccessInstructionsSection } from 'accounts/components/home-access-instructions-section/home-access-instructions-section';
import { UpcomingAppointmentsSection } from 'accounts/components/upcoming-appointments-section/upcoming-appointments-section';
import Address from 'models/common/Address';
import UnitLease from 'models/common/UnitLeaseDetail';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';

type SetupFlowSectionsProps = {
  properties: [HomeownerAccountProperty];
  propertySection: any;
  accountSection: any;
  shouldShowCommonSteps: boolean;
  openInitialModal: (unit: UnitLease, address: Address) => void;
};

export function SetupFlowSections({
  properties,
  propertySection,
  accountSection,
  shouldShowCommonSteps,
  openInitialModal,
}: SetupFlowSectionsProps) {
  return (
    <>
      {propertySection.map((section) => {
        const unitProperty = properties?.find((property) =>
          property.units.some((unit) => unit?.basicInfo?.unitId === section?.unitId)
        );

        const selectedUnit = unitProperty?.units?.find((unit) => unit?.basicInfo?.unitId === section?.unitId);

        const inspectionScheduleSubSection = section.subSections.find(
          (subSection) => subSection.name === 'InspectionSchedule'
        );

        return (
          <Fragment key={section.unitId}>
            <UpcomingAppointmentsSection
              coverageRegionId={unitProperty.basicInfo.coverageRegionId}
              timeZone={section.timeZone}
              unit={selectedUnit}
            />
            {inspectionScheduleSubSection?.isCompleted && <HomeAccessInstructionsSection unit={selectedUnit} />}
            <CollapseFlowSection
              properties={properties}
              key={section.sectionTitle}
              section={section}
              shouldShowCommonSteps={shouldShowCommonSteps}
              openInitialModal={openInitialModal}
            />
          </Fragment>
        );
      })}
      {shouldShowCommonSteps && accountSection && (
        <div className="flex flex-col mb-xl">
          <CollapseFlowSection
            properties={properties}
            section={accountSection}
            shouldShowCommonSteps={shouldShowCommonSteps}
            openInitialModal={openInitialModal}
          />
        </div>
      )}
    </>
  );
}
