import { Fragment } from 'react';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { HOME_FEATURES_STRINGS } from 'accounts/constants/strings/home-features.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { getFeatureAttributes, getFeatureFormObject, getHomeFeaturesList } from 'accounts/utils/home-features';
import { FeaturesComponentFinalFormAdapter } from 'components/FeaturesComponent/FeaturesComponent';
// Final Form imports
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import arrayMutators from 'final-form-arrays';
// Component imports
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEmpty, cloneDeep } from 'lodash';
import { HomeFeatureCategories } from 'models/enums';

export function HomeFeatures() {
  const { currentStep, currentStepFormData, handleSaveAndNext, goPreviousStep, isMultiUnit, setLoading } =
    useSetupFlowStep();

  const isInspectionCompleted = !!currentStep?.property?.basicInfo?.isInspectionCompleted;
  const homeFeaturesList = getHomeFeaturesList(isInspectionCompleted);

  async function handleSubmit(values) {
    try {
      setLoading(true);

      const submitValues = cloneDeep(values);

      delete submitValues.features;
      submitValues.features = [];

      Object.keys(values.features).forEach((featuresKey) => {
        const feature = values.features[featuresKey];

        if (Array.isArray(feature)) {
          feature.forEach((f) => {
            if (f.selected) {
              const featureDetailedObject = getFeatureAttributes({
                ...f,
                name: featuresKey,
              });

              if (isEmpty(featureDetailedObject.attributes)) {
                delete featureDetailedObject.attributes;
              }

              submitValues.features.push(featureDetailedObject);
            }
          });
        } else if (feature.selected) {
          const featureDetailedObject = getFeatureAttributes({
            ...feature,
            name: featuresKey,
          });

          if (isEmpty(featureDetailedObject.attributes)) {
            delete featureDetailedObject.attributes;
          }

          submitValues.features.push(featureDetailedObject);
        }
      });

      handleSaveAndNext(submitValues);
    } catch (e) {
      console.error(e);
    }
  }

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const { features } = currentStepFormData;
    const initialValues = {
      ...currentStepFormData,
    };

    if (!initialValues.features?.length) {
      initialValues.features = {
        Parking: [
          {
            name: HomeFeatureCategories.Parking,
            selected: false,
          },
        ],
      };
    } else if (initialValues.features.length > 0) {
      const featuresMap = {
        Parking: [],
      };

      features.forEach((feature) => {
        const featureObject = getFeatureFormObject(feature);

        if (featureObject?.category === HomeFeatureCategories.Parking) {
          featuresMap.Parking.push({ ...featureObject });
        } else {
          featuresMap[feature.name] = { ...featureObject };
        }
      });

      if (!featuresMap.Parking.length) {
        featuresMap.Parking = [
          {
            name: HomeFeatureCategories.Parking,
            selected: false,
          },
        ];
      }

      initialValues.features = featuresMap;
    }

    return { ...initialValues };
  }

  return (
    <StepFormLayout
      handleBackStep={goPreviousStep}
      onSubmit={handleSubmit}
      title={HOME_FEATURES_STRINGS.title}
      initialValues={getInitialValues()}
      disableProgressBar
      mutators={{
        ...arrayMutators,
      }}
      getForm={({ form }) => (
        <Fragment>
          <FormLayout.Section>
            <Field
              name="features"
              component={FeaturesComponentFinalFormAdapter}
              featuresList={homeFeaturesList}
              placeholder="Features"
              form={form}
              showTrashButton={false}
              showCheckmark
              isMultiUnit={isMultiUnit}
            />
          </FormLayout.Section>
          <FormLayout.Section sectionTitle={HOME_FEATURES_STRINGS.features.missing.title}>
            <Field
              name="additionalUnitFeatures"
              placeholder={HOME_FEATURES_STRINGS.features.missing.placeholder}
              component={InputFinalFormAdapter}
              textarea
            />
          </FormLayout.Section>
        </Fragment>
      )}
    />
  );
}
