import PaymentMethod from 'models/common/PaymentMethod';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { PaymentPriority, PaymentPreference } from 'models/enums/index';

export default class PaymentMethods {
  constructor(data = []) {
    this.paymentMethods = data?.map((payment) => new PaymentMethod(payment));
    this.rent = this.getRentPaymentMethod();
    this.payout = this.getPayoutPaymentMethod();
    this.maintenance = this.getMaintenancePaymentMethod();
    this.validPaymentMethods = filter(this.paymentMethods, ['isVerified', true]);
    this.unverifiedPaymentMethods = filter(this.paymentMethods, ['isVerified', false]);
  }

  // fetches the primary and secondary Rent payment methods
  getRentPaymentMethod() {
    const rentPrimary = this.paymentMethods.filter((transactions) => {
      if (
        transactions?.preferences?.find((trans) => {
          if (trans.paymentType === PaymentPreference.Rent && trans.priority === PaymentPriority.Primary) {
            return true;
          }

          return false;
        })
      ) {
        return true;
      }

      return false;
    });
    const rentObject = {
      Primary: rentPrimary[0] || null,
    };

    return rentObject;
  }

  // fetches the primary and secondary Rent payment methods
  getPayoutPaymentMethod() {
    const payoutPrimary = this.paymentMethods.filter((transactions) => {
      if (
        transactions?.preferences?.find((trans) => {
          if (trans.paymentType === PaymentPreference.Payout && trans.priority === PaymentPriority.Primary) {
            return true;
          }

          return false;
        })
      ) {
        return true;
      }

      return false;
    });
    const rentObject = {
      Primary: payoutPrimary[0] || null,
    };

    return rentObject;
  }

  getMaintenancePaymentMethod() {
    const maintenancePrimary = this.paymentMethods.filter((transactions) => {
      if (
        transactions?.preferences?.find((trans) => {
          if (trans.paymentType === PaymentPreference.Maintenance && trans.priority === PaymentPriority.Primary) {
            return true;
          }

          return false;
        })
      ) {
        return true;
      }

      return false;
    });
    const rentObject = {
      Primary: maintenancePrimary[0] || null,
    };

    return rentObject;
  }

  findObjectBasedOnAccountId(account = '') {
    return find(this.paymentMethods, (paymentMethod) => paymentMethod.accountId === account);
  }
}
