import React from 'react';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES, BUTTON_SIZES } from 'components/Button/Button';
import Image from 'components/Image/Image';
import SuccessTimeline, { LABEL_POSITION } from 'components/SuccessTimeline/SuccessTimeline';
import { find, isNil, isEmpty } from 'lodash';
import { EmployeeAssignmentTypes } from 'models/enums';
import { ReportType } from 'models/enums/index';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../PostInspectionContentLayout/PostInspectionContentLayout';
import styles from './Success.module.css';

const cx = classNames.bind(styles);

const pricingApproved = (reports = []) => {
  let approved = true;

  if (!reports.length) return false;

  reports.forEach((r) => {
    approved =
      approved &&
      r.details?.listing?.pricingReportDetails?.userAcceptedInitialPricing &&
      r.details?.listing?.pricingReportDetails?.userAcceptedCheckInPrice;
  });

  return approved;
};

const improvementsApproved = (reports = []) => {
  let approved = true;

  reports.forEach((r) => {
    approved = approved && r.report?.isConfirmed;
  });

  return approved;
};

const Success = ({ allReports, flow, homeId }) => {
  let employee = {};

  const finalReport = find(allReports, (reportDetail) => {
    return homeId === reportDetail.report?.homeId && reportDetail.report?.type === 'Pricing';
  });

  const pricingAnalystEmployeeAssignment = finalReport.employeeAssignments?.find(
    (ea) => ea?.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.PricingAnalyst
  );

  if (pricingAnalystEmployeeAssignment) {
    employee = {
      ...pricingAnalystEmployeeAssignment.employee,
      ...pricingAnalystEmployeeAssignment.user,
      profileImage: pricingAnalystEmployeeAssignment.user.profileImageUrl,
      bannerImage: pricingAnalystEmployeeAssignment.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[pricingAnalystEmployeeAssignment.employeeAssignment.assignmentType],
    };
  }

  const directToAccount = () => {
    if (isNil(flow) || isEmpty(flow)) {
      return true;
    }
    return false;
  };

  const approved =
    pricingApproved(allReports.filter((r) => r.report.type === ReportType.Pricing)) &&
    improvementsApproved(allReports.filter((r) => r.report.type === ReportType.Improvements));

  const timelineSteps = [
    {
      label: POST_INSPECTION_FLOW_STRINGS['success.steps.setup-account'],
      position: LABEL_POSITION.RIGHT,
      selected: true,
    },
    {
      label: POST_INSPECTION_FLOW_STRINGS['success.steps.your-plan'],
      position: LABEL_POSITION.LEFT,
      selected: approved,
    },
    {
      label: POST_INSPECTION_FLOW_STRINGS['success.steps.improvements'],
      position: LABEL_POSITION.RIGHT,
      selected: false,
    },
    {
      label: POST_INSPECTION_FLOW_STRINGS['success.steps.photos-media'],
      position: LABEL_POSITION.LEFT,
      selected: false,
    },
    {
      label: POST_INSPECTION_FLOW_STRINGS['success.steps.listing-showing'],
      position: LABEL_POSITION.RIGHT,
      selected: false,
    },
  ];

  return (
    <>
      <PostInspectionContentLayout
        title={
          approved
            ? POST_INSPECTION_FLOW_STRINGS['success.title.approved']
            : POST_INSPECTION_FLOW_STRINGS['success.title.not-approved']
        }
        employee={employee}
        noMinHeight
        noOverlay
      >
        <div className={cx('center')}>
          <Button
            buttonType={BUTTON_TYPES.DEFAULT}
            size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
            to={directToAccount() ? BASE_PATHS.ACCOUNTS : BASE_PATHS.POST_INSPECTION_FLOW}
          >
            {POST_INSPECTION_FLOW_STRINGS['success.cta']}
          </Button>
        </div>
        <div className={cx('center', 'whats-next')}>{POST_INSPECTION_FLOW_STRINGS['success.whats-next']}</div>
        <div className={cx('timeline-wrapper', 'center')}>
          <SuccessTimeline steps={timelineSteps} />
        </div>
      </PostInspectionContentLayout>
      <div className={cx('city-image')}>
        <Image src="/city_images/modal_city.svg" alt="city" />
      </div>
    </>
  );
};

Success.propTypes = {
  homeId: PropTypes.string.isRequired,
  allReports: PropTypes.array.isRequired,
  flow: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
};

Success.defaultProps = {
  flow: {},
};

export default Success;
