import React, { Component } from 'react';
// Redux imports
import { connect } from 'react-redux';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import BorderBoxCheckbox from 'components/BorderBoxCheckbox/BorderBoxCheckbox';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { remove } from 'lodash';
import UserBasicInfo from 'models/common/UserBasicInfo';
import PropTypes from 'prop-types';
import { selectUser } from 'store/redux/user/selectors';
import { converDateTimeServerResponseToDateTimeObject } from 'utils/dateTimeUtils';
import styles from './ApplicationSelector.module.css';

const cx = classNames.bind(styles);

class ApplicationSelector extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserBasicInfo).isRequired,
    onSelect: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    application: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  getNamesForAppliedWith() {
    const { application, user } = this.props;
    const currentUserEmail = user.email;
    const listOfApplicants = [application.applicants.mainApplicant, ...application.applicants.coApplicants];
    remove(listOfApplicants, (applicant) => applicant.email === currentUserEmail);
    return [...listOfApplicants];
  }

  render() {
    const { application, onSelect, checked } = this.props;

    this.getNamesForAppliedWith();

    return (
      <BorderBoxCheckbox onSelect={onSelect} checked={checked}>
        <div className={cx('previous-application-wrapper')}>
          <div className={cx('date-section')}>
            <div className={cx('created-on')}>
              Created on{' '}
              {formatDateTime({
                dateTime: legacyParse(converDateTimeServerResponseToDateTimeObject(application.basicInfo.createdOn)),
                format: DATE_FORMATS.DOTTED_MEDIUM,
              })}
            </div>
          </div>
          <div
            className={cx('co-applicants-section', {
              hidden: !application.applicants.coApplicants || application.applicants.coApplicants.length === 0,
            })}
          >
            <div className={cx('applied-with')}>Applied with</div>
            <div className={cx('co-applicants')}>
              {this.getNamesForAppliedWith().map((coApplicant, coApplicantIndex) => (
                <div className={cx('co-applicant')} key={coApplicantIndex}>
                  {coApplicant.firstName} {coApplicant.lastName}
                </div>
              ))}
            </div>
          </div>
        </div>
      </BorderBoxCheckbox>
    );
  }
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(ApplicationSelector);
