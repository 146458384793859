import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PaymentMethodDisplay from 'components/Payments/PaymentMethodDisplay/PaymentMethodDisplay';
import PaymentMenuSelectionContainer from 'containers/PaymentSelectionMenuContainer/PaymentMenuSelectionContainer';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import styles from './PaymentMethodSelector.module.css';

const cx = classNames.bind(styles);

class PaymentMethodSelector extends Component {
  static propTypes = {
    selectedPaymentMethodId: PropTypes.string.isRequired,
    needsPaymentMethod: PropTypes.bool,
    paymentMethods: PropTypes.array,
    onSelectPaymentMethod: PropTypes.func.isRequired,
    paymentModalProps: PropTypes.object,
    showOnlyValidPaymentMethods: PropTypes.bool,
  };

  static defaultProps = {
    paymentMethods: [],
    paymentModalProps: {},
    showOnlyValidPaymentMethods: false,
    needsPaymentMethod: false,
  };

  getCurrentPaymentMethod() {
    const { selectedPaymentMethodId, paymentMethods } = this.props;
    return find(paymentMethods, { accountId: selectedPaymentMethodId });
  }

  render() {
    const {
      showOnlyValidPaymentMethods,
      selectedPaymentMethodId,
      onSelectPaymentMethod,
      paymentModalProps,
      needsPaymentMethod,
    } = this.props;
    const selectedPaymentMethod = this.getCurrentPaymentMethod();
    const hasNoPaymentMethod = needsPaymentMethod && !selectedPaymentMethod;

    return (
      <div className={cx('payment-method-selector', { hasNoPaymentMethod })}>
        <PaymentMethodDisplay
          hasNoPaymentMethod={hasNoPaymentMethod}
          paymentMethod={selectedPaymentMethod}
          paymentMethodType={selectedPaymentMethod?.getAccountType()}
        />
        <PaymentMenuSelectionContainer
          hasNoPaymentMethod={hasNoPaymentMethod}
          selectedPaymentMethodId={selectedPaymentMethodId}
          menuButtonLabel={selectedPaymentMethodId ? 'CHANGE' : 'ADD PAYMENT'}
          onSelectPaymentMethod={onSelectPaymentMethod}
          paymentModalProps={paymentModalProps}
          showOnlyValidPaymentMethods={showOnlyValidPaymentMethods}
        />
      </div>
    );
  }
}

export default PaymentMethodSelector;
