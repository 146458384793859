// React imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
// Component imports
import Spinner from 'components/Spinner/Spinner';
// Constant & String imports
import Space from 'corecomponents/Space/Space';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import IdentityVerification from 'formcomponents/IdentityVerification/IdentityVerification';
import FormLayout from 'layouts/FormLayout/FormLayout';
// Lodash imports
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  createIdentityVerificationInfo,
  createRunIdentityVerification,
  fetchIdenityVerificationInfo,
} from 'store/redux/user/actions';
// Redux store
// import {
//   selectResidentApplyForHouseId,
//   selectResidentApplicationBasicInfo,
//   selectResidentApplicationId,
//   selectResidentApplicationsForUser,
//   selectResidentApplicationApplicantType
// } from 'store/redux/resident-application/selectors';
// import {
//   fetchApplicantInfo,
//   fetchAllApplicationsForUser
// } from 'store/redux/resident-application/actions';
import { selectIdentityVerificationInfo, selectUser } from 'store/redux/user/selectors';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { getIdVerificationInitialValues } from '../../../../formcomponents/IdentityVerification/getIdVerificationInitialValues';
import { getResidentOnboardingStepPath, STEPS_DATA } from '../common';
// SCSS imports
import styles from './IdentityCheck.module.css';

const cx = classNames.bind(styles);
const { FormLayoutHeader } = FormLayout;
const RAS = RESIDENT_APPLICATION_STRINGS.identity_verification;

class IdentityCheck extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    fetchIdenityVerificationInfo: PropTypes.func.isRequired,
    createRunIdentityVerification: PropTypes.func.isRequired,
    createIdentityVerificationInfo: PropTypes.func.isRequired,
    identityVerification: PropTypes.object,
  };

  static defaultProps = {
    identityVerification: {},
  };

  constructor(props) {
    super(props);

    this.initialValuesObject = {};

    this.state = {
      awaitingFetchComplete: true,
    };
  }

  async componentDidMount() {
    const { identityVerification, fetchIdenityVerificationInfo: fetchIdenityVerificationInfoAction } = this.props;

    if (!identityVerification || isEmpty(identityVerification)) {
      try {
        await fetchIdenityVerificationInfoAction();
      } catch (err) {
        console.error(err);
      }
      this.setupInitialValues();
      return;
    }

    this.setupInitialValues();
  }

  setupInitialValues() {
    const { identityVerification } = this.props;

    if (isEmpty(identityVerification)) {
      this.initialValuesObject = {};
      this.setState({
        awaitingFetchComplete: false,
      });
      return;
    }

    this.initialValuesObject = getIdVerificationInitialValues(identityVerification);

    this.setState({
      awaitingFetchComplete: false,
    });
  }

  handleSubmit = async (values) => {
    const {
      history: { push },
      createIdentityVerificationInfo: createIdentityVerificationInfoAction,
      createRunIdentityVerification: createRunIdentityVerificationAction,
    } = this.props;

    this.setState({
      awaitingFetchComplete: true,
    });

    try {
      await createIdentityVerificationInfoAction(values);
    } catch (err) {
      console.error(err);
    }
    // Not waiting for this here.
    // To-Do: Add error handling for failed ID check condition and await here
    createRunIdentityVerificationAction().catch((err) => console.error(err));
    push(getResidentOnboardingStepPath(STEPS_DATA.SUCCESS_PAGE));
  };

  renderBottomBar = () => {
    return (
      <div className={cx('form-submit')}>
        <Button className={cx('cta-button')} type="submit" label="Done" />
      </div>
    );
  };

  render() {
    const { user } = this.props;
    const { awaitingFetchComplete } = this.state;

    if (awaitingFetchComplete) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <FormLayout>
        <FormLayoutHeader title={RAS.title} subtitle={RAS.subTitle} />
        <IdentityVerification
          initialValuesObject={this.initialValuesObject}
          userFirstName={user.firstName}
          onSubmit={this.handleSubmit}
          getCtaProps={() => ({
            label: 'Done',
          })}
        />
        <Space value="2xl" />
      </FormLayout>
    );
  }
}

export default connect(
  (state) => ({
    user: selectUser(state),
    identityVerification: selectIdentityVerificationInfo(state),
  }),
  {
    fetchIdenityVerificationInfo,
    createRunIdentityVerification,
    createIdentityVerificationInfo,
  }
)(IdentityCheck);
