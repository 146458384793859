export default {
  domain: 'localhost',
  app: {
    title: 'Belong',
    description: 'Belong',
  },
  facebookAppId: '221068578847756',
  googleOauthClientId: '825517590357-g3dt9hu350dc1gn7r26cffmkarog715l.apps.googleusercontent.com',
  cdnDomain: 'https://d2bccpbzpdjeaa.cloudfront.net',
  googleMapsApiKey: 'AIzaSyAsUODLFf9NnjfvIrNboZTbWIwK0Kt2Y2c',
  gaId: 'UA-122475191-2',
  ga4Id: 'G-5WW5V54XJV',
  homeownersLpExperimentOptimizeId: 'Ujdv0HR_SuiQ73HHanD5Kg',
  isDebug: false,
  mixpanelApiKey: '21f1f3efa645461fda9e85970863f8ff',
  plaidEnv: 'sandbox',
  plaidKey: 'ddd94ae2ebc85a13bcfb292a13a9b9',
  zendeskKey: 'df4d785d-b0da-4fe3-a0cd-0e51ddedb568',
  intercomId: 'shj3cyso',
  calendlyApiKey:
    'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjUxMTkzMDcyLCJqdGkiOiI3YTE5NDQ4Mi03NmJiLTQxZmUtYWIwOS05OTM3MWJlMWUwZjAiLCJ1c2VyX3V1aWQiOiJiY2RhOWFmNC1mOGU1LTQ3NTgtOTJkOC1kYzBhMTYxZjg1NzYifQ.zXGi3skHBNhMDRbtWVfq0hoXN8I4NRoNWJpf0U2B4qg',
  smsAssistUrl: 'https://belonghomersp-onestage.smsassist.com/BHI/Account/SingleSignOn?token=',
};
