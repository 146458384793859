import { isNil } from 'lodash';
import React from 'react';
import format from 'string-template';

export const getString = (string, data = {}) => format(string, data);

// This breaks the formating of the kind formatString(template, str1, str2), will only work for objects like
// formatString(template, obj), might refactor later, or stop supporting the first API.
const formatSpans = (str, valuesForPlaceholders) => {
  const spanContent = str?.match?.(/<span>([\s\S]*?)<\/span>/g) || [];
  const contentWithoutSpan = spanContent.map((str) => str.match(/<span>(.*)<\/span>/)[1]);
  if (contentWithoutSpan.length === 0) {
    return [str, valuesForPlaceholders];
  }
  const resultValuesForPlaceholders = [
    contentWithoutSpan.reduce((acc, val, index) => {
      return {
        ...acc,
        // Doesn't work if key is an integer.
        [`${index}x`]: <span style={{ whiteSpace: 'nowrap' }}>{formatString(val, valuesForPlaceholders[0])}</span>,
      };
    }, valuesForPlaceholders[0]),
  ];
  let resultStr = str;
  spanContent.forEach((val, index) => {
    resultStr = resultStr.replace(val, `{${index}x}`);
  });
  return [resultStr, resultValuesForPlaceholders];
};

// Inspired from https://github.com/stefalda/react-localization#readme
// Same as getString, but can use React elements/components as template values.
export const formatString = (str, ...valuesForPlaceholders) => {
  const placeholderRegex = /(\{[\d|\w]+\})/;
  const [resultStr, resultValuesForPlaceholders] = formatSpans(str, valuesForPlaceholders);

  let hasObject = false;
  const res = (resultStr || '')
    .split(placeholderRegex)
    .filter((textPart) => !!textPart)
    .map((textPart, index) => {
      if (textPart.match(placeholderRegex)) {
        const matchedKey = textPart.slice(1, -1);
        let valueForPlaceholder = resultValuesForPlaceholders[matchedKey];

        // If no value found, check if working with an object instead
        if (isNil(valueForPlaceholder)) {
          const valueFromObjectPlaceholder = resultValuesForPlaceholders[0][matchedKey];
          if (!isNil(valueFromObjectPlaceholder)) {
            valueForPlaceholder = valueFromObjectPlaceholder;
          } else {
            // If value still isn't found, then it must have been undefined/null
            return valueForPlaceholder;
          }
        }

        if (React.isValidElement(valueForPlaceholder)) {
          hasObject = true;
          return React.Children.toArray(valueForPlaceholder).map((component) => ({
            ...component,
            key: index.toString(),
          }));
        }

        return valueForPlaceholder;
      }
      return textPart;
    });
  // If the results contains a object return an array otherwise return a string
  if (hasObject) return res;
  return res.join('');
};
