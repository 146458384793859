import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import isNil from 'lodash/isNil';
import { dateOfBirthValidation, required } from 'utils/validation';
import { differenceInYears } from 'date-fns';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import Field from 'components/Field/Field';
import { maskMMDDYYYY, unmaskMMDDYYYY } from 'components/Input/masks';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { InputFinalFormAdapter } from '../../components/Input/Input';

const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);

  return differenceInYears(legacyParse(today), legacyParse(birthDate));
};

const RAS = RESIDENT_APPLICATION_STRINGS.people_and_pets;

export default class MultipleKidDetails extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  state = {
    errorMessage: '',
  };

  validateKidsDateOfBirth = (value) => {
    const validateDateString = dateOfBirthValidation(value);

    if (!isNil(validateDateString)) {
      this.setState({
        errorMessage: '',
      });
      return validateDateString;
    }

    if (getAge(value) > 17) {
      this.setState({
        errorMessage: RAS.kids_date_of_birth_error,
      });
      return RAS.kids_date_of_birth_error;
    }

    this.setState({
      errorMessage: '',
    });
  };

  render() {
    const { name } = this.props;
    const { errorMessage } = this.state;

    return (
      <>
        <Row>
          <Col md={6}>
            <Field
              name={`${name}.name`}
              component={InputFinalFormAdapter}
              key={errorMessage ? 'name0' : 'name1'}
              placeholder={RAS.kids_name_label}
              validate={required}
            />
          </Col>
          <Col md={6}>
            <Field
              name={`${name}.dateOfBirth`}
              component={InputFinalFormAdapter}
              placeholder={RAS.kids_date_of_birth_label}
              validate={this.validateKidsDateOfBirth}
              errorMessage={errorMessage}
              key={errorMessage ? 'name2' : 'name3'}
              mask={maskMMDDYYYY}
              unmask={unmaskMMDDYYYY}
            />
          </Col>
        </Row>
      </>
    );
  }
}
