import React from 'react';
import { Text, Image, Tooltip } from '@belong/ui';
import classNames from 'classnames/bind';
import CircledNumber from 'components/CircledNumber/CircledNumber';
import DividerRow from 'components/DividerRow/DividerRow';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import Icon, { ICONS } from 'components/Icon/Icon';
import Carousel from 'components/v2/Carousel/Carousel';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Page from 'layouts/Page/Page';
import flatten from 'lodash/flatten';
import { MaintenanceAssessment, EmployeeAssignmentTypes } from 'models/enums';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import styles from './Quality.module.css';

const cx = classNames.bind(styles);

const leftCheckedList = ['All Hand-Picked', 'All Experienced'];
const rightCheckedList = ['All Trusted Partners', 'All Licensed, Bonded & Insured'];
const qualityDividerRowItems = [
  {
    icon: <Icon icon={ICONS.INVESTIGATION.DEFAULT} responsive />,
    title: 'Optimal',
    subtitle: formatString(`The right solution to the problem. No more, no\u00a0less.`),
  },
  {
    icon: <Icon icon={ICONS.PREVENTATIVE.DEFAULT} responsive />,
    title: 'Enduring',
    subtitle: formatString(`Built to last, maximizing your home's\u00a0value.`),
  },
  {
    icon: <Icon icon={ICONS.NOTEPAD.DEFAULT} responsive />,
    title: 'Compliant',
    subtitle: formatString(`Meet all codes, standards, and\u00a0regulations.`),
  },
  {
    icon: <Icon icon={ICONS.RECURRING_MAINTENANCE.DEFAULT} responsive />,
    title: 'Expedited',
    subtitle: formatString(`All work starts within {br}5 days of approval.`, { br: <br /> }),
  },
  {
    icon: <Icon icon={ICONS.OPEN_HOME.DEFAULT} responsive />,
    title: 'Delightful',
    subtitle: formatString(`If you’re not delighted by the work we do, we’ll do it again. On us.`),
  },
];

const diyDividerRowItems = [
  {
    top: <CircledNumber>1</CircledNumber>,
    icon: <Image className={cx('divider-image')} src="/pro-services/quality_section_img_01.png" />,
    title: 'Reduced Pro Liability',
    subtitle: formatString(
      `We partner with licensed, bonded & insured pros, significantly reduce your\u00a0liability.`
    ),
  },
  {
    top: <CircledNumber>2</CircledNumber>,
    icon: <Image className={cx('divider-image')} src="/pro-services/quality_section_img_02.png" />,
    title: 'Reduced Vacancy Time',
    subtitle: formatString(`Work performed by outside contractors typically takes longer, increasing\u00a0vacancy.`),
  },
  {
    top: <CircledNumber>3</CircledNumber>,
    icon: <Image className={cx('divider-image')} src="/pro-services/quality_section_img_03.png" />,
    title: 'Reduced Redo Costs',
    subtitle: formatString(`Work performed by outside contractors often needs to be redone, increasing\u00a0costs.`),
  },
];

const dataSpeakItems = [
  {
    title: '1 Week',
    subtitle: 'Avg. time saved to listing homes with Belong-trusted Pros',
  },
  {
    title: '$800-$1200',
    subtitle: 'Vacancy savings when using Belong-trusted Pros',
  },
  {
    title: '20%',
    subtitle: 'Avg. number of fixes we have to redo due to poor quality from outside work',
  },
  {
    title: '12 Hours',
    subtitle: 'Avg. time spent by DIY homeowners managing outside contracts per issue',
  },
];

export const testimonialItems = [
  {
    title: `Don’s Bathroom Remodel`,
    subtitle: 'Belong Homeowner <span>Since December 2019</span>',
    text: `First I just have to acknowledge how great it was working with Milan and his crew. They are all working their tails off to get the work done in what are certainly <span>challenging times.</span>`,
    profileImage: '/post_inspection/testimonials/don_profile.jpg',
    beforeUrl: '/post_inspection/testimonials/postinspection_testm_01_before.jpg',
    afterUrl: '/post_inspection/testimonials/postinspection_testm_01_after.jpg',
  },
  {
    title: 'Margit’s Climate Control Upgrade',
    subtitle: 'Belong Homeowner <span>Since January 2019</span>',
    text: `Francesca has navigated through a complicated furnace installation, and a re-signing of the lease... I'd say she pretty <span>much rocks.</span>`,
    profileImage: '/post_inspection/testimonials/profile_margit.jpg',
    beforeUrl: '/post_inspection/testimonials/postinspection_testm_02_before.jpg',
    afterUrl: '/post_inspection/testimonials/postinspection_testm_02_after.jpg',
  },
];

export const DelightfulBadge = () => (
  <div className={cx('badge-with-sparkles-container')}>
    <GeneralIcon className={cx('sparkles')} color={COLORS.GREEN} icon={GENERAL_ICONS.CLOUDS_AND_SPARKLES} />
    <GeneralIcon className={cx('badge')} color={COLORS.GREEN} icon={GENERAL_ICONS.BIG_GOLD_BADGE} />
  </div>
);

export const QualityStaticContent = () => {
  return (
    <>
      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.diy_section.title'])}
        sectionSubtitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.diy_section.subtitle'])}
        removeBottomSpacing
      />
      <Space value={SPACE_TYPES.XL} />
      {/* Desktop DIY divider row */}
      <div className={cx('desktop-divider-row-container')}>
        <DividerRow desktopColumns={3} items={diyDividerRowItems} />
      </div>

      {/* Mobile DIY divider row */}
      <div className={cx('mobile-divider-row-container')}>
        <Carousel pagingDotsClassName="green">
          {diyDividerRowItems.map((item, index) => (
            <DividerRow.Item key={index} item={item} defaultMobileIconSize={false} />
          ))}
        </Carousel>
      </div>
      <Space value={SPACE_TYPES.XXXL} />

      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.data_speak'])}
        removeBottomSpacing
      />

      <Space value={SPACE_TYPES.XL} />
      <div className="grid grid-cols-2 gap-2sm lg:gap-md">
        {dataSpeakItems.map((item) => (
          <div key={item.title} className="bg-light-gray rounded pr-xs p-xs lg:p-sm">
            <Text fontWeight="semibold" className="text-h3-sm lg:text-h2-xl mb-xs">
              {item.title}
            </Text>
            <Text className="text-p1 lg:text-body leading-p2 lg:leading-p1">{item.subtitle}</Text>
          </div>
        ))}
      </div>

      <Space value={SPACE_TYPES.XXXL} />

      <div className="flex gap-sm lg:gap-3xl items-center flex-col lg:flex-row">
        <Page.Section
          sectionPretitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.pro_hero.pretitle'])}
          sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.pro_hero.title'])}
          removeBottomSpacing
        />
        <div className={styles['quality-heros-image']}>
          <Image src="/pro-services/post_inspection_qulity_hero_01.svg" />
        </div>
      </div>

      <Space value={SPACE_TYPES.XXXL} />

      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.checklist.title'])}
        removeBottomSpacing
      />
      <Space.XL />
      <Row>
        <Col sm={6}>
          {leftCheckedList.map((item) => (
            <div key={item} className={cx('checkmark-container')}>
              <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color="green" />
              <p className={cx('checkmark-paragraph')}>{item}</p>
            </div>
          ))}
        </Col>
        <Col sm={6}>
          {rightCheckedList.map((item) => (
            <div key={item} className={cx('checkmark-container')}>
              <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color="green" />
              <p className={cx('checkmark-paragraph')}>{item}</p>
            </div>
          ))}
        </Col>
      </Row>
      <Space value={SPACE_TYPES.XXXL} />

      <Page.Section
        sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['quality.maintenance_talent.title'])}
        removeBottomSpacing
      />
      <Space value={SPACE_TYPES.XL} />

      {/* Desktop qualities divider row */}
      <div className={cx('desktop-divider-row-container')}>
        <DividerRow desktopColumns={3} items={qualityDividerRowItems} />
      </div>

      {/* Mobile qualities divider row */}
      <div className={cx('mobile-divider-row-container')}>
        <Carousel pagingDotsClassName="green">
          {qualityDividerRowItems.map((item, index) => (
            <DividerRow.Item key={index} item={item} />
          ))}
        </Carousel>
      </div>
      <Space value={SPACE_TYPES.XXXL} />
    </>
  );
};

const propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

const Quality = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig }) => {
  const { homeListingDetailsModel } = stepResource;

  const concierge = Object.values(homeListingDetailsModel?.employeeAssignments)?.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.Concierge
  );

  const homeownerGrowth = Object.values(homeListingDetailsModel?.employeeAssignments)?.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const assessedBy = concierge ?? homeownerGrowth;
  let formattedAssessedBy = {};

  if (assessedBy) {
    formattedAssessedBy = {
      ...assessedBy.employee,
      ...assessedBy.user,
      profileImage: assessedBy.user.profileImageUrl,
      bannerImage: assessedBy.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[assessedBy.employeeAssignment.assignmentType],
    };
  }

  const flatBundles = flatten(
    stepResource?.details?.bundles.reduce((acc, bundle) => {
      acc.push(bundle.items);
      return acc;
    }, [])
  ).filter((maintenance) => maintenance?.assessment !== MaintenanceAssessment.Inspirational);

  const isApprovalStepHidden = stepConfigs?.find((x) => x.name === 'ImprovementsApproval')?.isHidden === true;
  const hasNextStep = flatBundles.length > 0 && !isApprovalStepHidden;
  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={
        flatBundles.length > 0
          ? formatString(POST_INSPECTION_FLOW_STRINGS['quality.title'])
          : formatString(POST_INSPECTION_FLOW_STRINGS['quality.title_no_improvements'])
      }
      employee={formattedAssessedBy}
      showCityBackground
    >
      <DelightfulBadge />
      <Space.XL />

      <div className="flex items-center justify-center mb-md lg:mb-xl text-center">
        <Text fontWeight="semibold" variant="h2">
          {formatString(POST_INSPECTION_FLOW_STRINGS['quality.heading'], { br: <br /> })}
        </Text>
        <div className="hidden ml-2xs lg:inline">
          <Tooltip
            contentProps={{ side: 'top' }}
            content={
              <div className="p-2sm rounded bg-black max-w-lg text-left">
                <Text className="text-white" variant="p1">
                  {POST_INSPECTION_FLOW_STRINGS['quality.headingTooltip']}
                </Text>
              </div>
            }
          />
        </div>
      </div>
      <div className="flex justify-center items-center mb-xl lg:hidden">
        <Tooltip
          contentProps={{ side: 'top' }}
          content={
            <div className="p-2sm rounded bg-black max-w-md text-left">
              <Text className="text-white" variant="p1">
                {POST_INSPECTION_FLOW_STRINGS['quality.headingTooltip']}
              </Text>
            </div>
          }
        >
          <Text fontWeight="semibold">LEARN MORE</Text>
        </Tooltip>
      </div>

      <div className={cx('button-container')}>
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {hasNextStep
            ? formatString(POST_INSPECTION_FLOW_STRINGS['quality.cta'])
            : formatString(POST_INSPECTION_FLOW_STRINGS['quality.cta_no_improvements'])}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <QualityStaticContent />
    </PostInspectionContentLayout>
  );
};

Quality.propTypes = propTypes;

export default Quality;
