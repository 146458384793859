import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { HOME_RESIDENT_EXPERIENCE_STRINGS } from 'accounts/constants/strings/home-resident-experience.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
// TODO: Regions: Fix it.
import { STATES } from 'consts/address';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import Space from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import arrayMutators from 'final-form-arrays';
import Condition from 'formcomponents/Condition/Condition';
import { Satisfaction } from 'formcomponents/satisfaction/satisfaction';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { cloneDeep } from 'lodash';
import { RentalStatus } from 'models/enums';
import { selectUser } from 'store/redux/user/selectors';
import { getString, formatString } from 'strings';
import { required } from 'utils/validation';

const openEmailAction = 'open_my_email';

export function HomeResidentExperience() {
  const user = useSelector(selectUser);
  const { currentStepFormData, selectedProperty, selectedUnit, goPreviousStep, handleSaveAndNext, setLoading } =
    useSetupFlowStep();

  const isAdoptedResident = selectedUnit?.isAdoptedAgreement;
  const isRenterOccupied = selectedUnit?.basicInfo?.onboardingInfo?.rentalStatus === RentalStatus.RenterOccupied;
  const homeowner = user?.firstName;
  const resident = selectedUnit?.residents?.[0]?.basicInfo.userInfo.firstName;
  const mailBodyData = {
    homeowner,
    resident: resident ? `Hi ${resident}` : 'Hello',
    direction:
      selectedProperty?.address.state === STATES.CA
        ? HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.direction_ca']
        : HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.direction_wa'],
    account:
      selectedProperty?.address.state === STATES.CA
        ? HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.account_ca']
        : HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.account_wa'],
  };
  const mailBodyHtml = formatString(HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.body'], mailBodyData);
  const mailBodyString = getString(HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.body'], mailBodyData).replaceAll(
    '<br />',
    ''
  );

  async function handleSubmit(values) {
    try {
      setLoading(true);

      const submitValues = cloneDeep(values);

      handleSaveAndNext(submitValues);
    } catch (e) {
      console.error(e);
    }
  }

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const initialValues = {
      ...currentStepFormData,
    };

    return { ...initialValues };
  }

  return (
    <StepFormLayout
      handleBackStep={goPreviousStep}
      onSubmit={handleSubmit}
      title={HOME_RESIDENT_EXPERIENCE_STRINGS.title}
      doneLabel={HOME_RESIDENT_EXPERIENCE_STRINGS.done_label}
      initialValues={getInitialValues()}
      mutators={{
        ...arrayMutators,
      }}
      getForm={() => (
        <Fragment>
          <FormLayout.Section sectionTitle={HOME_RESIDENT_EXPERIENCE_STRINGS['quirks.section_title']}>
            <Field
              name="homeQuirks"
              placeholder={HOME_RESIDENT_EXPERIENCE_STRINGS['quirks.placeholder']}
              component={InputFinalFormAdapter}
              validate={required}
              textarea
            />
          </FormLayout.Section>
          <FormLayout.Section sectionTitle={HOME_RESIDENT_EXPERIENCE_STRINGS['about_neighbors.section_title']}>
            <Satisfaction name="neighborhoodRelationship" validate={required} />
            <Field
              name="neighborhoodRelationshipComments"
              placeholder={HOME_RESIDENT_EXPERIENCE_STRINGS['about_neighbors.placeholder']}
              component={InputFinalFormAdapter}
              textarea
              multiLineLabel
            />
          </FormLayout.Section>
          {isAdoptedResident && isRenterOccupied && (
            <Fragment>
              <FormLayout.Section sectionTitle={HOME_RESIDENT_EXPERIENCE_STRINGS['introduction.title']}>
                <Field validate={required} component={YesNoFinalFormAdapter} name="introducedUsToResidents" />
              </FormLayout.Section>
              <Condition when="introducedUsToResidents" is={false}>
                <FormLayout.Section sectionTitle={HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.title']}>
                  <div className="rounded border border-gray border-solid bg-light-gray mt-sm">
                    <div className="border-0 border-b border-gray border-solid p-sm h-[250px] overflow-y-auto">
                      {mailBodyHtml.split('<br />')?.map((text, i) => (
                        <Fragment key={text}>
                          {i > 0 && <Space value={Space.SPACE_TYPES.SM} />}
                          <Text fontSize="p1" className="leading-p2">
                            {text}
                          </Text>
                        </Fragment>
                      ))}
                    </div>
                    <div className="flex items-center justify-center p-xs">
                      <LinkBase
                        a={`mailto:${HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.mail']}?subject=${
                          HOME_RESIDENT_EXPERIENCE_STRINGS['email_template.subject']
                        }&body=${encodeURIComponent(mailBodyString)}`}
                        action={openEmailAction}
                      >
                        <Text fontSize="body" fontWeight="semibold">
                          OPEN MY EMAIL
                        </Text>
                      </LinkBase>
                    </div>
                  </div>
                </FormLayout.Section>
              </Condition>
            </Fragment>
          )}
        </Fragment>
      )}
    />
  );
}
