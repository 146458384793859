import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { ICONS } from 'components/Icon/Icon.js';
import { labelShape } from 'components/Label/labelTypes';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import { Grid, Row, Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import MaintenanceTaskCost from './MaintenanceTaskCost/MaintenanceTaskCost';
import MaintenanceTaskIconLabel from './MaintenanceTaskIconLabel/MaintenanceTaskIconLabel';
import MaintenanceTaskLabelandDate from './MaintenanceTaskLabels/MaintenanceTaskLabels';
import MaintenanceTaskCostandStatus from './MaintenanceTaskStatus/MaintenanceTaskStatus';
import styles from './MaintenanceTasks.module.css';

const cx = classNames.bind(styles);

export default class MaintenanceTasks extends Component {
  static propTypes = {
    // Labels
    maintenanceLabels: PropTypes.arrayOf(PropTypes.shape(labelShape)).isRequired,
    // Should refactor date in the future so that it is not so many lines
    maintenanceDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // the image for the task. Maybe switch to maintenancemaintenanceTaskImage
    maintenanceTaskImage: PropTypes.string,
    // The name of the maintenance task
    maintenanceTask: PropTypes.string,

    maintenanceTaskStatusText: PropTypes.string.isRequired,
    // The Address
    // The estimated cost for the proeject. Should maybe refactor this and date.
    estimatedCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    actualCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    maintenanceTaskStatus: PropTypes.string.isRequired,
    // The type of Maintenance Task that I have functionality for.

    maintenanceTaskStatusLabel: PropTypes.string.isRequired,
    // The children node. Can be used as a replacement for maintenance task
    // children: PropTypes.node,
  };

  static defaultProps = {
    maintenanceDate: '',
    maintenanceTaskImage: ICONS.COMMUNICATION.DEFAULT,
    maintenanceTask: 'Please Enter A Task',
    // maintenanceTaskStatusLabel: 'Hello World',
  };

  render() {
    const {
      maintenanceTaskImage,
      maintenanceLabels,
      maintenanceDate,
      maintenanceTask,
      estimatedCost,
      actualCost,
      maintenanceTaskStatusLabel,
      maintenanceTaskStatusText,
      maintenanceTaskStatus,
    } = this.props;
    return (
      <>
        <div
          className={cx(
            'maintenance-tasks-list-flex',
            'transition-colors duration-hover hover:border-navy hover:bg-light-gray'
          )}
        >
          <Grid className="px-xs sm:px-sm">
            <Row className={cx('max-width', 'mx-0')}>
              <div className={cx('flexItemsForMaintenance')}>
                <Col md={4} className={cx('noRightPadding', 'pl-0')}>
                  <MaintenanceTaskIconLabel
                    maintenanceTaskImage={maintenanceTaskImage}
                    maintenanceTask={maintenanceTask}
                    maintenanceDate={maintenanceDate}
                    maintenanceTaskStatusLabel={maintenanceTaskStatusLabel}
                    maintenanceTaskStatusText={maintenanceTaskStatusText}
                  />
                </Col>
                <Col md={2} className={cx('noRightPadding', 'pl-0')}>
                  <MaintenanceTaskLabelandDate
                    maintenanceTaskStatus={maintenanceTaskStatus}
                    maintenanceLabels={maintenanceLabels}
                    estimatedCost={estimatedCost}
                    actualCost={actualCost}
                  />
                </Col>
                <Col md={4} className={cx('noRightPadding')}>
                  <MaintenanceTaskCostandStatus
                    maintenanceTaskStatus={maintenanceTaskStatus}
                    maintenanceTaskStatusLabel={maintenanceTaskStatusLabel}
                    moreInfo={maintenanceTaskStatusText}
                    mobileDisplay={MAINTENANCE_TASK_MOBILE_PROPS.HAS_MOBILE}
                  />
                </Col>
                <Col className="pr-0" md={2}>
                  <MaintenanceTaskCost
                    maintenanceTaskStatus={maintenanceTaskStatus}
                    estimatedCost={estimatedCost}
                    actualCost={actualCost}
                    mobileDisplay={MAINTENANCE_TASK_MOBILE_PROPS.HAS_MOBILE}
                  />
                </Col>
              </div>
            </Row>
          </Grid>
        </div>
      </>
    );
  }
}
