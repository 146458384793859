import { Text } from '@belong/ui';
import { InspectionDatePicker } from 'accounts/components/inspection-date-picker/inspection-date-picker';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { EMPLOYEE_STRINGS } from 'accounts/constants/strings/employee.strings';
import { HOME_INSPECTION_STRINGS } from 'accounts/constants/strings/home-inspection.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { format } from 'date-fns-tz';
import { EmployeeAssignmentTypes } from 'models/enums';

export function HomeInspectionSchedule() {
  const { currentStep, currentStepFormData, goPreviousStep, handleSaveAndNext, selectedUnit, selectedProperty } =
    useSetupFlowStep();

  const homeownerGrowth = selectedUnit?.employeeAssignments.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const formattedHomeownerGrowth = homeownerGrowth
    ? {
        ...homeownerGrowth?.employeeAssignment,
        ...homeownerGrowth?.employee,
        ...homeownerGrowth?.user,
        profileImage: homeownerGrowth?.user?.profileImageUrl,
        bannerImage: homeownerGrowth?.employee?.bannerImageUrl,
        jobTitle: EMPLOYEE_STRINGS[homeownerGrowth?.employeeAssignment?.assignmentType],
      }
    : null;

  function getInitialValues() {
    if (!currentStep) {
      return {};
    }

    const initialValues = {
      specificRequests: currentStepFormData.specificRequests,
    };

    if (currentStepFormData.startTime) {
      const date = new Date(currentStepFormData.startTime);

      const [, timeWithTimezoneData] = currentStepFormData.startTime.split('T');
      const [timeData] = timeWithTimezoneData.split('-');
      const [hours, minutes] = timeData.split(':');
      const time = `${hours}:${minutes}`;

      return {
        ...initialValues,
        date,
        time,
      };
    }

    return initialValues;
  }

  async function handleSubmit(values) {
    const { specificRequests, date, time } = values;

    const [hours, minutes] = time.split(':');

    date.setHours(hours);
    date.setMinutes(minutes);

    await handleSaveAndNext({
      specificRequests,
      startTime: format(date, "yyyy-MM-dd'T'HH:mm:ssxxx", {
        timeZone: selectedUnit?.basicInfo.timeZone,
      }),
    });
  }

  return (
    <StepFormLayout
      title={HOME_INSPECTION_STRINGS.title_schedule}
      subTitle={HOME_INSPECTION_STRINGS.sub_title_schedule}
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      handleBackStep={goPreviousStep}
      disableProgressBar
      doneLabel="Done"
      getForm={({ form }) => {
        return (
          <div className="relative top-sm">
            <Field
              name="date"
              withMarginTop={false}
              component={InspectionDatePicker}
              timeZone={selectedUnit?.basicInfo.timeZone}
              form={form}
              unit={selectedUnit}
              onChangeCustom={() => {
                form.change('time', undefined);
              }}
              wrapperClassName="sm:-ml-lg md:flex-nowrap mt-sm"
              sideInformationWrapperClassName="sm:w-auto md:pl-lg lg:w-full xl:w-auto"
              timePickerWrapperClassName="sm:w-[264px] lg:w-full lg:pr-lg xl:pr-0 xl:w-[264px]"
              calendarRootClassName="lg:pb-0"
            />
            <div className="mt-2xl w-full">
              <Text as="span" variant="h3" fontWeight="semibold">
                {HOME_INSPECTION_STRINGS['specific.request.title']}
              </Text>
            </div>
            <Field
              name="specificRequests"
              placeholder={HOME_INSPECTION_STRINGS['specific_requests.placeholder']}
              textarea
              component={InputFinalFormAdapter}
            />
          </div>
        );
      }}
    />
  );
}
