import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Text } from 'design-system';
import { find } from 'lodash';
import { ConsentStatus, MaintenanceAssessment, MaintenanceResponsibility } from 'models/enums';
import PropTypes from 'prop-types';
import Checkbox from '../../../../../../../components/Checkbox/Checkbox';
import Spinner from '../../../../../../../components/Spinner/Spinner';
import ImprovementCard from '../../../../../ImprovementCard/ImprovementCard';
import { isItemResidentResponsible } from '../../../utils';
import { ApprovalContext } from '../../ApprovalContext';
import styles from './BundleItem.module.css';

const cx = classNames.bind(styles);

export const BundleItem = ({ isPreventative, item, onChange, onClick, price = null }) => {
  const { loader } = useContext(ApprovalContext);

  const { maintenancePayments } = item;
  const homeownerPayment = maintenancePayments.find(
    (payment) => payment.paidBy === MaintenanceResponsibility.Homeowner
  );

  const selected =
    (homeownerPayment?.percentage > 0 && homeownerPayment?.consentStatus === ConsentStatus.Approved) ||
    isItemResidentResponsible(item);

  const isLoading = !!find(
    loader.items,
    (loadingItem) => loadingItem.maintenance.uniqueId === item.maintenance.uniqueId
  );

  const isDisabled = isItemResidentResponsible(item) || item.maintenance.assessment === MaintenanceAssessment.Required;

  const handleLetsDoThisClick = () => onChange(!selected, [item]);

  const handleLetsDoThisSelect = (isImprovementSelected) => onChange(isImprovementSelected, [item]);

  return (
    <div className={cx('bundle-item', { selected })}>
      {selected && <div className={cx('outline')} />}
      <div className={cx('card-container')}>
        {isLoading && (
          <div className={cx('card-spinner')}>
            <Spinner dark />
          </div>
        )}
        <ImprovementCard
          borderRadius={false}
          height={220}
          improvement={item}
          isPreventative={isPreventative}
          mediaContainerClassName={cx('card-image-container')}
          onClick={onClick}
          price={typeof price === 'function' ? price(selected) : price}
        />
        <div className={cx('bottom')}>
          <ButtonBase className={cx('select-button-wrapper')} disabled={isDisabled} onClick={handleLetsDoThisClick}>
            <Checkbox
              alignCenter
              disabled={isDisabled}
              label="LET'S DO THIS"
              onSelect={handleLetsDoThisSelect}
              useOnChangeFormToHandle
              value={selected}
            />
          </ButtonBase>
          <div className={cx('divider')} />
          <ButtonBase className={cx('show-details-button')} onClick={onClick}>
            <Text fontWeight="semibold">DETAILS</Text>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

BundleItem.propTypes = {
  isPreventative: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  price: PropTypes.node,
};
