import { useSelector } from 'react-redux';
import { isEmpty } from '@belong/common';
import { STEP_GATE_NAMES } from 'accounts/constants/steps';
import { getFilteredGateSteps } from 'accounts/utils/flow-steps';
import { getPropertySection, getUserSection } from 'accounts/utils/sections';
import { selectUser, selectUserFlows, selectUserReports } from 'store/redux/user/selectors';
import { useHomeownerInspections } from './use-homeowner-inspections';
import { useSelectedProperty } from './use-selected-property';

type UseSetupFlowSectionsProps = {
  properties: any[];
  allStepsByGate: any;
  selectedProperty?: any;
};

export function useSetupFlowSections({
  properties,
  allStepsByGate,
  selectedProperty: customSelectedProperty,
}: UseSetupFlowSectionsProps) {
  const user = useSelector(selectUser);
  const reports = useSelector(selectUserReports);
  const flows = useSelector(selectUserFlows);
  const { selectedProperty } = useSelectedProperty();
  const propertyToUse = customSelectedProperty || selectedProperty;
  const { inspections } = useHomeownerInspections();

  if (isEmpty(allStepsByGate) || !properties || !propertyToUse) {
    return {
      propertySection: [],
      accountSection: [],
      isFlowIncomplete: false,
      isPricingImprovementReady: false,
      shouldShowCommonSteps: true,
    };
  }

  const stepsByGate = getFilteredGateSteps({
    allStepsByGate,
    unitId: null,
    selectedProperty: propertyToUse,
    user,
  });

  const newAccountSection = getUserSection({ stepsByGate, property: propertyToUse, flows });
  const newPropertySection = getPropertySection({
    stepsByGate,
    selectedProperty: propertyToUse,
    reports,
    flows,
    inspections,
  });

  const isIncompleteFlow = newPropertySection.some((newProperty) => !newProperty.isCompleted);

  const isReadyPricingImprovement = newPropertySection.some((property) =>
    property.subSections.some(
      (subSection) =>
        [STEP_GATE_NAMES.HomeImprovements, STEP_GATE_NAMES.HomePricing].includes(subSection.name) &&
        !subSection.disabled
    )
  );

  // common steps are part of build listing and inspection schedule gates
  const newShouldShowCommonSteps =
    !newAccountSection.isCompleted ||
    !newPropertySection.every((section) =>
      section.subSections
        .filter((subSection) =>
          [STEP_GATE_NAMES.InspectionSchedule, STEP_GATE_NAMES.BuildListing].includes(subSection.name)
        )
        .every((subSection) => subSection.isCompleted)
    );

  return {
    propertySection: newPropertySection,
    accountSection: newAccountSection,
    isFlowIncomplete: isIncompleteFlow,
    isPricingImprovementReady: isReadyPricingImprovement,
    shouldShowCommonSteps: newShouldShowCommonSteps,
  };
}
