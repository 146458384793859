import { filter } from 'lodash';
import { ACTIONS as USER_ACTIONS } from '../user/constants';
import { ACTIONS } from './constants';

export const HOMEOWNER_ACCOUNTS_REDUCER = 'homeownerAccounts';

const initialState = {
  properties: null,
  units: {},
  timelines: [],
  documents: [],
  agreements: [],
  upcomingAppointments: {},
  homeAccessInstructions: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_ACCOUNT_PROPERTIES_SUCCESS: {
      const properties = action.result.properties || [];
      const filteredProperties = filter(properties, (property) => property.basicInfo.inCoverageArea);
      return {
        ...state,
        properties: filteredProperties,
      };
    }
    case ACTIONS.CLEAR_ACCOUNT_UNITS:
      return {
        ...state,
        units: {},
      };
    case ACTIONS.FETCH_ACCOUNT_UNIT_SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          [action.result.basicInfo.unitId]: action.result,
        },
      };
    case ACTIONS.FETCH_ACCOUNT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: [...action.result],
      };
    case ACTIONS.FETCH_ACCOUNT_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: [...action.result],
      };
    case USER_ACTIONS.USER_DATA_CLEAR:
      return {
        ...initialState,
      };
    case ACTIONS.FETCH_HOME_TIMELINE_SUCCESS:
      return {
        ...state,
        timelines: [...state.timelines, action.result],
      };
    case ACTIONS.FETCH_UPCOMING_APPOINTMENTS_SUCCESS: {
      if (action.result[0]?.unitId) {
        return {
          ...state,
          upcomingAppointments: {
            ...state.upcomingAppointments,
            [action.result[0].unitId]: [...action.result],
          },
        };
      }

      return { ...state };
    }
    case ACTIONS.FETCH_HOME_ACCESS_INSTRUCTIONS_SUCCESS: {
      return {
        ...state,
        homeAccessInstructions: {
          ...state.homeAccessInstructions,
          [action.result.unitId]: action.result,
        },
      };
    }
    default:
      return state;
  }
}
