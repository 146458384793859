import { Image, Text } from '@belong/ui';
import type { MaintenanceRecurrenceModel } from 'api/models';
import startCase from 'lodash/startCase';
import { RecurringProServicePrice } from 'maintenance/components/recurring-pro-service-price/recurring-pro-service-price';
import { RecurringProServiceStatusTag } from 'maintenance/components/recurring-pro-service-status-tag/recurring-pro-service-status-tag';
import type { RecurringProServiceStatus } from 'maintenance/types';
import styles from './recurring-pro-service-card.module.css';

type Props = {
  description: string;
  imageSrc: string;
  isHomeownerView?: boolean;
  name: MaintenanceRecurrenceModel['recurringTask'];
  price: string;
  status?: RecurringProServiceStatus;
};

export function RecurringProServiceCard({
  description,
  imageSrc,
  isHomeownerView = false,
  name,
  price,
  status = 'off',
}: Props) {
  return (
    <div className="rounded flex flex-col border border-solid border-gray sm:flex-row">
      <div className="relative">
        {!isHomeownerView && (
          <div className="absolute top-xs left-sm z-10">
            <RecurringProServiceStatusTag status={status} />
          </div>
        )}
        <div className="absolute bottom-xs left-sm z-10 sm:hidden">
          <RecurringProServicePrice price={price} />
        </div>
        <div className={styles.backdrop} />
        <div className={styles.image}>
          {imageSrc && (
            <Image alt={startCase(name)} className="rounded-t sm:rounded-tr-none sm:rounded-l" src={imageSrc} />
          )}
        </div>
      </div>
      <div className="p-sm">
        <div className="hidden sm:block sm:mb-xs">
          <RecurringProServicePrice price={price} />
        </div>
        <Text>{description}</Text>
      </div>
    </div>
  );
}
