import React, { Component } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { AutoCompleteFinalFormAdapter } from 'components/AutoComplete/AutoComplete';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import String from 'components/String/String';
import MaintenanceModalAttachmentFields from 'containercomponents/Modals/AddTaskModal/MaintenanceModalAttachmentFields';
import arrayMutators from 'final-form-arrays';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { RequestType, MissingKeyTypes, HaulingTypes } from 'models/enums/index';
import HaulingForm from 'pages/Accounts/FormLayouts/HaulingForm/HaulingForm';
import KeysForm from 'pages/Accounts/FormLayouts/KeysForm/KeysForm';
import ResidentAvailabilityForm from 'pages/Accounts/FormLayouts/ResidentAvailabilityForm/ResidentAvailabilityForm';
import PropTypes from 'prop-types';
import { updateMaintenanceTask, createMaintenanceTask } from 'store/redux/maintenance/actions';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { required } from 'utils/validation';
import { PRO_SERVICE_TASKS } from '../AddTaskModal/AddTaskModal';
import { listOfAppliances, responsibility, urgency } from '../ModalListOfItems/ApplianceList/listOfFormObjects';
import styles from './RenterAddTaskModal.module.css';

const cx = classNames.bind(styles);

class RenterAddTaskModal extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateMaintenanceTask: PropTypes.func.isRequired,
    createMaintenanceTask: PropTypes.func.isRequired,
    task: PropTypes.object,
    onEdit: PropTypes.bool,
  };

  static defaultProps = {
    task: {},
    onEdit: false,
  };

  state = {
    loading: false,
  };

  getInitialValues() {
    const { task, onEdit } = this.props;

    const { media } = task;
    const filteredMedia = filter(media, (m) => m.stage === 'Before' && !m.subOwner);

    this.initValue = {
      ...task,
      doesResidentClaimResponsibility: task.doesResidentClaimResponsibility?.toString() || null,
      requestorAvailability: task.requestorAvailability || [],
      attachments: {
        availableMedia: filteredMedia,
        mediaToDisplay: filteredMedia,
        deletedMedia: [],
        later: onEdit ? !filteredMedia?.length : false,
      },
    };
    return this.initValue;
  }

  handleRegisterSubmit = async (values) => {
    const { unit, closeModal, onEdit, task } = this.props;

    const optionalObj = {};

    if (unit?.unitId) {
      optionalObj.homeUniqueId = unit.unitId;
    }

    if (onEdit) {
      optionalObj.auditInfo = {
        dataVersion: task.dataVersion,
      };
    }

    const getOnlyValidDates = values.requestorAvailability.filter((date) => {
      if (date.times?.length) {
        return true;
      }
      return false;
    });

    const validDates = getOnlyValidDates.length ? getOnlyValidDates : null;
    const sendObjectToBackend = {
      ...values,
      ...optionalObj,
      requestorAvailability: values.isRequestorPresent ? validDates : null,
    };

    this.setState({ loading: true });

    try {
      if (onEdit) {
        await this.props.updateMaintenanceTask(sendObjectToBackend, task.uniqueId);
      } else {
        await this.props.createMaintenanceTask(sendObjectToBackend);
      }
    } catch (err) {
      console.error(err);
    }

    this.setState({ loading: false });
    try {
      await closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { show, closeModal } = this.props;
    const { loading } = this.state;

    return (
      <ModalV2
        show={show}
        ignoreModalExitAndEnterFunctions
        onHide={closeModal}
        showSpinner={loading}
        type={MODAL_TYPES.MAINTENANCE_MODAL}
        noPadding
        backdrop="static"
      >
        <div className={cx('maintenanceStatusModal')}>
          <ModalLayout fixedBottom>
            <ModalLayout.Title>
              <String string={ADD_TASK_MODAL_STRINGS.title} />
            </ModalLayout.Title>
            <ModalLayout.Content>
              <ModalLayout.Section>
                <Form
                  onSubmit={this.handleRegisterSubmit}
                  initialValues={this.getInitialValues()}
                  getFormBottomBar={(formProps, nextButtonProps) => {
                    return (
                      <ModalLayout.BottomBar
                        ctaProps={{
                          label: 'Submit',
                        }}
                        nextButtonWrapperProps={nextButtonProps}
                        fixedBottom
                      />
                    );
                  }}
                  errorProps={{
                    errorClassNames: cx('transform-error-position'),
                    inline: false,
                  }}
                  mutators={{
                    ...arrayMutators,
                  }}
                  bottomPosition="25px"
                  getForm={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                      <FormLayout>
                        <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS['renter_upgrade.title']}>
                          <Field
                            fluid
                            name="requestType"
                            component={SelectorFinalFormAdapter}
                            placeholder="Task Type"
                            validate={required}
                            buttons={[
                              { ...PRO_SERVICE_TASKS.REPAIR },
                              { ...PRO_SERVICE_TASKS.HAULING_AND_STORAGE },
                              { ...PRO_SERVICE_TASKS.KEYS_AND_ACCESS },
                            ]}
                            onChangeCustom={() => {
                              if (
                                form.getState().values.requestType === RequestType.HaulingAndStorage &&
                                !get(form.getState().values, 'requestTypeDetails.items')
                              ) {
                                form.change('requestTypeDetails.items', [{}]);
                              }
                            }}
                          />
                        </FormLayout.Section>
                        <Condition when="requestType" is={RequestType.Repair}>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS['renter_repair_modal.repair_title']}>
                            <Field
                              name="summary"
                              allowUserInput
                              hideAutoCompleteIconOnMobile
                              component={AutoCompleteFinalFormAdapter}
                              items={listOfAppliances}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['renter_repair_modal.describe_issue_title']}
                            sectionSubTitle={
                              <String string={ADD_TASK_MODAL_STRINGS['renter_repair_modal.describe_issue_subtitle']} />
                            }
                          >
                            <Field
                              name="description"
                              component={InputFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.repair.describe_issue_placeholder}
                              validate={required}
                              textarea
                            />
                          </FormLayout.Section>

                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['renter_repair_modal.responsibility_title']}
                            sectionSubTitle={
                              <String string={ADD_TASK_MODAL_STRINGS['renter_repair_modal.responsibility_subtitle']} />
                            }
                          >
                            <Field
                              name="doesResidentClaimResponsibility"
                              component={SelectorFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
                              buttons={responsibility}
                              validate={required}
                              type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section
                            sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                            sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS['upload.subtitle']} />}
                          >
                            <MaintenanceModalAttachmentFields form={form} />
                          </FormLayout.Section>

                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.urgency}>
                            <Field
                              name="urgency"
                              component={SelectorFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
                              buttons={urgency}
                              validate={required}
                              fluid
                              type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                            />
                          </FormLayout.Section>
                          <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />
                        </Condition>
                        <Condition when="requestType" is={RequestType.KeysAndAccess}>
                          <KeysForm form={form} />
                          <Condition when="requestTypeDetails.reason" hasValue>
                            <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />
                          </Condition>
                        </Condition>
                        <Condition when="requestType" is={RequestType.HaulingAndStorage}>
                          <HaulingForm form={form} />
                          <Condition when="requestTypeDetails.type" hasValue>
                            <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />
                          </Condition>
                        </Condition>

                        <OnChange name="requestType">
                          {(value, previous) => {
                            if (previous === RequestType.HaulingAndStorage || previous === RequestType.KeysAndAccess) {
                              form.change('summary', '');
                            }
                            if (value === RequestType.HaulingAndStorage) {
                              let valuesToChange = get(form.getState().values, 'requestTypeDetails.type');
                              if (valuesToChange === HaulingTypes.Haul) {
                                valuesToChange = 'Item removal';
                              } else if (valuesToChange === HaulingTypes.Storage) {
                                valuesToChange = 'Item storage';
                              }
                              form.change('summary', valuesToChange);
                            }
                            if (value === RequestType.KeysAndAccess) {
                              let valuesToChange = get(form.getState().values, 'requestTypeDetails.reason');
                              if (valuesToChange === MissingKeyTypes.MissingKey) {
                                valuesToChange = 'Missing key';
                              } else if (valuesToChange === MissingKeyTypes.Rekey) {
                                valuesToChange = 'Rekeying';
                              } else if (valuesToChange === MissingKeyTypes.LockedOut) {
                                valuesToChange = 'Lockout';
                              } else {
                                valuesToChange = 'Keys';
                              }

                              form.change('summary', valuesToChange);
                            }
                          }}
                        </OnChange>
                      </FormLayout>
                    </form>
                  )}
                />
              </ModalLayout.Section>
            </ModalLayout.Content>
          </ModalLayout>
        </div>
      </ModalV2>
    );
  }
}

const mapDispatchToProps = {
  updateMaintenanceTask,
  createMaintenanceTask,
};

export default connect(null, mapDispatchToProps)(RenterAddTaskModal);
