import { useState, useLayoutEffect, useEffect, useCallback, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { match as Match } from 'react-router';
import { usePagination, PaginatedList, PATHS } from '@belong/common';
import { Text, Tabs } from '@belong/ui';
import { BillInfoDetailModel, GroupBalanceInfoModel } from 'api/models';
import { MonthlyStatementDetailInfoModel, UnderReviewInfoModel, BillingInfoModel } from 'api/models.v2';
import PaymentMethodContainer from 'containers/PaymentMethodContainer/PaymentMethodContainer';
import { format, endOfMonth, startOfMonth, addMonths, subMonths } from 'date-fns';
import { History } from 'history';
import { sortBy } from 'lodash';
import { PaymentPreference, BillType } from 'models/enums/index';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { HomeMenu } from 'pages/Accounts/Components/home-menu/home-menu';
import { PastMonthsSection } from 'pages/Accounts/Components/past-months-section';
import { UnderReviewSection } from 'pages/Accounts/Components/under-review-section';
import { UpcomingSection } from 'pages/Accounts/Components/upcoming-section';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import { balancesDataMapper, underReviewDataMapper } from 'pages/Accounts/utils/balances-data-mapper';
import queryString from 'query-string';
import {
  fetchUserPastBills,
  fetchUserGroupBalances,
  fetchUserBalancesUnderReview,
  fetchUserGroupBalancesByMonth,
  fetchUserBalancesPastMonths,
  fetchUserBillsById,
} from 'store/redux/accounts/actions';
import {
  selectBalancesUnderReview,
  selectBalancesByMonth,
  selectBalancesPastMonths,
} from 'store/redux/accounts/selectors';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectFeatureFlags } from 'store/redux/settings';
import { getUserDevices } from 'store/redux/user/actions';
import { PastEarningsSection } from '../../../Components/past-earnings-section';
import { PaymentBillDetailModal } from '../../../Components/payment-bill-detail-modal/payment-bill-detail-modal';
import { PayoutBillDetailModal } from '../../../Components/payout-bill-detail-modal/payout-bill-detail-modal';
import BalanceDetailsModal from './BalanceDetailsModal/BalanceDetailsModal';
import FinanceCloseModal from './FinanceCloseModal/FinanceCloseModal';
import FinanceDetailsModal from './FinanceDetailsModal/FinanceDetailsModal';
import FinanceSuccessModal from './FinanceSuccessModal/FinanceSuccessModal';
import MobileBanner from './MobileBanner/MobileBanner';
import { EARNINGS_STRINGS } from './earnings.strings';
import { PastMonthModal } from './past-month-modal/past-month-modal';

type EarningsProps = {
  match: Match<{ propertyId: string }>;
  history: History;
  location: Location;
  properties: HomeownerAccountProperty[];
  balances: MonthlyStatementDetailInfoModel[] | null;
  balancesUnderReview: UnderReviewInfoModel[] | null;
  pastMonthsBalances: MonthlyStatementDetailInfoModel[] | null;
  fetchUserBillsById: (id?: string, type?: BillInfoDetailModel['billInfo']['type']) => void;
  fetchUserPastBills: (queryParams?: string) => Promise<PaginatedList<BillInfoDetailModel>>;
  fetchUserGroupBalancesByMonth: (queryParams?: string) => void;
  fetchUserBalancesPastMonths: (queryParams?: string) => void;
  fetchUserBalancesUnderReview: (queryParams?: string) => void;
  getUserDevices: () => Promise<
    Array<{
      isActive: boolean;
    }>
  >;
  isAutoSplitFeatureFlagEnabled?: boolean;
};

const TRANSACTIONS_TABS = [
  { key: 'homeowners-transactions-montly', text: 'Monthly' },
  { key: 'homeowners-transactions-receipts', text: 'Receipts' },
];

function Earnings({
  history,
  match,
  properties,
  balances,
  pastMonthsBalances,
  balancesUnderReview,
  location,
  fetchUserBillsById: fetchUserBillsByIdAction,
  fetchUserPastBills: fetchUserPastBillsAction,
  fetchUserGroupBalancesByMonth: fetchUserGroupBalancesByMonthAction,
  fetchUserBalancesPastMonths: fetchUserBalancesPastMonthsAction,
  fetchUserBalancesUnderReview: fetchUserBalancesUnderReviewAction,
  getUserDevices: getUserDevicesAction,
  isAutoSplitFeatureFlagEnabled,
}: EarningsProps) {
  const { search, pathname } = location;
  const queryParams = queryString.parse(search);
  const pastMonthsRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [selectedHome, setSelectedHome] = useState<string>(
    queryParams.homeId ? (typeof queryParams.homeId === 'string' ? queryParams.homeId : queryParams.homeId?.[0]) : 'all'
  );
  const [type, setType] = useState(null);
  const [billId, setBillId] = useState(queryParams.id || null);
  const [financeDetailModal, setFinanceDetailModal] = useState(false);
  const [financeSuccessModal, setFinanceSuccessModal] = useState(false);
  const [financeCloseModal, setFinanceCloseModal] = useState(false);
  const [financePlanSelected, setFinancePlanSelected] = useState<string>();
  const [balanceDetailsModal, setBalanceDetailsModal] = useState(false);
  const [selectedPastMonth, setSelectedPastMonth] = useState(null);
  const [selectedBalance, setSelectedBalance] = useState<
    | {
        balance: GroupBalanceInfoModel;
        address: string;
        billingInfo?: BillingInfoModel;
        autosplittable?: boolean;
      }
    | undefined
  >();
  const [balanceStandalone, setBalanceStandalone] = useState(false);
  const [hasApp, setHasApp] = useState(false);
  const [_selectedTabIndex, setSelectedTabIndex] = useState(0);

  const restParams = useMemo(() => (selectedHome === 'all' ? {} : { homeUniqueIds: [selectedHome] }), [selectedHome]);

  const { items: bills, loadMore, hasMorePages } = usePagination({ fetch: fetchUserPastBillsAction, restParams });
  const hasManyHomes = properties.length > 1 || properties?.some((property) => property.units.length > 1);

  const fetchData = useCallback(
    async function () {
      setLoading(true);

      const selectedHomeParam = JSON.parse(
        JSON.stringify({ homeUniqueIds: selectedHome === 'all' ? undefined : [selectedHome] })
      );

      const from = startOfMonth(new Date());
      const until = endOfMonth(addMonths(from, 1));

      const balancesQueryParams = queryString.stringify({
        ...selectedHomeParam,
        from: `${format(from, 'yyyy-MM-dd')}T00:00:00.000Z`,
        until: `${format(until, 'yyyy-MM-dd')}T23:59:59.999Z`,
        groupBalanceType: 'ExpensesAndRevenues',
      });

      const pastBalancesQueryParams = queryString.stringify({
        ...selectedHomeParam,
        from: `${format(subMonths(from, 12), 'yyyy-MM-dd')}T00:00:00.000Z`,
        until: `${format(subMonths(until, 2), 'yyyy-MM-dd')}T23:59:59.999Z`,
        groupBalanceType: 'ExpensesAndRevenues',
      });

      await fetchUserGroupBalancesByMonthAction(balancesQueryParams);
      await fetchUserBalancesPastMonthsAction(pastBalancesQueryParams);
      await fetchUserBalancesUnderReviewAction(queryString.stringify({ ...selectedHomeParam }));
      const devices = await getUserDevicesAction();

      if (devices.length > 0) setHasApp(true);
      setLoading(false);
    },
    [
      fetchUserGroupBalancesByMonthAction,
      fetchUserBalancesUnderReviewAction,
      fetchUserBalancesPastMonthsAction,
      getUserDevicesAction,
      selectedHome,
    ]
  );

  function handleModalHide() {
    setBillId(null);
    setType(null);

    history.push(PATHS.HOMEOWNER_ACCOUNT_EARNINGS);
  }

  function handleFinancingModal(id: string, balance, address: string) {
    setSelectedBalance({ balance, address });
    setFinancePlanSelected(id);
    setFinanceDetailModal(true);
  }

  function handleShowPastMonthModal(detail) {
    setSelectedPastMonth(detail);
  }

  function handleFinancingModalHide() {
    setFinanceDetailModal(false);
  }

  function handleFinancingSuccessModalHide() {
    setFinanceSuccessModal(false);
  }

  function handleBalanceDetailsModalHide() {
    setBalanceDetailsModal(false);
  }

  function handleFinancingCloseModalHide() {
    fetchData();
    setFinanceCloseModal(false);
  }

  function handleShowFinanceSuccessModal() {
    fetchData();
    setFinanceSuccessModal(true);
  }

  function handleShowBalanceDetailsModal(balance, address, billingInfo, standalone = false, autosplittable = false) {
    setBalanceDetailsModal(true);
    setSelectedBalance({ balance, address, billingInfo, autosplittable });
    setBalanceStandalone(standalone);
  }

  function handleHomeMenuChange(homeId: string) {
    setSelectedHome(homeId);

    history.push({
      pathname,
      search: `?homeId=${homeId}`,
    });
  }

  function handleUngroupModal(id: string) {
    setFinanceCloseModal(true);
    setFinancePlanSelected(id);
  }

  function onTabIndexChange(index: number) {
    setSelectedTabIndex(index);
  }

  function handleShowEarningsModal(bill: BillInfoDetailModel) {
    return async () => {
      setLoading(true);

      try {
        await fetchUserBillsByIdAction(bill.billInfo.id, bill.billInfo.type);
      } catch (err) {
        console.error(err);
      }

      setBillId(bill.billInfo.id);
      setType(bill.billInfo.type);
      setLoading(false);

      history.push(`${PATHS.HOMEOWNER_ACCOUNT_EARNINGS}/${bill.billInfo.id}`);
    };
  }

  useLayoutEffect(() => {
    async function fetchUserBillsOnMount() {
      if (match.params.propertyId) {
        let bill;
        try {
          bill = await fetchUserBillsByIdAction(match.params.propertyId);
        } catch (err) {
          console.error(err);
        }

        setBillId(match.params.propertyId);
        setType(bill?.billInfo?.type);
      }
    }

    fetchUserBillsOnMount();
  }, [fetchUserBillsByIdAction, match.params.propertyId]);

  useLayoutEffect(() => {
    if (!loading && queryParams.scroll === 'pastMonths') {
      pastMonthsRef.current.scrollIntoView({ block: 'center' });
    }
  }, [loading, queryParams.scroll]);

  useEffect(() => {
    fetchData();
  }, [selectedHome, fetchUserGroupBalancesByMonthAction, fetchData]);

  return (
    <AccountPage
      loading={loading}
      headerProps={{
        children: (
          <div className="flex items-center justify-between">
            <Text variant="h2" fontWeight="semibold">
              {EARNINGS_STRINGS['page.title']}
            </Text>
            {hasManyHomes && (
              <HomeMenu
                properties={properties}
                selectedHomeId={selectedHome}
                allHomesLabel={EARNINGS_STRINGS.all_homes}
                onChange={handleHomeMenuChange}
              />
            )}
          </div>
        ),
      }}
    >
      <div>
        <Tabs hasBlurOnScroll={false} index={_selectedTabIndex} onChange={onTabIndexChange}>
          <Tabs.TabList className="mx-auto w-fit">
            {TRANSACTIONS_TABS.map((tab, index) => {
              return (
                <Tabs.TabItem key={tab.key} index={index}>
                  <p>{tab.text}</p>
                </Tabs.TabItem>
              );
            })}
          </Tabs.TabList>
          <Tabs.PanelItem index={0} className="pt-xl">
            <UpcomingSection
              balances={balances}
              selectedHome={selectedHome}
              handleFinancingModal={handleFinancingModal}
              handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
              handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
              hasManyHomes={hasManyHomes}
            />
            <UnderReviewSection
              homesBalances={balancesUnderReview}
              selectedHome={selectedHome}
              handleFinancingModal={handleFinancingModal}
              handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
              handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
              hasManyHomes={hasManyHomes}
            />

            {!hasApp && <MobileBanner hasApp={hasApp} />}

            <PastMonthsSection
              balances={pastMonthsBalances}
              handleShowPastMonthModal={handleShowPastMonthModal}
              isResident={false}
              selectedHome={selectedHome}
              hasManyHomes={hasManyHomes}
              ref={pastMonthsRef}
            />
            <AccountPageSection
              title={EARNINGS_STRINGS['section.bank.title']}
              noPaddingTop
              content={
                <PaymentMethodContainer
                  paymentType={PaymentPreference.Payout}
                  isRenter={false}
                  shouldShowAutoSplitPaymentMethod={isAutoSplitFeatureFlagEnabled}
                />
              }
            />
          </Tabs.PanelItem>
          <Tabs.PanelItem index={1} className="pt-2xl">
            <PastEarningsSection
              earnings={bills}
              selectedHome={selectedHome}
              handleShowEarningsModal={handleShowEarningsModal}
              loadMore={loadMore}
              hasMorePages={hasMorePages}
              hasManyHomes={hasManyHomes}
            />
          </Tabs.PanelItem>
        </Tabs>
      </div>
      <PaymentBillDetailModal
        show={type === BillType.Payment}
        onHide={handleModalHide}
        billId={typeof billId === 'string' ? billId : billId?.[0]}
        isHomeowner
        handleFinancingModal={handleFinancingModal}
        handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
        home={selectedBalance?.address}
      />
      <PayoutBillDetailModal
        show={type === BillType.Payout}
        onHide={handleModalHide}
        handleFinancingModal={handleFinancingModal}
        handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
        home={selectedBalance?.address}
      />
      <FinanceDetailsModal
        show={financeDetailModal}
        onHide={handleFinancingModalHide}
        financeUniqueId={financePlanSelected}
        handleUngroupModal={handleUngroupModal}
        handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
        home={selectedBalance?.address}
      />
      <FinanceCloseModal
        show={financeCloseModal}
        financeUniqueId={financePlanSelected}
        onHide={handleFinancingCloseModalHide}
      />
      <FinanceSuccessModal show={financeSuccessModal} onHide={handleFinancingSuccessModalHide} />
      <BalanceDetailsModal
        show={balanceDetailsModal}
        balance={selectedBalance}
        onHide={handleBalanceDetailsModalHide}
        standalone={balanceStandalone}
        handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
      />
      {!!selectedPastMonth && (
        <PastMonthModal
          show
          homeDetail={selectedPastMonth}
          onHide={() => setSelectedPastMonth(null)}
          handleShowBalanceDetailsModal={handleShowBalanceDetailsModal}
          handleFinancingModal={handleFinancingModal}
          handleShowFinanceSuccessModal={handleShowFinanceSuccessModal}
        />
      )}
    </AccountPage>
  );
}

function mapStateProps(state) {
  return {
    balances: balancesDataMapper(selectBalancesByMonth(state)),
    pastMonthsBalances: balancesDataMapper(selectBalancesPastMonths(state)),
    properties: selectAccountProperties(state),
    isAutoSplitFeatureFlagEnabled: !!selectFeatureFlags(state).isAutoSplitEnabled,
    balancesUnderReview: underReviewDataMapper(selectBalancesUnderReview(state)),
  };
}
const mapDispatchToProps = {
  fetchUserPastBills: fetchUserPastBills as unknown as (
    queryParams: string
  ) => Promise<PaginatedList<BillInfoDetailModel>>,
  fetchUserBillsById,
  fetchUserGroupBalances,
  fetchUserGroupBalancesByMonth,
  fetchUserBalancesPastMonths,
  fetchUserBalancesUnderReview,
  getUserDevices,
};

export default connect(mapStateProps, mapDispatchToProps)(Earnings);
