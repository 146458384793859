import { useSelector } from 'react-redux';
import { Text } from '@belong/ui';
import { selectNoApprovalMaximumThresholdAmount } from 'store/redux/settings';
import { getString } from 'strings';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';

const agreementSummaryItemTexts = [
  AGREEMENT_FLOW_STRINGS['sign.summary.first'],
  AGREEMENT_FLOW_STRINGS['sign.summary.second'],
  AGREEMENT_FLOW_STRINGS['sign.summary.third'],
  AGREEMENT_FLOW_STRINGS['sign.summary.fourth'],
];

export const AgreementSummary = () => {
  const noApprovalMaximumThresholdAmount = useSelector(selectNoApprovalMaximumThresholdAmount);

  return (
    <ul>
      {agreementSummaryItemTexts.map((agreementSummaryItemText) => (
        <li className="mb-2xs" key={agreementSummaryItemText}>
          <div className="flex">
            <Text className="flex-1">{agreementSummaryItemText}</Text>
          </div>
        </li>
      ))}
      <li className="mb-2xs">
        <div className="flex">
          <Text className="flex-1">
            {getString(AGREEMENT_FLOW_STRINGS['sign.summary.fifth'], {
              threshold: noApprovalMaximumThresholdAmount,
            })}
          </Text>
        </div>
      </li>
    </ul>
  );
};
