import { Component } from 'react';
import { connect } from 'react-redux';
import { Image, Toast } from '@belong/ui';
import classNames from 'classnames/bind';
import { QUERY_PARAMS, STRINGS } from 'consts/in-person-visits';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { every, find, some } from 'lodash';
import { parseCalendlyDateFromURL } from 'pages/HomeOwnerOnboarding/utils';
import PropTypes from 'prop-types';
import { selectUser } from 'store/redux/user/selectors';
import { getString } from 'strings';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { getSignUpFlowRoute } from '../steps';
import styles from './MultiplePropertiesCheck.module.css';
import PropertyCard from './PropertyCard';

const cx = classNames.bind(styles);

const { FormLayoutHeader } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.multiple_properties;

class MultiplePropertiesCheck extends Component {
  state = {
    isInPersonVisitsToastVisible: false,
    calendlySuccess: null,
    calendlyDate: null,
  };

  componentDidMount() {
    const parsedURL = new URLSearchParams(this.props.location.search);
    const calendlySuccessURLParam = parsedURL.get(QUERY_PARAMS.CALENDLY_SUCCESS);
    const calendlyDate = parseCalendlyDateFromURL(this.props.location);

    this.setState({
      isInPersonVisitsToastVisible: calendlySuccessURLParam !== null,
      calendlySuccess: calendlySuccessURLParam === 'true',
      calendlyDate,
    });
  }

  handleInPersonVisitsToastClose = () => {
    this.setState({
      isInPersonVisitsToastVisible: false,
    });
  };

  getPageTitle(atleastOnePropertyCompleted, allPropertiesCompleted) {
    const { user } = this.props;

    if (!atleastOnePropertyCompleted) {
      return getString(HOB.zero_properties_completed.title, { firstName: user.firstName });
    }

    if (allPropertiesCompleted) {
      return getString(HOB.all_properties_completed.title);
    }

    if (atleastOnePropertyCompleted) {
      return getString(HOB.atleast_one_property_completed.title);
    }
  }

  getPagePretitle(atleastOnePropertyCompleted, allPropertiesCompleted) {
    const { user } = this.props;

    if (!atleastOnePropertyCompleted) {
      return getString(HOB.zero_properties_completed.subtitle, { firstName: user.firstName });
    }

    if (allPropertiesCompleted) {
      return getString(HOB.all_properties_completed.subtitle);
    }

    if (atleastOnePropertyCompleted) {
      return getString(HOB.atleast_one_property_completed.subtitle);
    }
  }

  handleSubmit = () => {
    const { history, groups, onComplete } = this.props;
    const { user } = groups;
    if (user?.steps?.length) {
      history.push(getSignUpFlowRoute(user.steps[0]));
    } else {
      onComplete();
    }
  };

  handleCardClick = (step) => {
    const { history } = this.props;
    history.push(getSignUpFlowRoute(step));
  };

  render() {
    const { groups, onBack } = this.props;
    const { calendlyDate, calendlySuccess, isInPersonVisitsToastVisible } = this.state;

    const { homes } = groups;
    const atleastOnePropertyCompleted = some(homes, (home) => home.completed);
    const allPropertiesCompleted = every(homes, (home) => home.completed);

    return (
      <>
        <FormLayout>
          <FormLayoutHeader
            title={this.getPageTitle(atleastOnePropertyCompleted, allPropertiesCompleted)}
            pretitle={this.getPagePretitle(atleastOnePropertyCompleted, allPropertiesCompleted)}
          />
          {homes?.map(({ property, home, steps, completed, homeIndex, propertyIndex }, index) => {
            let disabled = false;
            if (homeIndex !== 0) {
              const previousHome = find(homes, { homeIndex: homeIndex - 1, propertyIndex });
              disabled = !previousHome?.completed;
            }
            return (
              <div key={property.basicInfo.propertyId} className={cx({ card: !(index === homes.length - 1) })}>
                <PropertyCard
                  property={property}
                  home={home}
                  completed={completed}
                  onClick={() => this.handleCardClick(steps[0])}
                  disabled={disabled}
                />
              </div>
            );
          })}
          <FormLayout.BottomBar
            ctaProps={{
              label: 'Next',
              type: 'submit',
              disabled: !allPropertiesCompleted,
              onClick: this.handleSubmit,
            }}
            previousStepProps={{
              onClick: onBack,
              to: null,
            }}
          />
        </FormLayout>
        <Toast
          icon={
            <Image
              alt=""
              className={calendlySuccess ? '' : 'object-scale-down'}
              src={calendlySuccess ? 'icons/confetti.svg' : 'icons/alert-white.svg'}
            />
          }
          isVisible={isInPersonVisitsToastVisible}
          onClose={this.handleInPersonVisitsToastClose}
          variant={calendlySuccess ? 'default' : 'danger'}
        >
          {calendlySuccess ? (
            <>
              {getString(STRINGS.SUCCESS_TOAST, {
                datetime: calendlyDate,
              })}
            </>
          ) : (
            STRINGS.FAILURE_TOAST
          )}
        </Toast>
      </>
    );
  }
}

MultiplePropertiesCheck.propTypes = {
  user: PropTypes.object,
  groups: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

MultiplePropertiesCheck.defaultProps = {
  user: {},
};

export default connect((state) => ({
  user: selectUser(state),
}))(MultiplePropertiesCheck);
