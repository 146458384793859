// React imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast } from '@belong/ui';
import classNames from 'classnames/bind';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
// Component imports
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Spinner from 'components/Spinner/Spinner';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
// Bootstrap imports
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
// Lodash imports
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
// Redux store
import {
  fetchApplicantInfo,
  fetchReferenceCheck,
  updateReferenceCheck,
} from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationCurrentAddress,
  selectResidentApplicationEmploymentInformation,
  selectResidentApplicationId,
  selectResidentApplicationReferenceCheck,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
// Constant & String imports
import { getString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { composeValidators, email, phoneValidation, required } from 'utils/validation';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
// SCSS imports
import styles from './ReferenceCheck.module.css';

const { FormLayoutHeader } = FormLayout;
const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.reference_check;

const SELECTOR_OPTIONS = {
  YES: 'yes',
  NO: 'no',
};

const compareInternationalAddress = (addressOne, addressTwo) => {
  let isSame = true;

  if (addressOne.completeAddress !== addressTwo.completeAddress) {
    isSame = false;
  } else if (addressOne.unitNumber !== addressTwo.unitNumber) {
    isSame = false;
  } else if (addressOne.country !== addressTwo.country) {
    isSame = false;
  }

  return isSame;
};

const compareUsAddress = (addressOne, addressTwo) => {
  let isSame = true;

  if (addressOne.streetAddress !== addressTwo.streetAddress) {
    isSame = false;
  } else if (addressOne.unitNumber !== addressTwo.unitNumber) {
    isSame = false;
  } else if (addressOne.city !== addressTwo.city) {
    isSame = false;
  } else if (addressOne.state !== addressTwo.state) {
    isSame = false;
  } else if (addressOne.zipcode !== addressTwo.zipcode) {
    isSame = false;
  }

  return isSame;
};

const compareAddressObjects = (addressOne, addressTwo) => {
  let isSame = true;

  if (addressOne.outsideUS !== addressTwo.outsideUS) {
    isSame = false;
    return isSame;
  }

  if (addressOne.outsideUS && addressTwo.outsideUS) {
    isSame = compareInternationalAddress(addressOne, addressTwo);
  } else {
    isSame = compareUsAddress(addressOne, addressTwo);
  }

  return isSame;
};

class ReferenceCheck extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object,
    }).isRequired,
    currentAddress: PropTypes.object,
    referenceCheck: PropTypes.object,
    applicationId: PropTypes.string.isRequired,
    houseId: PropTypes.string.isRequired,
    fetchReferenceCheck: PropTypes.func.isRequired,
    fetchApplicantInfo: PropTypes.func.isRequired,
    updateReferenceCheck: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentAddress: {},
    referenceCheck: {},
  };

  constructor(props) {
    super(props);

    this.initialValues = {};

    this.state = {
      awaitingFetchComplete: true,
      formData: this.initialValues || {},
      outsideUsCurrentAddress: false,
      isErrorToastVisible: false,
      errorText: '',
      isIncomeVerified: false,
    };
  }

  async componentDidMount() {
    const {
      referenceCheck,
      fetchReferenceCheck: fetchReferenceCheckAction,
      fetchApplicantInfo: fetchApplicantInfoAction,
      history: { location },
    } = this.props;

    if (location?.state?.verified) {
      this.setState({
        isIncomeVerified: true,
      });
    }

    if (!referenceCheck || isEmpty(referenceCheck)) {
      try {
        await Promise.all([fetchReferenceCheckAction(), fetchApplicantInfoAction()]);
      } catch (err) {
        console.error(err);
      }
    }

    this.setupInitialValues();
  }

  setupInitialValues() {
    const { referenceCheck, currentAddress } = this.props;

    const newState = {
      formData: {},
      outsideUsCurrentAddress: false,
    };

    if (referenceCheck && !isEmpty(referenceCheck)) {
      const { rentalAddress, reference } = referenceCheck;
      newState.formData.referenceCheckConsent = true;

      if (rentalAddress && compareAddressObjects(rentalAddress, currentAddress)) {
        newState.formData.currentlyRenting = SELECTOR_OPTIONS.YES;
        newState.formData.landlordName = reference.name;
        newState.formData.landlordEmail = reference.email;
        newState.formData.landlordPhone = reference.phone;
        newState.formData.notInformedLandlord = reference.canContact;
      } else if (rentalAddress && !compareAddressObjects(rentalAddress, currentAddress)) {
        newState.formData.rentalAddress = { ...rentalAddress };
        newState.formData.currentlyRenting = SELECTOR_OPTIONS.NO;
        newState.outsideUsCurrentAddress = rentalAddress.outsideUS;
        newState.formData.landlordName = reference.name;
        newState.formData.landlordEmail = reference.email;
        newState.formData.landlordPhone = reference.phone;
        newState.formData.notInformedLandlord = reference.canContact;
        newState.formData.referenceCheckConsent = true;
      } else if (!rentalAddress || isEmpty(rentalAddress)) {
        newState.formData.currentlyRenting = SELECTOR_OPTIONS.NO;
        newState.formData.neverRentedBefore = true;
        newState.formData.referenceName = reference.name;
        newState.formData.referenceEmail = reference.email;
        newState.formData.referencePhone = reference.phone;
        newState.formData.notInformedLandlord = reference.canContact;
      }
    }

    this.setState({
      awaitingFetchComplete: false,
      ...newState,
    });
  }

  handleOutsideUsAddressCallback = (outsideUs) => {
    this.setState({ outsideUsCurrentAddress: outsideUs });
  };

  // Update state with values set by user to ensure that changing address doesn't clear the form
  handleChange = (name, value) => {
    const { formData } = this.state;
    const updatedFormData = { ...formData };
    updatedFormData[name] = value;

    if (name === 'neverRentedBefore' && value === true) {
      updatedFormData.rentalAddress = {};
    }

    this.setState({
      formData: updatedFormData,
    });
  };

  handleSubmit = async (values) => {
    const {
      history: { push },
      updateReferenceCheck: updateReferenceCheckAction,
      applicationId,
      houseId,
      currentAddress,
    } = this.props;
    const { outsideUsCurrentAddress } = this.state;
    const serverObject = {};

    this.setState({
      awaitingFetchComplete: true,
      isErrorToastVisible: false,
    });

    serverObject.reference = {
      name: values.landlordName || values.referenceName,
      email: values.landlordEmail || values.referenceEmail,
      phone: values.landlordPhone || values.referencePhone,
      canContact: values.notInformedLandlord,
    };

    if (values.currentlyRenting === SELECTOR_OPTIONS.YES) {
      serverObject.rentalAddress = { ...currentAddress };
    } else if (values.currentlyRenting === SELECTOR_OPTIONS.NO && !values.neverRentedBefore) {
      const userCurrentAddress = { ...values.rentalAddress };
      userCurrentAddress.outsideUS = outsideUsCurrentAddress;
      serverObject.rentalAddress = { ...userCurrentAddress };
    }

    try {
      await updateReferenceCheckAction(serverObject);

      push(getResidentApplicationStepPath(STEPS_CONFIG.BACKGROUND_CHECK, { houseId, applicationId }));
    } catch (error) {
      console.error(error);

      if (error?.[0]?.errorCode === 'INVALID_ADDRESS') {
        this.setState({
          isErrorToastVisible: true,
          errorText: error?.[0]?.message,
          awaitingFetchComplete: false,
        });
      }
    }
  };

  handleToastClose = () => {
    this.setState({ isErrorToastVisible: false });
  };

  renderLandlordOrHousemateSection(form) {
    const { neverRentedBefore, currentlyRenting, rentalAddress } = form.getState().values;

    if (currentlyRenting === SELECTOR_OPTIONS.YES) {
      return this.renderLandlordSection();
    }

    if (!neverRentedBefore && !isEmpty(rentalAddress)) {
      return this.renderLandlordSection();
    } else if (neverRentedBefore) {
      return this.renderHousemateSection();
    }
  }

  renderLandlordSection() {
    return (
      <div className={cx('landlord-section-wrapper')}>
        <FormLayout.Section
          sectionTitle={RAS.landlord_info_section_title}
          sectionSubTitle={RAS.landlord_info_section_subTitle}
        >
          <div className={cx('landlord-form-components-wrapper')}>
            <Row>
              <Col md={6}>
                <Field
                  name="landlordName"
                  placeholder={RAS.landlord_name}
                  component={InputFinalFormAdapter}
                  validate={required}
                  onChangeCustom={(inputValue) => this.handleChange('landlordName', inputValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="landlordEmail"
                  placeholder={RAS.landlord_email}
                  component={InputFinalFormAdapter}
                  validate={email}
                  onChangeCustom={(inputValue) => this.handleChange('landlordEmail', inputValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="landlordPhone"
                  placeholder={RAS.landlord_phone}
                  component={InputFinalFormAdapter}
                  validate={composeValidators(phoneValidation, required)}
                  mask={maskPhoneNumber}
                  unmask={unmaskPhoneNumber}
                  onChangeCustom={(inputValue) => this.handleChange('landlordPhone', inputValue)}
                />
              </Col>
            </Row>
            <div className={cx('not-informed-landlord-checkbox-wrapper')}>
              <Row>
                <Col md={12}>
                  <Field
                    name="notInformedLandlord"
                    component={CheckboxFinalFormAdapter}
                    alignWithField={false}
                    label={RAS.not_informed_landlord_checkbox}
                    onClickCustom={(inputValue) => this.handleChange('notInformedLandlord', inputValue)}
                  />
                </Col>
              </Row>
            </div>
            <Condition when="notInformedLandlord" is>
              <Row>
                <Col md={12}>
                  <div className={cx('not-informed-landlord-text')}>{RAS.not_informed_landlord_text}</div>
                </Col>
              </Row>
            </Condition>
          </div>
        </FormLayout.Section>
      </div>
    );
  }

  renderHousemateSection() {
    return (
      <div className={cx('housemate-section-wrapper')}>
        <FormLayout.Section
          sectionTitle={RAS.recent_housemate_section_title}
          sectionSubTitle={RAS.recent_housemate_section_subTitle}
        >
          <div className={cx('housemate-form-components-wrapper')}>
            <Row>
              <Col md={6}>
                <Field
                  name="referenceName"
                  placeholder={RAS.recent_housemate_name}
                  component={InputFinalFormAdapter}
                  validate={required}
                  onChangeCustom={(inputValue) => this.handleChange('referenceName', inputValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="referenceEmail"
                  placeholder={RAS.recent_housemate_email}
                  component={InputFinalFormAdapter}
                  validate={email}
                  onChangeCustom={(inputValue) => this.handleChange('referenceEmail', inputValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="referencePhone"
                  placeholder={RAS.recent_housemate_phone}
                  component={InputFinalFormAdapter}
                  validate={composeValidators(phoneValidation, required)}
                  mask={maskPhoneNumber}
                  unmask={unmaskPhoneNumber}
                  onChangeCustom={(inputValue) => this.handleChange('referencePhone', inputValue)}
                />
              </Col>
            </Row>
          </div>
        </FormLayout.Section>
      </div>
    );
  }

  render() {
    const {
      currentAddress: { outsideUS: currentAddressOutsideUS, streetAddress },
    } = this.props;
    const {
      awaitingFetchComplete,
      formData,
      formData: { neverRentedBefore },
      outsideUsCurrentAddress,
    } = this.state;

    return (
      <>
        <FormLayout className="relative">
          <FormLayoutHeader pretitle={RAS.preTitle} title={RAS.title} />
          <Form
            initialValues={formData}
            onSubmit={this.handleSubmit}
            getFormBottomBar={(formProps, nextButtonProps) => (
              <FormLayout.BottomBar
                ctaProps={{
                  label: RAS.next_button_text,
                }}
                nextButtonWrapperProps={nextButtonProps}
              />
            )}
            getForm={({ handleSubmit, form }) => (
              <div className={cx('form-wrapper')}>
                <form onSubmit={handleSubmit}>
                  <div className={cx('currently-renting-section-wrapper')}>
                    <FormLayout.Section
                      firstSection
                      sectionTitle={
                        currentAddressOutsideUS
                          ? getString(RAS.currently_renting_section_title_international)
                          : getString(RAS.currently_renting_section_title, {
                              streetAddress,
                            })
                      }
                    >
                      <div className={cx('currently-renting-selector-buttons-wrapper')}>
                        <Row>
                          <Col md={12}>
                            <Field
                              name="currentlyRenting"
                              component={SelectorFinalFormAdapter}
                              validate={required}
                              buttons={[
                                {
                                  label: RAS.currently_renting_selector_yes,
                                  key: SELECTOR_OPTIONS.YES,
                                },
                                {
                                  label: RAS.currently_renting_selector_no,
                                  key: SELECTOR_OPTIONS.NO,
                                },
                              ]}
                              type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                              onChangeCustom={(inputValue) => this.handleChange('currentlyRenting', inputValue)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </FormLayout.Section>

                    <Condition when="currentlyRenting" is={SELECTOR_OPTIONS.NO}>
                      <FormLayout.Section sectionTitle={RAS.last_rented}>
                        <Row>
                          <Col md={12}>
                            <AddressField
                              form={form}
                              name="rentalAddress"
                              allowOutsideUs
                              outsideUs={outsideUsCurrentAddress}
                              outsideUsAddressCallback={this.handleOutsideUsAddressCallback}
                              notRequired={neverRentedBefore}
                              onSelectCallback={(rentalAddress) => this.handleChange('rentalAddress', rentalAddress)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Field
                              name="neverRentedBefore"
                              component={CheckboxFinalFormAdapter}
                              label={RAS.never_rented_checkbox}
                              alignWithField={false}
                              onClickCustom={(inputValue) => this.handleChange('neverRentedBefore', inputValue)}
                            />
                          </Col>
                        </Row>
                      </FormLayout.Section>
                    </Condition>
                    {this.renderLandlordOrHousemateSection(form)}
                  </div>
                </form>
              </div>
            )}
          />
        </FormLayout>
        <Toast isVisible={this.state.isErrorToastVisible} onClose={this.handleToastClose} variant="danger">
          {this.state.errorText}
        </Toast>
        {awaitingFetchComplete && (
          <Row className="w-full absolute top-0">
            <Col md={12}>
              <div className={cx('spinner')}>
                <Spinner />
              </div>
            </Col>
          </Row>
        )}
        <Toast isVisible={this.state.isIncomeVerified} onClose={() => this.setState({ isIncomeVerified: false })}>
          {RAS.income_verified}
        </Toast>
      </>
    );
  }
}

export default connect(
  (state) => ({
    employmentInformation: selectResidentApplicationEmploymentInformation(state),
    referenceCheck: selectResidentApplicationReferenceCheck(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    currentAddress: selectResidentApplicationCurrentAddress(state),
  }),
  {
    fetchReferenceCheck,
    updateReferenceCheck,
    fetchApplicantInfo,
  }
)(ReferenceCheck);
