import { US_STATES } from '@belong/common';

export const AGREEMENT_FLOW_STRINGS = {
  // 'UserPersonal.tips.title': 'Change of Address?',
  'UserPersonal.tips.description': [
    'We take the security of your personal information seriously. It stays between us; we’ll never give <span>it out.</span>',
  ],
  personal_info: {
    title: "Let's get to know you better, {firstName}.",
    subTitle:
      'Please confirm your <span>personal information</span> so that we can generate <span>tax forms</span> and send you documents <span>in the future.</span>',
    is_name_legal_question: "Is '{firstName} {lastName}' your legal name?",
    is_name_legal_yes: "Yes, that's correct.",
    is_name_legal_no: "No, it's different.",
    legal_first_name: 'Legal first name',
    legal_middle_name: 'Legal middle name',
    legal_last_name: 'Legal last name',
    date_of_birth: 'Date of Birth (MM/DD/YYYY)',
    current_address: 'Current address',
    unit_number: 'Unit',
    same_mailing_address: 'This is also my mailing address.',
    where_to_send_title: 'Where can we send you important documents?',
    mailing_address: 'Mailing address',
    next_button_text: 'Next',
  },

  //Plan page
  'Plan.tips.description': [
    'I hate hidden fees. We all do. That’s why, here at Belong, we don’t have any. No initial setup fee. No vacancy fee. No maintenance fee. No eviction fee. No early termination fee. No call out fee. No tax preparation fee. No opinion of value fee. Did I mention no <span>hidden fees?</span>',
  ],
  'plan.tips.guaranteed.title':
    "Great! Once we find someone to love your home, we'll deposit your rent on the 1st of each month, no matter what.",
  'plan.tips.upfront.title':
    "Ok! The moment we find someone to love your home, we'll pay you the entire year's worth of income. In the meantime, if you currently have residents, we'll deduct 8% monthly from the time we start managing.",
  'plan.tips.lets-discuss.title': "Ok! I'll reach out ASAP, or feel free to contact me now.",
  'plan.title': 'Let’s get your <span>home loved.</span>',
  'plan.title-plural': 'Let’s get your <span>homes loved.</span>',
  'plan.which-plan.section-title':
    'When we find someone to love your home, how would you like to <span>be paid?</span>',
  'plan.which-plan.section-subtitle': 'You can always change this later, and again each time your home is vacant.',
  'plan.which-plan.section-subtitle.plural':
    'You can always change this later, and again each time one of your homes become vacant.',
  'plan.selector.guaranteed': 'Guaranteed Monthly',
  'plan.selector.guaranteed.subLabel': '{guaranteedManagementFee}% Management Fee',
  'plan.selector.upfront': 'Annual Upfront',
  'plan.selector.upfront.subLabel': '{upfrontManagementFee}% Management Fee',
  'plan.lets-discuss.label': "Not sure! Let's discuss it.",
  'plan.tax-deductible.text': 'Both Tax Deductible',
  'plan.tax-deductible.compare': 'Compare Plans',

  // Ownership page
  // 'Ownership.tips.title': 'Change of Address?',
  'Ownership.tips.description':
    'A growing form of identity theft involves impersonating a homeowner to collect rental income. To help combat this, later, we’ll verify the identity of each individual on title we’re <span>working with.</span>',
  'ownership.title': 'Of those who own the home, who will we primarily be working with?',
  'ownership.title.plural': 'Of those who own the homes, who will we primarily be working with?',
  'ownership.subtitle': 'Add a party on title who will be making decisions with Belong.',
  'ownership.another-individual.section-title': 'Let’s meet them!',
  'ownership.another-individual.section-subtitle':
    "We'll reach out to get some information and have them sign an agreement.",
  'ownership.selector.me': 'Me',
  'ownership.selector.me.sublabel': '(as an individual)',
  'ownership.selector.another-individual': 'Another Individual',
  'ownership.selector.entity': 'Entity or Trust',
  'ownership.first-name': 'First Name',
  'ownership.last-name': 'Last Name',
  'ownership.email': 'Email',
  'ownership.phone': 'Phone',
  'ownership.entity.business-name': 'Business Name',
  'ownership.entity.self-managed': 'I manage this entity',
  'ownership.entity.who-is.title': 'Who is?',
  'ownership.email.error': 'Cannot be same as your email',

  // Payment page
  // 'Payment.tips.title': 'Change of Address?',
  'Payment.tips.description':
    'Later, we’ll ask some additional information about this individual or entity to prepare tax forms come <span>tax season.</span>',
  'payment.title': 'One last thing... who should we make the checks out to?',
  'payment.subtitle': 'This will also be the primary point of contact when it comes to decision-making.',
  'payment.selector.me': 'Me',
  'payment.selector.me.sublabel': '(as an individual)',
  'payment.selector.another-individual': 'Another Individual',

  //Sign step
  // 'Sign.tips.title': '',
  // TODO: Regions: Check it
  'Sign.tips.description': {
    [US_STATES.CA
      .code]: `Belong is licensed by the California Department of Real Estate (DRE License #02082345). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.TX
      .code]: `Belong is licensed by the Texas Department of Real Estate (DRE License #9013886). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.UT
      .code]: `Belong is licensed by the Utah Department of Real Estate (DRE License #13538686-CN00). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.WA
      .code]: `Belong is licensed by the Washington State Department of Licensing (License #21028494). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.FL
      .code]: `Belong is licensed by the Florida Department of Licensing (License #CQ1065529). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.NC
      .code]: `Belong is licensed by the North Carolina Real Estate Commission (License #C38631). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.GA
      .code]: `Belong is licensed by the Georgia Real Estate Commission (License #81255). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    [US_STATES.SC
      .code]: `Belong is licensed by the South Carolina Real Estate Commission (License #27097). Our standard rental agreement lays out responsibilities and protections for both you as a homeowner and the renter.`,
    'WA-notarize':
      'Leases longer than 12 months must be notarized in the state of Washington. We partner with Notarize to make this seamless. Every adult needs to sign in a timely manner to ensure you get the home.',
  },
  'sign.title': `You’re one step away from\u00a0being a Belong\u00a0homeowner!`,
  'sign.subtitle': `We just need to get some legal stuff out of the way first...`,
  'sign.summary.title': 'Here’s a quick summary of our agreement below:',
  'sign.summary.first': 'This management agreement is standard to the industry.',
  'sign.summary.second': "It's free of charge until we agree on and perform repairs or manage residents in the home.",
  'sign.summary.third': 'It includes our unique rent guarantee.',
  'sign.summary.fourth': 'It covers $15,000 in eviction protection.',
  'sign.summary.fifth': 'It includes approval for any maintenance over ${threshold}.',
  'sign.download': 'Download PDF',
  'sign.download.addendums': 'Download All',
  'sign.checkbox': 'I agree to electronically sign <span>this agreement.</span>',
  'sign.CTA': 'Sign To Join Belong',
  'sign.checkbox.addendums': 'I agree to electronically sign this agreement and its\u00a0addendums.',

  // Success Page
  'success.home.string': 'home',
  'success.co-signer.signatures-left':
    "We're just waiting for {namesYetToSign} to sign so we can give your {homeString} a whole lot of love.",
  'success.co-signer.all-signed': 'We look forward to giving your {homeString} a whole lot of love.',
  'success.primary.far-off.occupied':
    'Alright, you’re a Belong Homeowner! As we get closer to your move out, we’ll reach out to get you set up. In the meantime, please let us know if anything changes on your end.',
  'success.primary.far-off.vacant':
    'Alright, you’re a Belong Homeowner! As we get closer to your {homeOrHomes} becoming available, we’ll reach out to get you set up. In the meantime, please let us know if anything changes on your end.',
  'success.primary.far-off.mixed':
    'Alright, you’re a Belong Homeowner! As we get closer to your homes becoming available, we’ll reach out to get you set up. In the meantime, please let us know if anything changes on your end.',
  'success.primary.signatures-left.one': "We'll send an invite to {nameYetToSign} to complete the onboarding process.",
  'success.primary.signatures-left.multiple':
    "We'll send invites to {namesYetToSign} to complete the onboarding process.",
  'success.non-signer.info':
    "As you are not on title, you will no longer see this home in your account. But that doesn't mean we don't look forward to working with you!",
  shareableInfo: {
    bannerTitle: 'Love working with Belong so far?',
    bannerMessage: 'Refer your favorite homeowner. Earn $1,000.',
    shareableMessage:
      'I just started working with Belong, and am loving my experience. Belong takes care of everything, from guaranteeing my rent to providing 24/7 support to me and my resident. If you own a rental home, check them out!',
  },
};
