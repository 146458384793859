import { template } from '@belong/common';
import { ButtonBase, Image, Tabs, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import CurrentSupplyMapContainer from 'containers/CurrentSupplyMapContainer/CurrentSupplyMapContainer';
import { SPACE_TYPES } from 'corecomponents/Space/Space';
import Page from 'layouts/Page/Page';
import PostInspectionContentLayout from 'pages/PostInspectionFlow/PostInspectionContentLayout/PostInspectionContentLayout';
import PropTypes from 'prop-types';
import { LastUpdated, EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import styles from './CurrentSupply.module.css';
import { ComparablesTable } from './comparables-table/comparables-table';

const cx = classNames.bind(styles);

const CurrentSupply = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, onPrevious }) => {
  const { homeListingDetailsModel, details, employeeAssignments } = stepResource || {};
  const { user, employee } = employeeAssignments?.[0];
  const headOfPricingProfile = {
    ...user,
    bannerImageUrl: employee?.bannerImageUrl,
    jobTitle: EMPLOYEE_TITLES.ResidentGrowth,
  };

  let homes = [
    {
      ...homeListingDetailsModel.basicInfo,
      ...homeListingDetailsModel,
      rentEstimate: details?.listing?.estimatedRent,
      current: true,
    },
  ];

  if (details?.relatedListings) {
    homes = [...homes, ...details.relatedListings];
  }

  return (
    <PostInspectionContentLayout
      showCityBackground
      noOverlay
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={POST_INSPECTION_FLOW_STRINGS['map.title']}
      employee={headOfPricingProfile}
    >
      <Page.OffsetGrid ignoreOffset={[SPACE_TYPES.MD, SPACE_TYPES.LG, SPACE_TYPES.XL]}>
        <Tabs defaultIndex={0} hasBlurOnScroll={false}>
          <Tabs.TabList className="flex justify-center mb-sm">
            <Tabs.TabItem index={0} className="max-w-fit">
              Map
            </Tabs.TabItem>
            <Tabs.TabItem index={1} className="max-w-fit">
              List
            </Tabs.TabItem>
          </Tabs.TabList>

          <Tabs.PanelList>
            <Tabs.PanelItem>
              <div className={cx('current-supply-wrapper')}>
                <CurrentSupplyMapContainer homes={homes} />
              </div>
            </Tabs.PanelItem>
            <Tabs.PanelItem>
              <ComparablesTable homes={homes} />
            </Tabs.PanelItem>
          </Tabs.PanelList>
        </Tabs>
      </Page.OffsetGrid>

      <div className={cx('flex justify-end mt-xs mb-xl md:mb-xl')}>
        <Text variant="p1">
          {template(LastUpdated.last_updated_general, {
            date: (
              <BDate time={stepResource.details.relatedListingsLastModifiedOn} formatType={DATE_TYPES.DOTTED_MEDIUM} />
            ),
          })}
        </Text>
      </div>

      <div className="p-sm border border-solid border-green rounded bg-green-translucent-light mb-2xl">
        <div className="flex">
          <Image src="/pricing-flow/icon_lightbulb.svg" alt="" className="w-[16px] h-[24px]" />
          <Text fontWeight="semibold" className="ml-xs mb-sm">
            {POST_INSPECTION_FLOW_STRINGS['pricing.marketing.tip.title']}
          </Text>
        </div>
        <Text>{POST_INSPECTION_FLOW_STRINGS['pricing.marketing.tip.content-paragraph1']}</Text>
      </div>

      <div className="hidden md:flex justify-center">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {POST_INSPECTION_FLOW_STRINGS['map.button']}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
        <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
          <Text fontWeight="semibold" className="text-white">
            Previous
          </Text>
        </ButtonBase>

        <ButtonBase className="h-2xl flex-1 bg-green disabled:bg-gray" onClick={() => onSaveAndNext()}>
          <Text fontWeight="semibold" className="text-white">
            Next
          </Text>
        </ButtonBase>
      </div>
    </PostInspectionContentLayout>
  );
};

CurrentSupply.propTypes = {
  stepResource: PropTypes.object.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

export default CurrentSupply;
