import UserBasicInfo from 'models/common/UserBasicInfo';

export default class AssessmentInfo {
  constructor(data = {}) {
    this.assessedBy = new UserBasicInfo(data.assessedBy);
    this.assessedOn = data.assessedOn;
    this.assessmentResult = data.assessmentResult;
    this.assessmentNotes = data.assessmentNotes;
    this.cost = data.cost;
  }
}
