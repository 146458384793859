import { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useWindowSize, COHERE_CONFIG, PATHS } from '@belong/common';
import { BREAKPOINTS_WIDTHS } from '@belong/common/dist/constants/breakpoints';
import { Toast } from '@belong/ui';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import MetaNoIndex from 'components/Metatags/MetaNoIndex';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { FlowLayoutV2 } from 'layouts/FlowLayout/FlowLayoutV2/FlowLayoutV2';
import { find, findIndex, isNil, isEmpty } from 'lodash';
import UserBasicInfo from 'models/common/UserBasicInfo';
import {
  EmployeeAssignmentTypes,
  AgreementFlowDataTypes,
  AgreementStatus,
  AgreementFlowStepNames as stepNames,
} from 'models/enums';
import { getStepByName, STEPS_CONFIGURATION } from 'pages/AgreementFlow/consts';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { BASE_PATHS } from 'routes/paths';
import {
  fetchAgreementFlowById,
  fetchAgreementFlowStep,
  updateAgreementFlowStep,
} from 'store/redux/agreement-flow/actions';
import { fetchAccountProperties, fetchAccountAgreements } from 'store/redux/homeowner-accounts/actions';
import { showModal } from 'store/redux/modals/actions';
import { fetchSettings } from 'store/redux/settings';
import { fetchUserSetPasswordStatus, setUserSession } from 'store/redux/user/actions';
import { selectIsUserLoggedIn, selectUser } from 'store/redux/user/selectors';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { Analytics, PageViewTracker } from '../../analytics';
import { SCREENS } from '../../containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from '../../containercomponents/Modals/modal.consts';
import { STEPS_ROUTE_MAP } from './AgreementFlowSteps/Steps';

export const getStepPath = (mappedStep, flowId) =>
  `${BASE_PATHS.AGREEMENT_FLOW}/${mappedStep.key}/${flowId}/${mappedStep.id}`;

// Animation have a fixed length, so we reset it when we move right enought.
const handleAddStep = (state) => (state + 1) % 9;

const AgreementFlow = ({
  match,
  history,
  location,
  user,
  isUserLoggedIn,
  fetchAgreementFlowByIdAction,
  fetchAgreementFlowStepAction,
  updateAgreementFlowStepAction,
  fetchAccountPropertiesAction,
  fetchAccountAgreementsAction,
  fetchUserSetPasswordStatusAction,
  showModalAction,
  fetchSettingsAction,
  setUserSessionAction,
}) => {
  const [loading, setLoading] = useState(true);
  const [setupFlow, setSetupFlow] = useState(null);
  const [settings, setSettings] = useState(null);
  const [mappedSteps, setMappedSteps] = useState([]);
  const [sidebarTip, setSidebarTip] = useState(null);
  const [makeGreen, setMakeGreen] = useState(false);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [currentProperties, setCurrentProperties] = useState(null);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  // Steps can disable it not animate the text change in tips
  const [animateTextChange, setAnimateTextChange] = useState(true);
  const { isEnabled } = COHERE_CONFIG.homeowner;
  // This controls the ballong animation on the sidebar, moves to the right on every next
  const [animatedStep, setAnimatedStep] = useReducer(handleAddStep, 0);
  const [currentStepState, setCurrentStepState] = useState({
    currentMappedStep: null,
    currentStepFormData: null,
  });
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const { stepId, step, flowId } = match?.params || {};

  const HeaderLinks = [
    <ContactUs key="ContactUs" disableChat />,
    <RouterHeaderNavLink key="save" label="SAVE & EXIT" to={BASE_PATHS.ACCOUNTS} />,
  ];

  const checkIfCurrentUserIsPrimaryOwner = () => {
    const primaryUserOnAgreement = currentAgreement?.agreementUsers?.find(
      (au) => au?.agreementUser?.isPrimaryOwner === true
    );
    return user.email === primaryUserOnAgreement?.user?.email;
  };

  const navigateToSuccess = async () => {
    let agreements;
    try {
      agreements = await fetchAccountAgreementsAction();
    } catch (err) {
      console.error(err);
    }
    const agreementStep = mappedSteps.find((ms) => ms.dataType === AgreementFlowDataTypes.Agreement);
    const currentAgreementObject = agreements?.find((a) => a?.agreement?.uniqueId === agreementStep.dataId);

    const agreementSigned = currentAgreementObject?.agreement?.status === AgreementStatus.Signed;
    const currentUserIsPrimaryUser = checkIfCurrentUserIsPrimaryOwner();

    const shouldRedirectToSetupFlow = agreementSigned && currentUserIsPrimaryUser;

    const currentProperty = currentProperties?.find((property) => {
      return currentAgreementObject?.homeAgreements?.find((homeAgreement) =>
        property.units.some((unit) => unit.basicInfo.unitId === homeAgreement.homeId)
      );
    });

    // If property missing or true, must show set password modal.
    if (user?.userMustSetPassword !== false) {
      showModalAction(MODALS.LOGIN, {
        user,
        currentScreen: SCREENS.SET_PASSWORD_AFTER_AGREEMENT,
        closeButton: false,
        closable: false,
        onSucessfulLogin: async () => {
          if (shouldRedirectToSetupFlow && currentProperty?.basicInfo.propertyId) {
            history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${currentProperty?.basicInfo.propertyId}`);
            return;
          }

          history.push(`${STEPS_ROUTE_MAP.SUCCESS.path}/${flowId}`);
        },
      });
      return;
    }

    if (shouldRedirectToSetupFlow && currentProperty?.basicInfo.propertyId) {
      history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${currentProperty?.basicInfo.propertyId}`);
      return;
    }

    history.push(`${STEPS_ROUTE_MAP.SUCCESS.path}/${flowId}`);
  };

  const handleNext = () => {
    const currentStepBasedStepId = find(mappedSteps, { id: stepId });
    const currentStepIndex = findIndex(STEPS_CONFIGURATION, { name: currentStepBasedStepId?.name });

    if (!mappedSteps?.length) return;

    if (currentStepIndex < STEPS_CONFIGURATION.length - 1) {
      let stepIncrement = 1;

      while (currentStepIndex + stepIncrement < STEPS_CONFIGURATION.length) {
        const stepConfig = STEPS_CONFIGURATION[currentStepIndex + stepIncrement];
        const currentStep = find(mappedSteps, {
          stepName: stepConfig?.name,
        });

        if (currentStep?.isHidden) {
          stepIncrement += 1;
        } else {
          history.replace(getStepPath(currentStep, flowId));
          setAnimatedStep();
          return;
        }
      }

      if (currentStepIndex + stepIncrement === STEPS_CONFIGURATION.length) {
        navigateToSuccess();
        setAnimatedStep();
        return;
      }
    } else {
      navigateToSuccess();
    }
    setAnimatedStep();
    setSidebarTip(null);
  };

  const isLastStep = () => {
    const { currentMappedStep } = currentStepState;
    const filteredStepsConfig = mappedSteps.filter((ms) => {
      return !ms.isHidden;
    });
    const index = findIndex(filteredStepsConfig, { name: currentMappedStep.name });
    return index === filteredStepsConfig.length - 1;
  };

  const handleSave = async (values) => {
    try {
      // Added because errors are getting swallowed and it's almost impossible to debug without this
      setLoading(true);
      setIsErrorToastVisible(false);

      const { currentMappedStep } = currentStepState;

      try {
        await updateAgreementFlowStepAction(setupFlow.id, currentMappedStep.key, stepId, values);
      } catch (error) {
        console.error(error);

        if (error?.[0]?.errorCode === 'INVALID_ADDRESS') {
          setIsErrorToastVisible(true);
          setLoading(false);
          setErrorText(error?.[0]?.message);

          return;
        }
      }
      // const setUpFlow = await fetchAgreementFlowByIdAction(flowId);

      const currentStepIndex = findIndex(STEPS_CONFIGURATION, { name: currentMappedStep.name });

      if (currentStepIndex < STEPS_CONFIGURATION.length - 1) {
        let stepIncrement = 1;

        while (currentStepIndex + stepIncrement < STEPS_CONFIGURATION.length) {
          const stepConfig = STEPS_CONFIGURATION[currentStepIndex + stepIncrement];
          const currentStep = find(mappedSteps, {
            stepName: stepConfig.name,
          });

          if (currentStep.isHidden) {
            stepIncrement += 1;
          } else {
            history.push(getStepPath(currentStep, flowId));
            setAnimatedStep();
            return;
          }
        }

        if (currentStepIndex + stepIncrement === STEPS_CONFIGURATION.length) {
          navigateToSuccess();
          setAnimatedStep();
          return;
        }
      } else {
        navigateToSuccess();
      }

      setAnimatedStep();
      setLoading(false);
    } catch (e) {
      history.push(PATHS.SUPPORT);
      console.error(e);
    }
  };

  const setCurrentStep = async (currentFlow, steps) => {
    if (stepId) {
      const currentStep = find(steps, { id: stepId });
      let currentStepFormDataResponse;
      try {
        currentStepFormDataResponse = await fetchAgreementFlowStepAction(flowId, currentStep.key, stepId);
      } catch (err) {
        console.error(err);
      }

      setCurrentStepState({
        currentMappedStep: currentStep,
        currentStepFormData: currentStepFormDataResponse || {},
      });
    } else {
      setCurrentStepState({
        currentMappedStep: null,
        currentStepFormData: null,
      });
    }
  };

  const fetchData = async () => {
    let setUpFlow;

    try {
      if (flowId) {
        setUpFlow = await fetchAgreementFlowByIdAction(flowId);
      }
    } catch (err) {
      console.error(err);
    }

    if (isNil(setUpFlow) || isEmpty(setUpFlow)) {
      history.push(BASE_PATHS.ACCOUNTS); // Should it go to 404?
      return;
    }

    let properties;
    let agreements;
    try {
      [{ properties }, agreements] = await Promise.all([
        fetchAccountPropertiesAction(),
        fetchAccountAgreementsAction(),
      ]);
      setCurrentProperties(properties);
    } catch (err) {
      console.error(err);
    }

    const { steps: apiSteps } = setUpFlow;
    let currentAgreementObject;
    let property;

    let steps = apiSteps.map((apiStep) => {
      if (apiStep.dataType === AgreementFlowDataTypes.User) {
        return {
          ...apiStep,
          ...getStepByName(apiStep.stepName),
          data: user,
          rootDataType: AgreementFlowDataTypes.User,
          rootDataId: user.Id,
          rootData: user,
          properties,
          flowId: setUpFlow.id,
        };
      }

      if (apiStep.dataType === AgreementFlowDataTypes.Agreement) {
        currentAgreementObject = agreements?.find((a) => a?.agreement?.uniqueId === apiStep.dataId);

        return {
          ...apiStep,
          ...getStepByName(apiStep.stepName),
          data: user,
          rootDataType: AgreementFlowDataTypes.Agreement,
          rootDataId: currentAgreementObject?.uniqueId,
          rootData: currentAgreementObject,
          properties,
          flowId: setUpFlow.id,
        };
      }

      return {
        ...apiStep,
        flowId: setUpFlow.id,
      };
    });

    if (currentAgreementObject) {
      const homeIds = currentAgreementObject.homeAgreements?.map((h) => h.homeId);
      property = properties?.find((p) => p.units.find((u) => homeIds.includes(u.basicInfo.unitId)));
      steps = steps.map((s) => {
        return { ...s, property };
      });
    }

    try {
      const settingsObject = await fetchSettingsAction();
      setSettings(settingsObject);
    } catch (err) {
      console.error(err);
    }

    setSetupFlow(setUpFlow);
    setMappedSteps(steps);
    setCurrentAgreement(currentAgreementObject);
    await setCurrentStep(setUpFlow, steps);
  };

  const isValidUrl = () => {
    if (!step && !flowId && !stepId) {
      return true;
    }

    // When navigating to the success page
    if (step === 'success' && flowId && !stepId) {
      return true;
    }

    const [stepBasedOnStepId] = mappedSteps.filter((ms) => ms.id === stepId);

    if (!stepBasedOnStepId) {
      return false;
    }

    const { key, id } = stepBasedOnStepId;

    if (key === step && id === stepId) {
      return true;
    }

    return false;
  };

  const handleToastClose = () => {
    setIsErrorToastVisible(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      // If session is invalid, is either user is not logged in, or passwordless acount.
      if (!isUserLoggedIn) {
        let query;
        try {
          const queryParams = queryString.parse(location.search);
          const decodedQueryString = window.atob(queryParams?.data);
          query = queryString.parse(decodedQueryString);
        } catch (e) {
          console.error(e);
        }

        let passwordSet = true;
        try {
          const userSetPassword = await fetchUserSetPasswordStatusAction(query?.uniqueId);
          passwordSet = userSetPassword?.passwordSet;
        } catch (err) {
          console.error(err);
        }

        // Allow users without set password to continue.
        if (!passwordSet) {
          try {
            await setUserSessionAction({ user: query });
          } catch (err) {
            console.error(err);
          }

          history.push(location.pathname);
          setLoading(false);
        } else {
          showModalAction(MODALS.LOGIN, {
            currentScreen: SCREENS.LOGIN_SCREEN,
            redirectToHomeOnHide: true,
            email: query?.email,
            onSucessfulLogin: async () => {
              history.push(location.pathname);
              setLoading(false);
            },
          });
        }
      } else {
        try {
          await fetchData();
        } catch (err) {
          console.error(err);
        }

        setAnimateTextChange(true);
        setSidebarTip(null);
        setMakeGreen(false);
        setLoading(false);
      }
    })();
  }, [stepId, isUserLoggedIn]);

  useEffect(() => {
    if (mappedSteps?.length) {
      const currentStep = find(mappedSteps, { id: stepId });
      if (currentStep?.isHidden) {
        handleNext();
      }
    }
  }, [mappedSteps]);

  const { currentMappedStep, currentStepFormData } = currentStepState;

  if (!(loading || (stepId && !currentStepFormData))) {
    if (!isValidUrl()) {
      return <Redirect to={BASE_PATHS.ACCOUNTS} />;
    }
  }

  const getSidebarTitleText = () => {
    const stepName = currentStepState?.currentMappedStep?.stepName;

    if (sidebarTip) {
      return sidebarTip.title;
    }

    if (stepName) {
      return AGREEMENT_FLOW_STRINGS[`${stepName}.tips.title`];
    }

    return '';
  };

  const getSidebarDescriptions = () => {
    const stepName = currentStepState?.currentMappedStep?.stepName;

    if (sidebarTip) {
      return sidebarTip.descriptions;
    }

    if (stepName) {
      const description = AGREEMENT_FLOW_STRINGS[`${stepName}.tips.description`];

      if (stepName === stepNames.Sign) {
        const agreementHomeIds = currentAgreement?.homeAgreements?.map((homeAgreement) => homeAgreement.homeId);
        const anyAgreementHome = currentProperties?.find((property) => {
          return property.units.some((unit) => {
            return agreementHomeIds.includes(unit.basicInfo.unitId);
          });
        });

        return description[anyAgreementHome?.address?.state] || description.california;
      }

      return description;
    }

    return [];
  };

  let employeeAssignment = currentMappedStep?.properties[0]?.units[0]?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const defaultHomeownerGrowth = getDefaultHomeownerGrowth(currentMappedStep?.property?.basicInfo?.coverageRegionId);
  if (!employeeAssignment) {
    employeeAssignment = defaultHomeownerGrowth;
  } else {
    employeeAssignment.employee.phone = defaultHomeownerGrowth.employee.phone;
  }

  if (step === 'success') {
    history.push(BASE_PATHS.ACCOUNTS);
  }
  const progressItems = mappedSteps
    .filter((mappedStep) => !mappedStep.isHidden)
    .map(({ stepName, displayName, key, id }) => {
      return {
        active: stepName === currentMappedStep?.stepName,
        stepName,
        label: displayName,
        key,
        id,
      };
    });

  const onProgressStepClick = (mappedStep) => history.push(getStepPath(mappedStep, flowId));

  return (
    <>
      <MetaNoIndex />
      <FlowLayoutV2
        location={location}
        loading={loading || (stepId && !currentStepFormData)}
        sidebarProps={{
          step: animatedStep,
          makeGreen,
          animateTextChange: animateTextChange && !loading,
        }}
        headerMainProps={{ disableLogo: false }}
        navigationComponents={HeaderLinks}
        tip={{
          title: getSidebarTitleText(),
          description: getSidebarDescriptions(),
        }}
        steps={Object.values(STEPS_ROUTE_MAP)}
        stepProps={{
          onNext: handleNext,
          onSubmit: handleSave,
          setLoading,
          mappedSteps,
          currentMappedStep,
          isLastStep: currentMappedStep ? isLastStep() : false,
          currentStepFormData: stepId === currentMappedStep?.id ? currentStepFormData : null,
          setupFlow,
          history,
          setSidebarTip,
          setMakeGreen,
          settings,
          setAnimateTextChange,
        }}
        employeeAssignment={employeeAssignment}
        showProgressBar
        progressItems={progressItems}
        onlyActiveLabel={false}
        withBalloon={false}
        onProgressStepSelection={onProgressStepClick}
      />
      <ChatWidget
        tags={['Page: Agreement flow']}
        shouldShow={step !== 'success'}
        isEnabled={isEnabled}
        showOnNavbar={isMobile}
      />
      <Toast isVisible={isErrorToastVisible} onClose={handleToastClose} variant="danger">
        {errorText}
      </Toast>
    </>
  );
};

AgreementFlow.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  fetchAgreementFlowByIdAction: PropTypes.func.isRequired,
  fetchAgreementFlowStepAction: PropTypes.func.isRequired,
  fetchAccountPropertiesAction: PropTypes.func.isRequired,
  fetchAccountAgreementsAction: PropTypes.func.isRequired,
  updateAgreementFlowStepAction: PropTypes.func.isRequired,
  fetchUserSetPasswordStatusAction: PropTypes.func.isRequired,
  fetchSettingsAction: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserBasicInfo),
  showModalAction: PropTypes.func.isRequired,
  setUserSessionAction: PropTypes.func.isRequired,
};

AgreementFlow.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  isUserLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = {
  fetchAgreementFlowByIdAction: fetchAgreementFlowById,
  fetchAgreementFlowStepAction: fetchAgreementFlowStep,
  fetchAccountPropertiesAction: fetchAccountProperties,
  fetchAccountAgreementsAction: fetchAccountAgreements,
  updateAgreementFlowStepAction: updateAgreementFlowStep,
  fetchSettingsAction: fetchSettings,
  fetchUserSetPasswordStatusAction: fetchUserSetPasswordStatus,
  showModalAction: showModal,
  setUserSessionAction: setUserSession,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Analytics(({ match }) => ({ screen: match?.params?.step })),
  PageViewTracker
)(AgreementFlow);
