import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { COOKIES_CONFIG } from '@belong/common';
import { Button } from '@belong/ui';
import String from 'components/String/String';
import config from 'config/config';
import { BookAProButton } from 'maintenance/components/book-a-pro-button/book-a-pro-button';
import { TaskList } from 'maintenance/components/task-list/task-list';
import { parseCookies } from 'nookies';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import { PATHS } from 'routes/paths';
import { fetchResidentMaintenance } from 'store/redux/maintenance/actions';
import { RENTER_ACCOUNTS_STRINGS } from 'strings/renter-accounts.strings';

const RAC = RENTER_ACCOUNTS_STRINGS.maintenance;
const { JWT_TOKEN } = COOKIES_CONFIG;

export function ResidentMaintenance() {
  const cookies = parseCookies();
  const jwtToken = cookies[JWT_TOKEN.name];
  const dispatch = useDispatch();
  const history = useHistory();

  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const response = await dispatch(fetchResidentMaintenance());
      setTasks(response);
    };
    setIsLoading(true);

    try {
      fetchMaintenanceDetails();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }, [dispatch]);

  const showMaintenanceModal = (task) => () => {
    history.push(`${PATHS.RESIDENTS_ACCOUNT_MAINTENANCE}/${task.uniqueId}`);
  };

  return (
    <>
      <AccountPage
        loading={isLoading}
        headerProps={{
          headerText: RAC.title,
          subtitle: <String string={RAC.subtitle} />,
        }}
        cta={
          <div className="hidden sm:flex items-center justify-end">
            <Button
              size="auto"
              className="px-md"
              onClick={() => window.open(`${config.smsAssistUrl}${jwtToken}`, '_blank')}
            >
              {RAC.createRequest}
            </Button>
          </div>
        }
      >
        <div className="mb-6xl">
          <TaskList showMaintenanceModal={showMaintenanceModal} tasks={tasks} type="non-recurring" view="resident" />
        </div>

        <BookAProButton onClick={() => window.open(`${config.smsAssistUrl}${jwtToken}`, '_blank')} />
      </AccountPage>
    </>
  );
}
