import { COOKIES_CONFIG } from '@belong/common';
import { Text, Button } from '@belong/ui';
import DottedMenu, { STYLED_MENUS } from 'components/DottedMenu/DottedMenu';
import config from 'config/config';
import { DEFAULT_LINKS } from 'consts/links';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';

const { JWT_TOKEN } = COOKIES_CONFIG;

export const MaintenanceStatusFooter = ({ menuActions, path, isRenter = false }) => {
  function onEdit() {
    const cookies = parseCookies();
    const jwtToken = cookies[JWT_TOKEN.name];

    window.open(`${config.smsAssistUrl}${jwtToken}`, '_blank');
  }

  return (
    <div className="flex align-center justify-end my-xl py-xl border-0 border-t border-solid border-gray">
      <LinkBase href={path}>
        <Text className="text-navy" fontWeight="semibold">
          {DEFAULT_LINKS.GET_HELP.label}
        </Text>
      </LinkBase>
      {isRenter && (
        <Button variant="text" onClick={onEdit} className="ml-sm">
          EDIT
        </Button>
      )}
      {!!menuActions && !isRenter && (
        <div className="ml-lg">
          <DottedMenu
            className="px-0 py-2sm cursor-default"
            style={STYLED_MENUS.NO_BORDER_BETWEEN}
            menuItems={menuActions}
            menuButtonLabel={
              <div>
                <div>MORE</div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

MaintenanceStatusFooter.propTypes = {
  menuActions: PropTypes.array,
  path: PropTypes.string.isRequired,
  isRenter: PropTypes.bool,
};
MaintenanceStatusFooter.defaultProps = { menuActions: null };
