// React imports
import { FieldArray } from 'react-final-form-arrays';
// Component imports
import { AutoCompleteFinalFormAdapter } from 'components/AutoComplete/AutoComplete';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
// Final form imports
import Field from 'components/Field/Field';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
// Bootstrap imports
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { HOME_OWNER_PAPER_WORK_STRINGS } from 'strings/homeowner-paperwork.strings';
// Util, const, string imports
import { required } from 'utils/validation';
import { listOfFurnitures } from './listOfFurnitures';

export function FurnituresList({ name, inputComponent, checkboxComponent, form }) {
  return (
    <FieldArray name={`${name}`}>
      {({ fields }) =>
        fields.map((fieldName, fieldIndex) => (
          <div key={`${fieldName}.${fieldIndex}`}>
            <Row className="flex items-end">
              <Col md={7}>
                <Field
                  name={`${fieldName}.item`}
                  allowUserInput
                  hideAutoCompleteIconOnMobile
                  openMenuOnFocus
                  component={AutoCompleteFinalFormAdapter}
                  items={listOfFurnitures}
                  validate={required}
                  placeholder={HOME_OWNER_PAPER_WORK_STRINGS['homeFurnitures.furnitures.item']}
                  {...inputComponent}
                />
              </Col>
              <Col md={5}>
                <Field
                  name={`${fieldName}.isValuable`}
                  useOnChangeFormToHandle
                  component={CheckboxFinalFormAdapter}
                  label={HOME_OWNER_PAPER_WORK_STRINGS['homeFurnitures.furnitures.valuable']}
                  marginOnDesktop
                  {...checkboxComponent}
                />
              </Col>
              {fields.length > 1 && (
                <Col md={1} className="text-right">
                  <IconButton
                    align={ALIGN_TYPES.RIGHT}
                    icon={GENERAL_ICONS.TRASH}
                    onClick={() => {
                      if (fieldIndex === fields.length - 1) {
                        fields.remove(fieldIndex);
                      } else {
                        const getNextIndexValue = cloneDeep(fields.value[fieldIndex + 1]);
                        fields.remove(fieldIndex + 1);
                        fields.update(fieldIndex, getNextIndexValue);
                      }
                    }}
                  />
                </Col>
              )}
            </Row>
            <div className="mt-sm flex justify-end">
              {fieldIndex === fields.length - 1 && (
                <IconButton
                  align={ALIGN_TYPES.RIGHT}
                  icon={GENERAL_ICONS.PLUS}
                  onClick={() => form.mutators.push(`${name}`, {})}
                >
                  ADD ANOTHER
                </IconButton>
              )}
            </div>
          </div>
        ))
      }
    </FieldArray>
  );
}

FurnituresList.propTypes = {
  name: PropTypes.string,
  form: PropTypes.object.isRequired,
  checkboxComponent: PropTypes.object,
  inputComponent: PropTypes.object,
};

FurnituresList.defaultProps = {
  name: '',
  checkboxComponent: {},
  inputComponent: {},
};

export default FurnituresList;
