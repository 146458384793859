import { useState } from 'react';
import { MoneyRange, Money } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import Collapsible from 'components/Collapsible/Collapsible';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import Tooltip from 'components/Tooltip/Tooltip';
import { BackedByBelong } from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay';
import styles from 'containercomponents/MaintenanceStatus/ModalGroupMaintenance/ModalGroupMaintenance.module.css';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';
import { USERACTIONMAINTENANCE_TYPES } from 'containercomponents/MaintenanceStatus/UserActionMaintenance/userActionMaintenanceTypes';
import { SplitInfoBox } from 'containercomponents/MaintenanceStatus/split-infobox/split-infobox';
import GetInfoModal from 'containercomponents/Modals/GetInfoModal/GetInfoModal';
import Space from 'corecomponents/Space/Space';
import { Flex, Box, Collapsible as CollapsibleV2, Text } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import { find, flatten } from 'lodash';
import {
  EmployeeAssignmentTypes,
  MaintenanceRequester,
  FeatureOwner,
  MaintenanceResponsibility,
  ConsentStatus,
  MaintenanceStatusDisplay,
} from 'models/enums';
import ImprovementCard from 'pages/PostInspectionFlow/ImprovementCard/ImprovementCard';
import ImprovementModal from 'pages/PostInspectionFlow/ImprovementModal/ImprovementModal';
import { SharedResponsibilityLabel, ResidentResponsibilityLabel } from 'pages/PostInspectionFlow/Labels/Labels';
import { COSTS_CONFIG } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/costs.utils';
import PriceTable, {
  getHomeownerBundlePriceSection,
} from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import { ResponsibilityContent } from 'pages/PostInspectionFlow/steps/Improvements/Responsiblity/Responsiblity';
import { getPaymentBreakdown, getPayments } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';

const cx = classNames.bind(styles);

const GroupEmployeeAssignments = ({ report, task, listingsData, propertiesData, inspectionData }) => {
  // const assessor = report?.employeeAssignments?.find(
  //   (ea) => ea.employeeAssignment?.assignmentType === EmployeeAssignmentTypes.ImprovementsAssessor
  // );
  const reportDetails = report?.report || {};
  const maintenancePayment = report.details?.group?.payments?.find((p) => p.paidBy === MaintenanceRequester.Homeowner);
  const homeowner = task?.homeowner;
  const approved =
    maintenancePayment?.consentStatus === ConsentStatus.Approved && reportDetails.completedOn && homeowner;

  const listing = find(
    listingsData,
    (listingDetail) => listingDetail.homeListingDetailsModel.basicInfo.unitId === report.report.homeId
  );
  const inspectedBy = inspectionData?.inspectedBy || listing?.homeListingDetailsModel?.inspectedBy;

  let foundUnit;
  // eslint-disable-next-line no-unused-expressions
  propertiesData?.properties?.forEach((p) => {
    const unit = p.units.find((u) => u.basicInfo.unitId === report.report.homeId);
    if (unit) {
      foundUnit = unit;
    }
  });

  const concierge = foundUnit?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.Concierge
  );

  const homeownerGrowth = foundUnit?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const assessor = concierge ?? homeownerGrowth;
  const assessed = assessor && reportDetails.publishedOn;

  return (
    <>
      <h3>Summary</h3>
      {inspectedBy?.userId && (
        <UserActionMaintenance
          userId={inspectedBy.userId}
          leftPadding
          withSpace={false}
          taskType={USERACTIONMAINTENANCE_TYPES.REPORTED}
          time={task.createdOn}
          name={`${inspectedBy.firstName} ${inspectedBy.lastName}`}
          profileImage={inspectedBy.profileImageUrl}
          reportedBy="Inspector Extraordinaire"
          belongEmployee
        />
      )}

      {assessed && (
        <UserActionMaintenance
          userId={assessor.user.userId}
          leftPadding
          withSpace={false}
          taskType={USERACTIONMAINTENANCE_TYPES.ASSESSED}
          time={reportDetails.publishedOn}
          name={assessor.user.name}
          profileImage={assessor.user.profileImageUrl}
          reportedBy={EMPLOYEE_TITLES[assessor.employeeAssignment.assignmentType]}
          belongEmployee
        />
      )}

      {approved && (
        <UserActionMaintenance
          userId={homeowner.userId}
          leftPadding
          reportedBy="Homeowner"
          withSpace={false}
          taskType={USERACTIONMAINTENANCE_TYPES.APPROVED}
          time={reportDetails.completedOn}
          profileImage={homeowner.profileImageUrl}
          name="You"
        />
      )}
    </>
  );
};

const GroupImprovementCards = ({ report, groupTask, listingsData, hasAppointments, isRenter }) => {
  const [showAll, setShowAll] = useState(false);
  const [currentImprovement, setCurrentImprovement] = useState(null);
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const isHomeowner = !isRenter;

  const listing = find(
    listingsData,
    (listingDetail) => listingDetail.homeListingDetailsModel.basicInfo.unitId === report.report.homeId
  );

  let bundles;
  if (isRenter) {
    bundles =
      groupTask?.subtasks.filter((x) => {
        const breakdown = getPayments(x.items[0].maintenancePayments);
        return breakdown.resident?.percentage > 0;
      }) || [];
  } else {
    bundles = report.details?.bundles || [];
  }
  const { spaces = [] } = listing?.homeListingDetailsModel || {};
  const flatBundles = flatten(
    bundles.reduce((acc, bundle) => {
      acc.push(bundle.items);
      return acc;
    }, [])
  );

  const isInvoiced =
    !!groupTask?.requestTypeDetails?.hasPublishedInvoices ||
    !!report.details?.group?.requestTypeDetails?.hasPublishedInvoices;

  const groupPayment = groupTask?.payments || [];

  const improvementsGroupedByRoomOrSpace = flatBundles.reduce((acc, item) => {
    const { maintenance, ...rest } = item;
    const improvement = { ...maintenance, ...rest };
    let roomOrSpace;
    if (improvement?.ownerType === FeatureOwner.Space) {
      roomOrSpace = spaces.find((space) => space.id === improvement.ownerId);
    }

    const { type } = roomOrSpace || { type: 'Around The Home' };
    if (acc[type]) {
      acc[type].push(improvement);
    } else {
      acc[type] = [improvement];
    }

    return acc;
  }, {});

  const sortedGroupedImprovements = Object.entries(improvementsGroupedByRoomOrSpace).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  });

  let flattenedItems = [];
  sortedGroupedImprovements.forEach(([key, roomOrSpace]) => {
    roomOrSpace.forEach((r) => {
      r.key = key;
    });
    flattenedItems = [...flattenedItems, ...roomOrSpace];
  });

  if (isInvoiced) {
    // show only items approved by homeowner / resident
    flattenedItems = flattenedItems.filter((x) => {
      const homeownerPayment = x.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
      const residentPayment = x.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Resident);
      return (
        homeownerPayment?.consentStatus === ConsentStatus.Approved ||
        residentPayment?.consentStatus === ConsentStatus.Approved
      );
    });
  }

  const filteredItems = showAll ? flattenedItems : flattenedItems.slice(0, 4);
  const hasMore = Math.max(flattenedItems.length - filteredItems.length, 0);

  const renderStatusTag = (maintenance, defaultTagType) => {
    const homeownerPayment = maintenance.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
    let status;
    let tag = '';
    if (homeownerPayment?.consentStatus === ConsentStatus.Rejected) {
      status = 'Not Approved';
      tag = 'rejected';
    } else if (maintenance.proServiceResponsibility === MaintenanceResponsibility.Homeowner) {
      if (maintenance.status !== 'Closed') {
        status = 'Closed';
        tag = '';
      } else {
        status = 'Verification Required';
        tag = 'navy';
      }
    } else if (hasAppointments) {
      status = maintenance.status === 'Closed' ? MaintenanceStatusDisplay.Closed : MaintenanceStatusDisplay.InProgress;
      tag = maintenance.status !== 'Closed' ? defaultTagType || 'progress' : '';
    } else {
      status = MaintenanceStatusDisplay[maintenance.status];
      tag = maintenance.status !== 'Closed' ? defaultTagType || 'progress' : '';
    }

    return <div className={cx('top-left-tag', tag)}>{status}</div>;
  };

  const renderMaintenanceTag = (maintenance) => {
    const homeownerPayment = maintenance.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
    const residentPayment = maintenance.payments?.find((payment) => payment.paidBy === MaintenanceRequester.Resident);
    const sharedResponsiblity = residentPayment?.percentage > 0 && homeownerPayment?.percentage > 0;
    const residentResponsiblity = residentPayment?.percentage === 100;

    return (
      <>
        {renderStatusTag(maintenance)}
        {sharedResponsiblity && <SharedResponsibilityLabel isHomeowner={isHomeowner} />}
        {isHomeowner && residentResponsiblity && <ResidentResponsibilityLabel isHomeowner={isHomeowner} />}
        <Box marginTop="2xs">
          <Text className={cx('image-text')} fontWeight="semibold">
            {maintenance.summary}
          </Text>
        </Box>
      </>
    );
  };
  return (
    <>
      <Text className="mt-xl" as="p" fontWeight="semibold" fontSize="h3">
        Related Issues
      </Text>
      <Row>
        {filteredItems.map(
          (
            {
              estimatedCostBreakdown,
              estimatedDisplayCost,
              estimatedDisplayDiscount,
              estimatedDisplayDiscountedCost,
              estimatedDisplayCostForAllParties,
              actualCostBreakdown,
              actualDisplayCost,
              actualDisplayDiscount,
              actualDisplayDiscountedCost,
              actualDisplayCostForAllParties,
              isTBD,
              ...item
            },
            i
          ) => {
            const fullImprovement = {
              maintenance: { ...item },
              maintenancePayments: item.payments,
              roomOrSpace: item.key,
              estimatedCostBreakdown,
              estimatedDisplayCost,
              estimatedDisplayDiscount,
              estimatedDisplayDiscountedCost,
              estimatedDisplayCostForAllParties,
              actualCostBreakdown,
              actualDisplayCost,
              actualDisplayDiscount,
              actualDisplayDiscountedCost,
              actualDisplayCostForAllParties,
              isTBD,
            };

            return (
              <Col key={i} sm={6} style={{ marginTop: 10, marginBottom: 10 }}>
                <ImprovementCard
                  height={180}
                  onClick={() => {
                    setModalOpen();
                    setCurrentImprovement(fullImprovement);
                  }}
                  customTopLeft={renderMaintenanceTag}
                  improvement={fullImprovement}
                  context="pro-service"
                />
              </Col>
            );
          }
        )}
      </Row>
      {!!hasMore && (
        <div onClick={() => setShowAll(true)} className={cx('load-more')}>
          + {hasMore} MORE
        </div>
      )}
      {!hasMore && filteredItems.length > 4 && (
        <div onClick={() => setShowAll(false)} className={cx('load-more')}>
          SHOW LESS
        </div>
      )}
      <Space />
      {currentImprovement && (
        <ImprovementModal
          show={isModalOpen}
          onHide={setModalClose}
          improvement={currentImprovement}
          isHomeowner={isHomeowner}
          groupPayment={groupPayment}
          isInvoiced={isInvoiced}
          extraTags={[renderStatusTag(currentImprovement.maintenance, 'navy')]}
          showPriceBreakdown
        />
      )}
    </>
  );
};

const GroupHomeownerReceiptCosts = ({ groupTask, report }) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const homeownerPayment = report.details?.group?.payments?.find((p) => p.paidBy === MaintenanceRequester.Homeowner);

  if (!homeownerPayment || !groupTask) return null;

  const residentPayment = report.details.group.payments.find((p) => p.paidBy === MaintenanceRequester.Resident);
  const belongPayment = report.details.group.payments.find((p) => p.paidBy === MaintenanceRequester.Belong);

  const isInvoiced = report.details.group.requestTypeDetails.hasPublishedInvoices;
  const { isLegacyInvoicing, financedPayment, result, payments, displayAsPriceRange, priceRange } = groupTask;

  const homeownerRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Homeowner');
  const residentRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Resident');
  const belongRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Belong');

  const showUpfrontAmount = !isInvoiced && homeownerPayment.estimatedUpfrontAmount > 0;

  const isMonthly = homeownerPayment.paymentPlan === 'Monthly';
  const showMonthlyAmount = !isInvoiced && isMonthly && homeownerPayment.installmentCount;

  const bundleSections = getHomeownerBundlePriceSection({
    bundles: report.details.bundles,
    priceRange: displayAsPriceRange ? priceRange : null,
    isInvoiced,
  });

  const groupPayment = payments.find((p) => p.paidBy === MaintenanceRequester.Homeowner);
  const financePlanId = groupPayment?.balances?.find((balance) => !!balance.currentFinanceId)?.currentFinanceId;
  const hasElegibleOpenBalance = !!groupPayment?.balances?.find((balance) => balance.isOpen && balance.isFinanceable);

  let costs;
  if (isInvoiced) {
    costs = {
      subtotal: 'actualAdjustmentApplied',
      discount: 'actualDiscountAmount',
      netTotal: 'actualNetTotal',
    };
  } else {
    costs = {
      subtotal: 'estimatedAdjustmentApplied',
      discount: 'estimatedDiscountAmount',
      netTotal: 'estimatedDiscountApplied',
      installmentEach: 'estimatedInstallmentEach',
    };
  }

  const homeownerSubtotal =
    homeownerPayment[costs.subtotal] +
    (residentPayment?.[costs.subtotal] || 0) +
    (belongPayment?.[costs.subtotal] || 0);

  const tooltip = (
    <Tooltip contentStyle={{ width: 280, textAlign: 'center', border: 'noone' }} className={cx('tooltip')}>
      {homeownerPayment.installmentCount === 1 ? '1% fee ($20 minimum)' : '1% fee/month ($20 minimum)'}
    </Tooltip>
  );

  return (
    <Collapsible
      createMarginAndPaddingClassName={cx('margin-padding')}
      collapsed={false}
      title={isInvoiced ? 'Receipt' : 'Estimated Pricing'}
      inner={
        <div className={cx('content')}>
          <BackedByBelong showModal={isModalOpen} onClick={setModalOpen} onHide={setModalClose} />
          <Space />
          <div className={cx('maintenanceCostDisplay')}>
            <PriceTable nameHeader="Item" valueHeader="Amount" sections={[bundleSections]} />
            <div className={cx('underline')} />
            <NameValueTable
              noBold
              items={[
                {
                  name: 'Subtotal',
                  value: displayAsPriceRange ? (
                    <MoneyRange
                      lower={priceRange.lowerBoundTotal}
                      upper={priceRange.upperBoundTotal}
                      format="DOLLARS_NO_CENTS"
                    />
                  ) : (
                    <Money value={homeownerSubtotal} format="DOLLARS_NO_CENTS" />
                  ),
                },
              ]}
            />

            {/* Deductions */}
            <NameValueTable
              noBold
              discountValue
              items={[
                {
                  name: 'Savings',
                  value: (
                    <>
                      -<Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.discount]} />
                    </>
                  ),
                },
              ]}
            />
            {(residentPayment?.[costs.subtotal] > 0 || residentRange?.upperBound > 0) && (
              <NameValueTable
                noBold
                discountValue
                items={[
                  {
                    name: 'Resident Responsibility',
                    value: displayAsPriceRange ? (
                      <div className="flex">
                        -
                        <MoneyRange
                          lower={residentRange.lowerBoundTotal}
                          upper={residentRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      </div>
                    ) : (
                      <>
                        -<Money value={residentPayment[costs.subtotal]} format="DOLLARS_NO_CENTS" />
                      </>
                    ),
                  },
                ]}
              />
            )}
            {(belongPayment?.[costs.subtotal] > 0 || belongRange?.upperBound > 0) && (
              <NameValueTable
                noBold
                discountValue
                items={[
                  {
                    name: 'Belong Responsibility',
                    value: displayAsPriceRange ? (
                      <div className="flex">
                        -
                        <MoneyRange
                          lower={belongRange.lowerBoundTotal}
                          upper={belongRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      </div>
                    ) : (
                      <>
                        -<Money value={belongPayment[costs.subtotal]} format="DOLLARS_NO_CENTS" />
                      </>
                    ),
                  },
                ]}
              />
            )}

            <div className={cx('underline')} />

            {isLegacyInvoicing ? (
              <>
                <NameValueTable
                  boldValue
                  items={[
                    {
                      name: 'Total',
                      value: <Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.netTotal]} />,
                    },
                  ]}
                />
                {isInvoiced && isMonthly && (
                  <Flex justifyContent="flex-end">
                    <Text variant="body-responsive" mr="xs">
                      (w/ installment fees)
                    </Text>
                    {tooltip}
                  </Flex>
                )}
                {showMonthlyAmount && (
                  <>
                    <div className={cx('underline')} />
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: (
                            <Flex>
                              <Box mr="xs">
                                {formatString(
                                  `With ${homeownerPayment.installmentCount}-Month <span>Payment Plan</span>`
                                )}
                              </Box>
                              {tooltip}
                            </Flex>
                          ),
                          value: (
                            <>
                              <Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.installmentEach]} />
                              /mo.
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                )}
                {showUpfrontAmount && (
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: (
                          <Flex>
                            <Box mr="xs">Due Now (25%)</Box>
                          </Flex>
                        ),
                        value: (
                          <>
                            <Money format="DOLLARS_NO_CENTS" value={homeownerPayment.estimatedUpfrontAmount} />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
              </>
            ) : (
              <>
                <NameValueTable
                  boldValue
                  items={[
                    {
                      name: isInvoiced ? 'Total' : 'Est. Total',
                      value: displayAsPriceRange ? (
                        <MoneyRange
                          lower={homeownerRange.lowerBoundTotal}
                          upper={homeownerRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      ) : (
                        <Money value={homeownerPayment[costs.netTotal]} format="DOLLARS_NO_CENTS" />
                      ),
                    },
                  ]}
                />
                {financedPayment?.dueNow > 0 && !isInvoiced && (
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: (
                          <Flex>
                            <Box mr="xs">Due Upfront</Box>
                          </Flex>
                        ),
                        value: (
                          <>
                            <Money format="DOLLARS_NO_CENTS" value={financedPayment?.dueNow} />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                <div className="mt-sm">
                  <SplitInfoBox
                    financePlanId={financePlanId}
                    hasOpenBalance={hasElegibleOpenBalance}
                    isMaintenanceCompleted={result === 'Completed'}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      }
    />
  );
};

// https://www.figma.com/file/52tR6ruj9iZDZNV6mx3usB/belong_resident_post_moveout_report?node-id=0%3A1
const GroupResidentReceiptCosts = ({ groupTask }) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const [isResponsibilityModalOpen, openResponsibilityModal, closeResponsibilityModal] = useModal();

  if (!groupTask?.subtasks) return null;

  const validBundles = groupTask.subtasks.filter((x) => {
    const breakdown = getPayments(x.items[0].maintenancePayments);
    return breakdown.resident?.percentage > 0;
  });

  const residentPayment = groupTask.payments.find((p) => p.paidBy === MaintenanceRequester.Resident);
  if (!residentPayment) return null;

  const isInvoiced = groupTask.requestTypeDetails?.hasPublishedInvoices;
  let costs;
  if (isInvoiced) {
    costs = {
      bundleCost: 'actualCost',
      lineItemBreakdown: 'actualCostBreakdown',
      netTotal: 'actualDiscountApplied',
    };
  } else {
    costs = {
      bundleCost: 'estimatedCost',
      lineItemBreakdown: 'estimatedCostBreakdown',
      netTotal: 'estimatedDiscountApplied',
    };
  }

  let homeownerRepairTotalAmount = 0;
  return (
    <Collapsible
      createMarginAndPaddingClassName={cx('margin-padding')}
      collapsed={false}
      title={isInvoiced ? 'Receipt' : 'Estimated Pricing'}
      inner={
        <div className={cx('content')}>
          <BackedByBelong showModal={isModalOpen} onClick={setModalOpen} onHide={setModalClose} />
          <Space />
          <div className={cx('maintenanceCostDisplay')}>
            <NameValueTable
              boldValue
              items={[
                {
                  name: 'Item',
                  value: 'Amount',
                },
              ]}
            />

            {validBundles.length > 0 && <div className={cx('underline')} />}

            {validBundles.map((bundle, idx) => {
              const bundleItem = bundle.items[0];
              const itemPaymentsBreakdown = getPayments(bundleItem.maintenancePayments);
              const breakdown = getPaymentBreakdown(bundle);
              const item = bundleItem.maintenance;
              let name = item.summary;
              if (itemPaymentsBreakdown.resident.percentage < 100) {
                name += ` <span>(${itemPaymentsBreakdown.resident.percentage}% Responsibility)</span>`;
              }

              const _isTBD = bundleItem.isTBD && !isInvoiced;

              // TODO: CHANGE THIS AFTER TURNOVERS PHASE 2
              // If homeowner is fixing, we don't create invoices right now for those repair
              // So display the estimated cost.
              const isHomeownerFixing = item.proServiceResponsibility === 'Homeowner';
              const itemCost = isHomeownerFixing ? 'totalEstimatedCost' : costs.bundleCost;
              const itemLineBreakdown = isHomeownerFixing ? 'estimatedCostBreakdown' : costs.lineItemBreakdown;

              if (isHomeownerFixing) homeownerRepairTotalAmount += breakdown.resident[itemCost];

              return (
                <Box mb="xs" key={idx}>
                  <CollapsibleV2
                    // Disable if homeowner is fixing
                    disabled={isHomeownerFixing}
                    showArrow={!isHomeownerFixing}
                    leftContent={<Text variant="body-responsive">{name}</Text>}
                    rightContent={
                      <Box textAlign="right" width={[100, 150, 300]}>
                        <Text variant="body-responsive">
                          {_isTBD ? 'TBD' : <Money format="DOLLARS_NO_CENTS" value={breakdown.resident[itemCost]} />}
                        </Text>
                      </Box>
                    }
                    innerContent={(() => {
                      if (isHomeownerFixing) return null;
                      const lineItems = breakdown.resident[itemLineBreakdown].filter((x) => x.cost > 0);
                      const formattedLineItems = [];
                      COSTS_CONFIG.forEach((config) => {
                        const autoCosts = config.autoCosts?.map((x) => x.type) || [];
                        if (autoCosts.length > 0) {
                          const validAutoCosts = lineItems.filter((x) => autoCosts.includes(x.type));
                          if (validAutoCosts.length > 0) {
                            // push parent
                            formattedLineItems.push({ name: config.displayName });
                            //push children
                            validAutoCosts.forEach((autoCost) => {
                              formattedLineItems.push({
                                name: config.autoCosts.find((x) => x.type === autoCost.type)?.displayName,
                                cost: autoCost.cost,
                                indent: true,
                              });
                            });
                          }
                        } else {
                          const existingCost = lineItems.find((x) => config.type === x.type);
                          if (existingCost) {
                            formattedLineItems.push({
                              name: config.displayName,
                              cost: existingCost.cost,
                            });
                          }
                        }
                      });

                      return (
                        <Box mt="xs">
                          {formattedLineItems.map((lineItem) => {
                            return (
                              <Flex mb="xs" ml={lineItem.indent ? 'xl' : 'md'} key={lineItem.name}>
                                <Box flex={1}>
                                  <Text variant="body-responsive">{lineItem.name}</Text>
                                </Box>
                                <Text textAlign="right">
                                  {lineItem.cost > 0 && (
                                    <Text variant="body-responsive">
                                      <Money format="DOLLARS_NO_CENTS" value={lineItem.cost} />
                                    </Text>
                                  )}
                                </Text>
                              </Flex>
                            );
                          })}

                          <Flex ml="md" alignItems="center">
                            <Flex flex={1}>
                              <Text fontWeight="semibold" variant="body-responsive">
                                Resident Responsibility
                              </Text>
                              <Box mx={['xs', 'xs', 'md']}>
                                <Button
                                  smallOnMobile
                                  buttonType={BUTTON_TYPES.TEXT}
                                  label="LEARN MORE"
                                  onClick={openResponsibilityModal}
                                />
                              </Box>
                            </Flex>
                            <Text variant="body-responsive" fontWeight="semibold" textAlign="right">
                              {_isTBD ? (
                                'TBD'
                              ) : (
                                <Money format="DOLLARS_NO_CENTS" value={breakdown.resident[itemCost]} />
                              )}
                            </Text>
                          </Flex>

                          {idx < validBundles.length - 1 && <div className={cx('underline')} />}
                        </Box>
                      );
                    })()}
                  />
                </Box>
              );
            })}

            <div className={cx('underline')} />
            {/* Totals */}
            <NameValueTable
              boldValue
              items={[
                {
                  name: 'Total',
                  value: (
                    <Money
                      format="DOLLARS_NO_CENTS"
                      value={residentPayment[costs.netTotal] + homeownerRepairTotalAmount}
                    />
                  ),
                },
              ]}
            />
          </div>

          <GetInfoModal
            description={
              <Box bg="white" color="navy" textAlign="left" py="xl">
                <ResponsibilityContent />
              </Box>
            }
            closeButton
            show={isResponsibilityModalOpen}
            shadow
            closable={false}
            onHide={closeResponsibilityModal}
          />
        </div>
      }
    />
  );
};

GroupEmployeeAssignments.propTypes = {
  report: PropTypes.object,
  propertiesData: PropTypes.object,
  listingsData: PropTypes.object,
  inspectionData: PropTypes.object,
  task: PropTypes.object.isRequired,
};
GroupImprovementCards.propTypes = {
  report: PropTypes.object,
  listingsData: PropTypes.object,
  groupTask: PropTypes.object,
  hasAppointments: PropTypes.bool,
  isRenter: PropTypes.bool,
};
GroupHomeownerReceiptCosts.propTypes = {
  report: PropTypes.object.isRequired,
  groupTask: PropTypes.object.isRequired,
};
GroupResidentReceiptCosts.propTypes = {
  groupTask: PropTypes.object.isRequired,
};

export { GroupEmployeeAssignments, GroupImprovementCards, GroupHomeownerReceiptCosts, GroupResidentReceiptCosts };
