import MaintenanceTaskModel from 'models/maintenance/MaintenanceTaskModel';
import { createSelector } from 'reselect';
import { MAINTENANCE_REDUCER } from './reducer';

const _selectAccounts = (state) => state[MAINTENANCE_REDUCER];

const _selectCurrentTask = (state) => _selectAccounts(state).currentTask;

const _selectHomeownerTasks = (state) => _selectAccounts(state).homeTasks;

const _selectRentTasks = (state) => _selectAccounts(state).rentTasks;
const _selectImprovementsReport = (state) => _selectAccounts(state).improvementsReport;

export const selectHomeownerTasks = createSelector(_selectHomeownerTasks, (tasks) =>
  tasks ? tasks.map((task) => new MaintenanceTaskModel(task)) : []
);

export const selectImprovementsReport = createSelector(_selectImprovementsReport, (report) => report || {});

export const selectRentTasks = createSelector(_selectRentTasks, (tasks) =>
  tasks ? tasks.map((task) => new MaintenanceTaskModel(task)) : []
);

export const selectCurrentTask = createSelector(_selectCurrentTask, (task) => new MaintenanceTaskModel(task) || {});

export const selectRecurringTasks = (state) => _selectAccounts(state).recurringTasks;
