import React, { Component } from 'react';
import { IconPlay } from '@belong/icons';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import Icon, { ICONS } from 'components/Icon/Icon';
import CarouselModal from 'containercomponents/Modals/CarouselModal/CarouselModal';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { MediaType } from 'models/enums/index';
import PropTypes from 'prop-types';
import styles from './MediaPreview.module.css';

const cx = classNames.bind(styles);

class MediaPreview extends Component {
  static propTypes = {
    availableMedia: PropTypes.array,
    previewClassName: PropTypes.string,
    previewDocumentClassName: PropTypes.string,
    ignoreModalExitAndEnterFunctions: PropTypes.bool,
    fullPage: PropTypes.bool,
  };

  static defaultProps = {
    availableMedia: [],
    previewClassName: '',
    previewDocumentClassName: '',
    ignoreModalExitAndEnterFunctions: false,
    fullPage: false,
  };

  state = {
    showCarousel: false,
    imageIndex: 0,
    hoveredIndex: -1,
  };

  handleImageClick = (imageIndex) => {
    this.setState({
      showCarousel: true,
      imageIndex,
    });
  };

  handleClose = () => {
    this.setState({
      showCarousel: false,
    });
  };

  changeToHover = (index) => {
    this.setState({
      hoveredIndex: index,
    });
  };

  changeToNotHover = () => {
    this.setState({
      hoveredIndex: -1,
    });
  };

  handleShowCarousel = () => {
    const { imageIndex } = this.state;
    const { availableMedia, ignoreModalExitAndEnterFunctions, fullPage } = this.props;

    return (
      <CarouselModal
        show
        carouselV2
        images={availableMedia}
        onHide={this.handleClose}
        index={imageIndex}
        ignoreModalExitAndEnterFunctions={ignoreModalExitAndEnterFunctions}
        fullPage={fullPage}
      />
    );
  };

  handleDocumentClick = (document) => {
    const { url } = document;
    window.open(url, '_blank');
  };

  renderPdf = ({ file, index }) => {
    const { previewDocumentClassName } = this.props;

    return (
      <div className={cx('document-wrapper', previewDocumentClassName)} key={index}>
        <Button href={file.url} className={cx('document-button')} target="_blank">
          <div className={cx('pdf')}>
            <Icon icon={ICONS.DOCUMENT.PDF} />
          </div>
        </Button>
      </div>
    );
  };

  renderVideo = ({ index }) => {
    const { previewDocumentClassName } = this.props;
    const { hoveredIndex } = this.state;

    return (
      <div className={cx('document-wrapper', previewDocumentClassName)} key={index}>
        <ButtonBase
          onMouseEnter={() => this.changeToHover(index)}
          onMouseLeave={() => this.changeToNotHover()}
          className={cx('document-button')}
          onClick={() => {
            this.handleImageClick(index);
          }}
        >
          <div className={cx('video')}>
            {index === hoveredIndex ? <IconPlay width={36} className="text-dark-navy" /> : <IconPlay width={36} />}
          </div>
        </ButtonBase>
      </div>
    );
  };

  renderImage = ({ file, index }) => {
    const { previewDocumentClassName } = this.props;

    return (
      <div className={cx('document-wrapper', previewDocumentClassName)} key={index}>
        <ButtonBase
          className={cx('image-button')}
          onClick={() => {
            this.handleImageClick(index);
          }}
        >
          <img src={file.url} alt="preview" />
        </ButtonBase>
      </div>
    );
  };

  render() {
    const { availableMedia, previewClassName } = this.props;
    const { showCarousel } = this.state;
    return (
      <div className={cx('preview-media', previewClassName)}>
        {availableMedia?.map((file, index) => {
          if (file.mediaType === MediaType.Video) {
            return this.renderVideo({
              file,
              index,
            });
          }

          if (file.mediaType === MediaType.PDF) {
            return this.renderPdf({
              file,
              index,
            });
          }

          return this.renderImage({
            file,
            index,
          });
        })}

        {showCarousel && this.handleShowCarousel()}
      </div>
    );
  }
}

export default MediaPreview;
