export const STRINGS = {
  '3-fee-instant': '(3% Fee • Instant)',
  'add-a-credit-card': 'Add A Credit Card',
  'add-a-new-payment-method': 'Add New Payment Method',
  'additional-fees': 'Additional fees may apply for credit cards issued outside the US.',
  approve: 'Approve',
  'approve-pay-deposit': 'Approve | Pay Deposit',
  'approve-pay-deposit.pre_move_out': 'Sounds Good',
  'credit-card': 'Credit Card',
  'credit-card-number': 'Credit Card Number',
  cvc: 'CVC',
  done: 'Done',
  'due-now': 'Due Now',
  'due-upfront': 'Due Upfront',
  'e-check-ach': 'eCheck ACH',
  est: 'Est.',
  'exp-mm-yy': 'Exp MM/YY',
  'flagged-items-warning':
    "We'll reach out to discuss any items you flagged. Your plan can still be modified after approving here",
  'free-3-5-business-days': '(FREE • 3-5 business days)',
  'from-rent': 'From Rent',
  'get-to-work-belong': 'Get to work, Belong!',
  'improvements-report-success': "Great! We're excited to get to work, and give your home all the love it deserves.",
  'improvements-report-success.pre_move_out':
    'We’ll start marketing your home to potential residents and share the improvement plan. Prices and improvements will be decided after the final\u00a0inspection.',
  'lets-talk': "Let's talk",
  monthly: '/mo.',
  next: 'Next',
  'not-verified': 'NOT VERIFIED',
  'pay-now': 'Pay Now',
  'payment-method': 'Payment Method',
  'payment-method-question': 'How would you like to pay?',
  'payment-method-step-subtitle':
    'This will be your payment method for Pro Services, which you can change at any time. Upon completion of each, you’ll also have the option to pay in installments.',
  'payment-method-split-step-subtitle':
    'We will automatically set your preference to pay for all Pro Services, starting with your Improvements.',
  'payment-method-step-title': 'How would you like to pay the {dueNow} deposit?',
  'payment-method-step-title-split': 'How would you like to pay for improvements?',
  'payment-method-step-title-no-due': 'Let’s set up your Pro Services payment method.',
  'payment-method-step-title-no-due-confirm': 'Let’s confirm your Pro Services payment method.',
  'previous-step': 'previous-step',
  'payment-method-pro-service': 'We will keep this as your payment method for Pro Service work.',
  'payment-method-pro-service-empty':
    'We will keep this as your payment method for Pro Service work which you can change at anytime.',
  'questions-or-concerns': 'Questions or concerns?',
  'ready-to-get-your-home-loved': 'Ready to get your home loved?',
  'ready-to-get-your-home-loved.move-out': 'Ready to get your home re-loved?',
  'secured-encrypted-payments': 'This is a Secure 128-bit SSL encrypted payment.',
  'successfully-paid': 'Successfully Paid',
  'up-to-12-month-financing-available': 'Up To 12-Month Financing Available',
  'upon-completion': 'Upon Completion',
  zip: 'Zip',
  'deposit-paid': 'Successfully paid {deposit} deposit!',
};
