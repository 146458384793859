import { IconRightCaret } from '@belong/icons';
import { Money, Text } from '@belong/ui';
import { GroupedBalancesInfoModel, BillStatusInfo, BillingInfoModel } from 'api/models.v2';
import cx from 'classnames';
import { getBalanceSubtitle, getBalanceSubtitleStyles } from 'pages/Accounts/utils/get-balance-subtitle';
import { BalanceItemIcon } from '../balance-item-icon/balance-item-icon';

// type BalanceItemProps = GroupBalanceInfoModel;
interface BalanceItemProps extends GroupedBalancesInfoModel {
  address: string;
  cutOffOn?: string;
  dueOn?: string;
  billStatusInfo?: BillStatusInfo;
  billingInfo?: BillingInfoModel;
  isResident?: boolean;
  navigateToFinanceDetails: (id: string | number, balance: GroupedBalancesInfoModel, home: string) => void;
  handleShowBalanceDetailsModal?: (
    balance: GroupedBalancesInfoModel,
    home: string,
    billingInfo: BillingInfoModel,
    standalone?: boolean,
    isAutoSplittable?: boolean
  ) => void;
  noBorder?: boolean;
  hideSplitIt?: boolean;
  isFastMaintenanceEnabled: boolean;
  isAutoSplittable?: boolean;
}

export function BalanceItem({
  description,
  netBalance,
  isFinanceable,
  navigateToFinanceDetails,
  handleShowBalanceDetailsModal,
  address,
  cutOffOn,
  isAutoSplittable,
  dueOn,
  billingInfo,
  billStatusInfo,
  balances,
  isFastMaintenanceEnabled,
  canBePaid,
  category,
  hideSplitIt = false,
  isResident = false,
  noBorder = false,
  ...rest
}: BalanceItemProps) {
  function handleFinanceItem(id: string, balance: GroupedBalancesInfoModel) {
    if (rest.sourceType === 'Finance') {
      navigateToFinanceDetails(id, balance, address);
    }

    if (rest.sourceType === 'GroupKey' || rest.sourceType === 'Balance') {
      if (handleShowBalanceDetailsModal) {
        handleShowBalanceDetailsModal(balance, address, billingInfo, false, isAutoSplittable);
      }
    }
  }

  return (
    <button
      className={cx(
        'w-full flex items-center justify-between mb-2xs p-2xs pl-xs hover:bg-[#F3FDFA] rounded-[10px] cursor-pointer',
        noBorder && 'pl-0 ml-0'
      )}
      onClick={() => handleFinanceItem(rest.sourceKey, { description, netBalance, isFinanceable, balances, ...rest })}
      type="button"
    >
      <div>
        <div className="flex items-center">
          <Text className="text-left">{description}</Text>
          {!isResident && !hideSplitIt && <BalanceItemIcon isFinanceable={isFinanceable} className="ml-xs p-2xs" />}
        </div>
        {isFastMaintenanceEnabled && (
          <Text variant="p1" className={cx('text-left', getBalanceSubtitleStyles(billStatusInfo))}>
            {getBalanceSubtitle({
              dueOn,
              cutOffOn,
              billStatusInfo,
              isFinanceable,
              canBePaid,
              category,
              isAutoSplittable,
            })}
          </Text>
        )}
      </div>
      <div className="flex items-center justify-end ml-2xs md:ml-0">
        <Money className="text-body font-semibold" value={netBalance} format="DOLLARS_WITH_CENTS" />
        <IconRightCaret height={15} className="ml-xs" />
      </div>
    </button>
  );
}
