import { startCase } from '@belong/common';
import find from 'lodash/find';
import { RequestType } from 'models/enums/index';

export const getEstimatedCostFromCost = (estimatedcost, splitBy = 1) => {
  const x = parseInt(estimatedcost, 10) / splitBy;
  return Math.floor(x).toString();
};

export const formatMaintenanceStringsForTasks = (maintenance, task = {}) => {
  // In the payments return object we alwys show approval pending.
  // Is it ok to show approval pending until everyone confirms?

  const objectOfStatuses = {
    PendingAssessment: 'Reviewing',
    InProgress: 'In Progress',
    AssessmentScheduled: 'In Progress',
    PendingQuote: 'In Progress',
    PendingScheduling: 'In Progress',
    // OwnerRejected: 'Not Approved',
    // ResidentRejected: 'Cancelled',
    Canceled: 'Cancelled',
    Cancelled: 'Cancelled',
    RequireFollowup: 'Followup Required',
    AppointmentScheduled: 'Scheduled',
    Completed: 'Completed',
    Rejected: 'Not Approved',
    'Not Approved': 'Not Approved',
    Duplicate: 'Duplicate',
    'Not an Issue': 'Closed',
    'Will not do': 'Will Not Do',
  };

  if (task.status !== 'Closed') {
    if (task.workOrder) {
      return startCase(task.workOrder.status);
    }

    if (task.nextAppointmentUniqueId) {
      return objectOfStatuses.AppointmentScheduled;
    }

    if (!task.nextAppointmentUniqueId && !!task.appointments.length) {
      return objectOfStatuses.RequireFollowup;
    }
  }

  if (task?.payments?.length) {
    const renterResponsibility = find(task.payments, (payment) => payment.paidBy === 'Resident');
    const homeOwnerResponsibility = find(task.payments, (payment) => payment.paidBy === 'Homeowner');

    // This task.estimated cost is only because we generate conset automatically which is linked to payments.
    // When we seperate the concept of consent from payments this should be removed.
    if (task.estimatedCost && task.status !== 'Closed') {
      if (renterResponsibility?.consentStatus === 'Required' || homeOwnerResponsibility?.consentStatus === 'Required') {
        return 'Approval Pending';
      }
    }
  }

  if (task?.result) {
    if (objectOfStatuses[task.result]) {
      return objectOfStatuses[task.result];
    }
  }

  return objectOfStatuses[maintenance] || maintenance;
};

export const getImage = (nameOfTask) => {
  switch (nameOfTask) {
    case RequestType.Repair:
    case RequestType.Group:
      return '/pro-services/repair.svg';
    case RequestType.Addition:
      return '/pro-services/addition.svg';
    case RequestType.Upgrade:
      return '/pro-services/upgrade.svg';
    case RequestType.Preventative:
      return '/pro-services/preventative.svg';
    case RequestType.Recondition:
      return '/pro-services/reconditioning.svg';
    case RequestType.PickupAndDelivery:
      return '/pro-services/pickup.svg';
    case RequestType.HaulingAndStorage:
      return '/pro-services/hauling.svg';
    case RequestType.Cleaning:
      return '/pro-services/cleaning.svg';
    case RequestType.Inspection:
      return '/pro-services/inspections.svg';
    case RequestType.KeysAndAccess:
      return '/pro-services/keys.svg';
    case RequestType.Photography:
      return '/pro-services/photography.svg';
    case RequestType.Investigation:
      return '/pro-services/investigation.svg';
    case 'Recurring':
    default:
      return '/pro-services/legacy.svg';
  }
};

export const getStringFromTime = (repeatDays) => {
  if (repeatDays === 365) {
    return 'Yearly';
  } else if (repeatDays === 90) {
    return 'Quarterly';
  } else if (repeatDays === 30) {
    return 'Monthly';
  } else if (repeatDays > 0) {
    return `Every ${repeatDays} Days`;
  }
};
