import React from 'react';
import PropTypes from 'prop-types';
import { filter, find } from 'lodash';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Field from 'components/Field/Field';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { composeValidators, required } from '../../../../../utils/validation';
import { RELATIONSHIP_ITEMS } from '../../../../../formcomponents/MultipleResidentDetails/constants';

export const isOtherRelationShip = (relationship) => {
  const nonOther = filter(RELATIONSHIP_ITEMS, (item) => item.key !== 'Other');
  return !!find(nonOther, (item) => item.key !== relationship);
};

const RelationshipField = ({ value, namePrefix }) => {
  const prefixWithDotOrEmpty = namePrefix ? `${namePrefix}.` : '';
  return (
    <Row>
      <Col md={6}>
        <Field
          name={`${prefixWithDotOrEmpty}relationship.relationship_selected`}
          component={DropdownFinalFormAdapter}
          placeholder="Relationship"
          validate={required}
          items={RELATIONSHIP_ITEMS}
          value={value.relationship_selected}
        />
      </Col>
      {value.relationship_selected === 'Other' && (
        <Col md={6}>
          <Field
            name={`${prefixWithDotOrEmpty}relationship.relationship_other`}
            validate={composeValidators(required)}
            component={InputFinalFormAdapter}
            placeholder="Other Relationship"
            value={value.relationship_other}
          />
        </Col>
      )}
    </Row>
  );
};

RelationshipField.propTypes = {
  value: PropTypes.object.isRequired,
  namePrefix: PropTypes.string,
};

RelationshipField.defaultProps = {
  namePrefix: null,
};

const RelationshipFieldAdapter = ({ input, meta, ...rest }) => {
  return <RelationshipField {...rest} meta={meta} {...input} />;
};

RelationshipFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};
export default RelationshipFieldAdapter;
