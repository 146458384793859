import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { IconPlay } from '@belong/icons';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Icon, { ICONS } from 'components/Icon/Icon';
import Image from 'components/Image/Image';
import { PICTURE_TYPES } from 'components/TakePicture/TakePicture';
import TakePictureModal from 'containercomponents/Modals/TakePictureModal/TakePictureModal';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { filter, find, truncate } from 'lodash';
import { MediaType } from 'models/enums/index';
import PropTypes from 'prop-types';
import { getFileType } from 'utils/file';
import styles from './NewMediaUploader.module.css';

const cx = classNames.bind(styles);

export const SIZE = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  SMALL: 'small',
  FLUID: 'fluid',
};

const MEDIA_FILES = {
  IMAGE_VIDEO: 'image/*,video/*',
  IMAGE: 'image/*',
  MP4: 'video/mp4',
  PDF: 'application/pdf',
  DOCUMENTS: 'image/*, application/pdf',
};

export const MEDIA_LABEL = {
  DEFAULT: {
    label: 'Image',
    icon: ICONS.PHOTOGRAPHY.DEFAULT,
    labelOnHover: 'Image',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.DEFAULT,
  },
  PHOTO_OR_VIDEO: {
    label: 'Photo or Video',
    icon: ICONS.PHOTO_OR_VIDEO.DEFAULT,
    labelOnHover: 'Photo or Video',
    allowedFileType: MEDIA_FILES.IMAGE_VIDEO,
    pictureType: PICTURE_TYPES.DEFAULT,
  },
  ID_FRONT: {
    label: 'Front',
    icon: ICONS.ID_FRONT.DEFAULT,
    labelOnHover: 'Front Of Your ID',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.ID,
  },
  ID_BACK: {
    label: 'Back',
    icon: ICONS.ID_BACK.DEFAULT,
    labelOnHover: 'Back Of Your ID',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.ID,
  },
  SELFIE: {
    label: 'Selfie',
    icon: ICONS.SELFIE.DEFAULT,
    labelOnHover: 'Selfie',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.SELFIE,
  },
  PASSPORT: {
    label: 'Passport',
    icon: ICONS.PASSPORT.DEFAULT,
    labelOnHover: 'Passport',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.PASSPORT,
  },
  DOCUMENT: {
    label: 'Document',
    icon: ICONS.DOCUMENT.DOC,
    labelOnHover: 'Document',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.DOCUMENT,
  },
  FILE_UPLOAD: {
    label: 'Document',
    icon: ICONS.UPLOAD_LARGE.DEFAULT,
    labelOnHover: 'Document',
    allowedFileType: MEDIA_FILES.DOCUMENTS,
    pictureType: PICTURE_TYPES.DOCUMENT,
  },
  HOA: {
    label: 'HOA CC&Rs',
    icon: ICONS.UPLOAD_LARGE.DEFAULT,
    labelOnHover: 'HOA CC&Rs',
    allowedFileType: MEDIA_FILES.PDF,
    pictureType: PICTURE_TYPES.DOCUMENT,
  },
  INSURANCE: {
    label: 'Insurance',
    icon: ICONS.UPLOAD_LARGE.DEFAULT,
    labelOnHover: 'Insurance',
    allowedFileType: MEDIA_FILES.PDF,
    pictureType: PICTURE_TYPES.DOCUMENT,
  },
  LEASE: {
    label: 'Lease',
    icon: ICONS.UPLOAD_LARGE.DEFAULT,
    labelOnHover: 'Lease',
    allowedFileType: MEDIA_FILES.PDF,
    pictureType: PICTURE_TYPES.DOCUMENT,
  },
  HOME_PHOTO: {
    label: 'Front Of Your Home',
    icon: ICONS.HOME_PHOTO.DEFAULT,
    labelOnHover: 'Front Of Your Home',
    allowedFileType: MEDIA_FILES.IMAGE,
    pictureType: PICTURE_TYPES.HOME,
  },
  OFFER_LETTER: {
    label: 'Offer Letter',
    icon: ICONS.UPLOAD_LARGE.DEFAULT,
    labelOnHover: 'Offer Letter',
    allowedFileType: MEDIA_FILES.PDF,
    pictureType: PICTURE_TYPES.DOCUMENT,
  },
};

export default class NewMediaUploader extends Component {
  static propTypes = {
    mediaLabel: PropTypes.object,
    isSingleMedia: PropTypes.bool,
    availableMedia: PropTypes.array,
    handleMedia: PropTypes.func,
    defaultFileName: PropTypes.string,
    size: PropTypes.string,
    wrapperSize: PropTypes.string,
    disableWebcam: PropTypes.bool,
    error: PropTypes.any,
    viewOnly: PropTypes.bool,
    cleanOnMediaChange: PropTypes.bool,
  };

  static defaultProps = {
    mediaLabel: MEDIA_LABEL.DEFAULT,
    isSingleMedia: false,
    availableMedia: [],
    handleMedia: () => {},
    defaultFileName: 'Image',
    size: SIZE.DEFAULT,
    wrapperSize: 'default',
    disableWebcam: false,
    viewOnly: false,
    cleanOnMediaChange: false,
  };

  state = {
    showTakePictureModal: false,
    stagingMedia: [],
    deletedMedia: [],
    dragOver: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.availableMedia !== this.props.availableMedia && this.props.cleanOnMediaChange) {
      this.setState({ deletedMedia: [], stagingMedia: [] }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  onUpload = (files) => {
    const { stagingMedia } = this.state;

    const filesWithPreview = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    this.setState({ stagingMedia: [...stagingMedia, ...filesWithPreview] }, () => this.afterUploadOrDelete());
  };

  onDelete = (file) => {
    const isStagingMedia = file.preview;
    if (isStagingMedia) this.deleteStagingMedia(file);
    else this.deleteAvailableMedia(file);
  };

  deleteStagingMedia = (file) => {
    const { stagingMedia } = this.state;

    this.setState({ stagingMedia: stagingMedia.filter((media) => media !== file) }, () => this.afterUploadOrDelete());
  };

  deleteAvailableMedia = (file) => {
    const { deletedMedia } = this.state;

    this.setState({ deletedMedia: [...deletedMedia, file] }, () => this.afterUploadOrDelete());
  };

  afterUploadOrDelete = () => {
    const { availableMedia, handleMedia } = this.props;
    const { stagingMedia, deletedMedia } = this.state;
    const mediaToDisplay = filter([...availableMedia, ...stagingMedia], (media) => !find(deletedMedia, media));

    handleMedia({ availableMedia, stagingMedia, deletedMedia, mediaToDisplay });
  };

  handleClickTakePicture = () => {
    this.setState({ showTakePictureModal: true });
  };

  hideTakePictureModal = () => {
    this.setState({ showTakePictureModal: false });
  };

  handleCtaClickModal = (image) => {
    const { defaultFileName } = this.props;

    const imageDataString = window.atob(image.split(',')[1]);
    const imageStringLength = imageDataString.length;
    const imageUint8Array = new Uint8Array(new ArrayBuffer(imageStringLength));

    for (let i = 0; i < imageStringLength; i += 1) {
      imageUint8Array[i] = imageDataString.charCodeAt(i);
    }

    const imageFile = new File([imageUint8Array], `${defaultFileName}.jpg`, { type: 'image/jpeg' });
    Object.assign(imageFile, { preview: URL.createObjectURL(imageFile) });

    this.setState({ showTakePictureModal: false }, () => this.onUpload([imageFile]));
  };

  renderUploader = () => {
    const {
      mediaLabel: { label, icon, labelOnHover, allowedFileType, pictureType },
      isSingleMedia,
      size,
      disableWebcam,
      error,
    } = this.props;
    const { showTakePictureModal, dragOver } = this.state;
    const errorHighlight = error ? 'error-highlight' : '';

    return (
      <>
        {typeof window !== 'undefined' && (
          <Dropzone
            onDrop={(files) => this.onUpload(files)}
            multiple={!isSingleMedia}
            accept={allowedFileType}
            onDragOver={(e) => {
              e.stopPropagation();
              if (!dragOver) this.setState({ dragOver: true });
            }}
            onDragLeave={(e) => {
              e.stopPropagation();
              if (dragOver) this.setState({ dragOver: false });
            }}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div
                  {...getRootProps({
                    className: cx(
                      'wrapper-dashed',
                      `${dragOver ? 'onDrag' : ''}`,
                      `${size}-${isSingleMedia ? 'single' : 'multiple'}`,
                      errorHighlight,
                      // This can include sensitive information, so we always hide from fulstory
                      'fs-exclude'
                    ),
                  })}
                >
                  {/* Needed for the latest version of react-dropzone */}
                  <input {...getInputProps()} />
                  <Icon className={cx('icon')} icon={icon} responsive />
                  <div className={cx('document-label')}>{label}</div>
                  <Space value={SPACE_TYPES.XXS} />
                  <div className={cx('text')}>Drag file here...</div>
                  {!disableWebcam && (
                    <div className={cx('button-wrapper')}>
                      <div className={cx('upload-text')}>{labelOnHover}</div>
                      <Button buttonType="outline">Browse file</Button>
                      <Space />
                      <Button
                        label="Use a Webcam"
                        buttonType="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleClickTakePicture();
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            }}
          </Dropzone>
        )}
        <TakePictureModal
          show={showTakePictureModal}
          onHide={this.hideTakePictureModal}
          onCtaClick={this.handleCtaClickModal}
          pictureType={pictureType}
          {...this.props}
        />
      </>
    );
  };

  renderMedia = (availableMedia) => {
    const {
      mediaLabel: { label },
      isSingleMedia,
      wrapperSize,
      size,
      viewOnly,
    } = this.props;

    const { stagingMedia, deletedMedia } = this.state;

    const mediaElement = (media) => {
      let mediaType;

      if (media?.mediaType) mediaType = media?.mediaType;
      else if (media?.type) mediaType = getFileType(media?.type);
      else mediaType = MediaType.Image;

      switch (mediaType) {
        case MediaType.PDF:
          return (
            <div className={cx('media-radius')}>
              <Icon icon={ICONS.DOCUMENT.DEFAULT} />
              <div className={cx('document-label')}>{label}</div>
              {truncate(media.name, { length: 16, separator: /,? +/ })}
            </div>
          );
        case MediaType.Video:
          return (
            <div className={cx('media-radius')}>
              <IconPlay width={36} />
            </div>
          );
        default:
          return <Image className={cx('media-radius')} src={media.preview || media.url} />;
      }
    };

    const mediaWrapper = (media, ind) => (
      <div className={cx(`${size}-${isSingleMedia ? 'single' : 'multiple'}`)} key={ind}>
        {!viewOnly && (
          <ButtonBase className={cx('adjust-position')} onClick={() => this.onDelete(media)}>
            <GeneralIcon scale={1.5} icon={GENERAL_ICONS.RADIO_CLOSE} />
          </ButtonBase>
        )}
        {mediaElement(media)}
      </div>
    );

    const mediaToDisplay = filter([...availableMedia, ...stagingMedia], (media) => !find(deletedMedia, media));
    const isEmpty = !mediaToDisplay.length || !mediaToDisplay[0];

    return (
      <div className={cx(`wrapper-${wrapperSize}`)}>
        {mediaToDisplay.map((media, ind) => media && mediaWrapper(media, ind))}
        {(isEmpty || !isSingleMedia) && !viewOnly && this.renderUploader()}
      </div>
    );
  };

  render() {
    const { availableMedia } = this.props;

    return this.renderMedia(availableMedia);
  }
}

export const validateMedia = (value) => {
  if (value?.mediaToDisplay?.length) {
    return undefined;
  }

  return true;
};

export const NewMediaUploadAdapter = ({ input, meta, ignoreError, onChangeCustom, ...rest }) => {
  const handleChange = (value) => {
    input.onChange({ ...input.value, ...value });
    if (onChangeCustom) {
      onChangeCustom(value);
    }
  };

  const controlledProps = {};
  if (!ignoreError) {
    controlledProps.error = !!meta.error;
  }

  return (
    <NewMediaUploader
      {...rest}
      {...input}
      {...controlledProps}
      availableMedia={input.value?.availableMedia || []}
      handleMedia={handleChange}
    />
  );
};

NewMediaUploadAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func.isRequired,
  ignoreError: PropTypes.bool,
};

NewMediaUploadAdapter.defaultProps = {
  ignoreError: true,
};
