import { useDispatch } from 'react-redux';
import { PATHS } from '@belong/common';
import { MultiDropdownFinalFormAdapter } from 'components/Dropdown/MultiDropdown';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import arrayMutators from 'final-form-arrays';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { ContentLayout } from 'layouts/SingleColumnFlowLayout/ContentLayout/ContentLayout';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { updateBelongServicesInterest } from 'store/redux/homeowner-accounts/actions';
import { formatString } from 'strings';
import { STRINGS } from 'strings/occupancy-standalone.strings';
import { required } from 'utils/validation';

const reasonsItems = [
  { key: 'Selling My Home', value: 'Selling My Home' },
  { key: 'Moving Back Into Home', value: 'Moving Back Into Home' },
  { key: 'Family/Friend {Moving Into/Staying In} Home', value: 'Family/Friend {Moving Into/Staying In} Home' },
  { key: 'Chose Another Management Company', value: 'Chose Another Management Company' },
  { key: 'Planning To Self-Manage', value: 'Planning To Self-Manage' },
  { key: 'Converting To Short-Term Rental', value: 'Converting To Short-Term Rental' },
  { key: 'Converting to Vacation Rental', value: 'Converting to Vacation Rental' },
  { key: 'Converting to Corporate Housing', value: 'Converting to Corporate Housing' },
  { key: 'Financial Constraints', value: 'Financial Constraints' },
  { key: 'Maintenance Requirements', value: 'Maintenance Requirements' },
  { key: 'Pricing / Leasing Strategy', value: 'Pricing / Leasing Strategy' },
  { key: 'Found Renters Independently', value: 'Found Renters Independently' },
  { key: 'High Management Fees', value: 'High Management Fees' },
  { key: 'High Placement Fees', value: 'High Placement Fees' },
  { key: 'Taking Too Long', value: 'Taking Too Long' },
  { key: 'Bad Service / Experience', value: 'Bad Service / Experience' },
];

export const NoLongerNeedServices = ({ history, match, employee, user, unitId }) => {
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    try {
      dispatch(updateBelongServicesInterest(unitId, values));

      history.push(PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ContentLayout
      title={formatString(STRINGS['no-longer-interested.title'], { name: user?.firstName })}
      employee={employee}
    >
      <FormLayout>
        <Form
          mutators={{
            ...arrayMutators,
          }}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          onSubmit={handleSubmit}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              previousStep={match.url}
              previousStepCustomLabel="Nevermind"
              ctaProps={{
                label: 'Next',
                type: 'submit',
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={() => {
            return (
              <>
                <FormLayout.Section sectionTitle="">
                  <Row>
                    <Col xs={12}>
                      <Field
                        validate={required}
                        name="notInterestedInBelongServicesReasons"
                        component={MultiDropdownFinalFormAdapter}
                        placeholder="Reason(s)"
                        items={reasonsItems}
                        allowZero
                      />
                    </Col>
                  </Row>
                </FormLayout.Section>
                <FormLayout.Section sectionTitle="Anything else we should know?">
                  <Row>
                    <Col xs={12}>
                      <Field
                        component={InputFinalFormAdapter}
                        name="notInterestedInBelongServicesOtherReason"
                        placeholder="Additional Comments"
                        textarea
                      />
                    </Col>
                  </Row>
                </FormLayout.Section>
              </>
            );
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
};

NoLongerNeedServices.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  unitId: PropTypes.string.isRequired,
};
