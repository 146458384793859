import React, { Component } from 'react';
import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import styles from './MultipleUnitAddress.module.css';

const cx = classNames.bind(styles);

class MultipleUnitAddress extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    form: PropTypes.object,
    onSelectCallback: PropTypes.func,
  };

  static defaultProps = {
    form: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      trashIconFocus: false,
    };
  }

  handleTrashIconFocus = () => {
    this.setState({ trashIconFocus: true });
  };

  handleTrashIconBlur = () => {
    this.setState({ trashIconFocus: false });
  };

  render() {
    const { name, form, fields, index } = this.props;
    const { trashIconFocus } = this.state;

    return (
      <div className="flex flex-col relative">
        <AddressField
          multipleUnits
          name={name}
          form={form}
          onSelectCallback={this.props.onSelectCallback}
          layout={AddressField.LayoutTypes.SingleLine}
          fullWidth
        />

        <ButtonBase
          className={cx('absolute', 'trash')}
          onFocus={this.handleTrashIconFocus}
          onBlur={this.handleTrashIconBlur}
          onClick={() => {
            if (fields.value.length === 1) {
              fields.update(index, null);
            } else if (index === fields.length - 1) {
              fields.remove(index);
            } else {
              const getNextIndexValue = cloneDeep(fields.value[index + 1]);
              fields.remove(index + 1);
              fields.update(index, getNextIndexValue);
            }
          }}
        >
          <GeneralIcon icon={GENERAL_ICONS.TRASH} color={trashIconFocus ? COLORS.DARK_NAVY : COLORS.DEFAULT} />
        </ButtonBase>
      </div>
    );
  }
}

export default MultipleUnitAddress;
