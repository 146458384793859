import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDateTime } from '@belong/common';
import { Button, Text, Toast } from '@belong/ui';
import { MaintenanceRecurrenceDetailModel } from 'api/models';
import Grid from 'components/Grid/Grid';
import startCase from 'lodash/startCase';
import { URL_QUERY_PARAMS } from 'maintenance/constants';
import { STRINGS } from 'maintenance/strings';
import { getFormattedFrequency, homeOwnerTasks, residentTasks } from 'maintenance/utils';
import { MaintenanceRecurrenceApprovalResponsibility } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-approval-responsibility';
import { MaintenanceRecurrenceFooter } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-footer';
import { MaintenanceRecurrenceHeader } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-header';
import { MaintenanceRecurrenceNavBar } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-nav-bar';
import { MaintenanceRecurrenceResidentNotice } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-resident-notice';
import { MaintenanceRecurrenceSubtasksList } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-subtasks-list';
import {
  getFormattedAddress,
  getRecurrenceActivatedByUser,
} from 'pages/Accounts/Components/maintenance-recurrence/maintenance-recurrence.utils';
import { MaintenanceRecurrenceNotes } from './components/maintenance-recurrence-notes';

type MaintenanceRecurrenceProps = {
  task: MaintenanceRecurrenceDetailModel;
  onSubtaskClick: (subtask) => void;
  onClosePress: () => void;
  isResident?: boolean;
};

export const MaintenanceRecurrence = ({
  task,
  onSubtaskClick,
  onClosePress,
  isResident = false,
}: MaintenanceRecurrenceProps) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const summaryRef = useRef<HTMLParagraphElement>(null);
  const [isExpandSummaryButtonVisible, setIsExpandSummaryButtonVisible] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const { pathname, search } = useLocation();
  const { replace } = useHistory();

  const getToastText = () => {
    const parsedURL = new URLSearchParams(search);

    const cancelURLParam = parsedURL.get(URL_QUERY_PARAMS.CANCEL);
    const nextOnURLParam = parsedURL.get(URL_QUERY_PARAMS.NEXT_ON);
    const taskURLParam = parsedURL.get(URL_QUERY_PARAMS.TASK);

    let toastText = '';

    if (cancelURLParam) {
      toastText = STRINGS['single-visit-cancelled'];
    }

    if (cancelURLParam && nextOnURLParam) {
      const taskGroup = isResident ? residentTasks : homeOwnerTasks;
      const formattedTask = Object.values(taskGroup)
        .flat()
        .find((taskItem) => taskItem.key.toLowerCase() === taskURLParam.toLowerCase()).label;

      const formattedDate = formatDateTime({
        dateTime: nextOnURLParam,
        format: 'MM/dd/yy',
      });

      toastText = `${STRINGS['single-visit-cancelled-date']} ${formattedTask} ${STRINGS.around} ${formattedDate}.`;
    }

    return toastText;
  };

  const handleShowMoreClick = () => setIsSummaryExpanded((prevIsSummaryExpanded) => !prevIsSummaryExpanded);

  const handleToastClose = () => {
    setIsToastVisible(false);

    replace(pathname);
  };

  useEffect(() => {
    const parsedURL = new URLSearchParams(search);

    const toastVisibleURLParam = parsedURL.get(URL_QUERY_PARAMS.TOAST_VISIBLE);

    setIsToastVisible(toastVisibleURLParam === 'true');
  }, [search]);

  useLayoutEffect(() => {
    const lineHeight = 24; // we could probably abstract this in a custom hook.

    if (summaryRef.current?.clientHeight / lineHeight > 2) {
      setIsExpandSummaryButtonVisible(true);
    }
  }, []);

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <div className="w-full">
          <MaintenanceRecurrenceNavBar onClosePress={onClosePress} />
        </div>
        <div className="sm:max-w-[730px] px-2sm sm:px-0">
          {!!task && (
            <Grid extraPadding className="p-0">
              <MaintenanceRecurrenceHeader
                title={startCase(task.maintenanceRecurrence.summary)}
                frequency={getFormattedFrequency(task.maintenanceRecurrence.repeatFrequencyDays)}
                homeAddress={task.home?.address && getFormattedAddress(task.home.address)}
              />

              <div className="my-2xl">
                <Text fontWeight="semibold" variant="h3">
                  Summary
                </Text>
                <div className="mt-xs">
                  <Text className={isSummaryExpanded ? 'line-clamp-none' : 'line-clamp-2'}>
                    {task.maintenanceRecurrence.description}
                  </Text>
                  <Text className="invisible absolute top-0 left-0" ref={summaryRef}>
                    {task.maintenanceRecurrence.description}
                  </Text>
                  {isExpandSummaryButtonVisible && (
                    <div className="flex w-full justify-end mt-sm">
                      <Button onClick={handleShowMoreClick} variant="text">
                        SHOW {isSummaryExpanded ? 'LESS' : 'MORE'}
                      </Button>
                    </div>
                  )}
                </div>
                {isResident && task.maintenanceRecurrence.requestedBy === 'Homeowner' && (
                  <div className="mt-xs">
                    <MaintenanceRecurrenceResidentNotice
                      isRequestedByHomeowner
                      homeownerName={task.homeowner.firstName}
                      frequency={getFormattedFrequency(task.maintenanceRecurrence.repeatFrequencyDays)}
                    />
                  </div>
                )}
              </div>

              {task.subTasks?.length > 0 && (
                <MaintenanceRecurrenceSubtasksList subtasks={task.subTasks} onSubtaskClick={onSubtaskClick} />
              )}

              <MaintenanceRecurrenceNotes task={task} isResident={isResident} />
              <MaintenanceRecurrenceApprovalResponsibility
                userRole={task.maintenanceRecurrence.requestedBy}
                isActive={!task.maintenanceRecurrence.isCancelled}
                date={
                  task.maintenanceRecurrence.isCancelled
                    ? task.maintenanceRecurrence.endOn
                    : task.maintenanceRecurrence.createdOn
                }
                user={task && getRecurrenceActivatedByUser(task)}
              />

              <MaintenanceRecurrenceFooter isResident={isResident} task={task} />
            </Grid>
          )}
        </div>
      </div>
      <Toast isVisible={isToastVisible} onClose={handleToastClose}>
        <div className="sm:max-w-[530px]">{getToastText()}</div>
      </Toast>
    </>
  );
};
