import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import { COOKIES_CONFIG } from '@belong/common';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import HeaderMenuNavLink from 'components/HeaderMain/HeaderDesktopMenuComponent/HeaderMenuNavLink/HeaderMenuNavLink';
import { destroyCookie } from 'nookies';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { logOut } from 'store/redux/user/actions';
import { DEFAULT_LINKS } from '../../../consts/links';
import { PATHS } from '../../../routes/paths';
import styles from './HeaderDesktopMenuComponent.module.css';

const cx = classNames.bind(styles);

const { REFERRAL_USER, REFERRAL_ID } = COOKIES_CONFIG;

// TODO: Use a variable here instead of typing the strings manually.
// Issue right now is that includes removes the "/" in its partial matching, but we
// don't ignore them.
const REDIRECT_TO_HOME_ON_LOGOUT = [
  '/pay-deposit',
  BASE_PATHS.ACCOUNTS,
  BASE_PATHS.POST_INSPECTION_FLOW,
  BASE_PATHS.HOMEOWNER_SETUP_FLOW,
  PATHS.RESIDENT_APPLICATION,
];

class HeaderDesktopMenuComponent extends Component {
  static propTypes = {
    listOfLinks: PropTypes.array.isRequired,
    showSignOut: PropTypes.bool,
    menuClassName: PropTypes.string,
    firstName: PropTypes.string,
    location: PropTypes.object.isRequired,
    logOut: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showSignOut: false,
    menuClassName: '',
    firstName: false,
  };

  handleSignOut = async () => {
    const {
      logOut: logOutAction,
      location: { pathname },
      history,
    } = this.props;

    let redirectToHome = false;

    REDIRECT_TO_HOME_ON_LOGOUT.forEach((pathString) => {
      if (matchPath(pathname, { path: pathString })) {
        redirectToHome = true;
      }
    });

    try {
      destroyCookie(null, REFERRAL_USER.name, { path: '/' });
      destroyCookie(null, REFERRAL_ID.name, { path: '/' });

      logOutAction();

      if (redirectToHome) {
        history.push(PATHS.HOME_PAGE);
      }
    } catch (err) {
      console.error(err);
    }
  };

  isSelected = (to) => {
    const {
      location: { pathname },
    } = this.props;

    if (to === DEFAULT_LINKS.YOUR_PROFILE.to) {
      return to === pathname;
    }

    if (to === DEFAULT_LINKS.YOUR_HOME.to) {
      if (pathname.includes('accounts') && pathname !== DEFAULT_LINKS.YOUR_PROFILE.to) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { listOfLinks, showSignOut, menuClassName } = this.props;
    return (
      <>
        <ul className={cx('menu-x', menuClassName)}>
          {listOfLinks.map((link, index) => [
            <HeaderMenuNavLink tabIndex="-1" {...link} key={index} selected={this.isSelected(link.to)} />,
          ])}
          {showSignOut && (
            <div>
              <li className="border-transparent hover:border-l-4 border-l-4 box-border px-xs hover:border-navy border-solid border-0 mb-2sm last:mb-0">
                <Button className={cx('link')} buttonType={BUTTON_TYPES.TEXT} onClick={this.handleSignOut}>
                  SIGN OUT
                </Button>
              </li>
            </div>
          )}
        </ul>
      </>
    );
  }
}

export default withRouter(
  connect(null, {
    logOut,
  })(HeaderDesktopMenuComponent)
);
