import { Fragment, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { BREAKPOINTS_WIDTHS, COHERE_CONFIG, useWindowSize } from '@belong/common';
import { SetupFlowStepsLayout } from 'accounts/components/setup-flow-steps-layout/setup-flow-steps-layout';
import { SetupFlowStepProvider } from 'accounts/contexts/setup-flow-step';
import { useCheckPropertyIdParam } from 'accounts/hooks/homeowners/use-check-property-id-param';
import { useMultiUnit } from 'accounts/hooks/homeowners/use-mult-unit';
import { useSelectedUnit } from 'accounts/hooks/homeowners/use-selected-unit';
import { useSetupFlowSections } from 'accounts/hooks/homeowners/use-setup-flow-sections';
import { useSetupFlowSteps } from 'accounts/hooks/homeowners/use-setup-flow-steps';
import { useUnits } from 'accounts/hooks/homeowners/use-units';
import { useUserIsLogged } from 'accounts/hooks/homeowners/use-user-is-logged';
import { Analytics, PageViewTracker } from 'analytics';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { FlowStatus } from 'models/enums';
import { compose } from 'redux';
import { BASE_PATHS } from 'routes/paths';

export function HomeownerSetupFlowSteps() {
  const { propertyId, unitId, stepId } = useParams<any>();
  const history = useHistory();

  const { units } = useUnits();
  const { selectedUnit } = useSelectedUnit();
  const { isMultiUnit } = useMultiUnit();
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;
  const homeownersStepsProps = useSetupFlowSteps();

  const {
    loading,
    isValidUrl,
    currentStep,
    currentStepFormData,
    allStepsByGate,
    properties,
    selectedProperty,
    goNextStep,
  } = homeownersStepsProps;

  const { propertySection, accountSection, isFlowIncomplete, isPricingImprovementReady } = useSetupFlowSections({
    properties,
    allStepsByGate,
  });

  useCheckPropertyIdParam();
  useUserIsLogged();

  useEffect(() => {
    if (currentStep) {
      if (currentStep.isHidden) {
        goNextStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (currentStep?.flowStatus === FlowStatus.Completed) {
      history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}/setup-flow`);
    }
  }, [currentStep, history, propertyId]);

  const isLoading = loading || (stepId && !currentStepFormData);
  const { schedule, isEnabled } = COHERE_CONFIG.setupFlow;

  if (!(loading || (stepId && !currentStepFormData))) {
    if (!isValidUrl()) {
      return <Redirect to={`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}`} />;
    }
  }

  if (properties) {
    const unit = selectedProperty?.units.find((currentUnit) => currentUnit.basicInfo.unitId === unitId);

    if (!selectedProperty || (unitId?.toLowerCase() !== 'na' && !unit)) {
      return <Redirect to={BASE_PATHS.ACCOUNTS} />;
    }
  }

  return (
    <Fragment>
      <SetupFlowStepProvider
        {...homeownersStepsProps}
        units={units}
        selectedUnit={selectedUnit}
        propertySection={propertySection}
        accountSection={accountSection}
        isMultiUnit={isMultiUnit}
      >
        <ChatWidget
          schedule={schedule}
          shouldShow={isFlowIncomplete && !isPricingImprovementReady && !accountSection.isCompleted}
          isEnabled={isEnabled}
          containerWidth="w-container-xl"
          paddingRight="pr-2xl"
          showOnNavbar={isMobile}
        />
        <SetupFlowStepsLayout
          loading={isLoading}
          navigationComponents={[
            <ContactUs key="ContactUs" disableChat />,
            <RouterHeaderNavLink
              key="save"
              label="SAVE & EXIT"
              to={`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}`}
            />,
          ]}
        />
      </SetupFlowStepProvider>
    </Fragment>
  );
}

export default compose(
  Analytics(({ match }) => ({ screen: match?.params?.step })),
  PageViewTracker
)(HomeownerSetupFlowSteps);
