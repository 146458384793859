import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Label from 'components/Label/Label';
import { labelShape } from 'components/Label/labelTypes';
import PropTypes from 'prop-types';
import MaintenanceTaskCost from '../MaintenanceTaskCost/MaintenanceTaskCost';
import styles from './MaintenanceTaskLabelandDate.module.css';

const cx = classNames.bind(styles);

export default class MaintenanceTaskLabelandDate extends Component {
  static propTypes = {
    maintenanceTaskStatus: PropTypes.string.isRequired,
    maintenanceLabels: PropTypes.arrayOf(PropTypes.shape(labelShape)).isRequired,
    estimatedCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    actualCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    actualCost: null,
  };

  render() {
    const { maintenanceLabels, estimatedCost, actualCost, maintenanceTaskStatus } = this.props;
    return (
      <div className={cx('maintenanceTaskLabelandDate', 'pr-0 lg:h-2xl lg:flex lg:items-center lg:p-0')}>
        {maintenanceLabels.map((label, index) => (
          <Label text={label.text} color={label.color} key={index} />
        ))}
        <MaintenanceTaskCost
          maintenanceTaskStatus={maintenanceTaskStatus}
          estimatedCost={estimatedCost}
          actualCost={actualCost}
        />
      </div>
    );
  }
}
