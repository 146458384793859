import React, { Component } from 'react';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import CollapsibleTitle, { RightIcon } from 'components/Collapsible/CollapsibleTitle/CollapsibleTitle';
import { Flex, Box } from 'design-system';
import PropTypes from 'prop-types';
import styles from './Collapsible.module.css';

const cx = classNames.bind(styles);

export default class Collapsible extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    inner: PropTypes.node.isRequired,
    showWhenCollapsed: PropTypes.node,
    leftComponent: PropTypes.node,
    collapsed: PropTypes.bool,
    addPaddingToTitle: PropTypes.bool,
    createMarginAndPaddingClassName: PropTypes.string,
    disabled: PropTypes.bool,
    defaultClassName: PropTypes.string,
  };

  static defaultProps = {
    collapsed: true,
    addPaddingToTitle: false,
    createMarginAndPaddingClassName: '',
    showWhenCollapsed: '',
    disabled: false,
    defaultClassName: 'contents',
  };

  constructor(props) {
    super(props);
    this.state = { collapsed: props.collapsed };
  }

  handleCollapse = () => {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({ collapsed: !this.state.collapsed });
    }
  };

  render() {
    const {
      showWhenCollapsed,
      createMarginAndPaddingClassName,
      addPaddingToTitle,
      title,
      inner,
      leftComponent,
      defaultClassName,
      ...rest
    } = this.props;

    const { collapsed } = this.state;

    const isOpen = !collapsed;

    return (
      <div className={clsx(defaultClassName, isOpen ? 'mb-2xl' : 'mb-lg')}>
        <section className={cx('collapsible')} {...rest}>
          {leftComponent ? (
            <Flex alignItems="center" onClick={this.handleCollapse}>
              <Box>{leftComponent}</Box>
              <Box ml="xs">
                <RightIcon isOpen={isOpen} />
              </Box>
            </Flex>
          ) : (
            <div className="w-full">
              <CollapsibleTitle titleText={title} handleClick={this.handleCollapse} isOpen={isOpen} />
            </div>
          )}
        </section>
        <section className={cx({ collapsed }, createMarginAndPaddingClassName)}>{inner}</section>
        {collapsed && showWhenCollapsed}
      </div>
    );
  }
}

Collapsible.RightIcon = RightIcon;
