import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { IconInfo } from '@belong/icons';
import { Image, Money, Text, MoneyRange, Tooltip } from '@belong/ui';
import { MaintenancePriceRangeAggregateModel } from 'api/models';
import { FinancingInfobox } from 'post-inspection/components/financing-infobox/financing-infobox';
import { STRINGS } from 'post-inspection/constants/strings';
import { useImprovementsTotalCost } from 'post-inspection/hooks/use-improvements-total-cost';
import type { HomeOwnerPayment, ReportPayment } from 'post-inspection/models/improvements-report';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

type Props = {
  homeownerPayment: HomeOwnerPayment;
  isLegacyInvoicing: boolean;
  isMonthly: boolean;
  isPreMoveOut: boolean;
  maximumMonthlyPaymentPlanInstallmentCount: number;
  homeownerReportPayment: ReportPayment;
  priceRange?: MaintenancePriceRangeAggregateModel;
};

export const ImprovementsTotalCost = ({
  homeownerPayment,
  isLegacyInvoicing,
  isMonthly,
  isPreMoveOut,
  maximumMonthlyPaymentPlanInstallmentCount,
  homeownerReportPayment,
  priceRange,
}: Props) => {
  const { totalCost } = useImprovementsTotalCost({
    homeownerPayment,
    isLegacyInvoicing,
    isMonthly,
    maximumMonthlyPaymentPlanInstallmentCount,
    homeownerReportPayment,
  });

  const homeownerRange = priceRange?.priceRangeBreakdown.find((range) => range.paidBy === 'Homeowner');

  const { width } = useWindowSize();

  // todo: we may want to move this helpers to @belong/common
  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  if (isPreMoveOut) {
    return <FinancingInfobox />;
  }

  if (isLegacyInvoicing) {
    return (
      <div className="flex justify-end">
        <Money fontWeight="semibold" format="DOLLARS_WITH_CENTS" value={totalCost} variant="h3" />
      </div>
    );
  }

  return (
    <div className="flex flex-col sm:items-start sm:flex-row md:items-center sm:justify-between">
      <div className="flex items-start md:items-center mb-lg sm:mb-0">
        <div className="mr-xs">
          <Image alt="plan-it" className="h-auto w-[40px]" src="/improvement-reports/plan.svg" />
        </div>
        <div className="flex flex-col">
          <div className="mb-2xs">
            <Text variant={isMobile ? 'p1' : 'body'}>{POST_INSPECTION_FLOW_STRINGS['approvals.payment.plan']}</Text>
          </div>
          <div className="flex">
            <Text as="span" fontWeight="semibold" variant={isMobile ? 'p1' : 'body'}>
              {POST_INSPECTION_FLOW_STRINGS['approvals.est']}
            </Text>
            &nbsp;
            <Money
              as="span"
              fontWeight="semibold"
              format="DOLLARS_NO_CENTS"
              value={homeownerReportPayment.monthlyPayment}
              variant={isMobile ? 'p1' : 'body'}
            />
            <Text as="span" fontWeight="semibold" variant={isMobile ? 'p1' : 'body'}>
              /mo.
            </Text>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-col items-end">
          <div className="flex items-end mb-xs sm:mb-[3px] sm:mt-[-6px] lg:mt-[-7px]">
            <Text fontWeight="semibold">{POST_INSPECTION_FLOW_STRINGS['approvals.est']}</Text>
            &nbsp;
            {priceRange ? (
              <div className="flex gap-xs">
                <MoneyRange
                  lower={homeownerRange.lowerBoundTotal}
                  upper={homeownerRange.upperBoundTotal}
                  className="h3 font-semibold"
                  format="DOLLARS_WITH_CENTS"
                />
                <Tooltip
                  contentProps={{ className: 'z-50' }}
                  content={
                    <div className="p-2sm rounded bg-black max-w-md ">
                      <p className="p1 text-white font-regular">
                        We&apos;ve calculated a repair range factoring in potential scope changes that can affect the
                        final cost once a contractor takes a closer look. Belong will negotiate the lowest final price
                        with no mark-up. Greenlighting this range empowers our Pros to start work promptly.
                      </p>
                    </div>
                  }
                  arrowProps={{
                    className: 'fill-bg-black',
                  }}
                >
                  <div className="cursor-pointer" style={{ marginTop: '5px' }}>
                    <IconInfo width={20} />
                  </div>
                </Tooltip>
              </div>
            ) : (
              <Money fontWeight="semibold" format="DOLLARS_WITH_CENTS" value={totalCost} variant="h3" />
            )}
          </div>

          {Boolean(homeownerReportPayment.groupDueNow) && (
            <div className="mb-2xs">
              <Money
                as="span"
                format="DOLLARS_WITH_CENTS"
                value={homeownerReportPayment.groupDueNow}
                variant={isMobile ? 'p1' : 'body'}
              />
              &nbsp;
              <Text as="span" variant={isMobile ? 'p1' : 'body'}>
                {STRINGS['due-now']}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
