import React, { Component } from 'react';
import classNames from 'classnames/bind';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintAccountResponsibility/ModalMaintAccountResponsibility.module.css';
import ModalMaintenancePaymentFlowForHomeOwners from 'containercomponents/MaintenanceStatus/ModalMaintenancePaymentFlowForHomeOwners/ModalMaintenancePaymentFlowForHomeOwners';
import ModalMaintenancePaymentFlowForResidents from 'containercomponents/MaintenanceStatus/ModalMaintenancePaymentFlowForResidents/ModalMaintenancePaymentFlowForResidents';
// eslint-disable-next-line
import find from 'lodash/find';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const renderSplitResponsibilityQuestions = (task) => {
  // Owners are always responsible for repairs, unless the resident says they are claiming responsibility.
  if (task.requestType === 'Repair') {
    return false;
  }

  // Only show resident flow when the requestedBy is type Resident.
  if (task.requestedBy === 'Resident') {
    return true;
  }

  return false;
};

export default class ModalMaintAccountResponsibility extends Component {
  static propTypes = {
    isRenter: PropTypes.bool.isRequired,
    task: PropTypes.object.isRequired,
    renterSplitText: PropTypes.array.isRequired,
    shouldDoIt: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
  };

  renderAccountResponsibility() {
    const { close, task, isRenter, renterSplitText, shouldDoIt } = this.props;

    const homeOwnerResponsibility = find(task.payments, (payment) => payment.paidBy === 'Homeowner');
    const renterResponsibility = find(task.payments, (payment) => payment.paidBy === 'Resident');

    if (homeOwnerResponsibility && !isRenter) {
      return (
        <ModalMaintenancePaymentFlowForHomeOwners
          createMarginAndPaddingClassName={cx('margin-padding')}
          renterSplitText={renterSplitText}
          shouldDoIt={shouldDoIt}
          task={task}
          showResponsibilityQuestions={renderSplitResponsibilityQuestions(task)}
          isRenter={isRenter}
          close={close}
        />
      );
    } else if (renterResponsibility && isRenter) {
      return (
        <ModalMaintenancePaymentFlowForResidents
          createMarginAndPaddingClassName={cx('margin-padding')}
          task={task}
          isRenter={isRenter}
          close={close}
          renterResponsibility={renterResponsibility}
        />
      );
    }
  }

  render() {
    return this.renderAccountResponsibility();
  }
}
