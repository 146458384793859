// PLEASE READ DOCS BEFORE USING OR EDITING THIS COMPONENT

/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import styles from './String.module.css';

const cx = classNames.bind(styles);

export default class String extends Component {
  static propTypes = {
    string: PropTypes.node.isRequired,
    as: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    as: null,
    className: null,
  };

  parseLinks = (text) => {
    if (!text?.indexOf) {
      return '';
    }

    const linkStringStart = text?.indexOf('[');

    if (linkStringStart < 0) {
      return text;
    }

    const linkStringEnd = text.indexOf(']');
    const linkText = text.substring(linkStringStart + 1, linkStringEnd);
    const linkTagStart = linkStringEnd + 2;
    const linkTagEnd = text.indexOf(')', linkStringStart);
    const linkTag = text.substring(linkTagStart, linkTagEnd);
    const formatString = `${text.substring(0, linkStringStart)}<a href="${linkTag}">${linkText}</a>
         ${this.parseLinks(text.substring(linkTagEnd + 1))}`;
    return formatString;
  };

  createMarkup = () => {
    const { string } = this.props;

    if (isArray(string)) {
      const concatedString = string.map((s) => `<div>${this.parseLinks(s)}</div>`);
      return { __html: concatedString.join('') };
    }

    return { __html: this.parseLinks(string) };
  };

  render() {
    const { as, className } = this.props;
    const Tag = `${as || 'div'}`;
    return <Tag className={cx('string', className)} dangerouslySetInnerHTML={this.createMarkup()} />;
  }
}
