import { PATHS, template } from '@belong/common';
import { LinkBase, Text } from '@belong/ui';
import { ANALYTICS_CLICK_ACTIONS } from 'analytics';
import cx from 'classnames';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import { UserToDoTypes } from 'models/enums';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { TO_DO_STRINGS_MAP } from 'strings/to-do.strings';
import { pluralizeWithoutValue } from 'utils/pluralize';

export default function ToDoComponents({ type, metadata = {}, properties, openPricingModal, todos }) {
  if (type === 'HomeownerPlacementsQualificationFlowOpen') {
    return null;
  }

  const todoType = type;
  let linkPath = '';
  let label;
  let showIconButtonInRender = true;

  switch (type) {
    case UserToDoTypes.SetupEarningsPaymentMethod:
      linkPath = PATHS.HOMEOWNER_ACCOUNT_EARNINGS;
      break;
    case UserToDoTypes.HomeownerSetupFlowOpen: {
      const propertyWithoutSetupFlowCompleted = properties?.filter((property) => {
        if (property.getUnitsWithAgreements().length) {
          return property.units.some((unit) => !unit.isSetupFlowCompleted);
        }

        return false;
      });

      if (properties?.length > 1) {
        showIconButtonInRender = false;

        label = (
          <div className="flex flex-col w-full">
            {propertyWithoutSetupFlowCompleted.map((property, index) => {
              linkPath = `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${property?.basicInfo.propertyId}`;

              return (
                <div
                  key={`set-up-link-${property?.basicInfo.propertyId}`}
                  className={cx('flex justify-between items-center', {
                    'mb-xs': index + 1 !== propertyWithoutSetupFlowCompleted.length,
                  })}
                >
                  <LinkBase href={linkPath}>
                    <Text as="span" fontWeight="semibold">
                      SET UP{' '}
                    </Text>
                    <Text as="span">{property?.address.streetAddress}</Text>
                  </LinkBase>
                  <div className="flex">
                    <IconButton align={ALIGN_TYPES.RIGHT} to={linkPath} icon={GENERAL_ICONS.FRONT_ARROW} />
                  </div>
                </div>
              );
            })}
          </div>
        );
      } else {
        linkPath = `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${properties?.[0]?.basicInfo.propertyId}`;

        label = (
          <LinkBase href={linkPath}>
            <Text as="span" fontWeight="semibold">
              SET UP YOUR HOME
            </Text>
          </LinkBase>
        );
      }

      break;
    }
    case UserToDoTypes.AgreementFlowOpen: {
      const agreementFlowOpenTodos = todos.filter((todo) => todo.type === UserToDoTypes.AgreementFlowOpen);
      const { homesInAgreement, flowId, flowStepId } = metadata;
      linkPath = `${BASE_PATHS.AGREEMENT_FLOW}/plans/${flowId}/${flowStepId}`;

      if (agreementFlowOpenTodos.length > 1) {
        const [firstHomeInAgreement] = homesInAgreement;
        const homesInAgreementLen = homesInAgreement.length;

        if (homesInAgreementLen === 1) {
          label = (
            <LinkBase href={linkPath}>
              <Text as="span" fontWeight="semibold">
                SIGN AGREEMENT
              </Text>{' '}
              <Text as="span" className="uppercase">
                FOR {firstHomeInAgreement.address}
              </Text>
            </LinkBase>
          );
        } else {
          label = (
            <LinkBase href={linkPath}>
              <Text as="span" fontWeight="semibold">
                SIGN AGREEMENT
              </Text>{' '}
              <Text as="span" className="uppercase">
                FOR {firstHomeInAgreement.address} + {homesInAgreementLen} other{' '}
                {pluralizeWithoutValue(homesInAgreementLen, 'home')}
              </Text>
            </LinkBase>
          );
        }
      }

      break;
    }
    case UserToDoTypes.PendingLeaseSignature:
      linkPath = `/${metadata.cta}`;
      break;
    case UserToDoTypes.InitialPriceReportReview: {
      const unitProperty = properties?.find((property) =>
        property.units.some((unit) => unit?.basicInfo?.id.toString() === metadata?.unitId)
      );
      const currentUnit = unitProperty?.units?.find((unit) => unit?.basicInfo?.id.toString() === metadata?.unitId);

      if (currentUnit?.isInitialPriceRangePublished) {
        label = (
          <Button
            action={ANALYTICS_CLICK_ACTIONS.INITIAL_ESTIMATE_OPEN}
            onClick={() => openPricingModal(currentUnit, unitProperty?.address)}
            buttonType={BUTTON_TYPES.NOSTYLE}
          >
            <Text fontWeight="semibold">{`${TO_DO_STRINGS_MAP[todoType]} - ${
              unitProperty?.address?.streetAddress
            } - Unit ${currentUnit?.basicInfo?.unitNumber || '1'}`}</Text>
          </Button>
        );
      } else {
        return null;
      }

      break;
    }
    case UserToDoTypes.WealthAnalysisFlowOpen: {
      label = (
        <LinkBase href={`${PATHS.HOMEOWNER_ACCOUNT_WEALTH_ANALYSIS_FLOW}?flowId=${metadata.flowId}`}>
          <Text as="span">
            {template(TO_DO_STRINGS_MAP[todoType], {
              address: metadata.homeAddress.address,
            })}
          </Text>
        </LinkBase>
      );
      break;
    }
    case UserToDoTypes.W9FlowOpen:
      linkPath = PATHS.W9_FLOW.replace(':flowId', metadata.flowId);
      label = (
        <LinkBase href={linkPath}>
          <Text fontWeight="semibold">
            {TO_DO_STRINGS_MAP[todoType]} FOR {metadata.legalEntityName}
          </Text>
        </LinkBase>
      );
      break;
    case UserToDoTypes.SetupRentPaymentMethod:
    default:
      linkPath = PATHS.RESIDENTS_ACCOUNT_PAYMENTS;
      break;
  }

  if (!label) {
    label = (
      <LinkBase href={linkPath}>
        <Text fontWeight="semibold">{TO_DO_STRINGS_MAP[todoType]}</Text>
      </LinkBase>
    );
  }

  return (
    <div className="flex justify-between items-center">
      {label}
      {showIconButtonInRender && (
        <div className="flex">
          <IconButton align={ALIGN_TYPES.RIGHT} to={linkPath} icon={GENERAL_ICONS.FRONT_ARROW} />
        </div>
      )}
    </div>
  );
}

ToDoComponents.propTypes = {
  type: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  properties: PropTypes.array,
  openPricingModal: PropTypes.func,
  todos: PropTypes.array.isRequired,
};
