import React from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import styles from './AgreeToTermsFooter.module.css';

const cx = classNames.bind(styles);

const AgreeToTermsFooter = (
  <div className={cx('agree-to-terms')}>
    By continuing, I agree to Belong’s
    <br />
    <div className={cx('second-line')}>
      <Button to={{ pathname: '/privacy-policy' }} className={cx('agree-to-terms-bold')} buttonType={BUTTON_TYPES.TEXT}>
        PRIVACY POLICY
      </Button>
      and
      <Button
        to={{ pathname: '/terms-and-conditions' }}
        className={cx('agree-to-terms-bold')}
        buttonType={BUTTON_TYPES.TEXT}
      >
        TERMS OF SERVICE
      </Button>
    </div>
  </div>
);

export default AgreeToTermsFooter;
