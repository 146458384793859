import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { VerificationConsumerModel, VerificationsDetailModel } from 'api/models';
import { createPersonaInquiries, fetchUserVerification, getPersonaSessionToken } from 'store/redux/user/actions';

export function useStartPersonaInquiry({ onComplete, onCancelled, onError }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const startPersonaInquiry = useCallback(async () => {
    setLoading(true);
    const inquiryResponse = await dispatch(createPersonaInquiries());
    const personaInquiryId = inquiryResponse?.data?.id;

    if (personaInquiryId) {
      try {
        const sessionTokenResponse = await dispatch(getPersonaSessionToken(personaInquiryId));
        const personaSessionToken = sessionTokenResponse?.sessionToken;
        if (personaSessionToken) {
          const client = new (window as any).Persona.Client({
            inquiryId: personaInquiryId,
            sessionToken: personaSessionToken,
            onReady: () => client.open(),
            onComplete: (...params) => {
              onComplete(...params);
              setLoading(false);
            },
            onCancel: onCancelled,
            onError,
          });
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [dispatch, onCancelled, onComplete, onError]);

  return {
    startPersonaInquiry,
    isRetrievingPersonaIdentifiers: loading,
  };
}

const isVerificationInStatus = (
  status: VerificationConsumerModel['status'],
  userVerificationsDetail?: VerificationsDetailModel[]
) =>
  !!userVerificationsDetail?.find((userVerificationDetail) =>
    userVerificationDetail.verifications?.find((verification) => verification.status === status)
  );

const isPersonaVerificationCompleted = (userVerificationsDetail?: VerificationsDetailModel[]) =>
  isVerificationInStatus('Completed', userVerificationsDetail);

const isPersonaVerificationFailed = (userVerificationsDetail?: VerificationsDetailModel[]) =>
  isVerificationInStatus('Failed', userVerificationsDetail);

const isPersonaVerificationProcessing = (userVerificationsDetail?: VerificationsDetailModel[]) =>
  isVerificationInStatus('Processing', userVerificationsDetail);

export const useIsPersonaVerificationCompleted = () => {
  const [verification, setVerification] = useState<VerificationsDetailModel[]>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();

  async function fetchData() {
    setLoading(true);
    try {
      const data = await dispatch(
        fetchUserVerification({
          verificationType: 'Identity',
          verificationVendorProduct: 'Persona',
        })
      );
      setVerification(data as any);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setHasError(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return {
    isPersonaVerificationCompleted: isPersonaVerificationCompleted(verification),
    isPersonaVerificationFailed: isPersonaVerificationFailed(verification),
    isPersonaVerificationProcessing: isPersonaVerificationProcessing(verification),
    personaVerificationRetrievalFailed: hasError,
    isRetrievingPersonaVerificationsDetail: loading,
    refetchPersonaVerificationsDetail: fetchData,
  };
};