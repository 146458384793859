export const ResidentType = {
  Primary: 'Primary',
  Roommate: 'Roommate',
  CoSigner: 'CoSigner',
};

export const PropertyType = {
  SingleFamily: 'SingleFamily',
  MultiUnit: 'MultiUnit',
  Other: 'Other',
};

// DEPRECATED. DON'T USE.
export const UnitStatus = {
  ApplicationPending: 'ApplicationPending',
  InspectionPending: 'InspectionPending',
  InspectionScheduled: 'InspectionScheduled',
  InspectionCompleted: 'InspectionCompleted',
};

export const Milestone = {
  None: 'None',
  AgreementSigned: 'AgreementSigned',
  InspectionScheduled: 'InspectionScheduled',
  InspectionCompleted: 'InspectionCompleted',
  PostInspectionProposalSent: 'PostInspectionProposalSent',
  PostInspectionProposalApproved: 'PostInspectionProposalApproved',
  ShowReady: 'ShowReady',
  FullListingPublished: 'FullListingPublished',
  LeaseExecuted: 'LeaseExecuted',
  MoveInReady: 'MoveInReady',
  ResidentMovedIn: 'ResidentMovedIn',
  ManagingAdoptedResidents: 'ManagingAdoptedResidents',
  ResidentGaveMoveOutNotice: 'ResidentGaveMoveOutNotice',
  ListingRepublished: 'ListingRepublished',
};

export const MediaFor = {
  Interior: 'Interior',
  Exterior: 'Exterior',
  Banner: 'Banner',
};

export const MediaType = {
  Video: 'Video',
  Image: 'Image',
  PDF: 'Pdf',
  CSV: 'Csv',
};

export const RequestType = {
  Repair: 'Repair',
  Addition: 'Addition',
  Upgrade: 'Upgrade',
  Preventative: 'Preventative',
  Recondition: 'Recondition',
  PickupAndDelivery: 'PickupAndDelivery',
  HaulingAndStorage: 'HaulingAndStorage',
  Cleaning: 'Cleaning',
  Inspection: 'Inspection',
  KeysAndAccess: 'KeysAndAccess',
  Photography: 'Photography',
  Investigation: 'Investigation',
  Group: 'Group',
};

export const Urgency = {
  Now: 'Now',
  Soon: 'Soon',
  NextVisit: 'NextVisit',
};
export const AssessmentResult = {
  Required: 'Required',
  Recommended: 'Recommended',
  NotEligible: 'NotEligible',
};
export const DisplayStatus = {
  Approved: 'Approved',
  Withdrawn: 'Withdrawn',
  NotApproved: 'NotApproved',
  Applied: 'Applied',
  CanceledTour: 'CanceledTour',
  ScheduledTour: 'ScheduledTour',
  CompletedTour: 'CompletedTour',
};
export const Status = {
  Applied: 'Applied',
  ConditionallyApproved: 'ConditionallyApproved',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Withdrawn: 'Withdrawn',
  NotSelected: 'NotSelected',
};
export const TourStatus = {
  Scheduled: 'Scheduled',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};
export const TourAttendeeStatus = {
  Attended: 'Attended',
  Invited: 'Invited',
  DidNotAttend: 'DidNotAttend',
  Canceled: 'Canceled',
  Booked: 'Booked',
  Unknown: 'Unknown',
};
export const PaidBy = {
  Owner: 'Owner',
  Resident: 'Resident',
  Both: 'Both',
};
export const PaymentAgreement = {
  OwnerPays: 'OwnerPays',
  SplitPayment: 'SplitPayment',
  ResidentPays: 'ResidentPays',
  Declined: 'Declined',
};
export const MaintenanceType = {
  Paint: 'Paint',
  DeepClean: 'DeepClean',
  CarpetReplacement: 'CarpetReplacement',
  CustomRequest: 'CustomRequest',
};
export const Preference = {
  Definitely: 'Definitely',
  NoWay: 'NoWay',
  Sometimes: 'Sometimes',
};
export const ContactPreference = {
  PhoneCall: 'PhoneCall',
  VideoCall: 'VideoCall',
  InPerson: 'InPerson',
};

export const SubType = {
  Highrise: 'Highrise',
  Midrise: 'Midrise',
  Lowrise: 'Lowrise',
  Triplex: 'Triplex',
  Duplex: 'Duplex',
  InLaw: 'InLaw',
};
export const Condition = {
  QuiteRundown: 'QuiteRundown',
  NeedsWork: 'NeedsWork',
  QuiteAverage: 'QuiteAverage',
  PrettyGood: 'PrettyGood',
  ShinyPalace: 'ShinyPalace',
};

export const PropertySubType = {
  Highrise: 'Highrise',
  Midrise: 'Midrise',
  Lowrise: 'Lowrise',
  Triplex: 'Triplex',
  Duplex: 'Duplex',
  InLaw: 'InLaw',
};
export const Items = {
  Free: 'Free',
  CoinOperated: 'CoinOperated',
};
export const PetType = {
  Dog: 'Dog',
  Cat: 'Cat',
  Other: 'Other',
};

export const UnitPetFeeOption = {
  PerPet: 'PerPet',
  FlatFee: 'FlatFee',
};

export const Relationship = {
  Parent: 'Parent',
  Sibling: 'Sibling',
  Spouse: 'Spouse',
  Relative: 'Relative',
  Descendant: 'Descendant',
  Roommate: 'Roommate',
  Friend: 'Friend',
  Other: 'Other',
};
export const RentalHistory = {
  CurrentlyRenting: 'CurrentlyRenting',
  NeverRentBefore: 'NeverRentBefore',
  RentBefore: 'RentBefore',
};
export const LoginProvider = {
  Facebook: 'Facebook',
  LinkedIn: 'LinkedIn',
  Google: 'Google',
};
export const PaymentMethod = {
  Ach: 'Ach',
  CreditCard: 'CreditCard',
  WireTransfer: 'WireTransfer',
  Paypal: 'PayPal',
  Check: 'Check',
  Other: 'Other',
};
export const VerificationMethod = {
  Instant: 'Instant',
  MicroDeposit: 'MicroDeposit',
};
export const VerificationType = {
  Instant: 'Instant',
  Manual: 'Manual',
};
export const MicroDepositStatus = {
  Initiated: 'Initiated',
  Pending: 'Pending',
  Verified: 'Verified',
  Failed: 'Failed',
};
export const CardType = {
  Visa: 'Visa',
  MasterCard: 'MasterCard',
  AmericanExpress: 'AmericanExpress',
  Discover: 'Discover',
  JCB: 'JCB',
  Maestro: 'Maestro',
  Cirrus: 'Cirrus',
  UnionPay: 'UnionPay',
  DinersClub: 'DinersClub',
  Unknown: 'Unknown',
};

export const RentalStatus = {
  Vacant: 'Vacant',
  RenterOccupied: 'RenterOccupied',
  OwnerOccupied: 'OwnerOccupied',
  FamilyOccupied: 'FamilyOccupied',
};

export const UnitVisitType = {
  SelfTour: 'SelfTour',
  OpenHome: 'OpenHome',
};

export const UserVerificationType = {
  Identity: 'Identity',
  Income: 'Income',
  Criminal: 'Criminal',
  Eviction: 'Eviction',
  Credit: 'Credit',
  Reference: 'Reference',
};

export const IdVerificationDocuments = {
  DL: 'license',
  PASS: 'Passport',
  N_ID: 'national-id',
  OTHER: 'other',
};

export const PaymentPreference = {
  Maintenance: 'Maintenance',
  Rent: 'Rent',
  Payout: 'Payout',
  RentalHomeInsurance: 'RentalHomeInsurance',
};

export const PaymentPriority = {
  Primary: 'Primary',
  Backup: 'Backup',
};

export const TransactionStatus = {
  Initiated: 'Initiated',
  Completed: 'Completed',
  Failed: 'Failed',
  Canceled: 'Canceled',
};

export const ApplicantType = {
  MainApplicant: 'MainApplicant',
  CoApplicant: 'CoApplicant',
  CoSigner: 'CoSigner',
};

export const PaymentBasicInfoStatus = {
  Pending: 'Pending',
  Initiated: 'Initiated',
  Completed: 'Completed',
  Failed: 'Failed',
  Void: 'Void',
};

export const HousePageTabs = {
  ExteriorPhotos: 'ExteriorPhotos',
  RoomsPhotos: 'RoomsPhotos',
  Virtual3DTour: 'Virtual3DTour',
  StreetView: 'StreetView',
  Map: 'Map',
  GuidedTour: 'GuidedTour',
};

export const FeeOptions = {
  Percentage: 'Percentage',
  FlatFee: 'FlatFee',
};

export const UserToDoTypes = {
  SetupRentPaymentMethod: 'SetupRentPaymentMethod',
  SetupEarningsPaymentMethod: 'SetupEarningsPaymentMethod',
  SetupProfile: 'SetupProfile',
  HomeownerSetupFlowOpen: 'HomeownerSetupFlowOpen',
  PostInspectionFlowOpen: 'PostInspectionFlowOpen',
  AgreementFlowOpen: 'AgreementFlowOpen',
  PendingLeaseSignature: 'PendingLeaseSignature',
  FarOffMoveout: 'FarOffMoveout',
};

export const MaintenanceStatus = {
  PendingAssessment: 'PendingAssessment',
  AssessmentScheduled: 'AssessmentScheduled',
  PendingQuote: 'PendingQuote',
  PendingOwnerConsent: 'PendingOwnerConsent',
  OwnerRejected: 'OwnerRejected',
  PendingResidentConsent: 'PendingResidentConsent',
  ResidentRejected: 'ResidentRejected',
  PendingScheduling: 'PendingScheduling',
  AppointmentScheduled: 'AppointmentScheduled',
  Completed: 'Completed',
  RequireFollowup: 'RequireFollowup',
  Rejected: 'Rejected',
  Canceled: 'Canceled',
};

export const BillType = {
  Payment: 'Payment',
  Payout: 'Payout',
};

export const MaintenanceStatusDisplay = {
  New: 'Not Started',
  InProgress: 'In Progress',
  Closed: 'Closed',
};

export const BillStatus = {
  Unpublished: 'Unpublished',
  Published: 'Published',
  Paid: 'Paid',
  Failed: 'Failed',
  Refunded: 'Refunded',
  PartiallyRefunded: 'PartiallyRefunded',
  Void: 'Void',
  // Custom fields I don't know how to handle right now
  Initiated: 'Initiated',
  Unpaid: 'Unpaid',
  PartialPayment: 'PartialPayment',
};

export const PetsListingFilters = {
  AllPets: 'AllPets',
  Dogs: 'Dogs',
  Cats: 'Cats',
  NoPets: 'NoPets',
};

export const FeatureCategories = {
  Parking: 'Parking',
  'Climate Control': 'Climate Control',
  Laundry: 'Laundry',
  Cooking: 'Cooking',
  Lighting: 'Lighting',
  Aesthetics: 'Aesthetics',
  'Audio & Video': 'Audio & Video',
  Fitness: 'Fitness',
  Other: 'Other',
  Pool: 'Pool',
  'Safety & Security': 'Safety & Security',
  Storage: 'Storage',
  Surroundings: 'Surroundings',
  Utilities: 'Utilities',
  Yard: 'Yard',
  Accessibility: 'Accessibility',
};

export const FeatureHighlights = {
  DrivewayParking: 'Driveway Parking',
  LotParking: 'Lot Parking',
  GarageParking: 'Garage Parking',
  CarportParking: 'Carport Parking',
  ChargingStation: 'Charging Station',
  OnStreetPermit: 'On-Street Permit',
  Hood: 'Hood',
  Heat: 'Heat',
  'A/C': 'A/C',
  Thermostat: 'Thermostat',
  AlleyOffStreetParking: 'Alley / Off-Street Parking',
};

export const SetupDataTypes = {
  Home: 'Home',
  Property: 'Property',
  User: 'User',
  UserPayment: 'UserPayment',
};

export const FlowStatus = {
  Created: 'Created',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const StepStatus = {
  Created: 'Created',
  Started: 'Started',
  Completed: 'Completed',
  Incomplete: 'Incomplete',
};

export const HomeownerSetupStepNames = {
  UserPersonal: 'UserPersonal',
  UserProfile: 'UserProfile',
  UserIdentity: 'UserIdentity',
  UserTaxes: 'UserTaxes',
  UserPaymentMethods: 'UserPaymentMethods',
  PropertyFeatures: 'PropertyFeatures',
  PropertyHoa: 'PropertyHoa',
  PropertyInsurance: 'PropertyInsurance',
  UnitOccupancy: 'UnitOccupancy',
  UnitFeatures: 'UnitFeatures',
  UnitPreferencesPets: 'UnitPreferencesPets',
  UnitPreferencesUtilities: 'UnitPreferencesUtilities',
  UnitKeys: 'UnitKeys',
  UnitInspectionPreferences: 'UnitInspectionPreferences',
  UnitDisclosures: 'UnitDisclosures',
  UnitListing: 'UnitListing',
  UnitResidentExperience: 'UnitResidentExperience',
};

export const FlowStepDataType = {
  Home: 'Home',
  Property: 'Property',
  User: 'User',
  Agreement: 'Agreement',
  UserPayment: 'UserPayment',
  Report: 'Report',
};

export const HomeownerSignUpFlowStepType = {
  PropertyBasics: 'PropertyBasics',
  UnitBasics: 'UnitBasics',
  UnitConditions: 'UnitConditions',
  UnitOccupancy: 'UnitOccupancy',
  UnitMaintenanceSpend: 'UnitMaintenanceSpend',
  HomeownerMaintenancePreferences: 'HomeownerMaintenancePreferences',
  HomeownerMaintenanceInvolvement: 'HomeownerMaintenanceInvolvement',
};

export const STATUS = {
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_TOUCHED: 'NOT_TOUCHED',
};

export const PostInspectionFlowDataType = {
  Home: 'Home',
  Property: 'Property',
  User: 'User',
  Report: 'Report',
};

export const PostInspectionFlowStep = {
  ImprovementsGetStarted: 'ImprovementsGetStarted',
  ImprovementsFeatures: 'ImprovementsFeatures',
  ImprovementsResponsibility: 'ImprovementsResponsibility',
  ImprovementsReview: 'ImprovementsReview',
  ImprovementsQuality: 'ImprovementsQuality',
  ImprovementsApproval: 'ImprovementsApproval',
  ImprovementsSuccess: 'ImprovementsSuccess',
  PricingGetStarted: 'PricingGetStarted',
  PricingSupplyAndDemand: 'PricingSupplyAndDemand',
  PricingRelatedListings: 'PricingRelatedListings',
  PricingInitialListingPrice: 'PricingInitialListingPrice',
  PricingWaysToShowYourHome: 'PricingWaysToShowYourHome',
  PricingCheckInPrice: 'PricingCheckInPrice',
  PricingSmoothPayment: 'PricingSmoothPayment',
};

export const PostInspectionGroup = {
  Improvements: 'Improvements',
  Pricing: 'Pricing',
};

export const FlowType = {
  HomeownerSetup: 'HomeownerSetup',
  PostInspection: 'PostInspection',
  Agreement: 'Agreement',
  PostInspectionHomeownerMoveout: 'PostInspectionHomeownerMoveout',
  HomeownerSignUp: 'HomeownerSignUp',
};

export const ReportType = {
  Improvements: 'Improvements',
  Pricing: 'Pricing',
} as const;

export const ReportSubType = {
  ImprovementsRepairsInspection: 'ImprovementsRepairsInspection',
  ImprovementsMoveOutInspection: 'ImprovementsMoveOutInspection',
  PricingPostInspection: 'PricingPostInspection',
  PricingPostMoveOut: 'PricingPostMoveOut',
};

export const ReportStatus = {
  Unpublished: 'Unpublished',
  Published: 'Published',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const AgreementFlowStepNames = {
  Plan: 'Plan',
  UserPersonal: 'UserPersonal',
  Ownership: 'Ownership',
  Payment: 'Payment',
  Sign: 'Sign',
};

export const AgreementFlowDataTypes = {
  Agreement: 'Agreement',
  User: 'User',
};

export const LeaseSigningFlowStepNames = {
  LandingPage: 'LandingPage',
  Wallet: 'Wallet',
  WearAndTear: 'WearAndTear',
  Deposit: 'Deposit',
  Sign: 'Sign',
  Success: 'Success',
};

export const IMAGE_FILE_TYPE = {
  HoaAgreement: 'HoaAgreement',
  Insurance: 'Insurance',
  PetCertification: 'PetCertification',
  OnboardingLease: 'OnboardingLease',
  OfferLetter: 'OfferLetter',
  PAYSTUB: 'Paystub',
  BANK_STATEMENT: 'BankStatement',
  IdFront: 'IdFront',
  IdBack: 'IdBack',
  Selfie: 'Selfie',
  Homeowner1099: 'Homeowner1099',
};

export const HeatFeatureTypes = {
  Electric: 'Electric',
  Gas: 'Gas',
};

export const AcFeatureTypes = {
  CentralAc: 'CentralAc',
  WindowUnits: 'WindowUnits',
};

export const ParkingTypes = {
  GarageParking: 'Garage Parking',
  LotParking: 'Lot Parking',
  CarportParking: 'Carport Parking',
  DrivewayParking: 'Driveway Parking',
  OffStreetParking: 'Alley/ Off-Street Parking',
  OnStreetParking: 'On-Street Permit',
};

export const HomeType = {
  Studio: 'Studio',
  Loft: 'Loft',
};

export const HomeLevelType = {
  Penthouse: 'Penthouse',
  GroundLevel: 'GroundLevel',
  BasementLevel: 'BasementLevel',
};

export const SupportedHomeFeatureNames = {
  Heat: 'Heat',
  Ac: 'A/C',
  Laundry: 'Laundry',
  Parking: 'Parking',
  Pool: 'Pool',
  Lawn: 'Lawn',
  Trees_Landscaping: 'Trees/ Landscaping/ Garden',
  Garden: 'Garden',
  Storage: 'Storage',
};

export const HomeFeatureCategories = {
  'Climate Control': 'Climate Control',
  Laundry: 'Laundry',
  Parking: 'Parking',
  Pool: 'Pool',
  Yard: 'Yard',
  Storage: 'Storage',
};

export const UtilityResponsibility = {
  ResidentsResponsibility: 'ResidentsResponsibility',
  IncludedInRent: 'IncludedInRent',
  AvailableForPurchase: 'AvailableForPurchase',
  ProvidedByHoa: 'ProvidedByHoa',
  AdditionalRequiredFee: 'AdditionalRequiredFee',
};

export const UtilityBillingHoaType = {
  IncludedInHoa: 'IncludedInHoa',
  BilledSeparately: 'BilledSeparately',
};

export const SupportedUtilities = {
  Water: 'Water',
  Gas: 'Gas',
  Electricity: 'Electricity',
  Trash: 'Trash',
  Sewage: 'Sewage',
  Cable: 'Cable',
  Internet: 'Internet',
  Cleaning: 'Cleaning',
  Storage: 'Storage',
  Parking: 'Parking',
  Landscaping: 'Landscaping',
  PoolServicing: 'PoolServicing',
};

export const BillingHoaTypes = {
  IncludedInHoa: 'IncludedInHoa',
  BilledSeparately: 'BilledSeparately',
};

export const BillingManagedBy = {
  Provider: 'Provider',
  Homeowner: 'Homeowner',
  Hoa: 'HOA',
};

export const UtilityPaymentFrequency = {
  Monthly: 'Monthly',
  OneTime: 'OneTime',
  Quarterly: 'Quarterly',
  Annually: 'Annually',
};

export const SupportHomeKeys = {
  HouseKey: 'HouseKey',
  FrontDoor: 'FrontDoor',
  BackDoor: 'BackDoor',
  SideDoor: 'SideDoor',
  Lobby: 'LobbyMainEnterance',
  Gate: 'Gate',
  Garage: 'Garage',
  Mailbox: 'Mailbox',
  Mailroom: 'Mailroom',
  Storage: 'Storage',
};

export const KeyTypes = {
  Key: 'Key',
  AccessCode: 'AccessCode',
  KeyFob: 'KeyFob',
  Remote: 'Remote',
  OpenAccess: 'OpenAccess',
};

export const KeysDeliveredBy = {
  Mail: 'Mail',
  DropOff: 'DropOff',
  Uber: 'Uber',
  Lockbox: 'Lockbox',
  Inspection: 'Inspection',
};

export const SupportedPropertyFeatureNames = {
  Gym: 'Gym',
  Grill: 'Grill/BBQ',
  Tennis: 'Tennis Court',
  Basketball: 'Basketball Court',
  Yoga: 'Yoga Studio',
  Golf: 'Golf Course',
  Laundry: 'Laundry Room (communal)',
  ChargingStation: 'Charging Station',
  Pool: 'Pool',
  Jacuzzi: 'Hot Tub/ Jacuzzi',
  Spa: 'Spa',
  Sauna: 'Sauna',
  SteamRoom: 'Steam Room',
  Doorman: 'Doorman/ Reception',
  Mailroom: 'Mail Room',
};

export const PropertyFeatureCategories = {
  'Climate Control': 'Climate Control',
  Laundry: 'Laundry',
  Fitness: 'Fitness',
  Cooking: 'Cooking',
  Pool: 'Pool',
  Parking: 'Parking',
  Safety: 'Safety & Security',
};

export const AvailiabilityTimes = {
  AllDay: 'AllDay',
  Morning: 'Morning',
  Afternoon: 'Afternoon',
  Evening: 'Evening',
};

export const HaulingTypes = {
  Haul: 'Haul',
  Storage: 'Storage',
};

export const MissingKeyTypes = {
  MissingKey: 'MissingKey',
  Rekey: 'Rekey',
  LockedOut: 'LockedOut',
  Other: 'Other',
};

export const RentalManagement = {
  SelfManaging: 'SelfManaging',
  PropertyManager: 'PropertyManager',
  FriendFamilyMember: 'FriendFamilyMember',
};

export const MoveOutOptions = {
  OnSpecificDate: 'OnSpecificDate',
  InDateRange: 'InDateRange',
  WhenResidentsAreFound: 'WhenResidentsAreFound',
  NotSure: 'NotSure',
  AlreadyDid: 'AlreadyDid',
};

export const AvailableForRentOptions = {
  OnSpecificDate: 'OnSpecificDate',
  InCertainMonth: 'InCertainMonth',
  NotSure: 'NotSure',
  AlreadyIs: 'AlreadyIs',
};

export const InvolvementLevel = {
  SendMeTheChecks: 'SendMeTheChecks',
  KeepMeLooped: 'KeepMeLooped',
  WillTakeAllDecisions: 'WillTakeAllDecisions',
};

export const RentalPlan = {
  LessThanYear: 'LessThanYear',
  Year: 'Year',
  FewYears: 'FewYears',
  ForeseeableFuture: 'ForeseeableFuture',
};

export const HomeownerCreationSource = {
  Ingested: 'Ingested',
  Admin: 'Admin',
  AskedAQuestion: 'AskedAQuestion',
  SignUpFlow: 'SignUpFlow',
  Calculator: 'Calculator',
  TryThePros: 'TryThePros',
  JoinWaitlist: 'JoinWaitlist',
};

export const AppliancesAge = {
  Older: 'Older',
  RecentlyUpdated: 'RecentlyUpdated',
  BrandNew: 'BrandNew',
};

export const PayoutPlan = {
  Monthly: 'Monthly',
  Yearly: 'Yearly',
};

export const ApplicantStatus = {
  Invited: 'Invited',
  Started: 'Started',
  Submitted: 'Submitted',
  VerificationsCompleted: 'VerificationsCompleted',
};

export const ApplicationStatus = {
  Started: 'Started',
  Submitted: 'Submitted',
  VerificationsCompleted: 'VerificationsCompleted',
  Cancelled: 'Cancelled',
  Merged: 'Merged',
};

export const ListingApplicationStatus = {
  None: 'None',
  Qualified: 'Qualified',
  CoSignerRequired: 'CoSignerRequired',
  NotQualified: 'NotQualified',
  Withdrawn: 'Withdrawn',
  Rejected: 'Rejected',
  Waitlisted: 'Waitlisted',
  NotOffered: 'NotOffered',
  Approved: 'Approved',
};

export const ApplicationCta = {
  NoApplication: 'NoApplication',
  HasApplications: 'HasApplications',
  AllApplicationsCompleted: 'AllApplicationsCompleted',
};

export const IncomeSourceType = {
  Job: 'Job',
  Contract: 'Contract',
  Additional: 'Additional',
};

export const InterestLevel = {
  Interested: 'Interested',
  Neutral: 'Neutral',
  NotInterested: 'NotInterested',
};

export const Weekdays = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
};

export const GarbageDayFrequency = {
  Weekly: 'Weekly',
  Biweekly: 'Biweekly',
  Monthly: 'Monthly',
};

export const FeatureOwner = {
  Room: 'Room',
  Space: 'Space',
};

export const MaintenanceRequester = {
  Homeowner: 'Homeowner',
  Resident: 'Resident',
  Belong: 'Belong',
};

export const MaintenanceResponsibility = {
  Homeowner: 'Homeowner',
  Resident: 'Resident',
  Belong: 'Belong',
};

export const ConsentStatus = {
  Approved: 'Approved',
  Rejected: 'Rejected',
  Required: 'Required',
  NotRequired: 'NotRequired',
};

export const EmployeeAssignmentTypes = {
  Pro: 'Pro',
  Concierge: 'Concierge',
  HomeownerGrowth: 'HomeownerGrowth',
  ResidentGrowth: 'ResidentGrowth',
  PricingAnalyst: 'PricingAnalyst',
  SalesOperation: 'SalesOperation',
  ImprovementsAssessor: 'ImprovementsAssessor',
  HeadOfOperations: 'HeadOfOperations',
  HeadOfExperience: 'HeadOfExperience',
  SalesDevelopmentRepresentative: 'SalesDevelopmentRepresentative',
};

export const MaintenanceBundleType = {
  SafetyAndSecurity: 'SafetyAndSecurity',
  Habitability: 'Habitability',
  CodeCompliance: 'CodeCompliance',
  AppliancesAndEquipment: 'AppliancesAndEquipment',
  VacancyReduction: 'VacancyReduction',
  Inspirational: 'Inspirational',
  Preventative: 'Preventative',
  Recommended: 'Recommended',
};

export const AvailabilityFilter = {
  Available: 'Available',
};
export const MobileView = {
  List: 'List',
  Map: 'Map',
};

export const MaintenanceAssessment = {
  Required: 'Required',
  Recommended: 'Recommended',
  Inspirational: 'Inspirational',
};

export const MaintenanceBundleRewardType = {
  Discount: 'Discount',
  MonthlyInstallment: 'MonthlyInstallment',
};

export const MaintenancePaymentPlan = {
  OneTime: 'OneTime',
  Monthly: 'Monthly',
} as const;

export const MaintenancePaymentMethod = {
  Check: 'Check',
  CreditCard: 'CreditCard',
};

export const CustomMaintenancePaymentPlan = {
  FromRent: 'FromRent',
  UponCompletion: 'UponCompletion',
};

export const BusinessEntityTypes = {
  CCorporation: 'CCorporation',
  SCorporation: 'SCorporation',
  Partnership: 'Partnership',
  TrustOrEstate: 'TrustOrEstate',
  LLC: 'LLC',
  Other: 'Other',
};

export const LLCTaxClassification = {
  SingleMemberLLC: 'SingleMemberLLC',
  CCorporation: 'CCorporation',
  SCorporation: 'SCorporation',
  Partnership: 'Partnership',
};

export const Disclosures = {
  ConstructedBefore1978: 'ConstructedBefore1978',
  Asbestos: 'Asbestos',
  AsbestosTreatment: 'AsbestosTreatment',
  AsbestosReports: 'AsbestosReports',
  LeadPaint: 'LeadPaint',
  LeadPaintTreatment: 'LeadPaintTreatment',
  LeadPaintReports: 'LeadPaintReports',
  Death: 'Death',
  SharedGasMeters: 'SharedGasMeters',
  SharedElectricMeters: 'SharedElectricMeters',
  SharedWaterMeters: 'SharedWaterMeters',
  Mold: 'Mold',
  MoldTreatment: 'MoldTreatment',
  MoldReports: 'MoldReports',
  NoWaterConservingPlumbing: 'NoWaterConservingPlumbing',
  MilitaryOrdnanceNearby: 'MilitaryOrdnanceNearby',
  IntentionToDemolishHome: 'IntentionToDemolishHome',
  IsLocatedNearFloodZone: 'IsLocatedNearFloodZone',
  MortgageRequiresFloodInsurance: 'MortgageRequiresFloodInsurance',
  HasFloodInsurance: 'HasFloodInsurance',
  OtherMaterialFacts: 'OtherMaterialFacts',
  IsIn100YearFloodPlain: 'IsIn100YearFloodPlain',
  FloodedInLast5Years: 'FloodedInLast5Years',
  FloodedAtLeastThreeTimesInLast5Years: 'FloodedAtLeastThreeTimesInLast5Years',
};

export const HomeStatus = {
  Potential: 'Potential',
  Active: 'Active',
  Terminated: 'Terminated',
};

export const PayoutPlanTypes = {
  NoGuarantee: 'NoGuarantee',
  Monthly: 'Monthly',
  Yearly: 'Yearly',
};

export const AgreementStatus = {
  Created: 'Created',
  NotSigned: 'NotSigned',
  Signed: 'Signed',
  Terminated: 'Terminated',
  Voided: 'Voided',
};

export const LeaseStatus = {
  Created: 'Created',
  Offered: 'Offered',
  ResidentsSigned: 'ResidentsSigned',
  Executed: 'Executed',
  Declined: 'Declined',
  Canceled: 'Canceled',
  Voided: 'Voided',
};

export const UserSignatureStatus = {
  Pending: 'Pending',
  Signed: 'Signed',
};

export const VerificationStatus = {
  Processing: 'Processing',
  Completed: 'Completed',
  Failed: 'Failed',
};

export const BalanceType = {
  Receivable: 'Receivable',
  Payable: 'Payable',
};

// 'Other' means none of the current regions, which is different from null, which means closed by X
export const RegionStatus = {
  Active: 'Active',
  ComingSoon: 'ComingSoon',
  Other: 'Other',
};

export const NextHomePlans = {
  Rent: 'Rent',
  Own: 'Own',
  NotSure: 'NotSure',
};

export const MoveoutOwningStatus = {
  Looking: 'Looking',
  MadeAnOffer: 'MadeAnOffer',
  Closing: 'Closing',
  Closed: 'Closed',
  Renovating: 'Renovating',
  ReadyToGo: 'ReadyToGo',
};

export const MoveoutRentingStatusOptions = {
  Looking: 'Looking',
  Applied: 'Applied',
  SignedLease: 'SignedLease',
};

export const SubOwnerVisibility = {
  Consumer: 'Consumer',
  Internal: 'Internal',
};
