// React imports
import { useRef, useState, useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux';
import { Toast } from '@belong/ui';
import classNames from 'classnames/bind';
import Form from 'components/Form/Form';
import Spinner from 'components/Spinner/Spinner';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import FormLayout from 'layouts/FormLayout/FormLayout';
import isEmpty from 'lodash/isEmpty';
import { UserVerificationType } from 'models/enums';
import PropTypes from 'prop-types';
import { fetchAllApplicationsForUser, fetchApplicantInfo } from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationBasicInfo,
  selectResidentApplicationsForUser,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { showToast, clearToasts } from 'store/redux/toast';
import {
  createIdentityVerificationInfo,
  createRunIdentityVerification,
  fetchIdenityVerificationInfo,
  fetchVerificationFor,
} from 'store/redux/user/actions';
import { selectIdentityVerificationInfo, selectUser } from 'store/redux/user/selectors';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { getIdVerificationInitialValues } from '../../../../formcomponents/IdentityVerification/getIdVerificationInitialValues';
import { ProcessingVerificationsModal } from '../../ProcessingVerificationsModal/ProcessingVerificationsModal';
import styles from './ResidentIdentityVerification.module.css';
import { IdentityMethods } from './components/IdentityMethods';
import { useIsPersonaVerificationCompleted, useStartPersonaInquiry } from './hooks/usePersona';

const cx = classNames.bind(styles);

const { FormLayoutHeader } = FormLayout;
const RAS = RESIDENT_APPLICATION_STRINGS.identity_verification;

const ResidentIdentityVerification = ({
  identityVerification,
  basicInfo,
  existingApplications,
  fetchApplicantInfoAction,
  fetchIdenityVerificationInfoAction,
  fetchAllApplicationsForUserAction,
  fetchVerificationForAction,
  clearToastsAction,
}) => {
  const initialValuesObject = useRef({});
  const [isError, setIsError] = useState(false);
  const [awaitingFetchComplete, setAwaitingFetchComplete] = useState(true);
  const [isToastOpen, openToast, closeToast] = useModal();
  const [isVerificationsModalOpen, openVerificationsModal, closeVerificationsModal] = useModal();

  // Pending handleSubmit implementation, opening modal always only for testing purposes.
  async function handleSubmit() {
    setAwaitingFetchComplete(true);

    clearToastsAction();

    openVerificationsModal();

    setAwaitingFetchComplete(false);
  }

  const { isPersonaVerificationCompleted, isRetrievingPersonaVerificationsDetail, refetchPersonaVerificationsDetail } =
    useIsPersonaVerificationCompleted();

  function onPersonaInquiryCompleted() {
    // Adding timeout to make sure the BE has time to process the Persona request and update the DB
    setTimeout(() => {
      refetchPersonaVerificationsDetail();
      setAwaitingFetchComplete(false);
      openToast();
    }, [3000]);
  }

  function onError(error) {
    console.error(error);
    setAwaitingFetchComplete(false);
    refetchPersonaVerificationsDetail();
    setIsError(true);
    openToast();
  }

  function onCancel() {
    setAwaitingFetchComplete(false);
  }

  const { startPersonaInquiry } = useStartPersonaInquiry({
    onComplete: onPersonaInquiryCompleted,
    onError: (error) => {
      return onError(error);
    },
    onCancelled: () => onCancel(),
  });

  const setInitialValues = () => {
    if (isEmpty(identityVerification)) {
      initialValuesObject.current = {};
      setAwaitingFetchComplete(false);
      return;
    }

    initialValuesObject.current = getIdVerificationInitialValues(identityVerification);

    setAwaitingFetchComplete(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isVerified = await fetchVerificationForAction(UserVerificationType.Identity);

        if (isVerified) {
          setAwaitingFetchComplete(false);
          return openVerificationsModal();
        }
      } catch (e) {
        console.error(e);
      }

      if (
        !identityVerification ||
        isEmpty(identityVerification) ||
        !basicInfo ||
        isEmpty(basicInfo) ||
        !existingApplications.length
      ) {
        try {
          await fetchAllApplicationsForUserAction();
          await Promise.all([fetchIdenityVerificationInfoAction(), fetchApplicantInfoAction()]);
          setInitialValues();
          return;
        } catch (err) {
          console.error(err);
        }
      }

      setInitialValues();
    };
    fetchData();
  }, []);

  function handlePersonaVerification() {
    setIsError(false);
    setAwaitingFetchComplete(true);
    startPersonaInquiry();
  }

  if (awaitingFetchComplete) {
    return (
      <Row>
        <Col md={12}>
          <div className={cx('spinner')}>
            <Spinner />
          </div>
        </Col>
      </Row>
    );
  }

  function isDisabled() {
    return awaitingFetchComplete || isRetrievingPersonaVerificationsDetail;
  }

  return (
    <FormLayout>
      <FormLayoutHeader title={RAS.title} subtitle={RAS.subTitle} />

      <Form
        initialValues={{}}
        onSubmit={isPersonaVerificationCompleted ? handleSubmit : handlePersonaVerification}
        getFormBottomBar={(_, nextButtonProps) => (
          <FormLayout.BottomBar
            ctaProps={{
              label: isPersonaVerificationCompleted ? RAS.next_button_text : RAS.verify_me,
              disabled: isDisabled(),
            }}
            nextButtonWrapperProps={nextButtonProps}
          />
        )}
        getForm={() => {
          return (
            <div className={cx('form-wrapper')}>
              <form onSubmit={handlePersonaVerification}>
                <IdentityMethods identified={isPersonaVerificationCompleted} />
              </form>
            </div>
          );
        }}
      />
      {isVerificationsModalOpen ? (
        <ProcessingVerificationsModal show={isVerificationsModalOpen} onClose={closeVerificationsModal} />
      ) : null}
      <div className="container">
        <Toast
          className="max-w-[700px]"
          isVisible={isToastOpen}
          onClose={closeToast}
          variant={!isError ? 'default' : 'danger'}
        >
          {isError
            ? "The verification couldn't be completed. Please try again."
            : 'ID verification was successfully completed.'}
        </Toast>
      </div>
    </FormLayout>
  );
};

ResidentIdentityVerification.propTypes = {
  basicInfo: PropTypes.object.isRequired,
  existingApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchApplicantInfoAction: PropTypes.func.isRequired,
  fetchAllApplicationsForUserAction: PropTypes.func.isRequired,
  fetchIdenityVerificationInfoAction: PropTypes.func.isRequired,
  clearToastsAction: PropTypes.func.isRequired,
  fetchVerificationForAction: PropTypes.func.isRequired,
  identityVerification: PropTypes.object,
};

ResidentIdentityVerification.defaultProps = {
  identityVerification: {},
};

const mapStateToProps = (state) => ({
  houseId: selectResidentApplyForHouseId(state),
  user: selectUser(state),
  identityVerification: selectIdentityVerificationInfo(state),
  basicInfo: selectResidentApplicationBasicInfo(state),
  existingApplications: selectResidentApplicationsForUser(state),
});

const mapDispatchToProps = {
  fetchApplicantInfoAction: fetchApplicantInfo,
  fetchIdenityVerificationInfoAction: fetchIdenityVerificationInfo,
  createIdentityVerificationInfoAction: createIdentityVerificationInfo,
  fetchAllApplicationsForUserAction: fetchAllApplicationsForUser,
  createRunIdentityVerificationAction: createRunIdentityVerification,
  fetchVerificationForAction: fetchVerificationFor,
  showToastAction: showToast,
  clearToastsAction: clearToasts,
};

export default scriptLoader(['https://cdn.withpersona.com/dist/persona-v4.8.0.js'])(
  connect(mapStateToProps, mapDispatchToProps)(ResidentIdentityVerification)
);
