import { useEffect, useCallback, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Image, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import config from 'config/config';
import { useIntercom } from 'hooks/useIntercom';
import { isWindowOpenSelector } from 'store/redux/ui/chat-widget/selectors';
import { selectUser } from 'store/redux/user/selectors';
import styles from './ChatWidget.module.css';

const { intercomId } = config;

const cx = classNames.bind(styles);

type ChatWidgetProps = {
  shouldShow?: boolean;
  isEnabled: boolean;
  fromAccount?: boolean;
  initializeOpen?: boolean;
  shouldHideHelp?: boolean;
};

export function ChatWidget({
  isEnabled,
  shouldShow = true,
  fromAccount = false,
  initializeOpen = false,
  shouldHideHelp = false,
}: ChatWidgetProps) {
  const [showHelp, setShowHelp] = useState(false);
  const arrowRef = useRef();
  const isWindowOpen = useSelector(isWindowOpenSelector);
  const user = useSelector(selectUser);
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.LG;
  const showWidget = shouldShow && isEnabled;

  useIntercom(intercomId, initializeOpen);

  const onOpenChat = useCallback(() => {
    const userData = {
      name: user.firstName ? `${user?.firstName} ${user?.lastName}` : null,
      email: user?.email,
      user_id: user?.userId,
      user_hash: user?.webIntercomUserHmac,
    };

    window.Intercom?.('update', {
      hide_default_launcher: isMobile,
      ...userData,
    });

    window.Intercom?.('onHide', () => {
      setShowHelp(true);
    });

    window.Intercom?.('onShow', () => {
      setShowHelp(false);
    });
  }, [user, isMobile]);

  // Opening the window from navbar using redux
  useEffect(() => {
    if (isWindowOpen) {
      onOpenChat();
    }
  }, [isWindowOpen, onOpenChat]);

  useEffect(() => {
    onOpenChat();
  });

  if (!showWidget) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={cx('fixed flex justify-center right-0', styles.chatBox, {
        'pb-xs bottom-[36px]': fromAccount,
      })}
    >
      <div className="flex-col items-center mr-3xl mb-sm hidden lg:flex" ref={arrowRef}>
        {showHelp && initializeOpen && !shouldHideHelp && (
          <>
            <Text className="-ml-2xl" fontWeight="semibold">
              INBOX IS HERE!
            </Text>
            <div style={{ width: 50 }}>
              <Image src="support/inbox_arrow.png" alt="" />
            </div>
          </>
        )}
      </div>
      <div className="absolute" style={{ width: 70, height: 70 }} />
    </div>,
    document.getElementById('cohere-widget')
  );
}
