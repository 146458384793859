import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { STEP_NAMES } from 'accounts/constants/steps';
import { getDisplayStatus, getStepPath, isStepUnHidden, sortStepsByGate } from 'accounts/utils/flow-steps';
import { findIndex } from 'lodash';
import { STATUS, FlowStatus } from 'models/enums';

export function useStepFormLayout({ currentStep, propertyId, unitId, stepsByGate }) {
  const location = useLocation();
  const [progressItems, setProgressItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentStep && propertyId) {
      setLoading(true);

      let currentStepsGate = stepsByGate?.[currentStep?.gate]
        .filter(isStepUnHidden)
        .filter((step) => step.flowId === currentStep.flowId)
        .filter((step) => [FlowStatus.Created, FlowStatus.InProgress].includes(step.flowStatus));

      if (currentStepsGate.find((step) => step.name === STEP_NAMES.UserTaxes)) {
        const taxStepIndex = findIndex(currentStepsGate, (step: any) => step.name === STEP_NAMES.UserTaxes);
        const userPaymentW9Step = { ...currentStepsGate[taxStepIndex], displayName: 'W-9', key: 'w9' };

        currentStepsGate = [
          ...currentStepsGate.slice(0, taxStepIndex + 1),
          userPaymentW9Step,
          ...currentStepsGate.slice(taxStepIndex + 1),
        ];
      }

      const residentExperienceSteps = currentStepsGate.filter(
        (actualStep) => actualStep.name === STEP_NAMES.UnitResidentExperience
      );
      const newProgressItems = sortStepsByGate(currentStepsGate).map((step) => {
        const stepPath = getStepPath(step, propertyId, unitId);
        let label = step.displayName;

        if (step.name === STEP_NAMES.UnitResidentExperience) {
          if (residentExperienceSteps.length > 1) {
            label = `${label}<br />Unit ${step.unit?.basicInfo.unitNumber}`;
          }
        }

        const progressItem = {
          label,
          uncompleted:
            currentStep.uniqueId === step.uniqueId ? false : getDisplayStatus(step.status) === STATUS.INCOMPLETE,
          to: step.key !== 'w9' ? stepPath : location.pathname,
          active: stepPath === location.pathname,
        };

        return progressItem;
      });

      setProgressItems(newProgressItems);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, propertyId, unitId, stepsByGate]);

  return { progressItems, loading };
}
