import React, { Component } from 'react';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { maskCurrency, unmaskNumber } from 'components/Input/masks';
import arrayMutators from 'final-form-arrays';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { isEqual, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { composeValidators, required, dateValidationMMDDYY } from 'utils/validation';
import { DateInputFinalFormAdapter } from '../../../../components/DateInput/DateInput';
import GreenInfoBox from '../../../../components/GreenInfoBox/GreenInfoBox';
import { YesNoFinalFormAdapter } from '../../../../components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import String from '../../../../components/String/String';
import Space, { SPACE_TYPES } from '../../../../corecomponents/Space/Space';
import Condition from '../../../../formcomponents/Condition/Condition';
import PropertyRentalLabel from '../components/PropertyRentalLabel/PropertyRentalLabel';

const { FormLayoutHeader } = FormLayout;

const HOB = HOMEOWNER_ONBOARDING_STRINGS.UnitMaintenanceSpend;

class PreviousMaintenanceSpend extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    currentStepData: PropTypes.object.isRequired,
    currentStepResources: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;

    const maintenanceEstimate = Number(values.maintenanceSpending?.inputValue);
    const doesOwnerKnowCost = !values.maintenanceSpending?.checkboxValue;

    const data = {
      unitMaintenanceSpend: {
        ...values.unitMaintenanceSpend,
        estimatedAnnualSpend: maintenanceEstimate,
        doesOwnerKnowCost,
      },
    };

    onSaveAndNext(data);
  };

  getInitialValues() {
    const { currentStepData } = this.props;

    const { unitMaintenanceSpend } = currentStepData;

    const maintenanceSpending = {};

    if (unitMaintenanceSpend?.estimatedAnnualSpend) {
      maintenanceSpending.inputValue = unitMaintenanceSpend?.estimatedAnnualSpend;
    }

    if (!isUndefined(unitMaintenanceSpend?.doesOwnerKnowCost)) {
      maintenanceSpending.checkboxValue = !unitMaintenanceSpend?.doesOwnerKnowCost;
    }

    const initialValues = {
      maintenanceSpending,
      unitMaintenanceSpend: {
        ...unitMaintenanceSpend,
      },
    };

    return initialValues;
  }

  render() {
    const {
      groups: { homes },
      currentStepResources: { property, home },
      onBack,
    } = this.props;

    return (
      <FormLayout>
        {homes.length > 1 && <PropertyRentalLabel property={property} home={home} />}
        <FormLayoutHeader pretitle={HOB.subtitle} title={HOB.title} />
        <Form
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
              }}
              nextButtonWrapperProps={nextButtonProps}
              previousStepProps={{
                onClick: onBack,
                to: null,
              }}
            />
          )}
          mutators={{
            ...arrayMutators,
          }}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          initialValues={this.getInitialValues()}
          onSubmit={this.onSubmit}
          getForm={({ form }) => (
            <>
              <FormLayout.Section>
                <Row>
                  <Col md={12}>
                    <Field
                      form={form}
                      name="maintenanceSpending"
                      component={InputCheckboxAdapter}
                      inputProps={{
                        autoFocus: true,
                        mask: maskCurrency,
                        unmask: unmaskNumber,
                        validate: required,
                        placeholder: 'Maintenance expenses (est. $/year)',
                      }}
                    />
                  </Col>
                </Row>
              </FormLayout.Section>
              <FormLayout.Section sectionTitle={HOB.hasWarrantySingleUnit}>
                <Field
                  component={YesNoFinalFormAdapter}
                  name="unitMaintenanceSpend.hasWarrantyPolicy"
                  validate={required}
                />
                <Condition when="unitMaintenanceSpend.hasWarrantyPolicy" is>
                  <Row>
                    <Col xs={12} sm={6}>
                      <Field
                        component={DateInputFinalFormAdapter}
                        validate={composeValidators(required, dateValidationMMDDYY)}
                        name="unitMaintenanceSpend.warrantyPolicyExpiresOn"
                        placeholder={HOB.policyExpiryPlaceholder}
                      />
                    </Col>
                  </Row>
                  <Space value={SPACE_TYPES.XS} />
                  <GreenInfoBox>
                    <String string={HOB.warrantyDisclaimer} />
                  </GreenInfoBox>
                  <Space value={SPACE_TYPES.XXL} />
                </Condition>
              </FormLayout.Section>
            </>
          )}
        />
      </FormLayout>
    );
  }
}

export default PreviousMaintenanceSpend;
