import { useState } from 'react';
import { ButtonBase, Tabs, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import CarouselTabBar from 'components/CarouselTabBar/CarouselTabBar';
import PropTypes from 'prop-types';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import HomeGalleryPreview from './HomeGalleryPreview/HomeGalleryPreview';
import MajorListingSitesPreview from './MajorListingSitesPreview/MajorListingSitesPreview';
import ThreeDTourPreview from './ThreeDTourPreview/ThreeDTourPreview';
import styles from './WaysToShowYourHome.module.css';
import { WeeklyAndSelfTours } from './weekly-and-self-tours/weekly-and-self-tours';

const cx = classNames.bind(styles);

const propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

const TAB_KEYS = {
  HIGH_RES_PHOTOS: 'highResPhotos',
  THREE_D_TOURS: 'threeDTours',
  WEEKLY_AND_SELF_TOURS: 'weeklyAndSelfTours',
  EXTERNAL_LISTINGS: 'allMajorListings',
};

const WaysToShowYourHome = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, onPrevious }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { user, employee } = stepResource?.employeeAssignments?.[0];
  const headOfPricingProfile = {
    ...user,
    bannerImageUrl: employee?.bannerImageUrl,
    jobTitle: EMPLOYEE_TITLES.ResidentGrowth,
  };

  const items = Object.values(TAB_KEYS).map((k, index) => ({
    key: k,
    text: POST_INSPECTION_FLOW_STRINGS[`pricing.marketing.tabs.${k}`],
    index,
  }));

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      showCityBackground
      title={POST_INSPECTION_FLOW_STRINGS['pricing.marketing.title']}
      employee={headOfPricingProfile}
      noOverlay
    >
      <div className="w-full">
        <div className={cx('tabs-wrapper-mobile')}>
          <CarouselTabBar items={items} selected={selectedTab} onSelect={setSelectedTab} />
        </div>
        <Tabs hasBlurOnScroll={false} index={selectedTab} onChange={setSelectedTab}>
          <Tabs.TabList className="mx-auto w-fit hidden lg:block">
            {items.map((tab, index) => {
              return (
                <Tabs.TabItem key={tab.key} index={index}>
                  <p>{tab.text}</p>
                </Tabs.TabItem>
              );
            })}
          </Tabs.TabList>
          <Tabs.PanelItem index={0} className="pt-xl">
            <HomeGalleryPreview />
            <Text className="mt-sm leading-p1">{POST_INSPECTION_FLOW_STRINGS['pricing.marketing.highRes']}</Text>
          </Tabs.PanelItem>
          <Tabs.PanelItem index={1} className="pt-xl">
            <ThreeDTourPreview />
            <Text className="mt-sm leading-p1">{POST_INSPECTION_FLOW_STRINGS['pricing.marketing.3dTour']}</Text>
          </Tabs.PanelItem>
          <Tabs.PanelItem index={2} className="pt-xl">
            <WeeklyAndSelfTours />
          </Tabs.PanelItem>
          <Tabs.PanelItem index={3} className="pt-xl">
            <MajorListingSitesPreview />
            <Text className="mt-sm leading-p1">{POST_INSPECTION_FLOW_STRINGS['pricing.marketing.allListings']}</Text>
          </Tabs.PanelItem>
        </Tabs>
      </div>

      <div className={cx('next-button-wrapper', 'hidden md:flex')}>
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {POST_INSPECTION_FLOW_STRINGS['pricing.marketing.cta']}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
        <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
          <Text fontWeight="semibold" className="text-white">
            Previous
          </Text>
        </ButtonBase>

        <ButtonBase className="h-2xl flex-1 bg-green disabled:bg-gray" onClick={() => onSaveAndNext()}>
          <Text fontWeight="semibold" className="text-white">
            Next
          </Text>
        </ButtonBase>
      </div>
    </PostInspectionContentLayout>
  );
};

WaysToShowYourHome.propTypes = propTypes;

export default WaysToShowYourHome;
