import { formatDateTime } from '@belong/common';
import type { InspectionModel, MaintenanceRecurrenceModel } from 'api/models';
import { isAfter } from 'date-fns';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import { STRINGS } from 'maintenance/strings';
import type { ProServiceView } from 'maintenance/types';
import MaintenanceTaskModel from 'models/maintenance/MaintenanceTaskModel';

export const getClosedTasks = (tasks: MaintenanceTaskModel[]) =>
  tasks?.filter((task) => task.status === 'Closed') ?? [];

export const getTurnedOffRecurringTasks = (tasks: MaintenanceRecurrenceModel[]) =>
  tasks
    ?.filter((task) => ['Completed', 'Cancelled', 'TurnedOff'].includes(task.recurringServiceStatus))
    .sort((activeTaskA: MaintenanceRecurrenceModel, activeTaskB: MaintenanceRecurrenceModel) => {
      const firstDate = getRawRecurringTaskDateByStatus({ task: activeTaskA });
      const secondDate = getRawRecurringTaskDateByStatus({ task: activeTaskB });

      if (!firstDate) {
        return -1;
      }

      if (!secondDate) {
        return 1;
      }

      return isAfter(new Date(firstDate), new Date(secondDate)) ? 1 : -1;
    }) ?? [];

export const getActiveTasks = (tasks: MaintenanceTaskModel[]) =>
  tasks?.filter((task) => task.status !== 'Closed') ?? [];

export const getActiveRecurringTasks = (tasks: MaintenanceRecurrenceModel[]) =>
  tasks
    ?.filter((task) => !['Completed', 'Cancelled', 'TurnedOff'].includes(task.recurringServiceStatus))
    .sort((activeTaskA: MaintenanceRecurrenceModel, activeTaskB: MaintenanceRecurrenceModel) => {
      const firstDate = getRawRecurringTaskDateByStatus({ task: activeTaskA });
      const secondDate = getRawRecurringTaskDateByStatus({ task: activeTaskB });

      if (!firstDate) {
        return -1;
      }

      if (!secondDate) {
        return 1;
      }

      return isAfter(new Date(firstDate), new Date(secondDate)) ? 1 : -1;
    }) ?? [];

export const getTasksByUnit = (tasks: MaintenanceTaskModel[]) => groupBy(tasks, (task) => task.homeUniqueId);

export const getRecurringTasksByUnit = (tasks: MaintenanceRecurrenceModel[]) =>
  groupBy(tasks, (task) => task.homeUniqueId);

export const getTasksWithInspection = (tasks: MaintenanceTaskModel[], inspections: InspectionModel[]) =>
  tasks?.map((task) => {
    task.inspection = inspections?.find((x) => x.groupUniqueId === task.uniqueId);

    return task;
  });

export const getRecurringTaskImageByRecurringTask = (
  recurringTask: MaintenanceRecurrenceModel['recurringTask']
): string => {
  switch (recurringTask) {
    case 'RainSpoutAndGutterCleaning':
      return '/pro-services/gutter.svg';
    case 'Landscaping':
      return '/pro-services/gardening.svg';
    case 'PoolMaintenance':
      return '/pro-services/pool.svg';
    case 'ExteriorPowerWash':
      return '/pro-services/exterior-wash.svg';
    case 'ExteriorWindowWashing':
      return '/pro-services/exterior-window.svg';
    case 'MaintenancePackage':
      return '/pro-services/recurring.svg';
    case 'PestControl':
      return '/pro-services/pest-control.svg';
    case 'Cleaning':
      return '/pro-services/cleaning.svg';
    case 'MainSewerCleanOut':
      return '/pro-services/sewer.svg';
    default:
      break;
  }
};

export const getTaskImageByRequestType = (requestType: MaintenanceRecurrenceModel['requestType']): string => {
  switch (requestType) {
    case 'Addition':
      return '/pro-services/addition.svg';
    case 'Cleaning':
      return '/pro-services/cleaning.svg';
    case 'Repair':
    case 'Group':
      return '/pro-services/repair.svg';
    case 'HaulingAndStorage':
      return '/pro-services/hauling.svg';
    case 'Inspection':
      return '/pro-services/inspections.svg';
    case 'Investigation':
      return '/pro-services/investigation.svg';
    case 'KeysAndAccess':
      return '/pro-services/keys.svg';
    case 'Photography':
      return '/pro-services/photography.svg';
    case 'PickupAndDelivery':
      return '/pro-services/pickup.svg';
    case 'Preventative':
      return '/pro-services/preventative.svg';
    case 'Recondition':
      return '/pro-services/reconditioning.svg';
    case 'Upgrade':
      return '/pro-services/upgrade.svg';
    default:
      return '/pro-services/repair.svg';
  }
};

export function getFormattedFrequency(frequency: number): string {
  if (frequency / 365 === 1) {
    return 'Yearly';
  }

  if (frequency % 365 === 0) {
    const years = frequency / 365;

    return `Every ${years} Years`;
  }

  if (frequency / 90 === 1) {
    return 'Quarterly';
  }

  if (frequency % 90 === 0) {
    const quarters = frequency / 90;

    return `Every ${quarters} Quarters`;
  }

  if (frequency / 30 === 1) {
    return 'Monthly';
  }

  if (frequency % 30 === 0) {
    const months = frequency / 30;

    return `Every ${months} Months`;
  }

  if (frequency / 7 === 1) {
    return 'Weekly';
  }

  if (frequency % 7 === 0) {
    const weeks = frequency / 7;

    return `Every ${weeks} Weeks`;
  }

  if (frequency === 1) {
    return 'Every Day';
  }

  return `Every ${frequency} Days`;
}

type GetRecurringTaskPricingParams = {
  task: MaintenanceRecurrenceModel;
  view?: ProServiceView;
};

export function getRecurringTaskPricing({ task, view = 'homeOwner' }: GetRecurringTaskPricingParams) {
  if (view === 'homeOwner') {
    if (task.responsibility === 'Homeowner') {
      return task.estimatedCost?.totalCost ?? STRINGS['pricing-tbd'];
    }

    const responsibility = task.responsibility ?? 'Homeowner';

    return `${responsibility} ${STRINGS.responsibility}`;
  }

  if (task.responsibility === 'Resident') {
    return task.estimatedCost?.totalCost ?? STRINGS['pricing-tbd'];
  }

  return 'Homeowner Responsibility';
}

export function getRecurringTaskDateByStatus({ task }: Record<'task', MaintenanceRecurrenceModel>) {
  const { recurringServiceStatus } = task;

  if (recurringServiceStatus === 'OccurringAround') {
    return formatDateTime({
      dateTime: task.requestNextOn,
      format: 'LLLL do, Y',
    });
  }

  if (['Completed', 'Cancelled', 'Scheduled'].includes(recurringServiceStatus)) {
    return formatDateTime({
      dateTime: task.appointmentNextOn,
      format: 'LLLL do, Y',
    });
  }

  if (recurringServiceStatus === 'TurnedOff') {
    return formatDateTime({
      dateTime: task.cancelledOn,
      format: 'LLLL do, Y',
    });
  }

  return '';
}

export function getRawRecurringTaskDateByStatus({ task }: Record<'task', MaintenanceRecurrenceModel>) {
  const { recurringServiceStatus } = task;

  if (recurringServiceStatus === 'OccurringAround') {
    return task.requestNextOn;
  }

  if (['Completed', 'Cancelled', 'Scheduled'].includes(recurringServiceStatus)) {
    return task.appointmentNextOn;
  }

  if (recurringServiceStatus === 'TurnedOff') {
    return task.cancelledOn;
  }

  return '';
}

export function getRecurringTaskStatus({ task }: Record<'task', MaintenanceRecurrenceModel>) {
  if (task.recurringServiceStatus === 'OccurringAround') {
    return STRINGS['estimated-date'];
  }

  return startCase(task.recurringServiceStatus);
}

export type Task = {
  label: string;
  image: string;
  key?: string;
  recurring?: boolean;
  multiUnit?: boolean;
};

export type Tasks = {
  [key: string]: Task[];
};

export const homeOwnerTasks: Tasks = {
  [STRINGS['pristine-home']]: [
    {
      label: 'Landscaping',
      image: '/pro-services/create_gardening.png',
      key: 'Landscaping',
      recurring: true,
    },
    {
      label: 'Pool Maintenance',
      image: '/pro-services/create_pool_maintenance.png',
      key: 'PoolMaintenance',
      recurring: true,
    },
    {
      label: 'Exterior Window Washing',
      image: '/pro-services/create_exterior_washing.png',
      key: 'ExteriorWindowWashing',
      recurring: true,
    },
    {
      label: 'Exterior Power Wash',
      image: '/pro-services/create_exterior_power_wash.png',
      key: 'ExteriorPowerWash',
      recurring: true,
    },
  ],
  [STRINGS.preventative]: [
    {
      label: 'Pest Control',
      image: '/pro-services/create_pest_control.png',
      key: 'PestControl',
      recurring: true,
    },
    {
      label: 'Maintenance Package',
      image: '/pro-services/create_maintenance_package.png',
      key: 'MaintenancePackage',
      recurring: true,
      multiUnit: true,
    },
    {
      label: 'Rain Spout & Gutter Cleaning',
      image: '/pro-services/create_rain_gutter.png',
      key: 'RainSpoutAndGutterCleaning',
      recurring: true,
    },
    {
      label: 'Main Sewer Clean\u2011out',
      image: '/pro-services/create_main_sewer.png',
      key: 'MainSewerCleanOut',
      recurring: true,
    },
  ],
  [STRINGS['home-services']]: [
    {
      label: 'Repair Something',
      image: '/pro-services/create_repair_something.png',
      multiUnit: true,
      key: 'Repair',
    },
    {
      label: 'Haul Something',
      image: '/pro-services/create_haul.png',
      multiUnit: true,
      key: 'HaulingAndStorage',
    },
    {
      label: 'Upgrade Something',
      image: '/pro-services/create_upgrade.png',
      multiUnit: true,
      key: 'Upgrade',
    },
  ],
};

export const residentTasks: Tasks = {
  [STRINGS['pristine-home']]: [
    {
      label: 'Repair',
      image: '/pro-services/create_repair_something.png',
      key: 'Repair',
    },
    {
      label: 'Cleaning',
      image: '/pro-services/create_cleaning.png',
      key: 'Cleaning',
      recurring: true,
    },
  ],
  [STRINGS['get-settled']]: [
    {
      label: 'Haul Something',
      image: '/pro-services/create_haul.png',
      key: 'HaulingAndStorage',
    },
    {
      label: 'Keys Please',
      image: '/pro-services/create_keys.png',
      key: 'KeysAndAccess',
    },
    {
      label: 'Mount TV',
      image: '/pro-services/create_mount_tv.png',
      key: 'MountTV',
    },
    {
      label: 'Interior Painting',
      image: '/pro-services/create_interior_painting.png',
      key: 'InteriorPainting',
    },
  ],
  [STRINGS['not-too-hot']]: [
    {
      label: 'Get a Window AC',
      image: '/pro-services/create_window_ac.png',
      key: 'WindowAC',
    },
    {
      label: 'HVAC Filter Replacement',
      image: '/pro-services/create_hvac_filter.png',
      key: 'HVACFilter',
    },
  ],
  [STRINGS['latest-tech']]: [
    {
      label: 'Ring Doorbell',
      image: '/pro-services/create_ring.png',
      key: 'RingDoorbell',
    },
    {
      label: 'Tesla or EV Charger',
      image: '/pro-services/create_tesla_charger.png',
      key: 'TeslaCharger',
    },
    {
      label: 'Nest Thermostat',
      image: '/pro-services/create_thermostat.png',
      key: 'NestThermostat',
    },
  ],
  [STRINGS.more]: [
    {
      label: 'Other Upgrade',
      image: '/pro-services/create_other.png',
      key: 'Upgrade',
    },
  ],
};

export const upgradesNames = {
  mounttv: { label: 'Mount TV', approval: false },
  interiorpainting: { label: 'Interior Painting', approval: true },
  windowac: { label: 'Get a Window AC', approval: true },
  hvacfilter: { label: 'HVAC Filter Replacement', approval: false },
  ringdoorbell: { label: 'Ring Doorbell', approval: true },
  teslacharger: { label: 'Tesla or EV Charger', approval: true },
  nestthermostat: { label: 'Nest Thermostat', approval: true },
  carpetcleaning: { label: 'Carpet Cleaning', approval: true },
};
