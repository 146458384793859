import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { PATHS } from '@belong/common';
import MaintenanceStatus from 'containercomponents/MaintenanceStatus/MaintenanceStatus';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectInspections } from 'store/redux/inspections';
import { fetchRecurrentMaintenanceByTaskId } from 'store/redux/maintenance/actions';

type HomeownerMaintenanceDetailsRouteParams = {
  taskId: string;
};

const HomeownerMaintenanceDetailsComponent = ({ inspections, history }) => {
  const { taskId } = useParams<HomeownerMaintenanceDetailsRouteParams>();
  const onClosePress = useCallback(() => history.push(PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE), [history]);

  return (
    <MaintenanceStatus
      taskId={taskId}
      inspection={inspections?.find((x) => x.groupUniqueId === taskId)}
      onClosePress={onClosePress}
      history={history}
    />
  );
};

function mapStateProps(state) {
  return {
    accountProperties: selectAccountProperties(state),
    inspections: selectInspections(state),
  };
}

const mapDispatchToProps = {
  fetchTask: fetchRecurrentMaintenanceByTaskId,
};

export default connect(mapStateProps, mapDispatchToProps)(HomeownerMaintenanceDetailsComponent);
