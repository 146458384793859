import find from 'lodash/find';
import BillBasicInfo from 'models/payments/BillBasicInfo';
import CustomPaymentFees from 'models/payments/CustomPaymentFees';
import PaymentTransaction from 'models/payments/PaymentTransaction';
import AccountPaymentItemDescription from 'models/payments/paymentinfo/AccountPaymentItemDescription';
import { TransactionStatus, BillStatus } from '../enums/index';

export default class BillDetailModel {
  constructor(
    data = {
      billInfo: {},
    }
  ) {
    this.billInfo = new BillBasicInfo(data.billInfo);
    this.fees = data.fees?.map((fee) => new CustomPaymentFees(fee)) || [];
    this.items = data.items?.map((item) => new AccountPaymentItemDescription(item)) || [];
    this.transfers =
      data.transfers
        ?.map((item) => new PaymentTransaction(item))
        .sort((a, b) => new Date(b.processedOn) - new Date(a.processedOn)) || [];
    this.billInfo.status = this.getStatus();
    this.finances = data.finances;
    this.groupBillItems = data.groupBillItems;
    this.paymentMethod = data.paymentMethod;
  }

  getStatus() {
    if (this.billInfo?.status === BillStatus.Published) {
      const hasInitiated = find(this.transfers, (transfer) => transfer.status === TransactionStatus.Initiated);

      if (hasInitiated) {
        return BillStatus.Initiated;
      }

      if (this.billInfo.paidAmount > 0 && this.billInfo.remainingAmount > 0) {
        return BillStatus.PartialPayment;
      }

      return BillStatus.Unpaid;
    }

    return this.billInfo.status;
  }

  getFeeFromItem(currentPaymentMethod) {
    return find(this.fees, (fee) => {
      if (fee.paymentMethod === currentPaymentMethod.paymentMethod) {
        if (currentPaymentMethod.isDomestic === fee.isDomestic) {
          return true;
        }
      }
    });
  }
}
