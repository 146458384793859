import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Balloon.module.css';

const cx = classNames.bind(styles);

export const Balloon = ({ step }) => (
  <>
    <div className={cx('scroll-image-container')} style={{ left: -400 * step }}>
      <img alt="nav-background" className={cx('scroll-image')} src="/belong_world_right_navi_bg.png" />
    </div>
  </>
);

Balloon.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Balloon;
