import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionPreferredDates } from 'accounts/utils/home-inspection';
import type { UnitSummaryModel } from 'api/models';
import { utcToZonedTime } from 'date-fns-tz';
import { fetchAvailabilityDates } from 'store/redux/homeowner-set-up-flow/actions';
import { fetchInitialPrice } from 'store/redux/initial-price/actions';
import { selectInitialPrice } from 'store/redux/initial-price/selectors';

export function useInspectionAvailabilityDates({ unit, startDate, endDate, timeZone }) {
  const [dates, setDates] = useState(null);
  const [disabledDates, setDisabledDates] = useState([]);
  const [preferredDates, setPreferredDates] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDates = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(
          fetchAvailabilityDates(
            unit.basicInfo.id,
            startDate.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0]
          )
        );

        const map = {};

        response.forEach((d) => {
          const date = utcToZonedTime(new Date(d), timeZone);
          const dateString = date.toISOString().split('T')[0];
          const [time, suffix] = date.toLocaleTimeString().split(' ');
          const splittedTime = time.split(':');
          const availableTime = `${splittedTime[0]}:${splittedTime[1]} ${suffix}`;
          const completeTime = {
            value: availableTime,
            key: `${date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`}:00`,
          };

          if (map[dateString]) {
            map[dateString] = [...map[dateString], completeTime];
          } else {
            map[dateString] = [completeTime];
          }
        });

        setDates(map);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (unit && startDate && endDate) fetchDates();
  }, [unit, startDate, endDate]);

  useEffect(() => {
    if (!dates) return;

    const end = new Date(endDate);

    const disabled: (Date | { before: Date })[] = [
      {
        before: new Date(startDate),
      },
    ];

    const dayCount = new Date(endDate).getDate() - new Date(startDate).getDate();

    for (let i = dayCount; i >= 0; i--) {
      const date = new Date(end.getFullYear(), end.getMonth(), end.getDate() - i);

      const availability = dates[date.toISOString().split('T')[0]];
      if (!availability) {
        disabled.push(date);
      }
    }

    setDisabledDates(disabled);
  }, [dates, setDisabledDates]);

  useEffect(() => {
    if (!dates) return;

    const preferredDays = getInspectionPreferredDates({
      availableDates: dates,
      timeZone,
    });

    setPreferredDates(preferredDays);
  }, [dates, setPreferredDates, timeZone]);

  return {
    availabilityDates: dates,
    disabledDates,
    preferredDates,
    isLoadingDates: loading,
  };
}

export function useDailyPotentialLoss({ unit }: Record<'unit', UnitSummaryModel>) {
  const [dailyPotentialLoss, setDailyPotentialLoss] = useState(100);
  const [isLoadingInitialPriceRange, setIsLoadingInitialPriceRange] = useState(false);
  const initialPriceRangeData = useSelector(selectInitialPrice);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInitialPricingData = async () => {
      try {
        setIsLoadingInitialPriceRange(true);

        await dispatch(fetchInitialPrice(unit.basicInfo.id));

        setDailyPotentialLoss(
          initialPriceRangeData.pricingEstimate ? initialPriceRangeData.pricingEstimate.maximumRentPrice / 30 : 100
        );
      } catch (error) {
        console.log('Could not fetch initial pricing data', error);
      } finally {
        setIsLoadingInitialPriceRange(false);
      }
    };

    if (unit.isInitialPriceRangePublished) {
      fetchInitialPricingData();
    }
  }, [unit, dispatch]);

  return {
    isLoadingDailyPotentialLoss: isLoadingInitialPriceRange,
    dailyPotentialLoss,
  };
}
