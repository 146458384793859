import { find } from 'lodash';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnitDetail from 'models/homeownerAccounts/HomeownerAccountUnitDetail';
import { createSelector } from 'reselect';
import { HOMEOWNER_ACCOUNTS_REDUCER } from './reducer';

const _selectHomeownerAccounts = (state) => state[HOMEOWNER_ACCOUNTS_REDUCER];
export const _selectAccountProperties = (state) => _selectHomeownerAccounts(state).properties;

const _selectAccountPropertyById = (state, id) =>
  find(_selectAccountProperties(state), (property) => property.basicInfo.propertyId === id);

const _selectAccountUnits = (state) => _selectHomeownerAccounts(state).units;
const _selectAccountUnitById = (state, id) => _selectAccountUnits(state)[id];

export const selectHOCurrentTask = (state) => _selectHomeownerAccounts(state).currentTask;

export const selectAccountProperties = createSelector(_selectAccountProperties, (properties) =>
  properties ? properties.map((property) => new HomeownerAccountProperty(property)) : null
);

export const selectAccountPropertyById = createSelector(
  _selectAccountPropertyById,
  (property) => new HomeownerAccountProperty(property)
);

export const selectAccountPropertyByUnitId = (unitId) =>
  createSelector(_selectAccountProperties, (properties) => {
    if (properties) {
      const propertyFound = properties.find((property) =>
        property.units.some((unit) => unit.basicInfo.unitId === unitId)
      );

      return propertyFound || null;
    }
    return null;
  });

export const selectAccountUnitByUnitId = (unitId) =>
  createSelector(_selectAccountProperties, (properties) => {
    if (properties) {
      const propertyFound = properties.find((property) =>
        property.units.some((unit) => unit.basicInfo.unitId === unitId)
      );
      const unitFound = propertyFound?.units.find((unit) => unit.basicInfo.unitId === unitId);

      return unitFound || null;
    }
    return null;
  });

export const selectAccountUnitById = createSelector(_selectAccountUnitById, (unitDetail) =>
  unitDetail ? new HomeownerAccountUnitDetail(unitDetail) : null
);

export const selectAccountUnitsValues = createSelector(_selectAccountUnits, (units) =>
  Object.values(units).map((unitDetail) => (unitDetail ? new HomeownerAccountUnitDetail(unitDetail) : null))
);

export const selectAccountUnitExternalPublishings = createSelector(_selectAccountUnits, (units) =>
  Object.values(units).map((unit) => ({
    unitId: unit.basicInfo.unitId,
    externalListingPublishings: unit.basicInfo.externalListingPublishings,
  }))
);

export const selectAccountDocuments = (state) => _selectHomeownerAccounts(state).documents;

export const selectAccountAgreements = (state) => _selectHomeownerAccounts(state).agreements;

const _selectTimelines = (state) => _selectHomeownerAccounts(state).timelines;

export const selectRawTimelines = createSelector(_selectTimelines, (timelines) => timelines);

export const selectTimelines = createSelector(_selectTimelines, (timelines) => {
  return timelines.reduce((acc, timeline) => {
    // Normalize by last event Id to make it easy to match with the corresponding event.
    const normalizeMilestonesByLastEventId = timeline.milestones.reduce((innerAcc, val) => {
      if (innerAcc[val.latestTimelineEventId]) {
        innerAcc[val.latestTimelineEventId].push(val);
      } else {
        innerAcc[val.latestTimelineEventId] = [val];
      }
      return innerAcc;
    }, {});

    const normalizeRequiredEventsByName = timeline?.requiredEventsForNextMilestone?.reduce((innerAcc, val) => {
      return { ...innerAcc, [val.timelineEventType]: val };
    }, {});

    const timelineEvents = timeline.timelineEvents.map((event) => {
      // Insert milestone into event that triggered the milestone if exists.
      if (normalizeMilestonesByLastEventId[event.id]) {
        event.milestones = normalizeMilestonesByLastEventId[event.id];
      }

      return event;
    });

    return {
      ...acc,
      [timeline.milestones[0].homeId]: {
        nextMilestone: timeline.nextMilestone,
        requiredEventsForNextMilestone: normalizeRequiredEventsByName,
        timelineEvents,
      },
    };
  }, {});
});

export const selectHomeAccessInstructions = (state, unitId) =>
  _selectHomeownerAccounts(state).homeAccessInstructions[unitId];

export const selectUpcomingAppointments = (state) => _selectHomeownerAccounts(state).upcomingAppointments;
