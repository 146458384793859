import React from 'react';
import classNames from 'classnames/bind';
import BelongEmployeeProfileView, { MODES } from 'components/BelongEmployeeProfileView/BelongEmployeeProfileView';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import Carousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import styles from './MultipleBelongEmployeeProfileView.module.css';
import { getFormattedEmployeeTitle } from './utils';

const cx = classNames.bind(styles);
const classNamesCollection = ['zero', 'one', 'two', 'three', 'four', 'five'];

const MultipleBelongEmployeeProfileView = ({ employees, showDivider }) => {
  return (
    <>
      <div className={cx('desktop-wrapper')}>
        {employees.some((employee) => employee?.topText) && (
          <div className={cx('labels-section-wrapper', `${classNamesCollection[employees.length]}`)}>
            {employees.map((employee, index) => (
              <div
                className={cx('label-wrapper', 'label-top', `${classNamesCollection[employees.length]}`)}
                key={index}
              >
                <div className={cx('title-wrapper')}>{employee?.topText}</div>
              </div>
            ))}
          </div>
        )}
        <div className={cx('employees-section-wrapper', `${classNamesCollection[employees.length]}`)}>
          {employees.map((employee, index) => (
            <React.Fragment key={index}>
              <div
                className={cx(
                  'employee-wrapper',
                  `${classNamesCollection[employees.length]}`,
                  employee.order && 'z-10'
                )}
              >
                <BelongEmployeeProfileView noLabel showDivider={showDivider} employee={employee} mode={MODES.LARGE} />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className={cx('shelf-box-shadow')} />
        <div className={cx('labels-section-wrapper', `${classNamesCollection[employees.length]}`)}>
          {employees.map((employee, index) => (
            <div className={cx('label-wrapper', `${classNamesCollection[employees.length]}`)} key={index}>
              <div className={cx('title-wrapper')}>{employee?.firstName}</div>
              <div className={cx('subtitle-wrapper')}>{getFormattedEmployeeTitle(employee?.jobTitle)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={cx('mobile-wrapper')}>
        <Carousel
          disableEdgeSwiping
          slideIndex={0}
          speed={350}
          renderBottomCenterControls={(controls) => {
            const children = [];

            for (let i = 0; i < controls.slideCount; i += 1) {
              const childListComponent = (
                <li key={i} className={cx('position-list-item', { currentSlide: controls.currentSlide === i })}>
                  <ButtonBase onClick={() => controls.goToSlide(i)}>&#183;</ButtonBase>
                </li>
              );
              children.push(childListComponent);
            }

            if (children.length > 1) {
              return <ul className={cx('position-list-wrapper')}>{children.map((child) => child)}</ul>;
            }
          }}
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
        >
          {employees.map((employee, index) => (
            <div key={index}>
              <div className={cx('mobile-label-wrapper', 'label-top', `${classNamesCollection[employees.length]}`)}>
                <div className={cx('title-wrapper')}>{employee?.topText}</div>
              </div>
              <div className={cx('mobile-employee-wrapper')}>
                <BelongEmployeeProfileView employee={employee} showDivider={showDivider} mode={MODES.LARGE} />
              </div>
              <div className={cx('mobile-label-wrapper', `${classNamesCollection[employees.length]}`)} key={index}>
                <div className={cx('title-wrapper')}>{employee?.firstName}</div>
                <div className={cx('subtitle-wrapper')}>{employee?.jobTitle}</div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

MultipleBelongEmployeeProfileView.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      college: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      aboutMe: PropTypes.string,
      quote: PropTypes.string,
      specialities: PropTypes.string,
      homeTown: PropTypes.string,
      serviceArea: PropTypes.string,
      profileImage: PropTypes.string.isRequired,
      bannerImage: PropTypes.string,
      jobTitle: PropTypes.string,
      order: PropTypes.number,
    })
  ).isRequired,
  showDivider: PropTypes.bool,
};

MultipleBelongEmployeeProfileView.defaultProps = {
  showDivider: false,
};

export default MultipleBelongEmployeeProfileView;
