import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { Modal, Form, TextField, Text, Spinner } from '@belong/ui';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import PropTypes from 'prop-types';
import { updateMaintenanceWithCancellation } from 'store/redux/maintenance/actions';
// updateResidentTaskWithCancellation
import styles from './MaintenanceCancelModal.module.css';

class MaintenanceCancelModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    updateMaintenanceWithCancellation: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    isCancelRecurrence: PropTypes.bool,
    history: PropTypes.func,
    isRenter: PropTypes.bool,
  };

  static defaultProps = {
    isCancelRecurrence: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isCancelling: false,
    };
  }

  onSubmit = async (values) => {
    const { taskId, isCancelRecurrence, isRenter, history } = this.props;
    const sendToBackend = { ...values };

    sendToBackend.cancelRecurrence = !!isCancelRecurrence;

    try {
      this.setState({ isCancelling: true });

      await this.props.updateMaintenanceWithCancellation(taskId, sendToBackend);

      history.push(isRenter ? PATHS.RESIDENTS_ACCOUNT_MAINTENANCE : PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE);
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isCancelling: false });
    }
  };

  getInitialValues() {
    this.initialValue = {};
    return this.initialValue;
  }

  validateCancelThisVisitOnly = ({ resultReason }) => {
    const errors = {};

    if (!resultReason) {
      return (errors.resultReason = 'Required');
    }

    return errors;
  };

  displayCancelModal() {
    const { close, show } = this.props;
    const { isCancelling } = this.state;

    return (
      <>
        {isCancelling && <Spinner />}
        <Modal
          isOpen={show}
          name="cancel_pro_service"
          onDismiss={close}
          size="auto"
          overlayClassName="z-fullscreen"
          contentClassName="sm:w-[730px] md:w-[730px]"
        >
          <div className="flex flex-col px-2sm pt-3xl sm:pt-2xl sm:px-lg">
            <div className="max-w-[264px] sm:max-w-none mb-2xl self-center text-center">
              <Text variant="h2" fontWeight="semibold">
                Why do you want to cancel?
              </Text>
            </div>
            <Form
              initialValues={this.getInitialValues()}
              onSubmit={this.onSubmit}
              validate={this.validateCancelThisVisitOnly}
            >
              {() => (
                <div className="flex flex-col">
                  <div className="mb-2xl">
                    <TextField label="Cancellation Reason" name="resultReason" />
                  </div>
                  <div className="sm:hidden mb-sm">
                    <Form.SubmitButton>Submit</Form.SubmitButton>
                  </div>
                  <div className={styles.footer}>
                    <QuestionsOrConcernsModalFooter>
                      <div className="hidden sm:block">
                        <Form.SubmitButton>Submit</Form.SubmitButton>
                      </div>
                    </QuestionsOrConcernsModalFooter>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </Modal>
      </>
    );
  }

  render() {
    return this.displayCancelModal();
  }
}

const mapDispatchToProps = {
  updateMaintenanceWithCancellation,
};

export default withRouter(connect(null, mapDispatchToProps)(MaintenanceCancelModal));
