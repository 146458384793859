import React from 'react';
import { connect } from 'react-redux';

import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import ResidentTileList from 'containercomponents/ResidentTileList/ResidentTileList';
import ResidentTileListWithStatus from 'containercomponents/ResidentTileListWithStatus/ResidentTileListWithStatus';
import { differenceInCalendarYears } from 'date-fns';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import flatten from 'lodash/flatten';
import { ResidentType } from 'models/enums';
import PropTypes from 'prop-types';
import { selectUser } from 'store/redux/user/selectors';

const getPetIcon = (type) => {
  if (type === 'Dog') {
    return GENERAL_ICONS.DOG_PROFILE;
  } else if (type === 'Cat') {
    return GENERAL_ICONS.CAT_PROFILE;
  }

  return GENERAL_ICONS.OTHER_PET_PROFILE;
};

const ResidentListContainer = ({ currentUser, residents, withStatus }) => {
  const { residents: residentsList = [], kids = [], pets = [] } = residents || {};
  const residentsProps = residentsList?.map((resident) => {
    const { basicInfo, leaseInfo } = resident;
    const { userInfo } = basicInfo;

    let residentSubtitle;
    switch (leaseInfo?.residentType) {
      case ResidentType.Primary:
        residentSubtitle = 'Primary Resident';
        break;
      case ResidentType.CoSigner:
        residentSubtitle = 'Co-signer';
        break;
      case ResidentType.Roommate:
      default:
        residentSubtitle = 'Resident';
        break;
    }
    return {
      profileProps: {
        imageUrl: userInfo.profileImageUrl,
        title: userInfo.userId === currentUser.userId ? 'Me' : `${userInfo.firstName} ${userInfo.lastName}`,
        subtitles: [residentSubtitle],
        userId: userInfo.userId,
        signatureStatus: leaseInfo?.signatureStatus,
      },
    };
  });

  const kidProps = kids?.map((kid) => {
    const birthdate = new Date(kid.dateOfBirth);
    const childAgeInYears = differenceInCalendarYears(legacyParse(Date.now()), legacyParse(birthdate));
    return {
      profileProps: {
        title: kid.name,
        subtitles: [childAgeInYears > 0 ? `${childAgeInYears} Year${childAgeInYears === 1 ? '' : 's'} Old` : 'Baby'],
        customEmptyProfileIcon: GENERAL_ICONS.KIDS_PROFILE,
        // signatureStatus: 'Signature Not Required',
      },
    };
  });

  const petProps = pets?.map((pet) => ({
    profileProps: {
      title: pet.name,
      subtitles: [`${pet.subType || ''}`],
      customEmptyProfileIcon: getPetIcon(pet.petType),
      // signatureStatus: 'Signature Not Required',
    },
  }));

  const getInfo = [...residentsProps, ...kidProps, ...petProps];
  const newArrayOfResidents = [];
  getInfo.forEach((value, index) => {
    const number = (index - 1) / 2;

    if (!newArrayOfResidents[Math.round(number)]) {
      newArrayOfResidents[Math.round(number)] = [];
    }

    newArrayOfResidents[Math.round(number)].push(value);
  });

  return withStatus ? (
    // Will never understand why we gave residents the shape we are giving it right now
    // But will flatten out since this component is be new.
    <ResidentTileListWithStatus residents={flatten(newArrayOfResidents)} />
  ) : (
    <ResidentTileList residents={newArrayOfResidents} />
  );
};

ResidentListContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  residents: PropTypes.object.isRequired,
  withStatus: PropTypes.bool,
};

ResidentListContainer.defaultProps = {
  withStatus: false,
};

const mapStateToProps = (state) => ({
  currentUser: selectUser(state),
});

export default connect(mapStateToProps)(ResidentListContainer);
