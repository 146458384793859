import { getMediaTypeFromFile } from '../../../utils/file';
import { uploadDocumentToS3 } from '../common/uploadDocumentToS3';
import { getApiActionTypesValues } from '../redux_utils';
import { ACTIONS } from './constants';

export const fetchHomeOwnersMaintenance = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_HOMEOWNER_MAINTENANCE'),
    promise: ({ client }) => client.get('/maintenance'),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchRecurringTasks = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RECURRING_TASKS'),
    promise: ({ client }) => client.get('/recurrence/list'),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchTaskByTaskId = (id) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_HO_TASK_BY_TASK_ID'),
    promise: ({ client }) => client.get(`/maintenance/${id}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchResidentMaintenance = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RESIDENT_MAINTENANCE'),
    promise: ({ client }) =>
      client.get('/maintenance', {
        params: {
          involvedRole: 'Resident',
        },
      }),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchRenterTaskByTaskId = (taskId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_TASK_BY_RENTER_ID'),
    promise: ({ client }) => client.get(`/maintenance/${taskId}`),
    auth: true,
  });

export const fetchImprovementReportByTaskId = (taskId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_IMPROVEMENTS_REPORT_BY_TASK_ID'),
    promise: ({ client }) => client.get(`/maintenance/${taskId}/improvements/report`),
    auth: true,
  });

export const fetchRecurrentMaintenanceByTaskId = (taskType, homeUniqueId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RECURRENT_MAINTENANCE_BY_TASK_ID'),
    promise: ({ client }) => client.get(`/recurrence/${taskType}/${homeUniqueId}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const maintenanceCurrentClear = () => ({
  type: ACTIONS.CURRENT_TASK_CLEAR,
});

export const updateMaintenanceConsent = (id, objectInfo) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_MAINTENANCE_CONSENT'),
    promise: ({ client }) => client.put(`/maintenance/${id}/consent`, objectInfo),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const updateMaintenanceWithCancellation = (taskId, cancelObj) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_RESIDENT_WITH_CANCELLATION'),
    promise: ({ client }) => client.put(`/maintenance/${taskId}/cancel`, cancelObj),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const getMainenanceUploadUrl = (mediaType) => ({
  promise: ({ client }) => client.get(`/maintenance/media/uploadurl/${mediaType}?rand=${Math.random()}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const uploadMaintenanceMedia = (file) => async (dispatch) => {
  const mediaType = getMediaTypeFromFile(file);
  const response = await dispatch(getMainenanceUploadUrl(mediaType));
  const { headers, uploadUrl, uniqueId } = response;
  await uploadDocumentToS3(dispatch, file, headers, uploadUrl);
  return {
    name: file.name,
    uniqueId,
    mediaType,
  };
};

export const createMediaToMaintenanceTask = (maintenanceId, media) => ({
  types: getApiActionTypesValues('CREATE_MEDIA_TO_MAINTENANCE_TASK'),
  promise: ({ client }) =>
    client.post(`/maintenance/${maintenanceId}/media`, {
      ...media,
      stage: 'Before',
    }),
  auth: true,
});

export const deleteMediaToMaintenanceTask = (maintenanceId, mediaId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('DELETE_MEDIA_TO_MAINTENANCE_TASK'),
    promise: ({ client }) => client.delete(`/maintenance/${maintenanceId}/media/${mediaId}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const createMaintenanceTask = (maintenance) => async (dispatch) => {
  const newMaintenance = await dispatch({
    types: getApiActionTypesValues('CREATE_MAINTENANCE_TASK'),
    promise: ({ client }) => client.post('/maintenance', maintenance),
    auth: true,
  });

  if (maintenance.attachments?.stagingMedia?.length) {
    const uploadPromises = [];
    maintenance.attachments.stagingMedia.forEach(async (file) => {
      uploadPromises.push(dispatch(uploadMaintenanceMedia(file)));
    });
    const attachments = await Promise.all(uploadPromises);

    const createPromises = [];
    attachments.forEach(async (media) => {
      createPromises.push(dispatch(createMediaToMaintenanceTask(newMaintenance.uniqueId, media)));
    });
    await Promise.all(createPromises);
  }
};

export const updateMaintenanceTask = (maintenance, maintenanceId) => async (dispatch) => {
  if (maintenance.attachments?.stagingMedia?.length) {
    const uploadPromises = [];
    maintenance.attachments.stagingMedia.forEach(async (file) => {
      uploadPromises.push(dispatch(uploadMaintenanceMedia(file)));
    });
    const attachments = await Promise.all(uploadPromises);

    const createPromises = [];
    attachments.forEach(async (media) => {
      createPromises.push(dispatch(createMediaToMaintenanceTask(maintenanceId, media)));
    });
    await Promise.all(createPromises);
  }
  // if (maintenance.attachments?.availableMedia?.length) {
  //   const newMedia = filter(maintenance.attachments.availableMedia, (m) => !m.uniqueId);

  //   if (newMedia.length) {
  //     const uploadPromises = [];
  //     newMedia.forEach(async (file) => {
  //       uploadPromises.push(dispatch(uploadMaintenanceMedia(file)));
  //     });
  //     const attachments = await Promise.all(uploadPromises);

  //     const createPromises = [];
  //     attachments.forEach(async (media) => {
  //       createPromises.push(dispatch(createMediaToMaintenanceTask(maintenanceId, media)));
  //     });
  //     await Promise.all(createPromises);
  //   }
  // }

  if (maintenance.attachments?.deletedMedia?.length) {
    const deletePromises = [];
    maintenance.attachments.deletedMedia.forEach(async (media) => {
      if (media.uniqueId) {
        deletePromises.push(dispatch(deleteMediaToMaintenanceTask(maintenanceId, media.uniqueId)));
      }
    });
    await Promise.all(deletePromises);
  }

  await dispatch({
    types: getApiActionTypesValues('UPDATE_MAINTENANCE_TASK'),
    promise: ({ client }) => client.put(`/maintenance/${maintenanceId}`, maintenance),
    auth: true,
    redirectToHomeOnHide: true,
  });
};
