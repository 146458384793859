import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PATHS } from '@belong/common';
import { Spinner, Text } from '@belong/ui';
import { getUnitsFromProperties } from 'accounts/utils/units';
import type { UnitBasicInfoModel } from 'api/models';
import { AutoCompleteFinalFormAdapter } from 'components/AutoComplete/AutoComplete';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { MEDIA_LABEL, NewMediaUploadAdapter, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import String from 'components/String/String';
import MaintenanceModalAttachmentFields from 'containercomponents/Modals/AddTaskModal/MaintenanceModalAttachmentFields';
import {
  listOfAppliances,
  urgency,
  responsibility,
} from 'containercomponents/Modals/ModalListOfItems/ApplianceList/listOfFormObjects';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import filter from 'lodash/filter';
import toLowerCase from 'lodash/toLower';
import { URL_QUERY_PARAMS } from 'maintenance/constants';
import { STRINGS } from 'maintenance/strings';
import type { EditProServiceRouteParams } from 'maintenance/types';
import { upgradesNames } from 'maintenance/utils';
import { RequestType } from 'models/enums/index';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HaulingForm from 'pages/Accounts/FormLayouts/HaulingForm/HaulingForm';
import KeysForm from 'pages/Accounts/FormLayouts/KeysForm/KeysForm';
import ResidentAvailabilityForm from 'pages/Accounts/FormLayouts/ResidentAvailabilityForm/ResidentAvailabilityForm';
import {
  createMaintenanceTask,
  updateMaintenanceTask,
  fetchTaskByTaskId,
  maintenanceCurrentClear,
} from 'store/redux/maintenance/actions';
import { selectCurrentTask } from 'store/redux/maintenance/selectors';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { required } from 'utils/validation';
import styles from './home-service-form.module.css';

type Props = {
  isHomeOwnerView: boolean;
  proService: string;
  properties: HomeownerAccountProperty[];
  unit?: UnitBasicInfoModel;
};

export function HomeServiceForm({ isHomeOwnerView, proService, properties, unit }: Props) {
  const { taskId } = useParams<EditProServiceRouteParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const task = useSelector<unknown, any>(selectCurrentTask); // eslint-disable-line @typescript-eslint/no-explicit-any

  const units = getUnitsFromProperties(properties);

  const homes = units
    .map((homeUnit) => {
      const formattedAddress = homeUnit.basicInfo.unitNumber
        ? `${homeUnit.property.address.streetAddress}, ${STRINGS.unit} ${homeUnit.basicInfo.unitNumber}`
        : homeUnit.property.address.streetAddress;

      return {
        key: homeUnit.basicInfo.unitId,
        value: formattedAddress,
        streetAddress: homeUnit.property.address.streetAddress,
      };
    })
    .sort((firstHome, secondHome) => {
      return firstHome.streetAddress.localeCompare(secondHome.streetAddress, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });

  const getInitialValues = () => {
    const firstHome = homes?.length === 1 ? homes[0] : null;
    const home = isHomeOwnerView ? firstHome : unit.unitId;
    const renterObj: any = {};

    if (!isHomeOwnerView) {
      renterObj.doesResidentClaimResponsibility = task.doesResidentClaimResponsibility?.toString() || null;
      renterObj.requestorAvailability = task.requestorAvailability || [];
      renterObj.summary = taskId ? task.summary : upgradesNames[toLowerCase(proService)]?.label;
    }

    if (taskId) {
      const filteredMedia = filter(task.media, (m) => m.stage === 'Before' && !m.subOwner);

      return {
        ...task,
        description: task.description || '',
        home: task.homeUniqueId,
        issueType: proService,
        attachments: {
          availableMedia: filteredMedia,
          mediaToDisplay: filteredMedia,
          deletedMedia: [],
          later: !filteredMedia?.length,
        },
        doesResidentClaimResponsibility: task.isResidentFault?.toString() || 'false',
        ...renterObj,
      };
    }

    const initialValues: any = {
      home,
      attachments: {
        availableMedia: [],
        deletedMedia: [],
      },
      issueType: proService,
      ...renterObj,
    };

    if (toLowerCase(proService) === 'haulingandstorage') {
      initialValues.requestTypeDetails = {
        type: 'Haul',
        items: [{}],
      };
    }

    return initialValues;
  };

  async function handleFormSubmit(values) {
    const submitValues = {
      homeUniqueId: values.home.key || values.home,
      requestType: values.issueType || proService,
      ...values,
    };

    if (!isHomeOwnerView) {
      const getOnlyValidDates = values.requestorAvailability.filter((date) => {
        if (date.times?.length) {
          return true;
        }
        return false;
      });
      const validDates = getOnlyValidDates.length ? getOnlyValidDates : null;

      submitValues.requestorAvailability = values.isRequestorPresent ? validDates : null;

      if (Object.keys(upgradesNames).includes(toLowerCase(proService))) {
        submitValues.requestType = 'Upgrade';
      }
    }

    if (submitValues.requestType === RequestType.HaulingAndStorage) {
      submitValues.summary = 'Item removal';
    }

    delete submitValues.home;

    setIsSubmitting(true);

    try {
      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append(URL_QUERY_PARAMS.TOAST_VISIBLE, 'true');
      urlSearchParams.append(URL_QUERY_PARAMS.SUCCESS, 'true');

      if (!taskId) {
        await dispatch(createMaintenanceTask(submitValues));

        urlSearchParams.append(URL_QUERY_PARAMS.CREATION, 'true');
      } else {
        await dispatch(updateMaintenanceTask(submitValues, taskId));

        urlSearchParams.append(URL_QUERY_PARAMS.UPDATE, 'true');
      }

      push({
        pathname: isHomeOwnerView ? PATHS.HOMEOWNER_ACCOUNT_MAINTENANCE : PATHS.RESIDENTS_ACCOUNT_MAINTENANCE,
        search: urlSearchParams.toString(),
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    const fetchTask = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchTaskByTaskId(taskId));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (taskId) {
      fetchTask();
    }

    return () => {
      dispatch(maintenanceCurrentClear());
    };
  }, [dispatch, taskId]);

  return (
    <>
      {(isSubmitting || isLoading) && <Spinner />}
      <Form
        onSubmit={handleFormSubmit}
        initialValues={getInitialValues()}
        mutators={{
          ...arrayMutators,
        }}
        getFormBottomBar={(formProps, nextButtonProps) => {
          return (
            <div className="flex items-center justify-center mb-3xl">
              <FormLayout.Button
                buttonClassNames="w-button-fluid sm:w-button-default h-button flex justify-center"
                ctaProps={{
                  label: 'Submit',
                }}
                nextButtonWrapperProps={nextButtonProps}
              />
            </div>
          );
        }}
        bottomPosition="25px"
        getForm={({ form, handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormLayout>
                {isHomeOwnerView && (
                  <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.which_home}>
                    <div className="mt-sm">
                      <Field
                        component={DropdownFinalFormAdapter}
                        itemClassname={styles.homes}
                        items={homes}
                        name="home"
                        placeholder={STRINGS['select-home']}
                        withMarginTop={false}
                        validate={required}
                      />
                    </div>
                  </FormLayout.Section>
                )}
                {isHomeOwnerView && (
                  <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.issue_type}>
                    <div className="mt-sm">
                      <Field
                        name="issueType"
                        component={SelectorFinalFormAdapter}
                        buttons={[
                          {
                            label: RequestType.Repair,
                            key: RequestType.Repair,
                          },
                          {
                            label: RequestType.Upgrade,
                            key: RequestType.Upgrade,
                          },
                        ]}
                        validate={required}
                        type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                        disabled={!!taskId}
                      />
                    </div>
                  </FormLayout.Section>
                )}
                {values.issueType === RequestType.Repair && (
                  <>
                    <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.repair.title}>
                      <Field
                        name="summary"
                        allowUserInput
                        hideAutoCompleteIconOnMobile
                        component={AutoCompleteFinalFormAdapter}
                        items={listOfAppliances}
                        validate={required}
                        placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                      />
                    </FormLayout.Section>
                    {!isHomeOwnerView && <ApprovalMessage show />}

                    <FormLayout.Section
                      sectionTitle={<String string={ADD_TASK_MODAL_STRINGS.repair.describe_issue_title} />}
                      sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS.repair.describe_issue_subtitle} />}
                    >
                      <Field
                        name="description"
                        component={InputFinalFormAdapter}
                        placeholder={ADD_TASK_MODAL_STRINGS.repair.describe_issue_placeholder}
                        validate={required}
                        textarea
                      />
                    </FormLayout.Section>
                    {!isHomeOwnerView && (
                      <FormLayout.Section
                        sectionTitle={ADD_TASK_MODAL_STRINGS['renter_repair_modal.responsibility_title']}
                        sectionSubTitle={
                          <String string={ADD_TASK_MODAL_STRINGS['renter_repair_modal.responsibility_subtitle']} />
                        }
                      >
                        <Field
                          name="doesResidentClaimResponsibility"
                          component={SelectorFinalFormAdapter}
                          placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
                          buttons={responsibility}
                          validate={required}
                          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                        />
                      </FormLayout.Section>
                    )}
                    <FormLayout.Section
                      sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                      sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.repair']} />}
                    >
                      <Field
                        name="attachments"
                        component={NewMediaUploadAdapter}
                        mediaLabel={MEDIA_LABEL.PHOTO_OR_VIDEO}
                        onChangeCustom={(value) => {
                          if (value.stagingMedia.length) {
                            form.change('attachments.later', false);
                          }
                        }}
                        wrapperSize="large"
                        validate={validateMedia}
                        disableWebcam
                      />
                    </FormLayout.Section>
                    <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.urgency}>
                      <Field
                        name="urgency"
                        component={SelectorFinalFormAdapter}
                        placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
                        buttons={urgency}
                        validate={required}
                        type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                      />
                    </FormLayout.Section>
                    {values.urgency === 'Urgent' && (
                      <div className="flex flex-row p-sm border border-solid border-green rounded bg-green-light-bg -mt-md mb-lg">
                        <Text>
                          <span role="img" aria-label="emergency emoji" className="mr-xs">
                            🚨️
                          </span>
                          <span>{ADD_TASK_MODAL_STRINGS.emergency}</span>
                          <String string={ADD_TASK_MODAL_STRINGS.danger} className="mt-sm" />
                        </Text>
                      </div>
                    )}
                    {!isHomeOwnerView && <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />}
                  </>
                )}
                {(values.issueType === RequestType.Upgrade ||
                  Object.keys(upgradesNames).includes(toLowerCase(proService))) && (
                  <>
                    <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.upgrade.title}>
                      <Field
                        name="summary"
                        allowUserInput
                        hideAutoCompleteIconOnMobile
                        component={AutoCompleteFinalFormAdapter}
                        items={listOfAppliances}
                        validate={required}
                        placeholder={ADD_TASK_MODAL_STRINGS.appliance_placeholder}
                      />
                    </FormLayout.Section>
                    {!isHomeOwnerView && (
                      <ApprovalMessage
                        show={
                          values.issueType === RequestType.Upgrade ||
                          toLowerCase(proService) === toLowerCase(RequestType.Upgrade)
                        }
                        taskName={toLowerCase(values.issueType || proService)}
                      />
                    )}
                    <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.upgrade.describe_issue_title}>
                      <Field
                        name="description"
                        component={InputFinalFormAdapter}
                        placeholder={ADD_TASK_MODAL_STRINGS.upgrade.describe_issue_placeholder}
                        validate={required}
                        textarea
                      />
                    </FormLayout.Section>
                    <FormLayout.Section
                      sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
                      sectionSubTitle={
                        <String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.upgrade_or_addition']} />
                      }
                    >
                      <MaintenanceModalAttachmentFields form={form} />
                    </FormLayout.Section>
                    {!isHomeOwnerView && (
                      <>
                        <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />{' '}
                        <div className="flex flex-row p-sm border border-solid border-green rounded bg-green-light-bg -mt-md mb-lg">
                          <Text>
                            <span role="img" aria-label="" className="mr-xs">
                              🔔
                            </span>
                            {ADD_TASK_MODAL_STRINGS.follow_up}
                          </Text>
                        </div>
                      </>
                    )}
                  </>
                )}
                {toLowerCase(proService) === toLowerCase(RequestType.HaulingAndStorage) && (
                  <>
                    <HaulingForm form={form} />
                    {!isHomeOwnerView && <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />}
                  </>
                )}
                {toLowerCase(proService) === toLowerCase(RequestType.KeysAndAccess) && (
                  <>
                    <KeysForm form={form} />
                    {!isHomeOwnerView && <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />}
                  </>
                )}
              </FormLayout>
            </form>
          );
        }}
      />
    </>
  );
}

type ApprovalMessageProps = {
  show?: boolean;
  taskName?: string;
};

const ApprovalMessage = ({ show, taskName }: ApprovalMessageProps) => {
  const requiresApproval = upgradesNames[taskName]?.approval;

  if (show || requiresApproval) {
    return (
      <div className="flex flex-row p-sm border border-solid border-green rounded bg-green-light-bg -mt-md mb-lg">
        <Text>
          <span role="img" aria-label="" className="mr-xs">
            ⚠️
          </span>
          {ADD_TASK_MODAL_STRINGS.approval_required}
        </Text>
      </div>
    );
  }

  return null;
};
