export const SECTIONS_STRINGS = {
  'inspection_schedule.displayName': 'Unlock Your <span>Complimentary Inspection</span>',
  'inspection_schedule.adopted_resident.displayName': 'Introduce Us To Your <span>Residents & Home</span>',
  'build_listing.displayName': 'Unlock Your <span>Professional Listing</span>',
  'build_listing.adopted_resident.displayName': 'Be Ready <span>To List</span>',
  'home_improvements.displayName': 'Approve Home Improvements',
  'home_improvements_move_out.displayName': 'Confirm Final Inspection Results',
  'home_improvements_pre_move_out.displayName': 'Confirm Final Inspection Results',
  'pre_move_out.displayName': 'Prepare Your Home For Re-Listing',
  'home_pricing.displayName': 'Set Price to Maximize\u00a0Earnings',
  'home_pricing_move_out.displayName': 'Set Listing Price to Maximize\u00a0Earnings',
  'next_level_profile.displayName': 'Take Your Profile To <span>The Next Level</span>',
  'get_paid.displayName': 'Unlock Payments',
  'account.title': 'Account',
  'what_you_need.sub_title': 'What You Need',
  hoa_agreement_and_info: 'To complete this, you’ll need your HOA Agreement, and your HOA contact information.',
  current_lease: 'Current Lease',
  resident_contact_info: 'Resident Contact Info',
  hoa_agreement: 'HOA Agreement',
  hoa_contact_info: 'HOA Contact Info',
  tax_info: 'Tax Info',
  insurance: "Proof of Homeowner's Insurance",
  valid_id: 'Valid ID',
  'upcoming_appointments.title': 'Upcoming Appointments',
  'upcoming_appointments.inspection': 'Inspection',
  'upcoming_appointments.reschedule': 'Reschedule',
  add_insurance: 'Add Insurance',
  w9: 'Be Ready for Tax Season',
};
