import React, { Component } from 'react';
import { Form } from 'react-final-form';
// Redux
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Collapsible from 'components/Collapsible/Collapsible';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
// Styling
import String from 'components/String/String';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintenancePaymentFlowForHomeOwners/ModalMaintenancePaymentFlowForHomeOwners.module.css';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
// FinalForm
import arrayMutators from 'final-form-arrays';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { PaymentAgreement } from 'models/enums';
import PropTypes from 'prop-types';
import { updateMaintenanceConsent } from 'store/redux/maintenance/actions';
import { getString, formatString } from 'strings';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';
import { required } from 'utils/validation';

const { shouldDoIt, renterSplitText, shouldDoItNoResident } = MAINTENACE_STATUS_MODAL_STRINGS;

const cx = classNames.bind(styles);

const getApprovalFromEnums = (paymentAgreement) => {
  const approvalEnums = {
    [PaymentAgreement.OwnerPays]: true,
    [PaymentAgreement.SplitPayment]: true,
    [PaymentAgreement.ResidentPays]: true,
    [PaymentAgreement.Declined]: false,
  };
  return approvalEnums[paymentAgreement] || false;
};

const getPercentageFromEnums = (paymentAgreement) => {
  const approvalEnums = {
    [PaymentAgreement.OwnerPays]: 100,
    [PaymentAgreement.SplitPayment]: 50,
    [PaymentAgreement.ResidentPays]: 0,
    [PaymentAgreement.Declined]: 100,
  };
  return approvalEnums[paymentAgreement];
};

class ModalMaintenancePaymentFlowForHomeOwners extends Component {
  static propTypes = {
    updateMaintenanceConsent: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    createMarginAndPaddingClassName: PropTypes.string,
    showResponsibilityQuestions: PropTypes.bool,
    close: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createMarginAndPaddingClassName: '',
    showResponsibilityQuestions: false,
  };

  onSubmit = async (values) => {
    const { task, close } = this.props;

    const sendObjectToBackend = {
      isApproved: getApprovalFromEnums(values.responsibilityForAdditionJob),
      percentage: getPercentageFromEnums(values.renterPaysHowMuch || values.responsibilityForAdditionJob),
      notes: values.otherReasonWhyNoUpgrade || values.reasonWhyNoUpgrade,
    };

    try {
      await this.props.updateMaintenanceConsent(task.uniqueId, sendObjectToBackend);
      await close();
    } catch (err) {
      console.error(err);
    }
  };

  getInitialValues() {
    this.initialValue = {};
    return this.initialValue;
  }

  changeFormValue = (value, form) => {
    const { reasonWhyNoUpgrade, renterPaysHowMuch } = value;

    form.batch(() => {
      form.change(reasonWhyNoUpgrade, '');
      form.change(renterPaysHowMuch, '');
    });
  };

  render() {
    const { createMarginAndPaddingClassName, task, showResponsibilityQuestions } = this.props;
    const { isLegacyInvoicing, financedPayment } = task;

    const getItems = showResponsibilityQuestions ? shouldDoIt : shouldDoItNoResident;

    return (
      <Form
        mutators={{
          ...arrayMutators,
        }}
        initialValues={this.getInitialValues()}
        onSubmit={this.onSubmit}
        render={({ handleSubmit, invalid, form }) => (
          <form onSubmit={handleSubmit}>
            <Collapsible
              title="Approval and Responsibility"
              collapsed={false}
              showWhenCollapsed={
                <div className={cx('border-replacement-for-bottom-bar')}>
                  <Space value={SPACE_TYPES.XL} />
                </div>
              }
              createMarginAndPaddingClassName={createMarginAndPaddingClassName}
              inner={
                <div className={cx('green-border')}>
                  <div className={cx('responsibility-title')}>
                    {MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.title}
                  </div>
                  <Field
                    name="responsibilityForAdditionJob"
                    component={SelectorFinalFormAdapter}
                    buttons={getItems}
                    validate={required}
                    type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                    fluid
                    onChangeCustom={() => {
                      this.changeFormValue(
                        {
                          renterPaysHowMuch: 'renterPaysHowMuch',
                          reasonWhyNoUpgrade: 'reasonWhyNoUpgrade',
                        },
                        form
                      );
                    }}
                  />

                  <Condition when="responsibilityForAdditionJob" is={PaymentAgreement.OwnerPays}>
                    <Space />
                    <String
                      string={
                        !isLegacyInvoicing && financedPayment?.dueNow > 0
                          ? formatString(
                              MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.homeownerpaysWithUpfront
                                .text,
                              {
                                upfront: financedPayment.dueNow,
                              }
                            )
                          : MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.homeownerpays.text
                      }
                    />
                  </Condition>

                  <Condition when="responsibilityForAdditionJob" is={PaymentAgreement.ResidentPays}>
                    <>
                      <div className={cx('h3')}>
                        {MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.renterpays.title}
                      </div>
                      <Row>
                        <Col sm={8} xs={12}>
                          <Field
                            name="renterPaysHowMuch"
                            component={SelectorFinalFormAdapter}
                            buttons={renterSplitText}
                            fluid
                            validate={required}
                            type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                          />
                        </Col>
                      </Row>
                      {task.resident && (
                        <Condition when="renterPaysHowMuch" is={PaymentAgreement.ResidentPays}>
                          <Space />
                          <div>
                            {getString(
                              MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.renterpaysfull.text
                            )}
                          </div>
                        </Condition>
                      )}

                      {task.resident && (
                        <Condition when="renterPaysHowMuch" is={PaymentAgreement.SplitContent}>
                          <Space />
                          <div>
                            {getString(
                              MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.renterpayshalf.text,
                              { firstName: task.resident.firstName }
                            )}
                          </div>
                        </Condition>
                      )}
                    </>
                  </Condition>
                  <Condition when="responsibilityForAdditionJob" is={PaymentAgreement.Declined}>
                    <div className={cx('h3')}>
                      {MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonhomeownerside.denied.text}
                    </div>
                    <Field
                      name="reasonWhyNoUpgrade"
                      transparent
                      component={InputFinalFormAdapter}
                      placeholder="Reason"
                      validate={required}
                    />
                  </Condition>
                </div>
              }
            />
            {/* Only have spacing when collapsed */}
            <ModalLayout.BottomBar
              ctaProps={{
                label: 'Submit',
                disabled: invalid,
              }}
              fixedBottom
              removeMarginTopAndBorderBottomBar
            />
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  updateMaintenanceConsent,
};

export default connect(null, mapDispatchToProps)(ModalMaintenancePaymentFlowForHomeOwners);
