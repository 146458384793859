import React, { Component } from 'react';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import isEqual from 'lodash/isEqual';
import { InvolvementLevel } from 'models/enums';
import PropTypes from 'prop-types';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { required } from 'utils/validation';
import GreenInfoBox from '../../../../components/GreenInfoBox/GreenInfoBox';
import String from '../../../../components/String/String';
import Space from '../../../../corecomponents/Space/Space';
import Condition from '../../../../formcomponents/Condition/Condition';

const { FormLayoutHeader } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.HomeownerMaintenanceInvolvement;

class MaintenanceInvolvement extends Component {
  static propTypes = {
    currentStepData: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;

    onSaveAndNext(values);
  };

  getInitialValues() {
    const { currentStepData } = this.props;

    return {
      ...currentStepData,
    };
  }

  render() {
    const { onBack } = this.props;

    return (
      <>
        <FormLayout>
          <FormLayoutHeader title={HOB.title} pretitle={HOB.pretitle} />
          <Form
            mutators={{
              ...arrayMutators,
            }}
            getFormBottomBar={(formProps, nextButtonProps) => (
              <FormLayout.BottomBar
                ctaProps={{
                  label: 'Next',
                  type: 'submit',
                }}
                nextButtonWrapperProps={nextButtonProps}
                previousStepProps={{
                  onClick: onBack,
                  to: null,
                }}
              />
            )}
            initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
            initialValues={this.getInitialValues()}
            onSubmit={this.onSubmit}
            getForm={() => (
              <>
                <Field
                  name="maintenanceInvolvement.involvementLevel"
                  component={SelectorFinalFormAdapter}
                  validate={required}
                  buttons={[
                    {
                      label: 'Just send me the checks.',
                      key: InvolvementLevel.SendMeTheChecks,
                    },
                    {
                      label: 'Keep me in the loop.',
                      key: InvolvementLevel.KeepMeLooped,
                    },
                    {
                      label: 'I want to make <span>all decisions.</span>',
                      key: InvolvementLevel.WillTakeAllDecisions,
                    },
                  ]}
                  type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                  fluid
                />
                <Space />
                <Condition when="maintenanceInvolvement.involvementLevel" is="SendMeTheChecks">
                  <GreenInfoBox>
                    <String string={HOB.responses.checks} />
                  </GreenInfoBox>
                </Condition>
                <Condition when="maintenanceInvolvement.involvementLevel" is="KeepMeLooped">
                  <GreenInfoBox>
                    <String string={HOB.responses.intheloop} />
                  </GreenInfoBox>
                </Condition>
                <Condition when="maintenanceInvolvement.involvementLevel" is="WillTakeAllDecisions">
                  <GreenInfoBox>
                    <String string={HOB.responses.alldecision} />
                  </GreenInfoBox>
                </Condition>
              </>
            )}
          />
        </FormLayout>
      </>
    );
  }
}

export default MaintenanceInvolvement;
