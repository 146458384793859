import { useSelector } from 'react-redux';
import { Spinner } from '@belong/ui';
import { useProperties } from 'accounts/hooks/homeowners/use-properties';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import { isNil } from 'lodash';
import { EmployeeAssignmentTypes, ReportType } from 'models/enums';
import { selectUserReports } from 'store/redux/user/selectors';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { FORMATTED_EMPLOYEES } from '../YourPortfolio/Properties/Property/constants';

export function Team() {
  const { properties = [], isLoadingProperties } = useProperties(true);
  const unitsWithAgreements = properties?.flatMap((prop) => prop.getUnitsWithAgreements()) || [];
  const selectedUnit = unitsWithAgreements[0];
  const allEmployees = selectedUnit?.employeeAssignments || [];
  const reports = useSelector(selectUserReports);

  const homeownerGrowth =
    getEmployeeByType(allEmployees, EmployeeAssignmentTypes.HomeownerGrowth) || getDefaultHomeownerGrowth();
  const conciergeOrGrowth = getEmployeeByType(allEmployees, EmployeeAssignmentTypes.Concierge) || homeownerGrowth;

  const selectedUnitPricingReport = reports?.find(({ report }) => {
    return report.homeId === selectedUnit?.basicInfo.unitId && report.type === ReportType.Pricing;
  });
  const pricingReportEmployees = selectedUnitPricingReport?.employeeAssignments || [];

  let sortedEmployees = [];
  if (selectedUnit?.currentLease) {
    sortedEmployees = [
      FORMATTED_EMPLOYEES.HEAD_OF_OPERATIONS,
      conciergeOrGrowth,
      pricingReportEmployees?.[0],
      FORMATTED_EMPLOYEES.HEAD_OF_EXPERIENCE,
    ];
  } else {
    sortedEmployees = [
      pricingReportEmployees?.[0],
      conciergeOrGrowth,
      getEmployeeByType(allEmployees, EmployeeAssignmentTypes.ResidentGrowth),
    ];
  }

  sortedEmployees = sortedEmployees.filter((employee) => {
    return !isNil(employee);
  });

  const formattedEmployees =
    sortedEmployees.map(({ employee, employeeAssignment, user, ...rest }) => ({
      ...employeeAssignment,
      ...employee,
      ...user,
      profileImage: user?.profileImageUrl,
      bannerImage: employee?.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[employeeAssignment?.assignmentType],
      ...rest,
    })) || [];

  function getEmployeeByType(employees, type) {
    return employees.find((employee) => employee?.employeeAssignment.assignmentType === type);
  }

  return (
    <>
      {isLoadingProperties && <Spinner />}
      <div className="flex justify-center mt-3xl">
        <MultipleBelongEmployeeProfileView employees={formattedEmployees} />
      </div>
    </>
  );
}
