import React, { useState } from 'react';
import { IconForward } from '@belong/icons';
import { Image, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import CircledNumber from 'components/CircledNumber/CircledNumber';
import DividerRow from 'components/DividerRow/DividerRow';
import ShadowMediaCard from 'components/ShadowMediaCard/ShadowMediaCard';
import Carousel from 'components/v2/Carousel/Carousel';
import { Flex, Box } from 'design-system';
import { useModal } from 'hooks/useModal';
import chunk from 'lodash/chunk';
import { getInspectionType } from 'pages/PostInspectionFlow/utils';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import styles from './Responsiblity.module.css';
import { WearAndTearModal } from './WearAndTearModal/WearAndTearModal';
import { belongStandardsItems, wearAndTearItems } from './constants';

const cx = classNames.bind(styles);

const responsibilityDividerRowItems = [
  {
    icon: <Image src="/improvement-reports/pre_moveout_icon_01.svg" className="w-[96px] h-[96px]" />,
    top: <CircledNumber>1</CircledNumber>,
    title: 'Accuracy',
    subtitle: formatString(
      `We'll help you take the guess work out of who owes what and why with our rigorous inspections.`
    ),
  },
  {
    icon: <Image src="/improvement-reports/pre_moveout_icon_02.svg" className="w-[96px] h-[96px]" />,
    top: <CircledNumber>2</CircledNumber>,
    title: 'Transparency',
    subtitle: formatString(`You'll never be in the dark on repairs, costs, or who is responsible.`),
  },
  {
    icon: <Image src="/improvement-reports/pre_moveout_icon_03.svg" className="w-[96px] h-[96px]" />,
    top: <CircledNumber>3</CircledNumber>,
    title: 'Speed',
    subtitle: formatString(`We'll get your home loved as quickly as possible by focusing on the most crucial repairs.`),
  },
];

const wearAndTearItemsDesktop = chunk(wearAndTearItems, 2);

const BelongStandard = ({ item, isEven, isLastIndex }) => {
  return (
    <Flex
      mx="-2sm"
      mb={(0, 0, isLastIndex ? 0 : '2xl')}
      flexDirection={['column', 'column', isEven ? 'row' : 'row-reverse']}
    >
      <div className="flex-1 px-2sm mb-sm lg:mb-0">
        <Image src={item.imageUrl} className={cx('belong-standard-image')} />
      </div>
      <div className="flex-1 px-2sm">
        <div className="mb-xs">
          <Text fontWeight="semibold">{item.title}</Text>
        </div>
        <div>
          <Text>{item.body}</Text>
        </div>
      </div>
    </Flex>
  );
};

BelongStandard.propTypes = {
  item: PropTypes.object.isRequired,
  isEven: PropTypes.bool.isRequired,
  isLastIndex: PropTypes.bool.isRequired,
};

const WearAndTearItem = ({ item, onClick }) => {
  return (
    <ShadowMediaCard
      topLeft={<Text className="text-white">{item.title}</Text>}
      topRight={<IconForward className="h-2sm text-white" />}
      onClick={onClick}
      imageUrl={item.imageUrl}
      height={194}
    />
  );
};

WearAndTearItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/prop-types
export const ResponsibilityContent = () => {
  const [isModalOpen, openModal, closeModal] = useModal();
  const [currentWearAndTear, setCurrentWearAndTear] = useState();
  return (
    <>
      <div className="mb-2xl lg:xl">
        <Text variant="h3" fontWeight="semibold">
          {POST_INSPECTION_FLOW_STRINGS['responsibility.our_promise.title']}
        </Text>
      </div>
      <div className="mb-4xl lg:mb-3xl">
        {/* Desktop qualities divider row */}
        <div className={cx('desktop-container')}>
          <DividerRow desktopColumns={3} items={responsibilityDividerRowItems} />
        </div>
        {/* Mobile qualities divider row */}
        <div className={cx('mobile-container')}>
          <Carousel pagingDotsClassName="green">
            {responsibilityDividerRowItems.map((item, index) => (
              <DividerRow.Item key={index} item={item} defaultMobileIconSize={false} />
            ))}
          </Carousel>
        </div>
      </div>

      <div className="mb-xl lg:mb-2xl">
        <Text variant="h3" fontWeight="semibold" className="mb-xs">
          {POST_INSPECTION_FLOW_STRINGS['responsibility.belong_standards.title']}
        </Text>
        <Text>
          We’ve developed Belong Standards to ensure your home is safe, and in condition to maximize your earnings all
          while ensuring that repairs don’t slow us down.
        </Text>
      </div>
      <div className="mb-4xl lg:mb-3xl">
        {/* Desktop Belong standards */}
        <div className={cx('desktop-container')}>
          {belongStandardsItems.map((item, index) => {
            const isLastIndex = index === responsibilityDividerRowItems.length - 1;
            const isEven = index % 2 === 0;
            return <BelongStandard key={index} item={item} isLastIndex={isLastIndex} isEven={isEven} />;
          })}
        </div>
        {/* Mobile Belong standards */}
        <div className={cx('mobile-container')}>
          <Carousel pagingDotsClassName="green">
            {belongStandardsItems.map((item, index) => {
              return <BelongStandard key={index} item={item} />;
            })}
          </Carousel>
        </div>
      </div>

      <Text variant="h3" fontWeight="semibold" className="mb-xs">
        {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear.title']}
      </Text>

      <Text className="mb-2xl">{POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear.body']}</Text>

      <div className={cx('desktop-container')}>
        {wearAndTearItemsDesktop.map((row, index) => {
          const [leftItem, rightItem] = row;
          const isLastRow = index === wearAndTearItemsDesktop.length - 1;
          return (
            <Flex mb={isLastRow ? 0 : 'lg'} key={index}>
              <Box flex={1} mr="lg">
                <WearAndTearItem
                  item={leftItem}
                  onClick={() => {
                    openModal();
                    setCurrentWearAndTear(2 * index);
                  }}
                />
              </Box>
              <Box flex={1}>
                <WearAndTearItem
                  item={rightItem}
                  onClick={() => {
                    openModal();
                    setCurrentWearAndTear(2 * index + 1);
                  }}
                />
              </Box>
            </Flex>
          );
        })}
      </div>
      <div className={cx('mobile-container')}>
        <Carousel pagingDotsClassName="green">
          {wearAndTearItems.map((item, index) => {
            return (
              <WearAndTearItem
                key={index}
                item={item}
                onClick={() => {
                  openModal();
                  setCurrentWearAndTear(index);
                }}
              />
            );
          })}
        </Carousel>
      </div>

      {isModalOpen && (
        <WearAndTearModal initialValueIndex={currentWearAndTear} show={isModalOpen} onHide={closeModal} />
      )}
    </>
  );
};

const Responsiblity = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, flow }) => {
  const inspectionType = getInspectionType(flow);

  const inspectedBy = stepResource?.homeListingDetailsModel?.inspectedBy?.employeeDetailModel;
  const formattedInspectedBy = {
    ...stepResource?.homeListingDetailsModel?.inspectedBy,
    ...inspectedBy?.employee,
    ...inspectedBy?.user,
    profileImage: inspectedBy?.user?.profileImageUrl,
    bannerImage: inspectedBy?.employee?.bannerImageUrl,
    jobTitle: EMPLOYEE_TITLES.Pro,
  };

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={
        inspectionType.isPreMoveOut
          ? formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.title.pre_move_out'])
          : formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.title.move_out'])
      }
      employee={formattedInspectedBy}
      showCityBackground
    >
      <Flex justifyContent="center" mb="3xl">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          Next
        </PostInspectionContentLayout.CtaButton>
      </Flex>
      <ResponsibilityContent />
    </PostInspectionContentLayout>
  );
};

Responsiblity.propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
  flow: PropTypes.object.isRequired,
};

export default Responsiblity;
