/* eslint-disable react/prop-types */
import React from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import classNames from 'classnames/bind';
import MediaPreview from 'components/MediaPreview/MediaPreview';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintenanceSummary/ModalMaintenanceSummary.module.css';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';
import Space from 'corecomponents/Space/Space';
import { filter } from 'lodash';
import { getReportedByUser } from 'utils/findUserBasedOnParams';
import { fixUserTypeEnums } from 'utils/formatName';

const cx = classNames.bind(styles);

export function ModalMaintenanceSummary({ hideDetails = false, task }) {
  const { width } = useWindowSize();

  const isDesktop = width > BREAKPOINTS_WIDTHS.SM;

  const user = getReportedByUser(task);
  const { media } = task;

  const taskImageUrls = filter(media, (m) => m.stage === 'Before' && !m.subOwner);

  return (
    <div className={cx('modalMaintenanceSummary')}>
      <h3>Summary</h3>
      <UserActionMaintenance
        userId={user?.userId}
        leftPadding
        requestHauling={{
          haulType: task.requestTypeDetails?.type,
          items: task.requestTypeDetails?.items,
        }}
        time={task.createdOn}
        name={`${user?.firstName} ${user?.lastName}`}
        description={!hideDetails && task.description}
        withSpace={!hideDetails}
        reportedBy={fixUserTypeEnums(task.requestedBy)}
        profileImage={user?.profileImageUrl}
      />
      {!!taskImageUrls.length && !hideDetails && (
        <>
          <Space />
          <MediaPreview ignoreModalExitAndEnterFunctions availableMedia={taskImageUrls} fullPage={isDesktop} />
        </>
      )}
    </div>
  );
}
