import { EmployeeAssignmentTypes } from 'models/enums';

export const EMPLOYEE_TITLES = {
  [EmployeeAssignmentTypes.PricingAnalyst]: 'Pricing Lead',
  [EmployeeAssignmentTypes.Concierge]: 'Your Member Success Lead',
  [EmployeeAssignmentTypes.HomeownerGrowth]: 'Homeowner Advisor',
  [EmployeeAssignmentTypes.ResidentGrowth]: 'Leasing Advisor',
  [EmployeeAssignmentTypes.SalesOperation]: 'Onboarding Ops',
  [EmployeeAssignmentTypes.ImprovementsAssessor]: 'Onboarding Ops',
  [EmployeeAssignmentTypes.Pro]: 'Belong Pro',
  [EmployeeAssignmentTypes.HeadOfOperations]: 'Head Of Operations',
  [EmployeeAssignmentTypes.HeadOfExperience]: 'Head Of Experience',
  [EmployeeAssignmentTypes.SalesDevelopmentRepresentative]: 'Homeowner Associate',
};

export const LastUpdated = {
  last_updated_general: 'Last Updated {date}',
};
