import { Component } from 'react';
import { FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from 'react-redux';
import Form from 'components/Form/Form';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import NotThereYetModal from 'containercomponents/Modals/NotThereYetModal/NotThereYetModal';
import arrayMutators from 'final-form-arrays';
import { Row } from 'forkedlibraries/react-bootstrap';
import MultipleUnitAddress from 'formcomponents/MultipleUnitAddress/MultipleUnitAddress';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { compact, find, includes, uniq } from 'lodash';
import { HomeownerCreationSource, FlowStatus } from 'models/enums';
import HomeAlreadyExistsModal from 'pages/HomeOwnerOnboarding/HomeAlreadyExistsModal/HomeAlreadyExistsModal';
import PropTypes from 'prop-types';
import { addPropertyErrorProps, removeErrorProps } from 'store/redux/errorHandling/actions';
import { selectErrorData } from 'store/redux/errorHandling/selectors';
import { fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';
import {
  checkAddressCoverage,
  createHomeownerLeads,
  createProperties,
  deleteAddresses,
  deleteOnboardingProperties,
  fetchOnboardingInfo,
  fetchOnboardingProperties,
  updateAddresses,
} from 'store/redux/homeowner-onboarding/actions';
import { filterApplicationPendingProperties } from 'store/redux/homeowner-onboarding/filters';
import { selectAddresses, selectOnboardingProperties } from 'store/redux/homeowner-onboarding/selectors';
import { hideModal, showModal } from 'store/redux/modals/actions';
import { selectIsUserLoggedIn } from 'store/redux/user/selectors';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { GA_ACTIONS, trackHomeownerAddressEntered } from '../../../../analytics/ga.utils';
import { MODALS } from '../../../../containercomponents/Modals/modal.consts';
import { getEntryPoint, STEPS_CONFIG } from '../steps';

const { FormLayoutHeader, Section } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.property_address;

const isValidAddress = (address) => {
  if (address && address.city) {
    return true;
  }

  return false;
};

const isValidAddresses = (addresses) => {
  if (!addresses) {
    return false;
  }

  if (find(addresses, isValidAddress)) {
    return undefined;
  }

  return 'Error';
};

const disableAddProperty = (fields) => {
  const fieldValues = fields.value;
  const lastField = fieldValues[fieldValues.length - 1];

  if (isValidAddress(lastField)) {
    return false;
  }

  return true;
};

class PropertyAddress extends Component {
  constructor(props) {
    super(props);
    this.fromCalculator = this.props.location.state?.fromCalculator;
    this.state = {
      initialValues: this.getInitialValues(props),
      showNotThereYetModal: false,
    };
  }

  componentDidMount() {
    if (this.fromCalculator) {
      // Taken from react-final-form subbmit from outside example.
      const addressForm = document.getElementById('addressForm');
      if (addressForm) {
        addressForm.dispatchEvent(new Event('submit', { cancelable: true }));
      }
      return;
    }

    const { properties, onboardingState, showModal: showModalAction, history, signUpFlow } = this.props;
    if (signUpFlow?.status && signUpFlow?.status !== FlowStatus.Completed) {
      showModalAction(MODALS.HOMEOWNER_ONBOARDING_RESUME_MODAL, {
        properties,
        onboardingState,
        history,
        closeButton: false,
        closable: false,
        onKeepWorkingClick: this.handleKeepWorkingClick,
        onNewProperty: () => {},
      });
    }
  }

  componentWillUnmount() {
    const { removeErrorProps: removeErrorPropsAction } = this.props;
    removeErrorPropsAction();
  }

  handleKeepWorkingClick = () => {
    const { onEnterFlow } = this.props;
    onEnterFlow();
  };

  checkCoverage(coverageStats, coverages) {
    if (!includes(coverageStats, true)) {
      const cities = uniq(coverages.map((coverage) => coverage.address.city));

      this.setState({
        showNotThereYetModal: true,
        cityName: cities.length === 1 ? cities[0] : null,
        multipleCities: cities.length > 1,
        property: coverages?.[0],
      });
    }
  }

  onSubmit = async (values, form) => {
    const {
      updateAddresses: updateAddressesAction,
      history,
      isUserLoggedIn,
      showModal: showModalAction,
      properties,
      fetchOnboardingProperties: fetchOnboardingPropertiesAction,
      checkAddressCoverage: checkAddressCoverageAction,
      showSpinner,
      hideSpinner,
    } = this.props;

    showSpinner();
    updateAddressesAction(values.addresses);

    try {
      const [coverages, ...rents] = await Promise.all([checkAddressCoverageAction(values.addresses)]);
      const coverageStats = coverages?.map((c) => c.inCoverageArea);

      rents?.forEach((rent, index) => {
        if (coverageStats[index]) {
          trackHomeownerAddressEntered(GA_ACTIONS.ADDRESS_ENTERED_SIGNUP_FLOW, values.addresses[index], rent);
        }
      });

      hideSpinner();

      if (!isUserLoggedIn) {
        showModalAction(MODALS.LIGHT_ACCOUNT_CREATION, {
          afterSucessfulLogin: async () => {
            showSpinner();

            const { properties: onboardingProperties } = await fetchOnboardingPropertiesAction();

            if (properties.length) {
              showModalAction(MODALS.HOMEOWNER_ONBOARDING_RESUME_MODAL, {
                properties: onboardingProperties,
                history,
                closeButton: false,
                closable: false,
                onNewProperty: () => this.handlePropertyCreation(onboardingProperties, values, form),
              });
            } else {
              await this.handlePropertyCreation(onboardingProperties, values, form);

              this.checkCoverage(coverageStats, coverages);
            }

            hideSpinner();
          },
        });
      } else {
        await this.handlePropertyCreation(properties, values, form);

        this.checkCoverage(coverageStats, coverages);
      }
    } catch (err) {
      console.error(err);
      hideSpinner();
    }
  };

  getInitialValues = (props) => {
    const { properties, addresses } = props;
    const applicationPendingProperties = filterApplicationPendingProperties(properties);

    const initialAddresses = [...addresses, ...applicationPendingProperties]
      .filter(Boolean)
      .filter((property) => property?.basicInfo?.inCoverageArea)
      .map((property) => {
        if (typeof property.address === 'string') {
          // It is an address that come from /homeowners page

          return {
            ...property,
          };
        } else {
          const { address, units } = property || {};

          if (property.latitude) {
            return null;
          }

          // Don't include the address without unit number in the tags field.
          const tags = units
            ?.filter(Boolean)
            ?.filter((unit) => unit.basicInfo?.unitNumber)
            ?.map((unit) => ({
              name: unit.basicInfo?.unitNumber,
              id: unit.basicInfo?.unitId,
            }));

          return {
            completeAddress: address ? `${address.streetAddress}, ${address.city} ${address.state}` : '',
            propertyId: property?.basicInfo?.propertyId,
            streetAddress: address?.streetAddress,
            city: address?.city,
            state: address?.state,
            zipcode: address?.zipcode,
            latitude: address?.coordinates.latitude,
            longitude: address?.coordinates.longitude,
            units: {
              inputText: '',
              tags,
            },
          };
        }
      })
      .filter(Boolean);

    return {
      addresses: initialAddresses.length ? initialAddresses : [null],
    };
  };

  saveAddresses = (form) => {
    const { updateAddresses: updateAddressesAction } = this.props;

    if (form) {
      updateAddressesAction(form.values.addresses);
    }
  };

  handleNofityMe = (user) => {
    const { history, createHomeownerLeads: createHomeownerLeadsAction } = this.props;

    createHomeownerLeadsAction(
      user,
      null,
      this.fromCalculator ? HomeownerCreationSource.Calculator : HomeownerCreationSource.SignUpFlow
    );
    history.push('/');
  };

  handlePropertyCreation = async (properties, values, form) => {
    const {
      createProperties: createPropertiesAction,
      deleteOnboardingProperties: deleteOnboardingPropertiesAction,
      history,
      fetchAccountProperties: fetchAccountPropertiesAction,
      deleteAddresses: deleteAddressesAction,
      addPropertyErrorProps: addPropertyErrorPropsAction,
      fetchOnboardingInfo: fetchOnboardingInfoAction,
      signUpFlow,
      onNext,
    } = this.props;

    try {
      const applicationPendingProperties = filterApplicationPendingProperties(properties);

      const formState = form.getState();

      if (formState.dirty || !applicationPendingProperties.length) {
        // If address form changed, and there are property with onboarding pending, delete everything before creating again.
        if (applicationPendingProperties.length) {
          await deleteOnboardingPropertiesAction(applicationPendingProperties);
        }

        let addresses = values.addresses.map((address) => {
          if (!address) {
            return null;
          }

          return {
            ...address,
            units: address.units?.tags?.map((tag) => tag.name) || [],
          };
        });

        addresses = compact(addresses);

        const onboardingInfo = await createPropertiesAction(addresses);
        await fetchOnboardingInfoAction();

        deleteAddressesAction();
        onNext(onboardingInfo);
      } else {
        deleteAddressesAction();
        onNext({ properties, signUpFlow });
      }
    } catch (e) {
      console.log(e);
      // If there is more than 1 unit and it fails, we immediately take them to the success screen.
      // If there is one unit and it fails, we ask them to try again
      // If there is one unit and they own the home, we tell them that they own the home and ask them to try again.

      if (
        (e[0].errorCode === 'HOME_ALREADY_CLAIMED' && values.addresses?.length > 1) ||
        (e[0].errorCode === 'HOME_ALREADY_CLAIMED' && values.addresses[0]?.units?.tags?.length > 1)
      ) {
        history.push(
          getEntryPoint(
            [],
            {},
            {
              nextStep: STEPS_CONFIG.SCHEDULE_CHAT,

              nextStepConfig: { mixedProperties: true },
            }
          )
        );
      } else if (e[0].errorCode === 'HOME_ALREADY_CLAIMED') {
        let handleErrorModal = false;

        const { streetAddress } = values.addresses[0];
        const unitNumber = values.addresses[0]?.units?.tags[0].name || null;

        try {
          const fetchedProperties = await fetchAccountPropertiesAction();
          if (fetchedProperties.properties) {
            fetchedProperties.properties.forEach((property) => {
              if (!unitNumber) {
                if (property.address.streetAddress === streetAddress) {
                  handleErrorModal = true;
                  addPropertyErrorPropsAction({
                    userOwnsDuplicateHome: true,
                    showExistingPropertyModal: true,
                  });
                }
              }
              if (unitNumber && property.address.streetAddress === streetAddress) {
                if (property.units) {
                  property.units.forEach((unit) => {
                    handleErrorModal = true;
                    if (unit.basicInfo.unitNumber === unitNumber) {
                      addPropertyErrorPropsAction({
                        userOwnsDuplicateHome: true,
                        showExistingPropertyModal: true,
                      });
                    }
                  });
                }
              }
            });
            if (!handleErrorModal) {
              addPropertyErrorPropsAction({
                showExistingPropertyModal: true,
              });
              handleErrorModal = true;
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  onHide = () => {
    this.props.history.push('/homeowners/x');
  };

  render() {
    const { removeErrorProps: removeErrorPropsAction, hideModal: hideModalAction, errorData } = this.props;
    const { initialValues, showNotThereYetModal, cityName, multipleCities, property } = this.state;

    return (
      <FormLayout>
        <FormLayoutHeader title={HOB.title} pretitle={HOB.subtitle} />
        <Form
          id="addressForm"
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              nextButtonWrapperProps={nextButtonProps}
              ctaProps={{
                label: 'Next',
                type: 'submit',
              }}
            />
          )}
          validate={(values) => isValidAddresses(values.addresses)}
          onSubmit={this.onSubmit}
          getForm={({ form }) => (
            <Section>
              <FormSpy subscription={{ values: true }} onChange={this.saveAddresses} />
              <FieldArray name="addresses">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <FormLayout.Section key={name}>
                        <Row>
                          <MultipleUnitAddress
                            autoFocus
                            form={form}
                            initialValue={fields.value[index]}
                            name={name}
                            fields={fields}
                            index={index}
                          />
                        </Row>
                        {fields.length - 1 === index && (
                          <div className="mt-sm flex">
                            <IconButton
                              align={ALIGN_TYPES.RIGHT}
                              icon={GENERAL_ICONS.PLUS}
                              onClick={() => fields.push(null)}
                              disabled={disableAddProperty(fields)}
                            >
                              ADD PROPERTY
                            </IconButton>
                          </div>
                        )}
                      </FormLayout.Section>
                    ))}
                  </>
                )}
              </FieldArray>

              <HomeAlreadyExistsModal
                userOwnsDuplicateHome={errorData.userOwnsDuplicateHome}
                show={errorData.showExistingPropertyModal}
                onRightNodeButtonClick={() => form.change('addresses', [null])}
                onHide={() => {
                  hideModalAction();
                  removeErrorPropsAction();
                }}
              />

              <NotThereYetModal
                cityName={cityName}
                property={property}
                multiple={multipleCities}
                show={showNotThereYetModal}
                onHide={this.onHide}
                onSubmit={this.handleNofityMe}
                history={this.props.history}
              />
            </Section>
          )}
        />
      </FormLayout>
    );
  }
}

PropertyAddress.propTypes = {
  // Addresses is used as a prop in the constructor.
  // eslint-disable-next-line react/no-unused-prop-types
  addresses: PropTypes.array,
  updateAddresses: PropTypes.func.isRequired,
  createProperties: PropTypes.func.isRequired,
  onboardingState: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  createHomeownerLeads: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  fetchOnboardingInfo: PropTypes.func.isRequired,
  fetchOnboardingProperties: PropTypes.func.isRequired,
  checkAddressCoverage: PropTypes.func.isRequired,
  deleteOnboardingProperties: PropTypes.func.isRequired,
  properties: PropTypes.array,
  deleteAddresses: PropTypes.func.isRequired,
  errorData: PropTypes.object,
  onEnterFlow: PropTypes.func.isRequired,
  fetchAccountProperties: PropTypes.func.isRequired,
  addPropertyErrorProps: PropTypes.func.isRequired,
  removeErrorProps: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  hideSpinner: PropTypes.func.isRequired,
  signUpFlow: PropTypes.object.isRequired,
};

PropertyAddress.defaultProps = {
  addresses: [],
  properties: [],
  errorData: {},
};

export default connect(
  (state) => ({
    addresses: selectAddresses(state),
    isUserLoggedIn: selectIsUserLoggedIn(state),
    properties: selectOnboardingProperties(state) || [],
    errorData: selectErrorData(state),
  }),
  {
    updateAddresses,
    deleteAddresses,
    createProperties,
    showModal,
    hideModal,
    fetchOnboardingProperties,
    fetchOnboardingInfo,
    deleteOnboardingProperties,
    createHomeownerLeads,
    checkAddressCoverage,
    fetchAccountProperties,
    addPropertyErrorProps,
    removeErrorProps,
  }
)(PropertyAddress);
