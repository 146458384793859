import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Money from 'components/Money/Money';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import styles from './MaintenanceTaskCost.module.css';

const cx = classNames.bind(styles);

export default class MaintenanceTaskCost extends Component {
  static propTypes = {
    estimatedCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    actualCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mobileDisplay: PropTypes.string,
    maintenanceTaskStatus: PropTypes.string.isRequired,
  };

  static defaultProps = {
    actualCost: null,
    mobileDisplay: MAINTENANCE_TASK_MOBILE_PROPS.NO_MOBILE,
  };

  render() {
    const { maintenanceTaskStatus, estimatedCost, actualCost, mobileDisplay } = this.props;
    let cost;

    if (actualCost) {
      //
      if (parseInt(actualCost, 10) > 0) {
        cost = (
          <div>
            {' '}
            <Money cash={actualCost} />{' '}
          </div>
        );
      } else {
        cost = 'Free';
      }
      //
    } else if (!isNil(estimatedCost)) {
      //
      if (parseInt(estimatedCost, 10) > 0) {
        cost = (
          <div>
            Est. <Money cash={estimatedCost} />
          </div>
        );
      } else {
        cost = maintenanceTaskStatus === 'Closed' ? '' : estimatedCost;
      }
    }

    return <div className={cx('maintenanceTaskCost', mobileDisplay)}>{cost}</div>;
  }
}
