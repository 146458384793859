import { Component } from 'react';
import { connect } from 'react-redux';
import { formatDateTime, DATE_FORMATS } from '@belong/common';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import Icon, { ICONS } from 'components/Icon/Icon';
import Money from 'components/Money/Money';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import Spinner from 'components/Spinner/Spinner';
import { Text } from 'design-system';
import arrayMutators from 'final-form-arrays';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import MultipleKidDetails from 'formcomponents/MultipleKidDetails/MultipleKidDetails';
import MultiplePetDetails from 'formcomponents/MultiplePetDetails/MultiplePetDetails';
import MultipleResidentDetails from 'formcomponents/MultipleResidentDetails/MultipleResidentDetails';
import StandardFieldArray from 'formcomponents/StandardFieldArray/StandardFieldArray';
import FormLayout from 'layouts/FormLayout/FormLayout';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { ApplicationStatus, PetType } from 'models/enums';
import { UnitPetFeeOption } from 'models/enums/index';
import { isOtherRelationShip } from 'pages/ResidentApplication/Steps/fields/RelationshipField/RelationshipField';
import PropTypes from 'prop-types';
import { fetchApplicantInfo, fetchPeopleAndPets, updatePeopleAndPets } from 'store/redux/resident-application/actions';
import {
  selectResidentApplicationApplicantInfo,
  selectResidentApplicationId,
  selectResidentApplyForHouseId,
} from 'store/redux/resident-application/selectors';
import { selectUser } from 'store/redux/user/selectors';
import { getString, formatString } from 'strings';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { converDateTimeServerResponseToDateTimeObject, parseDateTimeInputString } from 'utils/dateTimeUtils';
import { pluralize, pluralizeRaw } from 'utils/pluralize';
import { required } from 'utils/validation';
import { ProcessingVerificationsModal } from '../../ProcessingVerificationsModal/ProcessingVerificationsModal';
import { PEOPLE_AND_PETS } from '../../constants';
import { getResidentApplicationStepPath, STEPS_CONFIG } from '../steps';
import styles from './PeopleAndPets.module.css';

const { FormLayoutHeader } = FormLayout;
const cx = classNames.bind(styles);
const RAS = RESIDENT_APPLICATION_STRINGS.people_and_pets;

// Move to constants.js
const ME_OR_OTHERS = {
  JUST_ME: 'just_me',
  OTHERS: 'others',
};
const OTHER_ADULTS = {
  YES: 'yes',
  NO: 'no',
};

class PeopleAndPets extends Component {
  constructor(props) {
    super(props);

    this.mainApplicant = {
      isIncludedInLease: true,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
    };

    this.state = {
      // !!FIX THIS!!
      // To-Do Akash: create a method to check if other applicants
      // are part of the application saved state
      awaitingFetchComplete: true,
      justMeSelected: null,
      anyOtherAdultsSelected: false,
      anyPetsSelected: false,
      anyKidsSelected: false,
      isVerificationsModalOpen: false,
    };

    this.initialValues = {};
  }

  async componentDidMount() {
    const {
      applicantInfo,
      fetchApplicantInfo: fetchApplicantInfoAction,
      fetchPeopleAndPets: fetchPeopleAndPetsAction,
    } = this.props;

    const requests = [fetchPeopleAndPetsAction()];

    if (!applicantInfo || isEmpty(applicantInfo)) {
      requests.push(fetchApplicantInfoAction());
    }

    let peopleAndPets = {};
    try {
      [peopleAndPets] = await Promise.all(requests);
    } catch (err) {
      console.error(err);
    }

    this.setInitialValues(peopleAndPets);
  }

  openVerificationsModal = () => this.setState({ isVerificationsModalOpen: true });

  closeVerificationsModal = () => this.setState({ isVerificationsModalOpen: false });

  // Build this out to restore component state based on data
  setInitialValues(peopleAndPets) {
    const updatedState = {};

    // To-Do: Make this better.
    if (!isEmpty(peopleAndPets)) {
      this.initialValues = { ...peopleAndPets };

      if (isNil(this.initialValues.kids)) {
        this.initialValues.kids = [null];
      }

      if (isNil(this.initialValues.pets) || isEmpty(this.initialValues.pets)) {
        this.initialValues.pets = [null];
      } else {
        this.initialValues.pets = this.initialValues.pets.map((petElement) => ({
          ...petElement,
          pet: {
            ...petElement.pet,
            files: {
              availableMedia: cloneDeep(petElement.pet.files),
              deletedMedia: [],
              stagingMedia: [],
              mediaToDisplay: cloneDeep(petElement.pet.files),
            },
          },
        }));
      }

      this.initialValues.meAndOthersSelector = ME_OR_OTHERS.JUST_ME;
      this.initialValues.anyPetsSelector = OTHER_ADULTS.NO;
      updatedState.justMeSelected = true;

      if (peopleAndPets.coApplicants) {
        if (peopleAndPets.coApplicants.length !== 0) {
          this.initialValues.adults = cloneDeep(peopleAndPets.coApplicants).map((coApplicant) => ({
            ...coApplicant,
            relationship: {
              relationship_selected: isOtherRelationShip ? 'Other' : coApplicant.relationship,
              relationship_other: isOtherRelationShip ? coApplicant.relationship : '',
            },
          }));
          this.initialValues.meAndOthersSelector = ME_OR_OTHERS.OTHERS;
          this.initialValues.anyOtherAdultsSelector = OTHER_ADULTS.YES;
          updatedState.anyOtherAdultsSelected = true;
          updatedState.justMeSelected = false;
        } else {
          this.initialValues.adults = [null];
          this.initialValues.anyOtherAdultsSelector = OTHER_ADULTS.NO;
          this.initialValues.meAndOthersSelector = ME_OR_OTHERS.JUST_ME;
          updatedState.anyOtherAdultsSelected = false;
        }
      }

      if (peopleAndPets.kids && peopleAndPets.kids.length !== 0) {
        this.initialValues.anyKidsSelector = OTHER_ADULTS.YES;
        this.initialValues.meAndOthersSelector = ME_OR_OTHERS.OTHERS;
        updatedState.justMeSelected = false;
        updatedState.anyKidsSelected = true;
        this.initialValues.kids.forEach((kid) => {
          const dateOfBirthDateObject = converDateTimeServerResponseToDateTimeObject(kid.dateOfBirth);
          kid.dateOfBirth = formatDateTime({
            dateTime: legacyParse(dateOfBirthDateObject),
            format: DATE_FORMATS.STANDARD,
          });
        });
      } else {
        this.initialValues.kids = [null];
        this.initialValues.anyKidsSelector = OTHER_ADULTS.NO;
        updatedState.anyKidsSelected = false;
      }

      if (peopleAndPets.pets && peopleAndPets.pets.length !== 0) {
        this.initialValues.anyPetsSelector = OTHER_ADULTS.YES;
        updatedState.anyPetsSelected = true;
      }

      if (updatedState.anyKidsSelected && !updatedState.anyOtherAdultsSelected) {
        this.initialValues.anyKidsSelector = OTHER_ADULTS.YES;
        this.initialValues.anyOtherAdultsSelector = OTHER_ADULTS.NO;
      } else if (!updatedState.anyKidsSelected && updatedState.anyOtherAdultsSelected) {
        this.initialValues.anyKidsSelector = OTHER_ADULTS.NO;
        this.initialValues.anyOtherAdultsSelector = OTHER_ADULTS.YES;
      } else if (!updatedState.anyKidsSelected && !updatedState.anyOtherAdultsSelected) {
        delete this.initialValues.anyKidsSelector;
        delete this.initialValues.anyOtherAdultsSelector;
      }

      updatedState.awaitingFetchComplete = false;
      this.setState({ ...updatedState });
    } else {
      this.initialValues = {
        kids: [null],
        pets: [null],
        adults: [null],
        meAndOthersSelector: '',
      };
    }
  }

  handleChangeMeAndOthersSelector = (value) => {
    if (value === ME_OR_OTHERS.JUST_ME) {
      this.setState({
        anyOtherAdultsSelected: false,
        anyKidsSelected: false,
        justMeSelected: true,
      });
    } else {
      this.setState({
        justMeSelected: false,
      });
    }
  };

  handleChangeAnyOtherSelector = (value) => {
    if (value === OTHER_ADULTS.YES) {
      this.initialValues.adults = [null];
      this.setState({
        anyOtherAdultsSelected: true,
      });
    } else {
      this.setState({
        anyOtherAdultsSelected: false,
      });
    }
  };

  handleChangeAnyPetsSelector = (value) => {
    if (value === OTHER_ADULTS.YES) {
      this.setState({
        anyPetsSelected: true,
      });
    } else {
      this.setState({
        anyPetsSelected: false,
      });
    }
  };

  handleChangeAnyKidsSelector = (value) => {
    if (value === OTHER_ADULTS.YES) {
      this.setState({
        anyKidsSelected: true,
      });
    } else {
      this.setState({
        anyKidsSelected: false,
      });
    }
  };

  handleSubmit = async (values) => {
    const {
      applicationId,
      houseId,
      history,
      updatePeopleAndPets: updatePeopleAndPetsAction,
      applicantInfo,
    } = this.props;

    const { anyOtherAdultsSelected, anyPetsSelected, anyKidsSelected } = this.state;

    const serverObject = {
      mainApplicant: this.mainApplicant,
      coApplicants: cloneDeep(values.adults),
      kids: cloneDeep(values.kids),
      pets: cloneDeep(values.pets),
    };

    const adultsNotAdded = !anyOtherAdultsSelected;
    const kidsNotAdded = !anyKidsSelected;
    const petsNotAdded = !anyPetsSelected;

    this.setState({ awaitingFetchComplete: true });

    if (adultsNotAdded) {
      serverObject.coApplicants = [];
    }

    if (kidsNotAdded) {
      serverObject.kids = [];
    } else {
      serverObject.kids.forEach((kid) => {
        const dateOfBirthServerString = parseDateTimeInputString(kid.dateOfBirth);
        kid.dateOfBirth = dateOfBirthServerString;
      });
    }

    if (petsNotAdded) {
      serverObject.pets = [];
    } else {
      serverObject.pets.forEach((petElement) => {
        const { pet } = petElement;
        const weightInt = parseInt(pet.weight, 10);
        if (!pet.isServiceAnimal) {
          delete pet.files;
          pet.isServiceAnimal = false;
        }
        if (!pet.livesInCageOrAquarium) {
          pet.livesInCageOrAquarium = false;
        }
        pet.weight = weightInt;
        pet.petType = PEOPLE_AND_PETS.PET_TYPES_DISPLAY_TO_SERVER_MAP[pet.petType];
      });
    }

    serverObject.coApplicants.forEach((coApplicant) => {
      delete coApplicant.primaryIncomeEarner;
      coApplicant.isIncludedInLease = true;
      coApplicant.relationship =
        coApplicant.relationship?.relationship_selected === 'Other'
          ? coApplicant.relationship?.relationship_other
          : coApplicant.relationship?.relationship_selected;
    });

    try {
      await updatePeopleAndPetsAction(serverObject);

      this.setState({ awaitingFetchComplete: false });

      const {
        basicInfo: { status },
        applicationFee: { isPaid },
      } = applicantInfo;

      if (status === ApplicationStatus.Submitted && isPaid) {
        this.openVerificationsModal();
      } else {
        history.push(getResidentApplicationStepPath(STEPS_CONFIG.CREDIT_CHECK, { applicationId, houseId }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Returns any other adults? selector component
  renderAnyOtherAdultsSelector() {
    const { justMeSelected } = this.state;

    if (justMeSelected !== null && justMeSelected === false) {
      return (
        <div className={cx('any-adults-kids-pets-section-wrapper')}>
          <FormLayout.Section sectionTitle={RAS.any_other_adults}>
            <div className={cx('selector-buttons-wrapper')}>
              <Row>
                <Col md={12}>
                  <Field
                    name="anyOtherAdultsSelector"
                    component={SelectorFinalFormAdapter}
                    onChangeCustom={this.handleChangeAnyOtherSelector}
                    validate={required}
                    buttons={[
                      {
                        label: RAS.any_other_adults_yes,
                        key: OTHER_ADULTS.YES,
                      },
                      {
                        label: RAS.any_other_adults_nope,
                        key: OTHER_ADULTS.NO,
                      },
                    ]}
                    type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  />
                </Col>
              </Row>
            </div>
          </FormLayout.Section>
        </div>
      );
    }
  }

  renderOtherAdultsSection(form) {
    const { email } = this.props.user;
    const { anyOtherAdultsSelected } = this.state;

    if (anyOtherAdultsSelected) {
      return (
        <FormLayout.Section sectionTitle={RAS.lets_meet_them} sectionSubTitle={RAS.background_check_disclaimer}>
          <StandardFieldArray
            form={form}
            values={form.getState().values}
            primaryApplicantEmail={email}
            mutators={form.mutators}
            fieldArrayName="adults"
            FieldsComponent={MultipleResidentDetails}
          />
        </FormLayout.Section>
      );
    }
  }

  // Returns any kids? selector component
  renderKidsSelector() {
    const { justMeSelected } = this.state;

    if (justMeSelected !== null && justMeSelected === false) {
      return (
        <div className={cx('any-adults-kids-pets-section-wrapper')}>
          <FormLayout.Section sectionTitle={RAS.any_kids}>
            <div className={cx('selector-buttons-wrapper')}>
              <Row>
                <Col md={12}>
                  <Field
                    name="anyKidsSelector"
                    component={SelectorFinalFormAdapter}
                    onChangeCustom={this.handleChangeAnyKidsSelector}
                    validate={required}
                    buttons={[
                      {
                        label: RAS.any_other_adults_yes,
                        key: OTHER_ADULTS.YES,
                      },
                      {
                        label: RAS.any_other_adults_nope,
                        key: OTHER_ADULTS.NO,
                      },
                    ]}
                    type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  />
                </Col>
              </Row>
            </div>
          </FormLayout.Section>
        </div>
      );
    }
  }

  renderKidsSection(form) {
    const { anyKidsSelected } = this.state;

    if (anyKidsSelected) {
      return (
        <FormLayout.Section sectionTitle={RAS.kids_tell_us_about}>
          <div className={cx('co-applicants-details-form-wrapper')}>
            <StandardFieldArray
              values={form.getState().values}
              form={form}
              mutators={form.mutators}
              singleLine
              fieldArrayName="kids"
              FieldsComponent={MultipleKidDetails}
            />
          </div>
        </FormLayout.Section>
      );
    }
  }

  // Returns any pets? selector component
  renderPetsSelector() {
    const { justMeSelected } = this.state;

    if (justMeSelected !== null) {
      return (
        <div className={cx('any-adults-kids-pets-section-wrapper')}>
          <FormLayout.Section sectionTitle={RAS.any_pets}>
            <div className={cx('selector-buttons-wrapper')}>
              <Row>
                <Col md={12}>
                  <Field
                    name="anyPetsSelector"
                    component={SelectorFinalFormAdapter}
                    onChangeCustom={this.handleChangeAnyPetsSelector}
                    validate={required}
                    buttons={[
                      {
                        label: RAS.any_other_adults_yes,
                        key: OTHER_ADULTS.YES,
                      },
                      {
                        label: RAS.any_other_adults_nope,
                        key: OTHER_ADULTS.NO,
                      },
                    ]}
                    type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                  />
                </Col>
              </Row>
            </div>
          </FormLayout.Section>
        </div>
      );
    }
  }

  renderPetsSection(form) {
    const { home } = this.props;
    const { anyPetsSelected } = this.state;

    if (anyPetsSelected) {
      const { petInfo } = home.listingInfo || {};
      return (
        <FormLayout.Section sectionTitle={RAS.pets_tell_us_about}>
          <div className={cx('co-applicants-details-form-wrapper')}>
            {/* Refactor to use FeatureComponent */}
            <StandardFieldArray
              values={form.getState().values}
              form={form}
              mutators={form.mutators}
              fieldArrayName="pets"
              FieldsComponent={MultiplePetDetails}
              petInfo={petInfo}
              getPetNotes={this.getPetNotes}
            />
          </div>
        </FormLayout.Section>
      );
    }
  }

  get petInfo() {
    const { home } = this.props;
    const { petInfo } = home.listingInfo || {};
    return petInfo;
  }

  // per pet notes
  getPetNotes = (pet) => {
    const { petInfo } = this;
    const { maxWeightPerPet, restrictedBreeds, dogs, cats, anyPets, noPets } = petInfo;
    const { weight, subType, petType, isServiceAnimal, livesInCageOrAquarium } = pet || {};

    const hasNoPets = petInfo.noPets === undefined || noPets;
    const showPetTypeNote =
      !anyPets && petType && ((dogs && petType !== PetType.Dog) || (cats && petType !== PetType.Cat));
    const showMaxWeightNote = !isNil(maxWeightPerPet) && Number(weight) > Number(maxWeightPerPet);
    const showRestrictedBreedNote = Array.isArray(restrictedBreeds) && restrictedBreeds.includes(subType);

    let note;
    let isRestriction = false;

    if (isServiceAnimal) {
      note = formatString(RAS.pets_service_animal_info, {
        note: <b>Note</b>,
      });
    } else if (!hasNoPets && !livesInCageOrAquarium) {
      if (showPetTypeNote) {
        if (petType === PetType.Other) {
          if (subType) {
            note = formatString(RAS.pets_type_notes, {
              note: <b>Note</b>,
              petType: pluralizeRaw(subType.toLowerCase()),
            });
            isRestriction = true;
          }
        } else {
          note = formatString(RAS.pets_type_notes, {
            note: <b>Note</b>,
            petType: pluralizeRaw(petType.toLowerCase()),
          });
          isRestriction = true;
        }
      } else if (showMaxWeightNote && showRestrictedBreedNote) {
        note = formatString(RAS.pets_breed_weight_notes, {
          note: <b>Note</b>,
          weight: maxWeightPerPet,
        });
        isRestriction = true;
      } else if (showMaxWeightNote) {
        note = formatString(RAS.pets_weight_notes, {
          note: <b>Note</b>,
          weight: maxWeightPerPet,
        });
        isRestriction = true;
      } else if (showRestrictedBreedNote) {
        note = formatString(RAS.pets_breed_notes, {
          note: <b>Note</b>,
        });
        isRestriction = true;
      }
    }
    return [note, isRestriction];
  };

  getNonCagedAndNonServicePets = (values) => {
    const nonCagedAndNonServicePets =
      values?.pets?.filter(
        (petElement) => petElement?.pet && !petElement?.pet?.isServiceAnimal && !petElement?.pet?.livesInCageOrAquarium
      ) || [];
    return nonCagedAndNonServicePets;
  };

  getNonCagedAndNonServicePetsLen = (values) => {
    const nonCagedAndNonServicePetsLen =
      values?.anyPetsSelector === OTHER_ADULTS.YES ? this.getNonCagedAndNonServicePets(values).length : 0;

    return nonCagedAndNonServicePetsLen;
  };

  render() {
    const { awaitingFetchComplete, isVerificationsModalOpen } = this.state;
    const {
      user: { firstName },
      home,
    } = this.props;

    if (awaitingFetchComplete) {
      return (
        <Row>
          <Col md={12}>
            <div className={cx('spinner')}>
              <Spinner />
            </div>
          </Col>
        </Row>
      );
    }

    const { petInfo } = home.listingInfo || {};

    return (
      <FormLayout>
        <FormLayoutHeader title={RAS.title} pretitle={getString(RAS.pretitle, { firstName })} />
        <Form
          initialValues={this.initialValues}
          onSubmit={this.handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
          getFormBottomBar={(formProps, nextButtonProps) => {
            const { values } = formProps;
            const showPetDeposit = !isNil(petInfo.depositAmount) && !isNil(petInfo.depositOption);
            const nonCagedAndNonServicePetsLen = this.getNonCagedAndNonServicePetsLen(values);
            const showCallout = showPetDeposit && nonCagedAndNonServicePetsLen > 0;

            return (
              <FormLayout.BottomBar
                // skip={() => {}}
                callout={
                  showCallout && (
                    <Text>
                      {formatString(RAS.pets_deposit, {
                        money: (
                          <b>
                            <Money
                              cash={
                                petInfo.depositOption === UnitPetFeeOption.FlatFee
                                  ? petInfo.depositAmount
                                  : nonCagedAndNonServicePetsLen * petInfo.depositAmount
                              }
                            />
                          </b>
                        ),
                      })}
                    </Text>
                  )
                }
                ctaProps={{
                  label: RAS.next_button_text,
                }}
                nextButtonWrapperProps={nextButtonProps}
              />
            );
          }}
          getForm={({ handleSubmit, form }) => {
            const { values } = form.getState();
            const nonCagedAndNonServicePets = this.getNonCagedAndNonServicePets(values);
            const nonCagedAndNonServicePetsLen = this.getNonCagedAndNonServicePetsLen(values);
            const showPetLimitNotes =
              !isNil(petInfo.maxAllowedPets) && nonCagedAndNonServicePetsLen > petInfo.maxAllowedPets;

            const hasNoPets = petInfo.noPets === undefined || petInfo.noPets;
            const showNoPetsNotes = hasNoPets && !nonCagedAndNonServicePets?.every((p) => p.isServiceAnimal);

            // global notes
            let note;
            let hasRestriction = false;
            if (values?.anyPetsSelector === OTHER_ADULTS.YES) {
              if (showNoPetsNotes) {
                note = formatString(RAS.pets_not_allowed_notes, {
                  note: <b>Note</b>,
                });
                hasRestriction = true;
              } else if (showPetLimitNotes) {
                note = formatString(RAS.pets_limit_notes, {
                  note: <b>Note</b>,
                  maxAllowedPets: pluralize(petInfo.maxAllowedPets, 'pet'),
                });
                hasRestriction = true;
              }

              // check individual pet notes for restrictions
              if (!hasRestriction) {
                nonCagedAndNonServicePets.forEach((petElement) => {
                  // eslint-disable-next-line
                  const [_, isRestriction] = this.getPetNotes(petElement.pet);
                  if (isRestriction) {
                    hasRestriction = true;
                  }
                });
              }
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormLayout.Section>
                  <Field
                    name="meAndOthersSelector"
                    component={SelectorFinalFormAdapter}
                    onChangeCustom={this.handleChangeMeAndOthersSelector}
                    buttons={[
                      {
                        label: RAS.just_me,
                        icon: <Icon icon={ICONS.JUST_ME.DEFAULT} responsive />,
                        iconSelected: <Icon icon={ICONS.JUST_ME.INVERSE} responsive />,
                        key: ME_OR_OTHERS.JUST_ME,
                      },
                      {
                        label: RAS.others_and_me,
                        icon: <Icon icon={ICONS.OTHERS_AND_ME.DEFAULT} responsive />,
                        iconSelected: <Icon icon={ICONS.OTHERS_AND_ME.INVERSE} responsive />,
                        key: ME_OR_OTHERS.OTHERS,
                      },
                    ]}
                    type={SELECTOR_TYPES.SELECTOR}
                    validate={required}
                  />
                </FormLayout.Section>
                {this.renderAnyOtherAdultsSelector()}
                {this.renderOtherAdultsSection(form)}
                {this.renderKidsSelector()}
                {this.renderKidsSection(form)}
                {this.renderPetsSelector()}
                {this.renderPetsSection(form)}
                {note && (
                  <div className={cx('notes-container')}>
                    <Text>{note}</Text>
                  </div>
                )}
                {hasRestriction && (
                  <div className={cx('notes-container')}>
                    <Text>{formatString(RAS.pet_restriction_notes)}</Text>
                  </div>
                )}
              </form>
            );
          }}
        />
        {isVerificationsModalOpen ? (
          <ProcessingVerificationsModal show={isVerificationsModalOpen} onClose={this.closeVerificationsModal} />
        ) : null}
      </FormLayout>
    );
  }
}

PeopleAndPets.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  fetchPeopleAndPets: PropTypes.func.isRequired,
  updatePeopleAndPets: PropTypes.func.isRequired,
  fetchApplicantInfo: PropTypes.func.isRequired,
  applicantInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  houseId: PropTypes.string.isRequired,
  home: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    user: selectUser(state),
    applicationId: selectResidentApplicationId(state),
    houseId: selectResidentApplyForHouseId(state),
    applicantInfo: selectResidentApplicationApplicantInfo(state),
  }),
  {
    fetchPeopleAndPets,
    updatePeopleAndPets,
    fetchApplicantInfo,
  }
)(PeopleAndPets);
