import { Component } from 'react';
import { Image, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import PropTypes from 'prop-types';
import MaintenanceTaskCostandStatus from '../MaintenanceTaskStatus/MaintenanceTaskStatus';
import styles from './MaintenanceTaskIconLabel.module.css';

const cx = classNames.bind(styles);

export default class MaintenanceTaskIconLabel extends Component {
  static propTypes = {
    // This should be an object?
    maintenanceTaskImage: PropTypes.string.isRequired,
    // No need for children as maintenanceTask is a direct prop
    maintenanceTask: PropTypes.string,

    maintenanceDate: PropTypes.string.isRequired,
    maintenanceTaskStatusLabel: PropTypes.string,
    maintenanceTaskStatusText: PropTypes.string,
  };

  static defaultProps = {
    maintenanceTask: '',
    maintenanceTaskStatusLabel: '',
    maintenanceTaskStatusText: '',
  };

  render() {
    const {
      maintenanceTaskImage,
      maintenanceTask,
      maintenanceDate,
      maintenanceTaskStatusLabel,
      maintenanceTaskStatusText,
    } = this.props;
    return (
      <div className={cx('maintenanceTaskIconLabel')}>
        <div className={cx('maintenanceTaskImage', 'mr-sm')} style={{ minWidth: 50 }}>
          <Image alt="" className={styles.image} src={maintenanceTaskImage} />
        </div>
        <div className={cx('flex')}>
          <Text className="line-clamp-2" fontWeight="semibold">
            {maintenanceTask}
          </Text>
          <div className={cx('maintenanceTaskTime')}>
            <BDate time={maintenanceDate} formatType={DATE_TYPES.FULL_MONTH_DAY_YEAR} />
          </div>
          <MaintenanceTaskCostandStatus
            maintenanceTaskStatusLabel={maintenanceTaskStatusLabel}
            moreInfo={maintenanceTaskStatusText}
          />
        </div>
      </div>
    );
  }
}
