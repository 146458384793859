import UserBasicInfo from 'models/common/UserBasicInfo';
import AppointmentsLocation from 'models/appointments/AppointmentsLocation';

export default class Appointments {
  constructor(data = {}) {
    this.uniqueId = data.uniqueId;
    this.status = data.status;
    this.scheduledStartOn = data.scheduledStartOn;
    this.scheduledEndOn = data.scheduledEndOn;
    this.isAllDay = data.isAllDay;
    this.outcome = data.outcome;
    this.outcomeReason = data.outcomeReason;
    this.location = new AppointmentsLocation(data.location);
    this.pro = new UserBasicInfo(data.pro);
    this.partner = data.partner;
  }
}
