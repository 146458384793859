import Appointments from 'models/appointments/Appointments';
import Address from 'models/common/Address';
import UnitBasicInfo from 'models/common/UnitBasicInfo';
import UserBasicInfo from 'models/common/UserBasicInfo';
import MaintenanceRecurrence from 'models/maintenance/MaintenanceRecurrence';
import AssessmentInfo from './AssessmentInfo';

export default class MaintenanceTaskModel {
  constructor(data = {}) {
    this.involvedRole = data.involvedRole;
    this.homeUniqueId = data.homeUniqueId;
    this.uniqueId = data.uniqueId;
    this.jobId = data.jobId;
    this.createdOn = data.createdOn;
    this.closedOn = data.closedOn;
    this.status = data.status;
    this.result = data.result;
    this.resultReason = data.resultReason;
    this.requestType = data.requestType;
    this.requestedBy = data.requestedBy;
    this.summary = data.summary;
    this.propertyUniqueId = data.propertyUniqueId;
    this.nextAppointmentUniqueId = data.nextAppointmentUniqueId;
    this.description = data.description;
    this.urgency = data.urgency;
    this.doesResidentClaimResponsibility = data.doesResidentClaimResponsibility;
    this.address = new Address(data.address);
    this.home = new UnitBasicInfo(data.home);
    this.assessmentInfo = new AssessmentInfo(data.assessmentInfo);
    this.attachments = data.attachments;
    this.performedBy = new UserBasicInfo(data.performedBy);
    this.homeowner = new UserBasicInfo(data.homeowner);
    this.resident = new UserBasicInfo(data.resident);
    this.media = data.media;
    this.payments = data.payments;
    this.appointments = data?.appointments?.map((appointment) => new Appointments(appointment));
    this.actualCost = data.actualCost;
    this.estimatedCost = data.estimatedCost;
    this.recurrence = new MaintenanceRecurrence(data.recurrence);
    this.isRequestorPresent = data.isRequestorPresent;
    this.requestorAvailability = data.requestorAvailability;
    this.requestTypeDetails = data.requestTypeDetails;
    this.financedPayment = data.financedPayment;
    this.isLegacyInvoicing = data.isLegacyInvoicing;
    this.isHandledBySmsAssist = data.isHandledBySmsAssist;
    this.displayAsPriceRange = data.displayAsPriceRange;
    this.priceRange = data.priceRange;
    this.externalWorkOrderId = data.externalWorkOrderId;
    this.workOrder = data.workOrder;
  }
}
