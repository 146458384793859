import { AgreementFlowStepNames } from 'models/enums/index';
import { BASE_PATHS } from 'routes/paths';
import UserPersonal from './UserPersonal/UserPersonal';
import Plan from './Plan/Plan';
import Ownership from './Ownership/Ownership';
import Payment from './Payment/Payment';
import Sign from './Sign/Sign';
import SuccessPage from './SuccessPage/SuccessPage';

import { getStepByName } from '../consts';

const getStepPath = (stepName) => {
  const stepConfig = getStepByName(stepName);
  return `${BASE_PATHS.AGREEMENT_FLOW}/${stepConfig.key}`;
};

export const STEPS_ROUTE_MAP = {
  PLAN: {
    path: getStepPath(AgreementFlowStepNames.Plan),
    form: Plan,
  },
  USER_PERSONAL: {
    path: getStepPath(AgreementFlowStepNames.UserPersonal),
    form: UserPersonal,
  },
  OWNERSHIP: {
    path: getStepPath(AgreementFlowStepNames.Ownership),
    form: Ownership,
  },
  PAYMENT: {
    path: getStepPath(AgreementFlowStepNames.Payment),
    form: Payment,
  },
  SIGN: {
    path: getStepPath(AgreementFlowStepNames.Sign),
    form: Sign,
  },
  SUCCESS: {
    path: `${BASE_PATHS.AGREEMENT_FLOW}/success`,
    form: SuccessPage,
  },
};
