import React from 'react';
import classNames from 'classnames/bind';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import styles from './DividerRow.module.css';

const cx = classNames.bind(styles);

const DividerRow = ({ items, defaultMobileIconSize, desktopColumns, mobileColumns }) => {
  const desktopItems = chunk(items, desktopColumns);
  const mobileItems = chunk(items, mobileColumns);
  const lastDesktopIndex = desktopItems.length - 1;
  const lastMobileIndex = mobileItems.length - 1;
  return (
    <>
      {desktopItems.map((desktopChunk, index) => {
        return (
          <div
            key={index}
            className={cx('desktop-divider-row-container', { lastRowDesktop: index === lastDesktopIndex })}
          >
            {desktopChunk.map((item, innerIndex) => {
              return (
                <Item
                  key={innerIndex}
                  item={item}
                  style={{ maxWidth: `${Math.floor(100 / Math.min(desktopChunk.length, desktopColumns))}%` }}
                  defaultMobileIconSize={defaultMobileIconSize}
                />
              );
            })}
          </div>
        );
      })}
      {mobileItems.map((mobileChunk, index) => {
        return (
          <div key={index} className={cx('mobile-divider-row-container', { lastRowMobile: index === lastMobileIndex })}>
            {mobileChunk.map((item, innerIndex) => (
              <Item
                key={innerIndex}
                item={item}
                style={{ maxWidth: `${Math.floor(100 / Math.min(mobileChunk.length, mobileColumns))}%` }}
                defaultMobileIconSize={defaultMobileIconSize}
              />
            ))}
          </div>
        );
      })}
    </>
  );
};

DividerRow.propTypes = {
  items: PropTypes.array.isRequired,
  mobileColumns: PropTypes.number,
  desktopColumns: PropTypes.number,
  // Still haven't found a way to correctly scale 96x96 icons but by directly setting width and height on the parent,
  // this props does that for mobile. (See Icon component and the scale prop.)
  defaultMobileIconSize: PropTypes.bool,
};

DividerRow.defaultProps = {
  mobileColumns: 3,
  desktopColumns: 5,
  defaultMobileIconSize: true,
};

const Item = ({ item, defaultMobileIconSize, style }) => (
  <div className={cx('item-container')} style={style}>
    {item.top && <div className="mb-sm">{item.top}</div>}
    {item.icon && <div className={cx('icon-space', { defaultMobileIconSize })}>{item.icon}</div>}
    {item.title && (
      <p className={cx('title')} style={{ color: item.color }}>
        {item.title}
      </p>
    )}
    {item.subtitle && <p className={cx('subtitle')}>{item.subtitle}</p>}
  </div>
);

Item.propTypes = {
  item: PropTypes.object.isRequired,
  // Still haven't found a way to correctly scale 96x96 icons but by directly setting width and height on the parent,
  // this props does that for mobile. (See Icon component and the scale prop.)
  defaultMobileIconSize: PropTypes.bool,
  style: PropTypes.object,
};
Item.defaultProps = {
  defaultMobileIconSize: true,
  style: {},
};

DividerRow.Item = Item;

export default DividerRow;
