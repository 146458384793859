import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  ...getApiActionTypes('FETCH_HOMEOWNER_MAINTENANCE'),
  ...getApiActionTypes('FETCH_RECURRING_TASKS'),
  ...getApiActionTypes('FETCH_HO_TASK_BY_TASK_ID'),
  ...getApiActionTypes('FETCH_RESIDENT_MAINTENANCE'),
  ...getApiActionTypes('FETCH_TASK_BY_RENTER_ID'),
  ...getApiActionTypes('FETCH_IMPROVEMENTS_REPORT_BY_TASK_ID'),
  CURRENT_TASK_CLEAR: 'CURRENT_TASK_CLEAR',
};
