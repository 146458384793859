import { filter, find } from 'lodash';

export default class FullUser {
  constructor(data = {}) {
    this.user = data.user;
    this.paymentMethods = data.paymentMethods;
  }
  // I dont believe any of these work anymore.

  getPaymentMethods(method, showValidPaymentMethods) {
    if (!method) {
      if (showValidPaymentMethods === true) {
        return this.paymentMethods.validPaymentMethods;
      }

      return this.paymentMethods?.paymentMethods || [];
    }

    if (showValidPaymentMethods === true) {
      return filter(this.paymentMethods?.validPaymentMethods, { paymentMethod: method });
    }

    return filter(this.paymentMethods?.paymentMethods, { paymentMethod: method });
  }

  getPaymentMethodFor(type, priority, selectedPaymentMethod) {
    let paymentMethods = this.paymentMethods?.paymentMethods;

    if (selectedPaymentMethod) {
      paymentMethods = filter(paymentMethods, { paymentMethod: selectedPaymentMethod });
    }

    return find(paymentMethods, (paymentMethod) => {
      const { preferences } = paymentMethod;
      return !!find(preferences, (preference) => preference.paymentType === type && preference.priority === priority);
    });
  }

  getPaymentMethodById(id) {
    return find(this.paymentMethods, { accountId: id });
  }
}
