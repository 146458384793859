// KEEPING THIS FILE FOR EXAMPLE PURPOSES
import config from 'config/config';
import { includes } from 'lodash';

export const LAPricingExperimentName = 'LAPricingExperiment';

export const LAPricingExperimentVariants = {
  DEFAULT: 'DEFAULT', // DEFAULT
  LA_EXPERIMENT_VARIATION_A: 'LA_EXPERIMENT_VARIATION_A',
  LA_EXPERIMENT_VARIATION_B: 'LA_EXPERIMENT_VARIATION_B',
};

const allowedUTMs = [
  '11003009913',
  '11003009934',
  '9719179401',
  '11003009916',
  '11003009940',
  '9323402394',
  '11003009910',
  '11003009931',
  '11003009922',
  '9647529569',
  '11003009925',
  '8720235570',
  '11003009928',
  '9315943352',
  '8720235567',
  '11003009919',
  '11003009937',
];

const { lAExperimentOptimizeId } = config;
const optimizeId = lAExperimentOptimizeId;

export const LAPricingExperiment = {
  name: LAPricingExperimentName,
  salt: LAPricingExperimentName,
  optimizeId,
  createdOn: '11/26/2020',
  variations: [
    {
      value: LAPricingExperimentVariants.DEFAULT,
      weight: 0,
      description: '8% Management Fee, 50% Placement Fee',
    },
    {
      value: LAPricingExperimentVariants.LA_EXPERIMENT_VARIATION_A,
      weight: 1,
      description: '6% Management Fee, 100% Placement Fee',
    },
    {
      value: LAPricingExperimentVariants.LA_EXPERIMENT_VARIATION_B,
      weight: 0,
      description: '7% Management Fee, 75% Placement Fee',
    },
  ],
  condition: ({ req }) => {
    return includes(allowedUTMs, req.query.utm_campaign);
  },
};
