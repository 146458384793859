import { useCallback, useState } from 'react';
import scriptLoader from 'react-async-script-loader';
import { useModal } from '@belong/common';
import { Toast } from '@belong/ui';
import { USER_IDENTITY_STRINGS } from 'accounts/constants/strings/user-identity.strings';
import classNames from 'classnames/bind';
import Form from 'components/Form/Form';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import Image from 'components/Image/Image';
import Spinner from 'components/Spinner/Spinner';
import { Text, Flex } from 'design-system';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
// Component imports
import FormLayout from 'layouts/FormLayout/FormLayout';
// Lodash imports
import { IdentityMethods } from 'pages/ResidentApplication/Steps/ResidentIdentityVerification/components/IdentityMethods';
import {
  useIsPersonaVerificationCompleted,
  useStartPersonaInquiry,
} from 'pages/ResidentApplication/Steps/ResidentIdentityVerification/hooks/usePersona';
import PropTypes from 'prop-types';
// Constant & String imports
// SCSS imports
import styles from './identity-verification.module.css';

const cx = classNames.bind(styles);

export const SELECTOR_OPTIONS = {
  DL: 'Card',
  PASS: 'Passport',
  N_ID: 'NationalCard',
  OTHER: 'Other',
};

const TIPS_IMAGES = [
  '/resident_application/identity_verification_tips_2.svg',
  '/resident_application/identity_verification_tips_3.svg',
  '/resident_application/identity_verification_tips_4.svg',
  '/resident_application/identity_verification_tips_1.svg',
  '/resident_application/identity_verification_tips_0.svg',
];

function TipsBox(props) {
  const { tips } = props;

  return (
    <GreenInfoBox>
      <Text as="p" fontWeight="semibold">
        {USER_IDENTITY_STRINGS.tip_title}
      </Text>
      <div className={cx('tips-box')}>
        {tips.map((tip, index) => (
          <Flex alignItems="center" my="2xs" key={tip}>
            <Image src={TIPS_IMAGES[index]} className={cx('tip-icon')} alt={tip} />
            <Text as="p">{tip}</Text>
          </Flex>
        ))}
      </div>
    </GreenInfoBox>
  );
}

TipsBox.propTypes = {
  // Array that contains all the tips
  tips: PropTypes.arrayOf(PropTypes.string),
};

type IdentityVerificationProps = {
  // Submit function that returns the image files and idType
  onSubmit: any;
};

function IdentityVerificationMethod({ onSubmit }: IdentityVerificationProps) {
  const [isError, setIsError] = useState(false);
  const [isToastOpen, openToast, closeToast] = useModal();
  const [awaitingFetchComplete, setAwaitingFetchComplete] = useState(false);

  async function handleSubmit() {
    setAwaitingFetchComplete(true);
    await onSubmit();
    setAwaitingFetchComplete(false);
  }

  const { isPersonaVerificationCompleted, isRetrievingPersonaVerificationsDetail, refetchPersonaVerificationsDetail } =
    useIsPersonaVerificationCompleted();

  const onPersonaInquiryCompleted = useCallback(
    (_: string, status: string) => {
      if (status === 'approved') {
        refetchPersonaVerificationsDetail();
        setAwaitingFetchComplete(false);
        openToast();
      }
    },
    [openToast, refetchPersonaVerificationsDetail]
  );

  function onError(error) {
    console.error(error);
    setAwaitingFetchComplete(false);
    refetchPersonaVerificationsDetail();
    setIsError(true);
    openToast();
  }

  function onCancel() {
    console.log('oncancel');
    setAwaitingFetchComplete(false);
  }

  const { isRetrievingPersonaIdentifiers, startPersonaInquiry } = useStartPersonaInquiry({
    onComplete: onPersonaInquiryCompleted,
    onError: (error) => {
      return onError(error);
    },
    onCancelled: () => onCancel(),
  });

  function handlePersonaVerification() {
    setIsError(false);
    setAwaitingFetchComplete(true);
    startPersonaInquiry();
  }

  function isDisabled() {
    return isRetrievingPersonaVerificationsDetail;
  }

  if (awaitingFetchComplete || isRetrievingPersonaIdentifiers) {
    return (
      <div className="mt-3xl">
        <Row>
          <Col md={12}>
            <div className="h-3xl">
              <Spinner />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <Form
        initialValues={{}}
        onSubmit={isPersonaVerificationCompleted ? handleSubmit : handlePersonaVerification}
        getFormBottomBar={(_, nextButtonProps) => {
          return (
            <FormLayout.BottomBar
              ctaProps={{
                label: isPersonaVerificationCompleted ? 'Next' : 'Verify Me',
                disabled: isDisabled(),
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          );
        }}
        getForm={({ handleSubmit: formHandleSubmit }) => {
          return (
            <div className={cx('form-wrapper')}>
              <form onSubmit={formHandleSubmit}>
                <div className={cx('id-type-section-wrapper')}>
                  <FormLayout.Section firstSection>
                    <IdentityMethods identified={isPersonaVerificationCompleted} />
                  </FormLayout.Section>
                </div>
              </form>
            </div>
          );
        }}
      />
      <div className="container">
        <Toast
          className="max-w-[700px]"
          isVisible={isToastOpen}
          onClose={closeToast}
          variant={!isError ? 'default' : 'danger'}
        >
          {isError
            ? "The verification couldn't be completed. Please try again."
            : 'ID verification was successfully completed.'}
        </Toast>
      </div>
    </>
  );
}

export const IdentityVerification = scriptLoader(['https://cdn.withpersona.com/dist/persona-v4.8.0.js'])(
  IdentityVerificationMethod
);
