import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
// FinalForm
import classNames from 'classnames/bind';
import Collapsible from 'components/Collapsible/Collapsible';
// Styling
import Field from 'components/Field/Field';
import Money from 'components/Money/Money';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintenancePaymentFlowForResidents/ModalMaintenancePaymentFlowForResidents.module.css';
import Space from 'corecomponents/Space/Space';
// Redux
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { updateMaintenanceConsent } from 'store/redux/maintenance/actions';
import { getString } from 'strings';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';
import { required } from 'utils/validation';

const cx = classNames.bind(styles);

class ModalMaintenancePaymentFlowForResidents extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    updateMaintenanceConsent: PropTypes.func.isRequired,
    createMarginAndPaddingClassName: PropTypes.string,
    renterResponsibility: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createMarginAndPaddingClassName: '',
  };

  onSubmit = async (values) => {
    const { task, close } = this.props;

    const renterResponsibility = find(task.payments, (payment) => payment.paidBy === 'Resident');

    const sendObjectToBackend = {
      // NEED TO ADD ACCEPTED REASON
      isApproved: values.willingTopay !== 'Declined',
      percentage: renterResponsibility.percentage,
    };

    try {
      await this.props.updateMaintenanceConsent(task.uniqueId, sendObjectToBackend);
      await close();
    } catch (err) {
      console.error(err);
    }
  };

  getInitialValues() {
    this.initialValue = {};
    return this.initialValue;
  }

  render() {
    const { task, createMarginAndPaddingClassName, renterResponsibility } = this.props;

    const renterSplitTextIfHomeOwnerSplit = [
      {
        label: (
          <div>
            Yes, let's <span>split it.</span>
            <br />
            <Money cash={renterResponsibility.estimatedAdjustmentApplied} />
          </div>
        ),
        key: 'Something',
      },
      {
        label: 'No, not at <span>this time.</span>',
        key: 'Declined',
      },
    ];

    const renterPaysinFullIfHomeOwnerNoPay = [
      {
        label: (
          <div>
            Yes, I'll pay <span>for it.</span>
            <br />
            <Money cash={renterResponsibility.estimatedAdjustmentApplied} />
          </div>
        ),
        key: 'Something',
      },
      {
        label: 'No, not at <span>this time.</span>',
        key: 'Declined',
      },
    ];
    // const renterResponsibility = find(task.payments, (payment) => payment.paidBy === 'Resident');

    return (
      <Form
        mutators={{
          ...arrayMutators,
        }}
        initialValues={this.getInitialValues()}
        onSubmit={this.onSubmit}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              <Collapsible
                showWhenCollapsed={
                  <div className={cx('border-replacement-for-bottom-bar')}>
                    <Space value={SPACE_TYPES.XL} />
                  </div>
                }
                title="Approval and Responsibility"
                collapsed={false}
                createMarginAndPaddingClassName={createMarginAndPaddingClassName}
                inner={
                  <div className={cx('green-border')}>
                    {renterResponsibility.percentage === 100 && (
                      <>
                        <ModalLayout.SectionTitle>
                          <div className={cx('responsibility-title')}>
                            {MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonrenterside.renterpaysfull.title}
                          </div>
                        </ModalLayout.SectionTitle>

                        <Field
                          name="willingTopay"
                          component={SelectorFinalFormAdapter}
                          buttons={renterPaysinFullIfHomeOwnerNoPay}
                          validate={required}
                          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                          fluid
                        />
                      </>
                    )}

                    {renterResponsibility.percentage === 50 && (
                      <>
                        <div className={cx('responsibility-title')}>
                          {getString(MAINTENACE_STATUS_MODAL_STRINGS.responsibilityonrenterside.renterpayshalf.title, {
                            homeownername: task.homeowner?.firstName,
                          })}
                        </div>
                        <Field
                          name="willingTopay"
                          component={SelectorFinalFormAdapter}
                          buttons={renterSplitTextIfHomeOwnerSplit}
                          validate={required}
                          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                          fluid
                        />
                      </>
                    )}
                  </div>
                }
              />

              <ModalLayout.BottomBar
                ctaProps={{
                  label: 'Submit',
                  disabled: invalid,
                }}
                removeMarginTopAndBorderBottomBar
                fixedBottom
              />
            </FormLayout>
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  updateMaintenanceConsent,
};

export default connect(null, mapDispatchToProps)(ModalMaintenancePaymentFlowForResidents);
