import { lazy, useEffect, useState, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { useIsClientReady } from '@belong/common';
import { Image, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import DocumentCard from 'components/DocumentCard/DocumentCard';
import Field from 'components/Field/Field';
import TabBar from 'components/TabBar/TabBar';
import { useModal } from 'hooks/useModal';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import PropTypes from 'prop-types';
import { fetchAgreementPdfUrl } from 'store/redux/agreement-flow/actions';
import { fetchSettings } from 'store/redux/settings';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { requireTrue } from 'utils/validation';
import AgreementRenderer from '../../AgreementRenderer/AgreementRenderer';
import styles from './Sign.module.css';
import { AgreementSummary } from './agreement-summary/agreement-summary';

const cx = classNames.bind(styles);

const tabItems = [
  {
    text: 'Agreement',
    key: 0,
  },
  {
    text: 'Addendums',
    key: 1,
  },
];

const PdfModal = lazy(() => import('containercomponents/Modals/PdfModal/PdfModal'));

const Sign = ({ currentStepFormData = {}, onSubmit, setLoading, openTipModal, setSidebarTip, ...rest }) => {
  const dispatch = useDispatch();
  const isClientReady = useIsClientReady();
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [currentPdf, setCurrentPdf] = useState();
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const addendums = currentStepFormData?.addendums;

  const handleSelectedTab = async (tab) => {
    setSelectedTab(tab);
  };

  const handleDownloadClick = async () => {
    setLoading(true);

    try {
      const response = await dispatch(fetchAgreementPdfUrl(currentStepFormData?.documentInstance?.uniqueId));

      if (response.url) {
        window.open(response.url, '_blank');
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const getInitialValues = () => {
    const initialValues = { ...currentStepFormData };
    return initialValues;
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchSettings());
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <AgreementFlowFormLayout
        {...rest}
        onSubmit={handleSubmit}
        title={AGREEMENT_FLOW_STRINGS['sign.title']}
        initialValues={getInitialValues()}
        ctaProps={{
          label: AGREEMENT_FLOW_STRINGS['sign.CTA'],
          className: 'p-sm',
        }}
        getForm={() => (
          <div className={cx('form-container')}>
            <div className="hidden sm:block">
              <div className="relative">
                <Text variant="h3" fontWeight="semibold">
                  {AGREEMENT_FLOW_STRINGS['sign.summary.title']}
                </Text>
                <Image
                  alt="green-marker"
                  className="h-auto w-[200px] absolute top-sm left-[106px] z-[-1]"
                  src="agreement-flow/underline-green.svg"
                />
              </div>
              <div className="mb-lg mt-xs max-w-[670px]">
                <AgreementSummary />
              </div>
            </div>
            {!!addendums?.length && (
              <div className="mb-sm mt-2xl flex">
                <TabBar
                  name="aggrement-tabbar"
                  items={tabItems}
                  onSelect={handleSelectedTab}
                  selected={selectedTab.key}
                />
              </div>
            )}

            <div className={cx('agreement-container')}>
              {selectedTab.key === tabItems[0].key && (
                <AgreementRenderer
                  agreement={currentStepFormData?.documentInstance?.html}
                  setTip={setSidebarTip}
                  openTipModal={openTipModal}
                />
              )}

              {selectedTab.key === tabItems[1].key && (
                <div className="flex">
                  {addendums?.map((addendum) => (
                    <DocumentCard
                      bordered={false}
                      size={DocumentCard.SIZES.SMALL}
                      key={addendum.url}
                      onClick={() => {
                        setCurrentPdf(addendum.url);
                        setModalOpen();
                      }}
                      label={<Text>{addendum.documentType}</Text>}
                      type={addendum.mediaType}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className={cx('download-container')}>
              <Button
                onClick={handleDownloadClick}
                buttonType={BUTTON_TYPES.TEXT}
                label={AGREEMENT_FLOW_STRINGS[`sign.download${addendums?.length ? '.addendums' : ''}`]}
              />
            </div>
            <div className={cx('sign-checkbox-container', addendums?.length && 'sign-checkbox-container-addendum')}>
              <Field
                name="agreeToElectronicSign"
                component={CheckboxFinalFormAdapter}
                className={clsx(addendums?.length && 'md:max-w-3xl -mt-2xs')}
                label={AGREEMENT_FLOW_STRINGS[`sign.checkbox${addendums?.length ? '.addendums' : ''}`]}
                validate={requireTrue}
                firstSection
              />
            </div>
          </div>
        )}
      />
      {isClientReady && (
        <Suspense fallback={<div>Loading...</div>}>
          <PdfModal
            show={isModalOpen}
            onHide={() => {
              setModalClose();

              setCurrentPdf(null);
            }}
            file={currentPdf}
          />
        </Suspense>
      )}
    </>
  );
};

Sign.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentStepFormData: PropTypes.object.isRequired,
  fetchAgreementPdfUrlAction: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSidebarTip: PropTypes.func.isRequired,
  openTipModal: PropTypes.func.isRequired,
};

Sign.defaultProps = {};

export default Sign;
