import { PostInspectionFlowStep } from '../../../models/enums';
import { BASE_PATHS } from '../../../routes/paths';
import { getStepByName } from '../step.consts';
import Approval from './Improvements/Approval/Approval';
import Features from './Improvements/Features/Features';
import Intro from './Improvements/Intro/Intro';
import Quality from './Improvements/Quality/Quality';
import Responsiblity from './Improvements/Responsiblity/Responsiblity';
import CheckInPrice from './Pricing/CheckInPrice/CheckInPrice';
import CurrentSupply from './Pricing/CurrentSupply/CurrentSupply';
import GetStarted from './Pricing/GetStarted/GetStarted';
import InitialListing from './Pricing/InitialListing/InitialListing';
import { Seasonality } from './Pricing/Seasonality/seasonality';
import TheMarket from './Pricing/TheMarket/TheMarket';
import WaysToShowYourHome from './Pricing/WaysToShowYourHome/WaysToShowYourHome';

export const getStepPath = (stepName) => {
  const stepConfig = getStepByName(stepName);
  return `${BASE_PATHS.POST_INSPECTION_FLOW}/${stepConfig.group.key}/${stepConfig.key}`;
};

export const getStepPathFromConfig = (stepConfig) => {
  return `${getStepPath(stepConfig.name)}/${stepConfig.id}`;
};

export const POST_INSPECTION_STEPS_ROUTES = [
  {
    path: getStepPath(PostInspectionFlowStep.ImprovementsGetStarted),
    content: Intro,
  },
  {
    path: getStepPath(PostInspectionFlowStep.ImprovementsFeatures),
    content: Features,
  },
  {
    path: getStepPath(PostInspectionFlowStep.ImprovementsResponsibility),
    content: Responsiblity,
  },
  {
    path: getStepPath(PostInspectionFlowStep.ImprovementsQuality),
    content: Quality,
  },
  {
    path: getStepPath(PostInspectionFlowStep.ImprovementsApproval),
    content: Approval,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingGetStarted),
    content: GetStarted,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingSeasonality),
    content: Seasonality,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingWaysToShowYourHome),
    content: WaysToShowYourHome,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingSupplyAndDemand),
    content: TheMarket,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingRelatedListings),
    content: CurrentSupply,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingInitialListingPrice),
    content: InitialListing,
  },
  {
    path: getStepPath(PostInspectionFlowStep.PricingCheckInPrice),
    content: CheckInPrice,
  },
  // TODO: This step is gone, keeping just in case we are referencing it somewhere, but it will never display.
  {
    path: getStepPath(PostInspectionFlowStep.PricingSmoothPayment),
    content: () => null,
  },
];
