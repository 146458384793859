import { compact, filter } from 'lodash';
import { ACTIONS as USER_ACTIONS } from '../user/constants';
import { ACTIONS } from './constants';

export const RESIDENT_APPLICATION_REDUCER = 'residentApplication';

const initialState = {
  existingApplications: [],
  moveInDate: {},
  applyForHouseId: '',
  applicationId: '',
  applicantInfo: {},
  incomeSources: {},
  cosigners: {},
  incomeVerification: {},
  referenceCheck: {},
  selfServeDetails: null,
  mustCallCompleteEndpoint: false,
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FETCH_ALL_RESIDENT_APPLICATIONS_SUCCESS: {
      const applications = [...action.result];
      const filteredApplications = filter(applications, (application) => {
        if (!application.applicants?.mainApplicant) {
          return false;
        }
        const applicants = compact([...application.applicants.coApplicants, application.applicants.mainApplicant]);
        return applicants.length > 0;
      });
      return {
        ...state,
        existingApplications: filteredApplications,
      };
    }
    case ACTIONS.UPDATE_APPLICATION_ID_APP_STATE:
      return {
        applyForHouseId: state.applyForHouseId,
        applicationId: action.applicationId,
        existingApplications: [...state.existingApplications],
      };
    case ACTIONS.FETCH_MOVE_IN_DATE_SUCCESS:
      return {
        ...state,
        moveInDate: { ...action.result },
      };
    case ACTIONS.UPDATE_APPLY_FOR_UNIT_ID:
      return {
        ...state,
        applyForHouseId: action.houseId,
      };
    case ACTIONS.CREATE_MOVE_IN_DATE_SUCCESS:
      return {
        ...state,
        applicationId: action.result.applicationId,
      };
    case ACTIONS.FETCH_APPLICANT_INFO_SUCCESS:
      return {
        ...state,
        applicantInfo: { ...action.result },
      };
    case ACTIONS.FETCH_APPLICANT_INCOME_VERIFICATION_SUCCESS:
      return {
        ...state,
        incomeVerification: { ...action.result },
      };
    case ACTIONS.UPDATE_INCOME_VERIFICATION_APP_STATE:
      return {
        ...state,
        incomeVerification: { ...action.incomeVerification },
      };

    case ACTIONS.UPDATE_EMPLOYMENT_INFORMATION_SUCCESS:
    case ACTIONS.FETCH_EMPLOYMENT_INFORMATION_SUCCESS:
      return {
        ...state,
        incomeSources: { ...action.result },
      };

    case ACTIONS.UPDATE_COSIGNER_INFORMATION_SUCCESS:
    case ACTIONS.FETCH_COSIGNER_INFORMATION_SUCCESS:
      return {
        ...state,
        cosigners: { ...action.result },
      };

    case ACTIONS.FETCH_APPLICANT_REFERENCE_INFO_SUCCESS:
      return {
        ...state,
        referenceCheck: { ...action.result },
      };
    case ACTIONS.UPDATE_MUST_CALL_COMPLETE_ENDPOINT:
      return {
        ...state,
        mustCallCompleteEndpoint: action.mustCallCompleteEndpoint,
      };
    case ACTIONS.FETCH_APPLICATION_SELF_SERVE_DETAILS_SUCCESS:
      return {
        ...state,
        selfServeDetails: action.result,
      };
    case USER_ACTIONS.USER_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
